import * as turf from "@turf/turf"

export function sanitizeData(data) {
    if (!data) {
        return null
    }

    const dataFeatures = []
    turf.geomEach(data, (currentGeometry, featureIndex, featureProperties, featureBBox, featureId) => {
        const feature = data.features[featureIndex]
        dataFeatures.push({
            bbox: featureBBox,
            geometry: currentGeometry,
            id: featureId,
            properties: {
                ...featureProperties,
                linearLocation: feature.linearLocation,
                uuid: featureId,
            },
        })
    })

    return turf.featureCollection(dataFeatures)
}