import { createTheme } from "@mui/material/styles"

import { themeFromJson } from "./utils/formatThemeFromJson"

export const logiroadBlack = "#000033"
export const logiroadBlue = "#103563"
export const logiroadLightBlue = "#317EDE"
export const logiroadNightBlue = "#051D3B"
export const logiroadGreen = "#219653"
export const logiroadGrey = "#9D9FB1"
export const logiroadGrey2 = "#E3E3E8"
export const logiroadSemiGrey = "#D9D9D9"
export const logiroadLightGrey = "#F3F5FA"
export const logiroadLightGrey2 = "#F1F1F1"

export const white = "#fff"
export const black = "#000"

const customTheme = createTheme({
    backgroundBlack: logiroadBlack,
    incidentDefaultColor: logiroadLightBlue,
    incidentTriggerRule4: "#9B51E0",
    incidentTriggerRule5: "#F2994A",
    incidentTriggerRule6: "#2F80ED",
    errorBackground: logiroadLightGrey,
    palette: {
        map: {
            primary: logiroadLightBlue,
            disabled: logiroadGrey,
            darkReferential: "#333333",
            lightReferential: "#FFFFFF",
            text: white,
        },
    },
    typography: {
        fontFamily: "Poppins",
    },
    components: {
        icons: {
            defaultHeight: 24,
            defaultWidth: 24,
        },
        chip: {
            defaultHeight: 32,
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                    height: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: "Poppins",
                },
            },
        },
    },
    spacing: 4,
})

export const theme = {
    ...customTheme,
    palette: {
        ...customTheme.palette,
        ...themeFromJson.palette,
    },
    shadows: [
        ...customTheme.shadows,
        "0px 4px 15px rgba(132,131,131,0.5)",
        "0px 6px 15px rgba(132,131,131,0.3)",
        "0px 2px 1px rgba(0, 0, 0, 0.1)",
        "0px 1px 3px rgba(0, 0, 0, 0.3)",
        ...themeFromJson.boxshadows,
    ],
    typography: {
        ...customTheme.typography,
        ...themeFromJson.typography,
    },
}

export const globalStyles = {
    "input::-ms-reveal, input::-ms-clear": {
        display: "none",
    },
}
