import { useMemo } from "react"

import {
    useGeoServerFeaturesList,
} from "@l2r-front/l2r-geodata"

import { useRoadwayStateContext } from "../../../contexts/RoadwayContext"
import { useNetworkConditionForYearlyStatus } from "../networkConditions/useNetworkConditionForYearlyStatus"
import { networkDamagesQueryKeys } from "../networkGradings/queryKeys"

export function useDamagesStatsOnSegments(road, absStart = null, absEnd = null) {
    const { selectedNetworkYearlyStatus } = useRoadwayStateContext()

    const {
        networkCondition,
        isLoading: isLoadingNetworkCondition,
        isError: isErrorNetworkCondition,
    } = useNetworkConditionForYearlyStatus(selectedNetworkYearlyStatus)

    const queryParams = {
        layer: networkCondition?.layer,
        linear_location_road: road,
        ...(absStart && { linear_location_start: absStart }),
        ...(absEnd && { linear_location_end: absEnd }),
    }
    const {
        data: selectedNetworkDamagesFeatures,
        isLoading: isLoadingDamagesFeatures,
        isError: isErrorDamagesFeatures,
    } = useGeoServerFeaturesList(
        networkDamagesQueryKeys.list(queryParams),
        queryParams,
        {
            enabled: !!(road && !!networkCondition?.layer),
        })

    const isLoading = isLoadingNetworkCondition || (isLoadingDamagesFeatures && !!networkCondition?.layer)
    const isError = isErrorNetworkCondition || isErrorDamagesFeatures

    const selectedNetworkDamagesStats = useMemo(() => {

        if (!selectedNetworkDamagesFeatures?.length || isLoading || isError) {
            return null
        }

        const damagesLinearLocations = selectedNetworkDamagesFeatures.map((damageFeature) => {
            return {
                damageType: damageFeature.data.damageType,
                start: damageFeature.linearLocations[0].start,
                end: damageFeature.linearLocations[0].end,
            }
        })

        const uniqueDamagesStats = {}

        damagesLinearLocations.forEach((damageLinearLocation) => {
            const damageStat = Math.abs(damageLinearLocation.end - damageLinearLocation.start)
            if (damageLinearLocation.damageType in uniqueDamagesStats) {
                uniqueDamagesStats[damageLinearLocation.damageType].damageStat += damageStat
            } else {
                uniqueDamagesStats[damageLinearLocation.damageType] = { damageCode: damageLinearLocation.damageType, damageStat }
            }
        })

        return Object.values(uniqueDamagesStats)
    }, [
        selectedNetworkDamagesFeatures,
        isLoading,
        isError])

    return { data: selectedNetworkDamagesStats, isLoading, isError }
}