import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"
import { getContrastTextColor } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { impacts } from "../../constants/impacts"
import * as Styled from "./TaskImpactBadge.styled"

export function TaskImpactBadge(props) {

    const {
        className,
        impact,
        variant,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const theme = useTheme()

    const mainColor = impacts[impact].backgroundColor

    const badgeConfig = useMemo(() => {
        const constrastMainColor = getContrastTextColor(impacts[impact].backgroundColor)
        return ({
            ...otherProps,
            ...impacts[impact],
            backgroundColor: variant === "filled" ? mainColor : theme.palette.common.white,
            text: t(I18N_NAMESPACE, impacts[impact].acronyms),
            textColor: variant === "filled" ? constrastMainColor : mainColor,
        })
    }, [impact, mainColor, otherProps, t, theme, variant])

    return <Styled.ColoredBadge
        {...badgeConfig}
        borderColor={mainColor}
        className={className} textVariant="H3"
    />
}

TaskImpactBadge.defaultProps = {
    variant: "filled",
}

TaskImpactBadge.propTypes = {
    impact: PropTypes.number.isRequired,
    variant: PropTypes.oneOf(["filled", "outlined"]),
}