import { arrayOf, bool, number, oneOf, shape, string } from "prop-types"

import { linearLocation } from "../linearLocation"
import { timestamp } from "../timestamp"
import { uuid } from "../uuid"
import { task } from "./task"
import { technique } from "./technique"

export const roadwork = shape({
    createdAt: timestamp.isRequired,
    doneAt: timestamp,
    doneBy: string,
    isLogiroadRecommandation: bool,
    linearLocation: linearLocation.isRequired,
    status: oneOf([50, 100]),
    tasks: arrayOf(uuid.isRequired).isRequired,
    technique: technique.isRequired,
    updatedAt: timestamp.isRequired,
    uuid: uuid.isRequired,
    year: number.isRequired,
})

export const roadworkWithTasks = shape({
    createdAt: timestamp.isRequired,
    doneAt: timestamp,
    doneBy: string,
    isLogiroadRecommandation: bool,
    linearLocation: linearLocation.isRequired,
    status: oneOf(["TODO", "DONE"]),
    tasks: arrayOf(task.isRequired).isRequired,
    technique: technique.isRequired,
    updatedAt: timestamp.isRequired,
    uuid: uuid.isRequired,
    year: number.isRequired,
})