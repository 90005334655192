import { useState, useMemo } from "react"

import { FilterListRoundedIcon } from "@l2r-front/l2r-icons"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { RoadDetailInfo } from "../../../../common/containers/RoadDetailInfo"
import { RoadMultiYearGrading } from "../../../roadway/containers/RoadMultiYearGrading"
import { TECHNIQUES_FILTER, YEARS_FILTER } from "../../constants/filters"
import { RoadRoadworksList } from "../RoadRoadworksList"
import { TechniquesFilterList } from "../TechniquesFilterList"
import * as Styled from "./RoadworksRoadSidebarContent.styled"

export function RoadworksRoadSidebarContent(props) {

    const { className } = props

    const [displayFilters, setDisplayFilters] = useState(false)
    const isMobile = useIsMobileDevice()

    const closeFilters = () => setDisplayFilters(false)
    const toggleFilter = () => setDisplayFilters(displayFilters => !displayFilters)

    const enabledFilters = useMemo(() => [TECHNIQUES_FILTER, YEARS_FILTER], [])

    const DesktopFilterComponent = useMemo(() => <Styled.DisplayedFiltersWrapper>
        <TechniquesFilterList enabledFilters={enabledFilters} />
        <Styled.IconButton onClick={toggleFilter} id="filters-button">
            <FilterListRoundedIcon color="cta-bg/cta-bg-primary" />
        </Styled.IconButton>
    </Styled.DisplayedFiltersWrapper>, [enabledFilters])

    return (
        <>
            {isMobile && <Styled.TechniquesFilters
                enabledFilters={enabledFilters}
                close={closeFilters}
                displayFilters={displayFilters}
                isMobile={isMobile}
            />}
            <Styled.BackdropFilter
                id="backdrop-filters"
                isMobile={isMobile}
                onClick={toggleFilter}
                open={displayFilters}
            />

            <Styled.Header isMobile={isMobile}>
                <RoadMultiYearGrading />
                <RoadDetailInfo />
            </Styled.Header>
            <Styled.Container className={className} id="sidebar-content-container" isMobile={isMobile}>
                {!isMobile ? DesktopFilterComponent
                    : <Styled.MobileFilterButton enabledFilters={enabledFilters} onClick={toggleFilter} />}
                <Styled.Wrapper>
                    {!isMobile && <Styled.TechniquesFilters enabledFilters={enabledFilters} close={closeFilters} displayFilters={displayFilters} />}
                </Styled.Wrapper>
                <RoadRoadworksList />
            </Styled.Container>
        </>
    )
}