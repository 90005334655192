import { useEffect, useMemo } from "react"

import * as turf from "@turf/turf"
import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useMapContext, useMapDispatchContext } from "@l2r-front/l2r-map"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useEventLinestringQuery } from "../../hooks/queries/events/useEventLinestring"

export const MapAdapterIncident = () => {

    const { eventId } = useParams()
    const { t } = useTranslation(I18N_NAMESPACE)
    const [mapState, mapDispatch] = useMapContext()
    const { setError: setMapError } = mapDispatch
    const { mapRef } = mapState
    const { data } = useEventLinestringQuery(eventId, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.incidentDetailsLayer.error"),
        onError: () => setMapError(true),
    })
    const { setMapBoundingBoxToFit } = useMapDispatchContext()
    const linestringBoundingBox = useMemo(() => {
        if (!data) {
            return null
        }

        const [minLng, minLat, maxLng, maxLat] = turf.bbox(data.linestring)
        return ({
            minLng,
            minLat,
            maxLng,
            maxLat,
        })
    }, [data])

    useEffect(() => {
        if (linestringBoundingBox && mapRef) {
            setMapBoundingBoxToFit(linestringBoundingBox)
        }
    }, [mapRef, linestringBoundingBox, setMapBoundingBoxToFit])

    return null
}