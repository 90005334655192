export const roadworksMulhouse = [
    {
        "uuid": "60aa60d3-90bb-4c78-b061-795a4b331234",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2023,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "BOULEVARD DES ALLIES",
            "start": 200,
            "end": 310,
        },
        "technique": {
            "code": "PONTA",
            "cost": "13024",
            "impact": 10,
        },
        "tasks": [
            "8b8ece98-9c9a-4626-8c5f-81d0f8b112f7",
        ],
        "isLogiroadRecommandation": true,
        "feature": "60aa60d3-90bb-4c78-b061-795a4b331234",
    },
    {
        "uuid": "60aa60d3-90bb-4c78-b061-795a4b335364",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2023,
        "status": 100,
        "doneAt": "2023-08-24T14:15:22Z",
        "doneBy": "John Doe",
        "linearLocation": {
            "typeid": 1,
            "road": "BOULEVARD DES ALLIES",
            "start": 200,
            "end": 310,
        },
        "technique": {
            "code": "PONTA",
            "cost": "1817.76",
            "impact": 10,
        },
        "tasks": [
            "8b8ece98-9c9a-4626-8c5f-81d0f8b112f7",
        ],
        "isLogiroadRecommandation": true,
        "feature": "60aa60d3-90bb-4c78-b061-795a4b335364",
    },
    {
        "uuid": "4578e84f-c58c-4c7a-a375-a8dff3719d98",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2023,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "BOULEVARD DES ALLIES",
            "start": 205,
            "end": 305,
        },
        "technique": {
            "code": "PLR1",
            "cost": "28399.14",
            "impact": 30,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
        ],
        "isLogiroadRecommandation": true,
        "feature": "4578e84f-c58c-4c7a-a375-a8dff3719d98",
    },
    {
        "uuid": "224ac500-d009-4f2e-b076-baed33af3244",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2023,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "BOULEVARD DES ALLIES",
            "start": 210,
            "end": 300,
        },
        "technique": {
            "code": "PLR2",
            "cost": "2380.96",
            "impact": 30,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
        ],
        "isLogiroadRecommandation": true,
        "feature": "224ac500-d009-4f2e-b076-baed33af3244",
    },
    {
        "uuid": "52bcc7b8-6944-4b96-bb6f-3942ff3b08e1",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2023,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "BOULEVARD DES ALLIES",
            "start": 230,
            "end": 280,
        },
        "technique": {
            "code": "PLS1",
            "cost": "6261.42",
            "impact": 40,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
        ],
        "isLogiroadRecommandation": true,
        "feature": "52bcc7b8-6944-4b96-bb6f-3942ff3b08e1",
    },
    {
        "uuid": "52a58cf0-b644-4363-ba8d-df1a74c68314",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2023,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "BOULEVARD DES ALLIES",
            "start": 202,
            "end": 308,
        },
        "technique": {
            "code": "PL1",
            "cost": "6261.42",
            "impact": 20,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
        ],
        "isLogiroadRecommandation": true,
        "feature": "52a58cf0-b644-4363-ba8d-df1a74c68314",
    },
    {
        "uuid": "a33c2cdb-48b7-4e43-ba7b-0957f2578123",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2024,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "BOULEVARD DES ALLIES",
            "start": 220,
            "end": 290,
        },
        "technique": {
            "code": "PLR1",
            "cost": "28518.0",
            "impact": 30,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
        ],
        "isLogiroadRecommandation": true,
        "feature": "a33c2cdb-48b7-4e43-ba7b-0957f2578123",
    },
    {
        "uuid": "b0a73ec1-d286-4b0b-81a9-421327df336b",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2024,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "RUE LEFEBVRE",
            "start": 950,
            "end": 1000,
        },
        "technique": {
            "code": "PONTA",
            "cost": "1817.76",
            "impact": 10,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
        ],
        "isLogiroadRecommandation": true,
        "feature": "b0a73ec1-d286-4b0b-81a9-421327df336b",
    },
    {
        "uuid": "3a648b38-4be4-48af-952e-dcbee19f12d3",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2025,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "BOULEVARD DES ALLIES",
            "start": 240,
            "end": 300,
        },
        "technique": {
            "code": "PLR1",
            "cost": "11956.0",
            "impact": 30,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
        ],
        "isLogiroadRecommandation": true,
        "feature": "3a648b38-4be4-48af-952e-dcbee19f12d3",
    },
    {
        "uuid": "1fb8f896-c24c-4bf7-80d7-864bdb010268",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2025,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "RUE LEFEBVRE",
            "start": 300,
            "end": 330,
        },
        "technique": {
            "code": "PL1",
            "cost": "156.0",
            "impact": 20,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
        ],
        "isLogiroadRecommandation": true,
        "feature": "1fb8f896-c24c-4bf7-80d7-864bdb010268",
    },
    {
        "uuid": "63172b44-5ffb-4972-be33-5ff52f07e12e",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2025,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "RUE LEFEBVRE",
            "start": 980,
            "end": 1000,
        },
        "technique": {
            "code": "PL1",
            "cost": "1565.0",
            "impact": 20,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
        ],
        "isLogiroadRecommandation": true,
        "feature": "63172b44-5ffb-4972-be33-5ff52f07e12e",
    },
    {
        "uuid": "79a002d8-57ef-4454-be48-8bb60a572050",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2026,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "RUE LEFEBVRE",
            "start": 600,
            "end": 650,
        },
        "technique": {
            "code": "PLR2_PLS1",
            "cost": "458.0",
            "impact": 30,
        },
        "tasks": [
            "1aa4b179-9990-4e29-a531-049748dd94c8",
            "095be615-a8ad-4c33-8e9c-c7612fbf6c9f",
        ],
        "isLogiroadRecommandation": true,
        "feature": "79a002d8-57ef-4454-be48-8bb60a572050",
    },
    {
        "uuid": "af83ebfc-0c51-4a51-9992-b8cea9612bb6",
        "createdAt": "2019-08-24T14:15:22Z",
        "updatedAt": "2019-08-24T14:15:22Z",
        "year": 2026,
        "status": 50,
        "doneAt": null,
        "doneBy": null,
        "linearLocation": {
            "typeid": 1,
            "road": "RUE LEFEBVRE",
            "start": 1100,
            "end": 1120,
        },
        "technique": {
            "code": "PLS1",
            "cost": "1458.0",
            "impact": 40,
        },
        "tasks": [
            "8a2f3d4c-66e3-4ccc-831f-acaf715bcf53",
            "b5254c13-03e1-486e-ac1a-8ba7098feaa9",
        ],
        "isLogiroadRecommandation": true,
        "feature": "af83ebfc-0c51-4a51-9992-b8cea9612bb6",
    },
]

export const allRoadworks = {
    mulhouse: roadworksMulhouse,
}

export const getRoadwork = (networkSlug, roadworkUuid) => {
    const networkRoadworks = allRoadworks[networkSlug] || []
    return networkRoadworks.find(roadwork => (roadwork.uuid === roadworkUuid))
}

export const getRoadworkById = (roadworkUuid) => {
    return Object.values(allRoadworks).flat().find(roadwork => (roadwork.uuid === roadworkUuid))
}

function applyFilters(filtersParams, initialData) {
    return filtersParams.reduce((acc, filter) => {
        if (filter.filterKey === "year") {
            return acc.filter(roadwork => {
                return filter.filterValue.some(value => roadwork.year === parseInt(value))
            })
        }
        if (filter.filterKey === "technique_code") {
            return acc.filter(roadwork => {
                return filter.filterValue.some(value => roadwork.technique?.code === value)
            })
        }
        if (filter.filterKey === "status") {
            return acc.filter(roadwork => {
                return filter.filterValue.some(value => roadwork.status === value)
            })
        }
        return []
    }, initialData)
}

export const getRoadworks = (networkSlug, road = null, filterParams = null) => {
    const networkRoadworks = (allRoadworks[networkSlug] || []).filter(roadwork => (!road || roadwork.linearLocation.road === road))

    if (filterParams) {
        return applyFilters(filterParams, networkRoadworks)
    } else {
        return networkRoadworks
    }
}