import { allImageCollections } from "./image_collections"
import { allNetworkConditions } from "./network_conditions"
import { allNetworkGradings } from "./network_gradings"
import { allNetworkRoadworks } from "./network_roadworks"
import { allReferentials } from "./referentials"

const allLayers = {
    ...allReferentials,
    ...allNetworkGradings,
    ...allNetworkConditions,
    ...allImageCollections,
    ...allNetworkRoadworks,
}

const mapToFeatureItem = (feature) => {
    const {
        layer,
        ...data
    } = feature.properties
    return {
        uuid: feature.id,
        layer: layer,
        data: data,
        geometry: feature.geometry,
        linearLocations: feature.linearLocation,
    }
}

export const getFeatures = (layerUuid, road = null, absStart = null, absEnd = null, asGeoJSON = true) => {
    const layer = allLayers[layerUuid]
    if (road) {
        const roadFeatures = layer.features.filter(feature => {
            return feature.linearLocation.findIndex(
                loc => loc.road === road
                    && (!absStart || loc.start === absStart)
                    && (!absEnd || loc.end === absEnd)) >= 0
        })
        if (asGeoJSON) {
            return {
                "type": "FeatureCollection",
                "features": roadFeatures,
            }
        } else {
            return roadFeatures.map(feature => mapToFeatureItem(feature))
        }
    } else {
        if (asGeoJSON) {
            return layer
        } else {
            return layer.features.map(feature => mapToFeatureItem(feature))
        }
    }
}

export const getFeature = (featureId, asGeoJSON = false) => {
    for (let layer of Object.values(allLayers)) {
        const feature = layer.features.find(f => f.id === featureId)
        if (feature) {
            if (asGeoJSON) {
                return feature
            } else {
                return mapToFeatureItem(feature)
            }
        }
    }

    return null
}