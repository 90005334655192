import { Skeleton as UiSkeleton, styled, styledForMobile, Typography as UiTypography, Link as UiLink } from "@l2r-front/l2r-ui"

import { TaskImpactBadge as L2RTaskImpactBadge } from "../TaskImpactBadge"

export const RoadworkTaskWrapper = styled("div")(({ theme }) => ({
    position: "relative",
    marginBottom: theme.spacing(2),
    width: "100%",
    padding: theme.spacing(4),

    "&:last-child": {
        marginBottom: theme.spacing(0),
    },
    "& > div > #task-overlay": {
        opacity: 0,
    },
    "&:hover": {
        "& > div > #task-overlay": {
            opacity: 1,
        },
    },
}))

export const RoadworkTaskInfo = styledForMobile("div")(({ theme, isMobile }) => ({
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    marginRight: isMobile ? theme.spacing(-4) : 0,
}))

export const LeftWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    marginRight: "auto",
})

export const RightWrapper = styled("div")(() => ({
    display: "flex",
    position: "relative",
    alignItems: "center",
    minHeight: 48,
}))

export const Text = styledForMobile(UiTypography)(({ isMobile }) => ({
    textAlign: isMobile ? "left" : "right",
    textWrap: "nowrap",
}))

export const Quantity = styled(Text)(({ theme }) => ({
    color: theme.palette["texts/text-disabled"].main,
    textWrap: "nowrap",
}))

export const TaskImpactBadge = styled(L2RTaskImpactBadge, { shouldForwardProp: (prop) => prop !== "hidden" })(({ theme, hidden }) => ({
    opacity: hidden ? 0 : 1,
    height: "fit-content",
    marginLeft: theme.spacing(6),
}))

export const TextWrapper = styled("div", { shouldForwardProp: (prop) => prop !== "hidden" })(({ hidden }) => ({
    opacity: hidden ? 0 : 1,
    display: "flex",
    flexDirection: "column",
}))

export const MenuIconContainer = styled("div")({
    height: "100%",
})

export const Comments = styled("div")(({ theme }) => ({
    position: "relative",
    zIndex: 1,
    marginTop: theme.spacing(2),
}))

export const Skeleton = styled(UiSkeleton)({
    height: 48,
    transform: "scale(1)",
    width: "100%",
})

export const ErrorText = styled(UiTypography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
}))

export const Overlay = styled("div")(({ theme }) => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    transition: ".2s ease",
    backgroundImage: `linear-gradient(to right, color-mix(in srgb, ${theme.palette["cta-bg/cta-bg-primary"].main}, transparent 100%), color-mix(in srgb, ${theme.palette["cta-bg/cta-bg-primary"].main}, transparent 80%))`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
}))

export const OverlayButtonsContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(4),
}))

export const Link = styled(UiLink)(({ theme }) => ({
    color: theme.palette["cta-bg/cta-bg-primary"].main,
    textDecorationColor: theme.palette["cta-bg/cta-bg-primary"].main,
}))