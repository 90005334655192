import {
    styled,
    styledForMobile,
    Typography,
} from "@l2r-front/l2r-ui"

import { RoadDetailInfo } from "../../../../common/containers/RoadDetailInfo"
import { RoadworkDetailsFormPost } from "../../forms/RoadworkDetailsForm"

export const Container = styledForMobile("div")(({ isMobile, theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
    marginBottom: !isMobile && theme.spacing(-5),
}))

export const SegmentDetailInfo = styled(RoadDetailInfo)(({ isMobile, theme }) => ({
    margin: 0,
    padding: isMobile ? theme.spacing(0, 4) : theme.spacing(0, 8),
}))

export const NetworkGradingSelectorDescription = styled(Typography)(({ theme }) => ({
    display: "block",
    marginTop: theme.spacing(5),
    width: "100%",
}))

export const RoadworkDetailsForm = styled(RoadworkDetailsFormPost)(() => ({
    flex: 1,
}))

export const Header = styledForMobile("div")(({ theme, isMobile }) => ({
    paddingBottom: isMobile ? theme.spacing(6) : theme.spacing(6),
}))