import { LinearProgress, styled } from "@l2r-front/l2r-ui"

import { StyleSelector as L2rStyleSelector } from "../../containers/StyleSelector"

export const Loader = styled(LinearProgress)(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    width: "100%",
    "&[hidden]": {
        visibility: "hidden",
    },
}))

export const StyleSelector = styled(L2rStyleSelector)({
    position: "absolute",
    left: 10,
    bottom: 50,
    width: 90,
    height: 50,
    zIndex: 3,
})

export const Wrapper = styled("div")(({ scaleColor, theme }) => ({
    position: "relative",

    ".mapboxgl-ctrl-scale": {
        backgroundColor: "transparent",
        borderColor: theme.palette[scaleColor].main,
        color: theme.palette[scaleColor].main,
    },
    ".mapboxgl-ctrl-group": {
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        display: "flex",
        flexDirection: "row-reverse",

        ".mapboxgl-ctrl-zoom-in": {
            margin: theme.spacing(0, 2),
            ".mapboxgl-ctrl-icon": {
                backgroundImage: "url('assets/resources/Plus.svg')",
            },
        },

        ".mapboxgl-ctrl-zoom-out": {
            ".mapboxgl-ctrl-icon": {
                backgroundImage: "url('assets/resources/Moins.svg')",
            },
        },

        "& > button": {
            backgroundColor: theme.palette["surfaces/surface-primary"].main,
            borderRadius: theme.spacing(1),
            height: theme.spacing(8),
            width: theme.spacing(8),

            "&:hover": {
                backgroundColor: "#E5E5E6",
            },
        },
    },
}))
