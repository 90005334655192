import { useMemo, useCallback } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { CheckCircleIcon, PlaylistPlayRoundedIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, Typography } from "@l2r-front/l2r-ui"
import { formatMetersToDistance } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { formatNumber } from "../../utils/formatting"
import * as Styled from "./TechniqueItemCard.styled"

export function TechniqueItemCard(props) {

    const {
        currency,
        doneAt,
        handleClick,
        handleComplete,
        handleGoToDetails,
        technique,
        isSelected,
        isDisabled,
        ...otherProps
    } = props

    const formattedTechnique = useMemo(() => {
        return ({
            ...technique,
            cost: formatNumber(technique.cost),
            impact: technique.impact,
            length: technique.linearLocation ? formatMetersToDistance(Math.abs(technique.linearLocation.end - technique.linearLocation.start)) : null,
        })
    }, [technique])

    const {
        acronym,
        cost,
        impact,
        length,
        year,
    } = formattedTechnique

    const { t } = useTranslation(I18N_NAMESPACE)

    const handleDetailsButtonClick = useCallback(e => {
        e.stopPropagation()
        handleGoToDetails()
    }, [handleGoToDetails])

    const handleCompleteButtonClick = useCallback(e => {
        e.stopPropagation()
        handleComplete()
    }, [handleComplete])

    const displayButtonsZone = handleGoToDetails || handleComplete

    return <Styled.Card
        role="button"
        elevation={0}
        {...otherProps}
        onClick={handleClick}
        disabled={isDisabled}
        selected={isSelected}>
        <Styled.BadgeWrapper>
            {doneAt ? <Styled.DoneAtWrapper>
                <CheckCircleIcon color="objects/object-primary" />
                <Typography variant="H3">{doneAt}</Typography>
            </Styled.DoneAtWrapper> :
                <Typography variant="Regular">{year}</Typography>
            }
            <Styled.ColoredBadge id={"impact-badge"} impact={impact} />
        </Styled.BadgeWrapper>
        <Styled.Element>
            <Typography variant="H3">{acronym}</Typography>
        </Styled.Element>
        {length && <Styled.Element>
            <Styled.TextWrapper>
                <Typography variant="H3">{t(I18N_NAMESPACE, "components.techniqueCardItem.length")}</Typography>
                <Typography variant="Regular">{length}</Typography>
            </Styled.TextWrapper>
        </Styled.Element>}
        <Styled.Element>
            <Styled.TextWrapper>
                <Typography variant="H3">{t(I18N_NAMESPACE, "components.techniqueCardItem.cost")}</Typography>
                <Typography variant="Regular">{cost} {currency}</Typography>
            </Styled.TextWrapper>
        </Styled.Element>
        {displayButtonsZone && <Styled.Element>
            <Styled.ButtonsWrapper selected={isSelected}>
                {handleGoToDetails && <Styled.DetailButton
                    id="detail-button"
                    variant="outlined"
                    color="cta-bg/cta-bg-primary"
                    onClick={handleDetailsButtonClick}>
                    <PlaylistPlayRoundedIcon />
                </Styled.DetailButton>
                }
                {handleComplete && !isDisabled && <CTAButton
                    id="close-button"
                    variant="outlined"
                    onClick={handleCompleteButtonClick}>
                    {t(I18N_NAMESPACE, "components.techniqueCardItem.closeButton.label")}
                </CTAButton>
                }
            </Styled.ButtonsWrapper>
        </Styled.Element>}
    </Styled.Card>
}

TechniqueItemCard.defaultProps = {
    isDisabled: false,
    isSelected: false,
}

TechniqueItemCard.propTypes = {
    currency: PropTypes.string.isRequired,
    doneAt: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    handleComplete: PropTypes.func,
    handleGoToDetails: PropTypes.func,
    isDisabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    technique: PropTypes.technique.isRequired,
}
