export const networkGradingCD91Small2023 = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "6caa6f34-8fc9-4d74-8599-7548f7dcc686",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225015677677535,
                        48.59036507637893,
                    ],
                    [
                        2.225267381442702,
                        48.59039999905972,
                    ],
                    [
                        2.225443567781628,
                        48.59040654313984,
                    ],
                    [
                        2.225621096760776,
                        48.590413995263745,
                    ],
                    [
                        2.225687484151923,
                        48.59040929736768,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 0,
                    "end": 50,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.95,
                "gradeSurface": 2.79,
                "gradeStructure": 6.72,
            },
        },
        {
            "id": "633a764d-2c67-4af3-ad7f-aa3ee8223358",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225687484151923,
                        48.59040929736768,
                    ],
                    [
                        2.226307962941572,
                        48.59036538742608,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 50,
                    "end": 96,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.09,
                "gradeSurface": 3.59,
                "gradeStructure": 4.5,
            },
        },
        {
            "id": "40507c75-69f6-49fe-8fbf-cef28d2ac11a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227016415923118,
                        48.59032411238071,
                    ],
                    [
                        2.227046218994584,
                        48.5903261050659,
                    ],
                    [
                        2.227243834184816,
                        48.59035077703056,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 96,
                    "end": 113,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.29,
                "gradeSurface": 3.8,
                "gradeStructure": 4.68,
            },
        },
        {
            "id": "b6dd417f-6121-4ff3-b9f0-0318a6118eef",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245281025038835,
                        48.58926289245718,
                    ],
                    [
                        2.245443851200478,
                        48.588878909513916,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 113,
                    "end": 157,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.26,
                "gradeSurface": 3.77,
                "gradeStructure": 4.66,
            },
        },
        {
            "id": "63ffaee0-b859-47e0-8a89-0155547ce217",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.240007382242808,
                        48.591051860980855,
                    ],
                    [
                        2.24068807655684,
                        48.591054406162506,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 157,
                    "end": 207,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.18,
                "gradeSurface": 7.5,
                "gradeStructure": 5.1,
            },
        },
        {
            "id": "95c409f5-9cf5-427b-adf7-0bd52d81e918",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24068807655684,
                        48.591054406162506,
                    ],
                    [
                        2.241028423738649,
                        48.591055677293575,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 207,
                    "end": 232,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.84,
                "gradeSurface": 8.74,
                "gradeStructure": 8.92,
            },
        },
        {
            "id": "ed1406f5-0499-4ccd-9dd0-7dbd8652570b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266503486283121,
                        48.59527601958153,
                    ],
                    [
                        2.266605163071593,
                        48.59527844518431,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 232,
                    "end": 240,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 1.38,
                "gradeSurface": 3.06,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "73a04373-f25b-430b-a972-9bfba571f503",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.265178429598887,
                        48.59499348094964,
                    ],
                    [
                        2.265554040651321,
                        48.59509295171955,
                    ],
                    [
                        2.265668873703221,
                        48.595124245276686,
                    ],
                    [
                        2.265808563447852,
                        48.59515827386706,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 240,
                    "end": 290,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.95,
                "gradeSurface": 7.78,
                "gradeStructure": 8.1,
            },
        },
        {
            "id": "77fbf0d9-451e-4bc9-ba73-d1896a36d7c3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.265808563447852,
                        48.59515827386706,
                    ],
                    [
                        2.265899984492156,
                        48.5951805440306,
                    ],
                    [
                        2.266150256571084,
                        48.595224366594614,
                    ],
                    [
                        2.266335628808611,
                        48.595254295222354,
                    ],
                    [
                        2.266503486283121,
                        48.59527601958153,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 290,
                    "end": 343,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 1.69,
                "gradeSurface": 3.75,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "ea66c03c-1777-48a5-864d-ff9aced42631",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252364117327363,
                        48.5891150878087,
                    ],
                    [
                        2.252809846590139,
                        48.58952269217839,
                    ],
                    [
                        2.252947183755751,
                        48.58959102180422,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 343,
                    "end": 412,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.66,
                "gradeSurface": 2.31,
                "gradeStructure": 4.76,
            },
        },
        {
            "id": "896f5977-de0f-4a7d-841d-f937f0607b0e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245647367592548,
                        48.588399829839354,
                    ],
                    [
                        2.245884585655773,
                        48.58784000195692,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 412,
                    "end": 477,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.85,
                "gradeSurface": 2.79,
                "gradeStructure": 4.72,
            },
        },
        {
            "id": "fe7e7efd-cc7b-4d2a-a5a5-cc63d26a652d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260037981848081,
                        48.591970588370955,
                    ],
                    [
                        2.260195750208991,
                        48.592033639020954,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 477,
                    "end": 491,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 2.84,
                "gradeSurface": 1.35,
                "gradeStructure": 4.05,
            },
        },
        {
            "id": "4b8973e5-cd65-459d-bd65-77c6ea8a5cc2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.257958336814422,
                        48.59116602435011,
                    ],
                    [
                        2.258236407759133,
                        48.59125591499803,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 491,
                    "end": 514,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.74,
                "gradeSurface": 4.13,
                "gradeStructure": 7.05,
            },
        },
        {
            "id": "7ef5d145-0488-4c23-8c4a-b5ae694c5233",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.259706264016828,
                        48.59183808883618,
                    ],
                    [
                        2.260037981848081,
                        48.591970588370955,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 514,
                    "end": 543,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.68,
                "gradeSurface": 9.31,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "601e70c7-d644-43e8-87e1-e2cefceb6a3e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243393046767975,
                        48.59135859321727,
                    ],
                    [
                        2.244331143087355,
                        48.591472500609314,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 543,
                    "end": 613,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.02,
                "gradeSurface": 7.82,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "9d9ed2d8-4be1-4c31-ac9f-3cd983c00cae",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.262359016053809,
                        48.5931643232664,
                    ],
                    [
                        2.2626585117207,
                        48.59346753263306,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 613,
                    "end": 653,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.35,
                "gradeSurface": 10.0,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "fcb7917a-d782-4c7f-8be9-06ac721dee26",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264105386710302,
                        48.59463152505042,
                    ],
                    [
                        2.264197254166367,
                        48.594656381135636,
                    ],
                    [
                        2.264361575647428,
                        48.59473655823509,
                    ],
                    [
                        2.2644719728534,
                        48.594793912856424,
                    ],
                    [
                        2.264590594797886,
                        48.59484502124902,
                    ],
                    [
                        2.264673358435388,
                        48.59486768483097,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 653,
                    "end": 703,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.6,
                "gradeSurface": 4.98,
                "gradeStructure": 9.74,
            },
        },
        {
            "id": "86d8326c-b7e7-4a61-a84f-4936e5617154",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264673358435388,
                        48.59486768483097,
                    ],
                    [
                        2.264728390608184,
                        48.594882754523915,
                    ],
                    [
                        2.265178429598887,
                        48.59499348094964,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 703,
                    "end": 743,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.61,
                "gradeSurface": 0.0,
                "gradeStructure": 8.38,
            },
        },
        {
            "id": "8ccbe103-c055-4e75-89e0-c1a7d6db9ed7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251634370899419,
                        48.587517366133646,
                    ],
                    [
                        2.251723316164496,
                        48.58774551628873,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 743,
                    "end": 769,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "fc0b9a61-00da-4510-9d29-bd87b44cad44",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.270071032374565,
                        48.59540679590989,
                    ],
                    [
                        2.270745766254636,
                        48.595383377286986,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 769,
                    "end": 819,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.35,
                "gradeSurface": 10.0,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "07fe2a0a-9e30-46ce-8421-83ec577f8220",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.270745766254636,
                        48.595383377286986,
                    ],
                    [
                        2.271568940771172,
                        48.59535480138738,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 819,
                    "end": 880,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "0173890d-0646-456f-8add-6325e4f661bd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244331143087355,
                        48.591472500609314,
                    ],
                    [
                        2.244551560390845,
                        48.59095125133485,
                    ],
                    [
                        2.244559324153404,
                        48.59093276322005,
                    ],
                    [
                        2.24456700727779,
                        48.59091446711807,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 880,
                    "end": 944,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.57,
                "gradeSurface": 6.43,
                "gradeStructure": 8.5,
            },
        },
        {
            "id": "f6a8b1c0-05f6-465a-9df2-9dd024886e09",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245443851200478,
                        48.588878909513916,
                    ],
                    [
                        2.245647367592548,
                        48.588399829839354,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 944,
                    "end": 999,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.69,
                "gradeSurface": 0.44,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "3fb0e1b6-5195-48df-b6ec-8644c9775101",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244621742678838,
                        48.590786176379915,
                    ],
                    [
                        2.244749474864165,
                        48.59048563186046,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 999,
                    "end": 1034,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.5,
                "gradeSurface": 0.0,
                "gradeStructure": 8.18,
            },
        },
        {
            "id": "cdf8f5db-e171-48f3-90a1-276bf10e6da2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233311930114299,
                        48.59109887871172,
                    ],
                    [
                        2.233625567115902,
                        48.591072112341124,
                    ],
                    [
                        2.233827966425581,
                        48.591048227437646,
                    ],
                    [
                        2.233981196747314,
                        48.591029648654974,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1034,
                    "end": 1084,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "45e00339-63d6-4991-a741-6a33e3c6c775",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233981196747314,
                        48.591029648654974,
                    ],
                    [
                        2.234546584455256,
                        48.5909610950254,
                    ],
                    [
                        2.234649226743283,
                        48.590957177517325,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1084,
                    "end": 1134,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 1.78,
                "gradeSurface": 3.95,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "f131df00-69d4-4017-b74f-3acc3c439799",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234649226743283,
                        48.590957177517325,
                    ],
                    [
                        2.234849149363289,
                        48.59094954689306,
                    ],
                    [
                        2.235393236627949,
                        48.59092785534854,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1134,
                    "end": 1189,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 1.62,
                "gradeSurface": 3.62,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "b76f4f06-bc84-4f34-b550-dc5e32df1e47",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218411516972762,
                        48.5893872386649,
                    ],
                    [
                        2.218587873483341,
                        48.589382099617815,
                    ],
                    [
                        2.219083755613285,
                        48.589421112669164,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1189,
                    "end": 1239,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.82,
                "gradeSurface": 9.42,
                "gradeStructure": 6.5,
            },
        },
        {
            "id": "a92c3dfc-04f8-431e-8d88-c0463560c3b6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.219083755613285,
                        48.589421112669164,
                    ],
                    [
                        2.21929893900858,
                        48.589438041355365,
                    ],
                    [
                        2.219710894959912,
                        48.589456035471116,
                    ],
                    [
                        2.21975714312672,
                        48.58945404859105,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1239,
                    "end": 1289,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.25,
                "gradeSurface": 3.56,
                "gradeStructure": 8.45,
            },
        },
        {
            "id": "896561be-e4ba-4077-b914-5180b17841b8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.21975714312672,
                        48.58945404859105,
                    ],
                    [
                        2.220037900239427,
                        48.589441986519525,
                    ],
                    [
                        2.220308119576359,
                        48.589416769840554,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1289,
                    "end": 1330,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 2.9,
                "gradeSurface": 0.0,
                "gradeStructure": 5.28,
            },
        },
        {
            "id": "769ecb0b-c862-4f1a-9451-545a59738713",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.268101259803128,
                        48.59545317261242,
                    ],
                    [
                        2.268328972528086,
                        48.595461768968306,
                    ],
                    [
                        2.268779739732923,
                        48.59544418179753,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1330,
                    "end": 1380,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "6b1c64fb-7a65-47f1-96b8-587a9a9b9cac",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.268779739732923,
                        48.59544418179753,
                    ],
                    [
                        2.268826950025991,
                        48.595442339737346,
                    ],
                    [
                        2.269458691924116,
                        48.59542417215121,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1380,
                    "end": 1430,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "745449ad-4f3b-436a-a40e-b4d476ec55d5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.269458691924116,
                        48.59542417215121,
                    ],
                    [
                        2.269961143770789,
                        48.59540972027586,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1430,
                    "end": 1467,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.78,
                "gradeSurface": 6.5,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "4bcfa537-87a6-4150-ba60-6848654a2383",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24456700727779,
                        48.59091446711807,
                    ],
                    [
                        2.244595059451889,
                        48.590849876314,
                    ],
                    [
                        2.244621742678838,
                        48.590786176379915,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1467,
                    "end": 1482,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.19,
                "gradeSurface": 2.91,
                "gradeStructure": 3.43,
            },
        },
        {
            "id": "fc6ee716-8e60-4c58-89ec-ed5c5e5bfcc2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24992923168966,
                        48.58737799395477,
                    ],
                    [
                        2.250307106215488,
                        48.587408261418275,
                    ],
                    [
                        2.250453380342712,
                        48.58741997753347,
                    ],
                    [
                        2.250600747603817,
                        48.587431781018196,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1482,
                    "end": 1532,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.5,
                "gradeSurface": 4.03,
                "gradeStructure": 8.53,
            },
        },
        {
            "id": "23216aa6-a60f-43c5-9408-6999b860bc43",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.250600747603817,
                        48.587431781018196,
                    ],
                    [
                        2.250855634529942,
                        48.587452195922815,
                    ],
                    [
                        2.251114313509566,
                        48.58747361437618,
                    ],
                    [
                        2.251272020066682,
                        48.58748688227337,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1532,
                    "end": 1582,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.57,
                "gradeSurface": 6.79,
                "gradeStructure": 8.21,
            },
        },
        {
            "id": "9a67076c-bde4-43fa-8824-33c755a4e18a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251272020066682,
                        48.58748688227337,
                    ],
                    [
                        2.251634370899419,
                        48.587517366133646,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1582,
                    "end": 1609,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.61,
                "gradeSurface": 3.06,
                "gradeStructure": 4.05,
            },
        },
        {
            "id": "323f9169-1211-4bf3-a0fd-e6c5bdfae7e2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272927829586205,
                        48.59515710966892,
                    ],
                    [
                        2.272964292413546,
                        48.59516812710916,
                    ],
                    [
                        2.272989919542462,
                        48.59517817879916,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1609,
                    "end": 1614,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.61,
                "gradeSurface": 3.06,
                "gradeStructure": 4.05,
            },
        },
        {
            "id": "8d910b31-863a-464f-8165-a632f3cbe2e7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.254489680129126,
                        48.590111647306394,
                    ],
                    [
                        2.255100240186535,
                        48.59030890899787,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1614,
                    "end": 1664,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.13,
                "gradeSurface": 5.38,
                "gradeStructure": 8.56,
            },
        },
        {
            "id": "3b4bfceb-4a6f-4196-9d4c-74bd4c643a73",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.255100240186535,
                        48.59030890899787,
                    ],
                    [
                        2.255710804842384,
                        48.590506167525675,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1664,
                    "end": 1714,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.78,
                "gradeSurface": 2.31,
                "gradeStructure": 6.8,
            },
        },
        {
            "id": "78887865-7c0f-4c70-9a7a-82b9502baadb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.255710804842384,
                        48.590506167525675,
                    ],
                    [
                        2.256025772580531,
                        48.590607924091245,
                    ],
                    [
                        2.256096011319825,
                        48.5906272539886,
                    ],
                    [
                        2.256328552764937,
                        48.59069294689583,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1714,
                    "end": 1764,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.29,
                "gradeSurface": 0.97,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "bbd67cc6-653b-414d-a7ba-62babf655c1b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.256328552764937,
                        48.59069294689583,
                    ],
                    [
                        2.256953384925944,
                        48.59086945876026,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1764,
                    "end": 1814,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.36,
                "gradeSurface": 6.37,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "16ce8b11-1aea-4fce-8976-a164cc692db8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.256953384925944,
                        48.59086945876026,
                    ],
                    [
                        2.257578221297905,
                        48.591045967319275,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1814,
                    "end": 1864,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.93,
                "gradeSurface": 5.41,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "c3b1ad70-c88d-4cf5-9a5b-31e24381b4f0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.257578221297905,
                        48.591045967319275,
                    ],
                    [
                        2.257633013725251,
                        48.59106144528901,
                    ],
                    [
                        2.257870597170255,
                        48.591137590796926,
                    ],
                    [
                        2.257958336814422,
                        48.59116602435011,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1864,
                    "end": 1895,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.98,
                "gradeSurface": 4.36,
                "gradeStructure": 5.5,
            },
        },
        {
            "id": "fe55de21-eefe-400a-b9bb-8e60c0075f98",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227502345364066,
                        48.590383940033504,
                    ],
                    [
                        2.227699762059131,
                        48.590422103490546,
                    ],
                    [
                        2.228142265631698,
                        48.59052860396992,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1895,
                    "end": 1945,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.81,
                "gradeSurface": 2.53,
                "gradeStructure": 8.5,
            },
        },
        {
            "id": "7a520833-6e38-4a1e-a1ab-c6c88bcd95a5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228142265631698,
                        48.59052860396992,
                    ],
                    [
                        2.228994247297491,
                        48.59073365057348,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1945,
                    "end": 2012,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.32,
                "gradeSurface": 1.93,
                "gradeStructure": 6.27,
            },
        },
        {
            "id": "38533334-876a-4d79-a989-63f5c54bb844",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.2626585117207,
                        48.59346753263306,
                    ],
                    [
                        2.263027905109332,
                        48.59384650422117,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2012,
                    "end": 2062,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.38,
                "gradeSurface": 4.18,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "343933d2-1228-4735-b7a9-c0c60062d624",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263027905109332,
                        48.59384650422117,
                    ],
                    [
                        2.26319854091862,
                        48.59402156225961,
                    ],
                    [
                        2.263397303849292,
                        48.59422547454296,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2062,
                    "end": 2112,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.5,
                "gradeSurface": 8.5,
                "gradeStructure": 8.5,
            },
        },
        {
            "id": "ce902ee5-7f74-4aac-809d-e5082871e6d4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263397303849292,
                        48.59422547454296,
                    ],
                    [
                        2.263527440420881,
                        48.59435898150543,
                    ],
                    [
                        2.263584825309199,
                        48.59442500460388,
                    ],
                    [
                        2.263665464761674,
                        48.59447677852108,
                    ],
                    [
                        2.263769358781453,
                        48.5945143032116,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2112,
                    "end": 2155,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.48,
                "gradeSurface": 2.18,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "188dcd8d-7b4e-44d9-a5a7-8e4ef0ff897f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238252460816152,
                        48.59115938573596,
                    ],
                    [
                        2.238549471634791,
                        48.59115678832945,
                    ],
                    [
                        2.238690748282967,
                        48.591139700678184,
                    ],
                    [
                        2.238926555919239,
                        48.591114234458864,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2155,
                    "end": 2205,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.1,
                "gradeSurface": 6.99,
                "gradeStructure": 7.2,
            },
        },
        {
            "id": "d16d52db-1b58-4d8d-be0d-2e980c43f9c6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238926555919239,
                        48.591114234458864,
                    ],
                    [
                        2.239327730410625,
                        48.591070908192705,
                    ],
                    [
                        2.239600068523258,
                        48.5910574084972,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2205,
                    "end": 2255,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.86,
                "gradeSurface": 10.0,
                "gradeStructure": 9.76,
            },
        },
        {
            "id": "f583510d-21dd-4f61-ae73-b80a2722e126",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239600068523258,
                        48.5910574084972,
                    ],
                    [
                        2.239745691098462,
                        48.591050189783566,
                    ],
                    [
                        2.240007382242808,
                        48.591051860980855,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2255,
                    "end": 2285,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "3f06b262-58ba-4892-b28b-cb4fa80c0800",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272815998691309,
                        48.595202304172915,
                    ],
                    [
                        2.27282017916184,
                        48.59519423366146,
                    ],
                    [
                        2.272836688735993,
                        48.595177242854874,
                    ],
                    [
                        2.272858572446654,
                        48.59516388311822,
                    ],
                    [
                        2.272884424321541,
                        48.59515774410491,
                    ],
                    [
                        2.272915625367478,
                        48.59515703512555,
                    ],
                    [
                        2.272927829586205,
                        48.59515710966892,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2285,
                    "end": 2296,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "213ccd47-d343-46fe-a2eb-3eb8ff943faf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252072967855186,
                        48.588613098850956,
                    ],
                    [
                        2.252162199402648,
                        48.588821459755245,
                    ],
                    [
                        2.252232480474539,
                        48.58893254817359,
                    ],
                    [
                        2.252304092029148,
                        48.58904544403208,
                    ],
                    [
                        2.252364117327363,
                        48.5891150878087,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2296,
                    "end": 2356,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.43,
                "gradeSurface": 0.0,
                "gradeStructure": 6.24,
            },
        },
        {
            "id": "f0ea60e2-a5fd-4ada-852d-063c118abd8d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241028423738649,
                        48.591055677293575,
                    ],
                    [
                        2.241248016499596,
                        48.591061575153915,
                    ],
                    [
                        2.241705579884587,
                        48.59108762214054,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2356,
                    "end": 2406,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.96,
                "gradeSurface": 8.87,
                "gradeStructure": 9.03,
            },
        },
        {
            "id": "fd71e8f1-98c5-4a96-a290-2d1de5fcef63",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241705579884587,
                        48.59108762214054,
                    ],
                    [
                        2.241781855714192,
                        48.59109196400036,
                    ],
                    [
                        2.242361286771423,
                        48.5912014125185,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2406,
                    "end": 2456,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 2.84,
                "gradeSurface": 0.0,
                "gradeStructure": 5.17,
            },
        },
        {
            "id": "55c0d2ab-f3b3-4c89-b6be-9b2660996973",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.242361286771423,
                        48.5912014125185,
                    ],
                    [
                        2.242447209285567,
                        48.591217642110514,
                    ],
                    [
                        2.242970714367253,
                        48.591306431697376,
                    ],
                    [
                        2.243019218855413,
                        48.591312422481565,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2456,
                    "end": 2506,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.88,
                "gradeSurface": 5.4,
                "gradeStructure": 8.1,
            },
        },
        {
            "id": "2227b723-eca8-407b-9fde-58fdfc0b60dc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243019218855413,
                        48.591312422481565,
                    ],
                    [
                        2.243393046767975,
                        48.59135859321727,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2506,
                    "end": 2534,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.84,
                "gradeSurface": 7.42,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5093ee11-1850-4c36-9253-5767f16c921a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260670350036127,
                        48.59222729554731,
                    ],
                    [
                        2.260933261997554,
                        48.59233507688387,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2534,
                    "end": 2557,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.32,
                "gradeSurface": 6.69,
                "gradeStructure": 6.02,
            },
        },
        {
            "id": "ad3631a6-a959-4b1a-a5cb-5c95d04d29cc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.262097744337868,
                        48.59293961207076,
                    ],
                    [
                        2.26225338931049,
                        48.593057520135886,
                    ],
                    [
                        2.262359016053809,
                        48.5931643232664,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2557,
                    "end": 2589,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.15,
                "gradeSurface": 8.12,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "1b63e1e0-a648-4c51-9c8d-8405885c8749",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230987966507441,
                        48.59098673327829,
                    ],
                    [
                        2.23158324720736,
                        48.59108053465442,
                    ],
                    [
                        2.231800061224829,
                        48.591090929854175,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2589,
                    "end": 2650,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.14,
                "gradeSurface": 5.03,
                "gradeStructure": 8.86,
            },
        },
        {
            "id": "1801a24f-f0ed-4ea3-84b2-a1bc525a8e56",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.267079533202802,
                        48.59529845852845,
                    ],
                    [
                        2.267242004769074,
                        48.595317449996585,
                    ],
                    [
                        2.267737579492773,
                        48.595398947625,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2650,
                    "end": 2700,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.12,
                "gradeSurface": 0.0,
                "gradeStructure": 7.5,
            },
        },
        {
            "id": "2a07fbe5-5b7b-448c-b0e8-2d4308d4721a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.267737579492773,
                        48.595398947625,
                    ],
                    [
                        2.26781027894929,
                        48.59541090290887,
                    ],
                    [
                        2.268094542494381,
                        48.59544863350431,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2700,
                    "end": 2727,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.3,
                "gradeSurface": 8.45,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "c622a3ba-ff09-48a7-89e4-821411b1394e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231800061224829,
                        48.591090929854175,
                    ],
                    [
                        2.232057684580599,
                        48.59109259227569,
                    ],
                    [
                        2.232478541470151,
                        48.59109923995767,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2727,
                    "end": 2777,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.35,
                "gradeSurface": 10.0,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "0b23f39c-9fd4-419e-8323-fcccab5d8b2c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.232478541470151,
                        48.59109923995767,
                    ],
                    [
                        2.233020250821476,
                        48.59110779438247,
                    ],
                    [
                        2.23330525587662,
                        48.59109163917423,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2777,
                    "end": 2838,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "9d6fd96d-75e7-4bc8-bf18-3615c85b00e8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264026288555832,
                        48.594470014350414,
                    ],
                    [
                        2.264053282271756,
                        48.59447917686966,
                    ],
                    [
                        2.264080023233878,
                        48.594506329069546,
                    ],
                    [
                        2.264093330535625,
                        48.594524402588775,
                    ],
                    [
                        2.264106511469352,
                        48.59455147094932,
                    ],
                    [
                        2.264119692416701,
                        48.59457853930782,
                    ],
                    [
                        2.264119439666555,
                        48.59459652899354,
                    ],
                    [
                        2.264105626827692,
                        48.5946144348495,
                    ],
                    [
                        2.264105500450138,
                        48.594623429692135,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2838,
                    "end": 2858,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.7,
                "gradeSurface": 10.0,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "5c5164ae-e867-430e-be4c-cca80f65bdbb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235382428748355,
                        48.59092508721546,
                    ],
                    [
                        2.236047611165768,
                        48.590875384345246,
                    ],
                    [
                        2.23605657486973,
                        48.59087544187699,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2858,
                    "end": 2908,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.0,
                "gradeSurface": 5.0,
                "gradeStructure": 5.0,
            },
        },
        {
            "id": "6f8b8bf9-35cf-4525-a903-57bd9341802a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23605657486973,
                        48.59087544187699,
                    ],
                    [
                        2.236223879142496,
                        48.59087651556196,
                    ],
                    [
                        2.236670804338019,
                        48.59091536530092,
                    ],
                    [
                        2.237036244598194,
                        48.590962686744255,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2908,
                    "end": 2981,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.5,
                "gradeSurface": 8.5,
                "gradeStructure": 8.5,
            },
        },
        {
            "id": "38c37ee6-7463-440c-a52c-0919b6edefcb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245082295523098,
                        48.58969162576154,
                    ],
                    [
                        2.245278339223788,
                        48.589261076289795,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2981,
                    "end": 3031,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.04,
                "gradeSurface": 0.81,
                "gradeStructure": 8.5,
            },
        },
        {
            "id": "a33d6610-fb22-4049-963a-daaaf422670f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222677507175272,
                        48.58993422792878,
                    ],
                    [
                        2.223055953105312,
                        48.59001766020523,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3031,
                    "end": 3060,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.46,
                "gradeSurface": 7.38,
                "gradeStructure": 3.88,
            },
        },
        {
            "id": "0f158b14-4cf0-4c79-bbca-c47fa13ab444",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223055953105312,
                        48.59001766020523,
                    ],
                    [
                        2.223150331327859,
                        48.5900542590406,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3060,
                    "end": 3068,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "bbadda79-29c8-45b5-b775-4064b7f8a6bb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244745472013664,
                        48.59048110862366,
                    ],
                    [
                        2.244927793207683,
                        48.59005000558732,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3068,
                    "end": 3118,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.83,
                "gradeSurface": 7.0,
                "gradeStructure": 6.7,
            },
        },
        {
            "id": "b23e39e3-72d6-4101-a4e1-ed795bfcc772",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244927793207683,
                        48.59005000558732,
                    ],
                    [
                        2.245026245404668,
                        48.58981720976851,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3118,
                    "end": 3145,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "4aad8eb9-01f5-41d3-a59b-2200ac6cc5a9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245881951688872,
                        48.58783458785189,
                    ],
                    [
                        2.245937997634592,
                        48.587709003238885,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3145,
                    "end": 3160,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.16,
                "gradeSurface": 8.01,
                "gradeStructure": 8.29,
            },
        },
        {
            "id": "373e5265-4358-4411-9a0d-d2343595f4e7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245026245404668,
                        48.58981720976851,
                    ],
                    [
                        2.245082295523098,
                        48.58969162576154,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3160,
                    "end": 3175,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.61,
                "gradeSurface": 3.06,
                "gradeStructure": 4.05,
            },
        },
        {
            "id": "b7f8d89d-86fb-4d4a-84b7-1fd33ae4495b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.271567609761538,
                        48.595352994120354,
                    ],
                    [
                        2.271798634957881,
                        48.5953184251789,
                    ],
                    [
                        2.271920802543982,
                        48.5953101768557,
                    ],
                    [
                        2.272042970091606,
                        48.59530192840703,
                    ],
                    [
                        2.272151702320987,
                        48.59528460207387,
                    ],
                    [
                        2.272237911739468,
                        48.59527798869603,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3175,
                    "end": 3225,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.61,
                "gradeSurface": 3.06,
                "gradeStructure": 4.05,
            },
        },
        {
            "id": "36cf3a8a-e95c-4660-b55a-bdb46d02a3b0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272237911739468,
                        48.59527798869603,
                    ],
                    [
                        2.272260309499734,
                        48.595276270487524,
                    ],
                    [
                        2.272355481335445,
                        48.595258861065766,
                    ],
                    [
                        2.2724640884284,
                        48.59525052929332,
                    ],
                    [
                        2.27255913522231,
                        48.595242114555255,
                    ],
                    [
                        2.272640496809923,
                        48.595242611731074,
                    ],
                    [
                        2.272708547937443,
                        48.59522503630746,
                    ],
                    [
                        2.272762913871227,
                        48.59521637284879,
                    ],
                    [
                        2.27280384441629,
                        48.59519863167907,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3225,
                    "end": 3268,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.91,
                "gradeSurface": 8.5,
                "gradeStructure": 5.6,
            },
        },
        {
            "id": "a164b625-4085-4022-ad2f-d4ff8c3e91f8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.269953057738655,
                        48.59540607244044,
                    ],
                    [
                        2.270061540162361,
                        48.59540673770405,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3268,
                    "end": 3276,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.58,
                "gradeSurface": 10.0,
                "gradeStructure": 9.25,
            },
        },
        {
            "id": "d6704639-f913-46c0-bff9-384e48510c43",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.248710336183967,
                        48.58727675084185,
                    ],
                    [
                        2.249380086733255,
                        48.587330403575216,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3276,
                    "end": 3326,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.64,
                "gradeSurface": 9.49,
                "gradeStructure": 9.76,
            },
        },
        {
            "id": "1e4c0618-4cc9-47af-b289-3ae7b05e1666",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.249380086733255,
                        48.587330403575216,
                    ],
                    [
                        2.249929283207345,
                        48.58737439600259,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3326,
                    "end": 3367,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.57,
                "gradeSurface": 2.38,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "33e9008c-55ce-4921-bf5f-da7669f9b8ef",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246949973397527,
                        48.5871126988657,
                    ],
                    [
                        2.247044621653368,
                        48.58713128908192,
                    ],
                    [
                        2.247125841122561,
                        48.58714079857906,
                    ],
                    [
                        2.247619504887908,
                        48.58718315807548,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3367,
                    "end": 3417,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.45,
                "gradeSurface": 0.64,
                "gradeStructure": 5.75,
            },
        },
        {
            "id": "285d31c3-37f5-4d14-9f1c-407ff33f661e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.247619504887908,
                        48.58718315807548,
                    ],
                    [
                        2.248292857079386,
                        48.58724093256982,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3417,
                    "end": 3467,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.32,
                "gradeSurface": 0.0,
                "gradeStructure": 7.86,
            },
        },
        {
            "id": "61ef49c7-864c-46e6-8493-e4f02458b98c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.248292857079386,
                        48.58724093256982,
                    ],
                    [
                        2.248710336183967,
                        48.58727675084185,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3467,
                    "end": 3498,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.65,
                "gradeSurface": 9.22,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "268afcfd-8588-4422-bce1-0a7fb42beb6a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220488075110151,
                        48.58944313653914,
                    ],
                    [
                        2.220663401156132,
                        48.58950725462925,
                    ],
                    [
                        2.220933638614959,
                        48.589571993734936,
                    ],
                    [
                        2.221117383369897,
                        48.5896037486121,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3498,
                    "end": 3548,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.24,
                "gradeSurface": 3.09,
                "gradeStructure": 6.99,
            },
        },
        {
            "id": "ee5d438e-442c-439c-bd43-64253e503e79",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237696814345452,
                        48.59105253155738,
                    ],
                    [
                        2.238240257579361,
                        48.59115930763737,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4482,
                    "end": 4524,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.89,
                "gradeSurface": 4.59,
                "gradeStructure": 5.15,
            },
        },
        {
            "id": "4157db90-d7ff-44ea-8f59-047ee72e30d6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221117383369897,
                        48.5896037486121,
                    ],
                    [
                        2.221312346577803,
                        48.589637441915784,
                    ],
                    [
                        2.22174515683694,
                        48.589712238356995,
                    ],
                    [
                        2.221772144569008,
                        48.589718664177546,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3548,
                    "end": 3598,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 2.93,
                "gradeSurface": 3.97,
                "gradeStructure": 2.07,
            },
        },
        {
            "id": "c2006d6c-9530-4e19-b581-250454fdab84",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221772144569008,
                        48.589718664177546,
                    ],
                    [
                        2.222677507175272,
                        48.58993422792878,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3598,
                    "end": 3669,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.13,
                "gradeSurface": 4.42,
                "gradeStructure": 2.07,
            },
        },
        {
            "id": "d31e64e9-438a-48f3-9807-9a1c5842422a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220298655284184,
                        48.58941490869979,
                    ],
                    [
                        2.220299190832285,
                        48.58937892936736,
                    ],
                    [
                        2.22029945860606,
                        48.58936093970086,
                    ],
                    [
                        2.220313285031424,
                        48.589343038853904,
                    ],
                    [
                        2.220327111447339,
                        48.58932513800515,
                    ],
                    [
                        2.220367921264103,
                        48.589316409617226,
                    ],
                    [
                        2.220422289710097,
                        48.58930777002277,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3669,
                    "end": 3687,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "b7625082-aa0d-4c2b-b464-e0c5f1734752",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223150331327859,
                        48.5900542590406,
                    ],
                    [
                        2.223542204409086,
                        48.59014677308882,
                    ],
                    [
                        2.223799023130384,
                        48.590202422372286,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3687,
                    "end": 3738,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.92,
                "gradeSurface": 6.06,
                "gradeStructure": 7.62,
            },
        },
        {
            "id": "4692c39c-c8da-439e-85f6-b3dcfd0c35c0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246303195641254,
                        48.58682973813434,
                    ],
                    [
                        2.246437482165626,
                        48.5869205454496,
                    ],
                    [
                        2.246558599179702,
                        48.586984282138495,
                    ],
                    [
                        2.246679845839838,
                        48.58703902382132,
                    ],
                    [
                        2.246801222083986,
                        48.58708477049887,
                    ],
                    [
                        2.246949973397527,
                        48.5871126988657,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3738,
                    "end": 3796,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.63,
                "gradeSurface": 4.26,
                "gradeStructure": 3.12,
            },
        },
        {
            "id": "59087955-6500-4997-9d96-98816f30d07b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245937997634592,
                        48.587709003238885,
                    ],
                    [
                        2.246148620588371,
                        48.58720657851872,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3796,
                    "end": 3854,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.76,
                "gradeSurface": 4.22,
                "gradeStructure": 7.03,
            },
        },
        {
            "id": "d8346ca4-603c-42fe-8d72-d1f9f945538d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260195851843144,
                        48.59202644313497,
                    ],
                    [
                        2.260667638143122,
                        48.592227278702936,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3854,
                    "end": 3895,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 2.27,
                "gradeSurface": 0.0,
                "gradeStructure": 4.14,
            },
        },
        {
            "id": "19718bea-7337-4be0-8cb9-78e2f6696b45",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246148620588371,
                        48.58720657851872,
                    ],
                    [
                        2.246135709672301,
                        48.587161518252096,
                    ],
                    [
                        2.246136227452157,
                        48.587125538739265,
                    ],
                    [
                        2.246123187119717,
                        48.58708947334693,
                    ],
                    [
                        2.246083160054717,
                        48.58704424130872,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3895,
                    "end": 3914,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 2.53,
                "gradeSurface": 0.0,
                "gradeStructure": 4.61,
            },
        },
        {
            "id": "2d6fbb02-c329-4728-bf79-e848001c5dd0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246031128522139,
                        48.586890984811696,
                    ],
                    [
                        2.246126552727344,
                        48.5868556064813,
                    ],
                    [
                        2.246303195641254,
                        48.58682973813434,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3914,
                    "end": 3935,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.24,
                "gradeSurface": 6.09,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "c34e70a3-0930-4691-bc32-b24bb8435061",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263768015418775,
                        48.59451339534038,
                    ],
                    [
                        2.263781954779235,
                        48.59448649467893,
                    ],
                    [
                        2.26382288779563,
                        48.59446875658675,
                    ],
                    [
                        2.26387738083015,
                        48.59445110233729,
                    ],
                    [
                        2.263918060967959,
                        48.59445135389924,
                    ],
                    [
                        2.263985734800781,
                        48.59446076798191,
                    ],
                    [
                        2.264026288555832,
                        48.594470014350414,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3935,
                    "end": 3958,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.84,
                "gradeSurface": 7.43,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "42898845-dcf4-40ba-a83f-9eac851b14a4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223799023130384,
                        48.590202422372286,
                    ],
                    [
                        2.224110211212378,
                        48.590249429826805,
                    ],
                    [
                        2.224489194381047,
                        48.590296878202295,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3958,
                    "end": 4010,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 2.32,
                "gradeSurface": 0.0,
                "gradeStructure": 4.22,
            },
        },
        {
            "id": "1e44b643-018a-45fa-8a48-052e7d6d75e3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260991288624389,
                        48.59235522757761,
                    ],
                    [
                        2.261409223236956,
                        48.59252873883574,
                    ],
                    [
                        2.261570923309089,
                        48.59260170721559,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4010,
                    "end": 4061,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 1.45,
                "gradeSurface": 0.27,
                "gradeStructure": 2.42,
            },
        },
        {
            "id": "2f11437e-2d80-48d8-b2ef-84d96cca2e89",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251713825361156,
                        48.58774545661947,
                    ],
                    [
                        2.251752829966686,
                        48.587862645772354,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4061,
                    "end": 4074,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "6a2441a2-fcb7-40b0-8792-144a516a076d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.261570923309089,
                        48.59260170721559,
                    ],
                    [
                        2.261705631451685,
                        48.592665512312514,
                    ],
                    [
                        2.261853519240199,
                        48.59275638590341,
                    ],
                    [
                        2.262095057751275,
                        48.59293779628834,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4074,
                    "end": 4128,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "87812349-9a2c-4c8b-983d-3137872986ca",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228984795698721,
                        48.59073089064207,
                    ],
                    [
                        2.229079444114002,
                        48.5907494950892,
                    ],
                    [
                        2.229269005846819,
                        48.59076871409082,
                    ],
                    [
                        2.229648394300119,
                        48.59078916152099,
                    ],
                    [
                        2.229653908522265,
                        48.590789807933845,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4128,
                    "end": 4178,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.07,
                "gradeSurface": 6.82,
                "gradeStructure": 7.28,
            },
        },
        {
            "id": "d5a69a7c-472d-4c2e-9aa5-34ed4677ce24",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.229653908522265,
                        48.590789807933845,
                    ],
                    [
                        2.229892060330787,
                        48.59081772537327,
                    ],
                    [
                        2.230315640927514,
                        48.590883050240045,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4178,
                    "end": 4228,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.71,
                "gradeSurface": 8.6,
                "gradeStructure": 8.8,
            },
        },
        {
            "id": "0ef43559-ae2d-49c9-b548-2d08a2b8bf00",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230315640927514,
                        48.590883050240045,
                    ],
                    [
                        2.230987966507441,
                        48.59098673327829,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4228,
                    "end": 4279,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.09,
                "gradeSurface": 3.54,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5db0211c-ee77-472e-be1f-4b91dc74ef92",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252935019347687,
                        48.5895882467518,
                    ],
                    [
                        2.253123944366242,
                        48.589652402117025,
                    ],
                    [
                        2.253164364236825,
                        48.589670647089456,
                    ],
                    [
                        2.253474806342719,
                        48.589771546990896,
                    ],
                    [
                        2.253540531234517,
                        48.58979290874217,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4279,
                    "end": 4329,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.87,
                "gradeSurface": 5.28,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "f671d47e-439a-4f52-b0dc-9d89afd39156",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.253540531234517,
                        48.58979290874217,
                    ],
                    [
                        2.253785249644546,
                        48.58987244607428,
                    ],
                    [
                        2.254147997039421,
                        48.5899956238594,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4329,
                    "end": 4379,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.41,
                "gradeSurface": 4.0,
                "gradeStructure": 4.76,
            },
        },
        {
            "id": "b527cdb0-be49-444b-94ea-41de1479900e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.254147997039421,
                        48.5899956238594,
                    ],
                    [
                        2.254486981149475,
                        48.59011073083464,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4379,
                    "end": 4407,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "841b424d-43da-4fd9-956f-ddbdee9ce009",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22724112239995,
                        48.590350759424624,
                    ],
                    [
                        2.227498344010716,
                        48.59037941621639,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4407,
                    "end": 4426,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.05,
                "gradeSurface": 7.89,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "bd2c413c-e2ba-4337-8289-39e9f8c413c6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260923871886935,
                        48.592327822064405,
                    ],
                    [
                        2.260991288624389,
                        48.59235522757761,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4426,
                    "end": 4432,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.7,
                "gradeSurface": 4.5,
                "gradeStructure": 8.5,
            },
        },
        {
            "id": "9306eca4-ee83-42e8-8ef6-905fe1ef486f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237036244598194,
                        48.590962686744255,
                    ],
                    [
                        2.237442362854638,
                        48.591010267674186,
                    ],
                    [
                        2.237672344134869,
                        48.59104772356566,
                    ],
                    [
                        2.237696814345452,
                        48.59105253155738,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4432,
                    "end": 4482,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.53,
                "gradeSurface": 8.96,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "49b8d2e3-2beb-4273-b5ab-f736bebf57df",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.258227005477598,
                        48.59124955944555,
                    ],
                    [
                        2.258810262595666,
                        48.59148165387914,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4524,
                    "end": 4574,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.8,
                "gradeSurface": 3.27,
                "gradeStructure": 4.23,
            },
        },
        {
            "id": "166896b7-0ec4-45b8-9892-df407610ab4e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.258810262595666,
                        48.59148165387914,
                    ],
                    [
                        2.259393524882363,
                        48.591713745410765,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4574,
                    "end": 4624,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.19,
                "gradeSurface": 5.87,
                "gradeStructure": 6.46,
            },
        },
        {
            "id": "72d00e6b-a408-48ea-af7d-33d470076c85",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.259393524882363,
                        48.591713745410765,
                    ],
                    [
                        2.259696823314087,
                        48.59183443186032,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4624,
                    "end": 4650,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.53,
                "gradeSurface": 2.3,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "142711f3-dbe6-49c4-aa4b-3418759b6668",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246083160054717,
                        48.58704424130872,
                    ],
                    [
                        2.246031128522139,
                        48.586890984811696,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4650,
                    "end": 4667,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.17,
                "gradeSurface": 1.31,
                "gradeStructure": 4.68,
            },
        },
        {
            "id": "c048d8e3-4546-432b-a9e2-e745005e58c5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224489194381047,
                        48.590296878202295,
                    ],
                    [
                        2.225003501262026,
                        48.59036319795697,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4667,
                    "end": 4706,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.33,
                "gradeSurface": 0.91,
                "gradeStructure": 5.32,
            },
        },
        {
            "id": "39d1b970-2953-4df2-bcb4-edd266bc7eef",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266605175666583,
                        48.595277545700284,
                    ],
                    [
                        2.266781333185049,
                        48.59528762650184,
                    ],
                    [
                        2.266943930501376,
                        48.59529762353658,
                    ],
                    [
                        2.267079533202802,
                        48.59529845852845,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4706,
                    "end": 4741,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.51,
                "gradeSurface": 9.65,
                "gradeStructure": 7.57,
            },
        },
        {
            "id": "839c300d-c52b-4769-b77b-782fb672b302",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251752829966686,
                        48.587862645772354,
                    ],
                    [
                        2.251805264624431,
                        48.58798891501211,
                    ],
                    [
                        2.251935512824698,
                        48.588297946200406,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4741,
                    "end": 4791,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.59,
                "gradeSurface": 6.88,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "67d3c8c7-566f-4a0d-918a-a4dcc37bebda",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251935512824698,
                        48.588297946200406,
                    ],
                    [
                        2.252067570134959,
                        48.58861126579551,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4791,
                    "end": 4827,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "73dd77d8-b14d-4785-bbeb-b7b676d619d6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226305291013702,
                        48.59036267134899,
                    ],
                    [
                        2.22669889825379,
                        48.59033824215887,
                    ],
                    [
                        2.227011018903668,
                        48.590322278191614,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4827,
                    "end": 4879,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.25,
                "gradeSurface": 0.0,
                "gradeStructure": 5.91,
            },
        },
        {
            "id": "e77d3ff9-be85-471c-8392-fe85366a2eef",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220422289710097,
                        48.58930777002277,
                    ],
                    [
                        2.220462697921511,
                        48.589326026103784,
                    ],
                    [
                        2.220503106161091,
                        48.589344282170806,
                    ],
                    [
                        2.220502570753402,
                        48.58938026150509,
                    ],
                    [
                        2.220502035344977,
                        48.58941624083836,
                    ],
                    [
                        2.220488075110151,
                        48.58944313653914,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4879,
                    "end": 4897,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.69,
                "gradeSurface": 2.64,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "fbc65db7-8e49-4db6-bc60-f9d05616d6b9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224207974722415,
                        48.601685346857366,
                    ],
                    [
                        2.223671254822997,
                        48.601751114400976,
                    ],
                    [
                        2.223542302396363,
                        48.60176763554701,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 0,
                    "end": 50,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.18,
                "gradeSurface": 3.75,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "d8d18304-0bed-4cde-b2a8-0276063f27f1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223542302396363,
                        48.60176763554701,
                    ],
                    [
                        2.223190204019645,
                        48.60181274497323,
                    ],
                    [
                        2.22287777028105,
                        48.60185389073562,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 50,
                    "end": 100,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.93,
                "gradeSurface": 3.18,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "557c29f2-c6d0-4592-8b45-36b477ba11fb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22287777028105,
                        48.60185389073562,
                    ],
                    [
                        2.222213594408173,
                        48.60194135601107,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 100,
                    "end": 150,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.45,
                "gradeSurface": 7.23,
                "gradeStructure": 7.63,
            },
        },
        {
            "id": "1040c067-1085-4643-ace0-09ed00c3a9be",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222213594408173,
                        48.60194135601107,
                    ],
                    [
                        2.221549416316835,
                        48.60202881757501,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 150,
                    "end": 200,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.84,
                "gradeSurface": 5.03,
                "gradeStructure": 6.5,
            },
        },
        {
            "id": "c2e54546-fd24-4f3b-824f-a50880eb3068",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221549416316835,
                        48.60202881757501,
                    ],
                    [
                        2.220938370513529,
                        48.602109278935814,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 200,
                    "end": 246,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.3,
                "gradeSurface": 9.24,
                "gradeStructure": 9.34,
            },
        },
        {
            "id": "322164b3-01af-4141-97b3-c90a88ac90bc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245671371299025,
                        48.59859745301532,
                    ],
                    [
                        2.245646585621087,
                        48.598623383239875,
                    ],
                    [
                        2.245623194897372,
                        48.5986466236143,
                    ],
                    [
                        2.245582045143288,
                        48.59867874705579,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 246,
                    "end": 257,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.61,
                "gradeSurface": 3.06,
                "gradeStructure": 4.05,
            },
        },
        {
            "id": "419b81db-c103-4a7c-afc0-c294c763ad80",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233311532827964,
                        48.60048313682141,
                    ],
                    [
                        2.233008583172303,
                        48.60051716786905,
                    ],
                    [
                        2.232800643205323,
                        48.600546413094996,
                    ],
                    [
                        2.232772098014974,
                        48.6005507269427,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 257,
                    "end": 297,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.56,
                "gradeSurface": 2.92,
                "gradeStructure": 5.9,
            },
        },
        {
            "id": "0fc708b8-a14d-4922-aa63-a0e795e2cdb3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220938370513529,
                        48.602109278935814,
                    ],
                    [
                        2.220274452580064,
                        48.60219875136717,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 297,
                    "end": 347,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.93,
                "gradeSurface": 4.5,
                "gradeStructure": 5.28,
            },
        },
        {
            "id": "ea4419c3-278b-4db9-9bbb-271a8b7f30fa",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220274452580064,
                        48.60219875136717,
                    ],
                    [
                        2.219610532378096,
                        48.602288220089626,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 347,
                    "end": 397,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.49,
                "gradeSurface": 2.35,
                "gradeStructure": 6.24,
            },
        },
        {
            "id": "8e04ad80-a3bc-41cf-a20c-0d987c065b07",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.219610532378096,
                        48.602288220089626,
                    ],
                    [
                        2.219225956778939,
                        48.602340042986896,
                    ],
                    [
                        2.219099561790573,
                        48.60235720523168,
                    ],
                    [
                        2.218946640418086,
                        48.602377784204585,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 397,
                    "end": 447,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.85,
                "gradeSurface": 3.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "cf4cc3de-afd5-42f1-a14c-ebf1ce733309",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218946640418086,
                        48.602377784204585,
                    ],
                    [
                        2.218282681005833,
                        48.602467132246126,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 447,
                    "end": 497,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.35,
                "gradeSurface": 8.56,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "d5763649-8214-42f5-bb2f-24091d2b29aa",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218282681005833,
                        48.602467132246126,
                    ],
                    [
                        2.218277327671386,
                        48.60246785262015,
                    ],
                    [
                        2.217618202080539,
                        48.602554768590586,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 497,
                    "end": 547,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.78,
                "gradeSurface": 2.07,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "f5afa743-8688-4bd3-a0b2-615f8af7f7f5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.217618202080539,
                        48.602554768590586,
                    ],
                    [
                        2.216953716727249,
                        48.60264238733721,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 547,
                    "end": 597,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "a5e624fc-d565-44f6-bcb1-1127ee44765a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.216953716727249,
                        48.60264238733721,
                    ],
                    [
                        2.216522834511342,
                        48.60269920113463,
                    ],
                    [
                        2.216288460862269,
                        48.60272731809688,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 597,
                    "end": 647,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.78,
                "gradeSurface": 6.9,
                "gradeStructure": 8.5,
            },
        },
        {
            "id": "e3bdef71-6b02-460a-a1fc-78ff9e59b8b4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.216288460862269,
                        48.60272731809688,
                    ],
                    [
                        2.215755120975147,
                        48.602791299127105,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 647,
                    "end": 687,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.56,
                "gradeSurface": 5.04,
                "gradeStructure": 7.8,
            },
        },
        {
            "id": "03f29eb9-aa80-4cdb-b7df-9ffc1293eb61",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245103768329297,
                        48.59883403794259,
                    ],
                    [
                        2.245061676900529,
                        48.59883736926856,
                    ],
                    [
                        2.24501691213587,
                        48.598837984941454,
                    ],
                    [
                        2.24496819573179,
                        48.59883047948265,
                    ],
                    [
                        2.24492356065399,
                        48.59882210032941,
                    ],
                    [
                        2.244884350048308,
                        48.598813755567264,
                    ],
                    [
                        2.244837067618137,
                        48.59880086178197,
                    ],
                    [
                        2.244758698362738,
                        48.59878057427097,
                    ],
                    [
                        2.24472905843712,
                        48.598766892806,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 687,
                    "end": 716,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.61,
                "gradeSurface": 3.06,
                "gradeStructure": 4.05,
            },
        },
        {
            "id": "bfd4bfb5-18a8-4226-a1c3-848dd7e2cd36",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235031286121271,
                        48.6005769593983,
                    ],
                    [
                        2.235014841414818,
                        48.60058854802445,
                    ],
                    [
                        2.2349478377561,
                        48.600625899023164,
                    ],
                    [
                        2.234887706792464,
                        48.600656997225784,
                    ],
                    [
                        2.234829155333361,
                        48.600672813011045,
                    ],
                    [
                        2.234736686662043,
                        48.60068931031034,
                    ],
                    [
                        2.234656554838362,
                        48.600696891238336,
                    ],
                    [
                        2.23455889806271,
                        48.60069716294671,
                    ],
                    [
                        2.23447355192483,
                        48.60069031724779,
                    ],
                    [
                        2.234381490752197,
                        48.60067893049698,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 716,
                    "end": 768,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.94,
                "gradeSurface": 5.85,
                "gradeStructure": 7.83,
            },
        },
        {
            "id": "4e53021b-7188-4e66-994b-ac6bd374a7fa",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245582045143288,
                        48.59867874705579,
                    ],
                    [
                        2.245551847893061,
                        48.59870374340352,
                    ],
                    [
                        2.245514857094177,
                        48.59872959624857,
                    ],
                    [
                        2.245479313082328,
                        48.59874916132229,
                    ],
                    [
                        2.24545612957295,
                        48.598758009994164,
                    ],
                    [
                        2.245431564023473,
                        48.598768649024194,
                    ],
                    [
                        2.245383866770249,
                        48.59878453878993,
                    ],
                    [
                        2.245381115661667,
                        48.598787220034914,
                    ],
                    [
                        2.245334774490483,
                        48.598803118377006,
                    ],
                    [
                        2.245283047718611,
                        48.59881628387391,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 768,
                    "end": 795,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.42,
                "gradeSurface": 9.25,
                "gradeStructure": 9.55,
            },
        },
        {
            "id": "d42d238a-89dc-4f02-9a82-0a62ff1ae3f6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22996734275581,
                        48.60091673113117,
                    ],
                    [
                        2.229304457835753,
                        48.60100350614399,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 795,
                    "end": 845,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.27,
                "gradeSurface": 7.7,
                "gradeStructure": 6.91,
            },
        },
        {
            "id": "aaed3da3-a1a4-4cfb-9cab-aba0cfcc0a80",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.229304457835753,
                        48.60100350614399,
                    ],
                    [
                        2.228775574180139,
                        48.60107273685089,
                    ],
                    [
                        2.228608420765912,
                        48.60109504249216,
                    ],
                    [
                        2.228520084156046,
                        48.60110706394115,
                    ],
                    [
                        2.228405940491304,
                        48.60112161675078,
                    ],
                    [
                        2.228336643209723,
                        48.601130163214535,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 845,
                    "end": 918,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.85,
                "gradeSurface": 8.84,
                "gradeStructure": 8.85,
            },
        },
        {
            "id": "e27707aa-350a-4d55-a4df-0607c89b95d3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237312702935555,
                        48.599915127110165,
                    ],
                    [
                        2.23727201868392,
                        48.59991486645736,
                    ],
                    [
                        2.236973012266558,
                        48.599957928449186,
                    ],
                    [
                        2.236850697275528,
                        48.599975135648336,
                    ],
                    [
                        2.236648670994768,
                        48.599996906910434,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 918,
                    "end": 968,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "41abd1cb-e9a7-4511-8042-a5c7f0b71ef9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.236648670994768,
                        48.599996906910434,
                    ],
                    [
                        2.236456760069837,
                        48.60001758776157,
                    ],
                    [
                        2.236361567663236,
                        48.60003496841316,
                    ],
                    [
                        2.235983215633873,
                        48.60007531863885,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 968,
                    "end": 1018,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.83,
                "gradeSurface": 10.0,
                "gradeStructure": 9.7,
            },
        },
        {
            "id": "605417b1-d225-4365-b9f6-85bd2166e10c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235983215633873,
                        48.60007531863885,
                    ],
                    [
                        2.235804629626861,
                        48.60009436391608,
                    ],
                    [
                        2.235723129595221,
                        48.60010283633747,
                    ],
                    [
                        2.235614375322645,
                        48.60012012932657,
                    ],
                    [
                        2.235519051149617,
                        48.60014650407417,
                    ],
                    [
                        2.235423595563605,
                        48.60018187351556,
                    ],
                    [
                        2.235355131504332,
                        48.600226411862465,
                    ],
                    [
                        2.235176598965976,
                        48.60037819044877,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1018,
                    "end": 1090,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.24,
                "gradeSurface": 2.09,
                "gradeStructure": 6.0,
            },
        },
        {
            "id": "09718be1-0511-4de5-a42b-b04492fa1422",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244720934729476,
                        48.598765941704286,
                    ],
                    [
                        2.244572151507557,
                        48.59873801081761,
                    ],
                    [
                        2.244504345885771,
                        48.59873758052278,
                    ],
                    [
                        2.244436540265101,
                        48.59873715018934,
                    ],
                    [
                        2.244368604868397,
                        48.5987457146083,
                    ],
                    [
                        2.244273547193098,
                        48.59875410681333,
                    ],
                    [
                        2.244219043075124,
                        48.59877175202597,
                    ],
                    [
                        2.244150847968668,
                        48.598798305901475,
                    ],
                    [
                        2.244077703312295,
                        48.59882198569624,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1090,
                    "end": 1140,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "8a24c37a-4316-4086-9eb9-7c41de6f03be",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244077703312295,
                        48.59882198569624,
                    ],
                    [
                        2.244069091646932,
                        48.59882477362575,
                    ],
                    [
                        2.243987335242563,
                        48.5988512412933,
                    ],
                    [
                        2.243892017596039,
                        48.5988776227723,
                    ],
                    [
                        2.243238222615819,
                        48.59907137196787,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1140,
                    "end": 1208,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "9acfb7e6-a2c6-4efa-b8ee-5dd6b836f3e4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235176598965976,
                        48.60037819044877,
                    ],
                    [
                        2.235148424980801,
                        48.60044997434405,
                    ],
                    [
                        2.235120776397827,
                        48.60048577915673,
                    ],
                    [
                        2.235093390526168,
                        48.60050359442633,
                    ],
                    [
                        2.235065610497883,
                        48.60054839399227,
                    ],
                    [
                        2.235024531606149,
                        48.60057511687376,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1208,
                    "end": 1233,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 1.55,
                "gradeSurface": 1.04,
                "gradeStructure": 1.96,
            },
        },
        {
            "id": "99de512b-438e-4699-a719-1b7ea7f8c05a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245704591021183,
                        48.59826842397631,
                    ],
                    [
                        2.245731194862849,
                        48.59830457500901,
                    ],
                    [
                        2.24573054715197,
                        48.59834954898687,
                    ],
                    [
                        2.245743590017591,
                        48.59838561409044,
                    ],
                    [
                        2.24574307185669,
                        48.59842159327055,
                    ],
                    [
                        2.24572886309848,
                        48.59846648132159,
                    ],
                    [
                        2.245714783861307,
                        48.598502374575084,
                    ],
                    [
                        2.245700704604838,
                        48.59853826782582,
                    ],
                    [
                        2.245672934692484,
                        48.59858306993856,
                    ],
                    [
                        2.245659244051239,
                        48.598591978801664,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1233,
                    "end": 1271,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.35,
                "gradeSurface": 5.21,
                "gradeStructure": 5.47,
            },
        },
        {
            "id": "2e004514-e584-49cb-ae81-56fd553d3982",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234116037096767,
                        48.600560280088466,
                    ],
                    [
                        2.233994509140937,
                        48.60052351574594,
                    ],
                    [
                        2.23389984131605,
                        48.60050491535585,
                    ],
                    [
                        2.233832296677201,
                        48.600486489450034,
                    ],
                    [
                        2.23377818204015,
                        48.60047714555756,
                    ],
                    [
                        2.233710505868008,
                        48.600467714348966,
                    ],
                    [
                        2.233534468949251,
                        48.60044858988615,
                    ],
                    [
                        2.233303395893349,
                        48.600483084418464,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1271,
                    "end": 1333,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.1,
                "gradeSurface": 5.21,
                "gradeStructure": 6.83,
            },
        },
        {
            "id": "a491e221-27d3-4ee2-a342-527efdf14737",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228329928620117,
                        48.601125621884286,
                    ],
                    [
                        2.227663944431005,
                        48.60120948144517,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1333,
                    "end": 1383,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.07,
                "gradeSurface": 6.82,
                "gradeStructure": 7.28,
            },
        },
        {
            "id": "852f7718-e4ea-4cbc-85df-b0917907df7a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227663944431005,
                        48.60120948144517,
                    ],
                    [
                        2.227446692231247,
                        48.60123683659587,
                    ],
                    [
                        2.227000854070305,
                        48.6013026541436,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1383,
                    "end": 1433,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.43,
                "gradeSurface": 2.07,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "adbbb087-e080-440d-b67c-a6e0ba4c0b3c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227000854070305,
                        48.6013026541436,
                    ],
                    [
                        2.226454426488252,
                        48.60138331885256,
                    ],
                    [
                        2.226338604665911,
                        48.60139857394609,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1433,
                    "end": 1483,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.43,
                "gradeSurface": 5.12,
                "gradeStructure": 7.5,
            },
        },
        {
            "id": "522cbb18-7e12-419a-bd35-f0155f475c8f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226338604665911,
                        48.60139857394609,
                    ],
                    [
                        2.225673704321897,
                        48.60148614677029,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1483,
                    "end": 1533,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 2.91,
                "gradeSurface": 1.52,
                "gradeStructure": 4.06,
            },
        },
        {
            "id": "0faa6353-3808-4f01-9bba-f1b70a61c236",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225673704321897,
                        48.60148614677029,
                    ],
                    [
                        2.225217776375166,
                        48.6015461938982,
                    ],
                    [
                        2.225008984170844,
                        48.601574318304145,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1533,
                    "end": 1583,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.29,
                "gradeSurface": 6.21,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "93768fd7-8abc-4f95-9fa2-ea184b917fec",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225008984170844,
                        48.601574318304145,
                    ],
                    [
                        2.224198508074539,
                        48.60168348604533,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1583,
                    "end": 1644,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.66,
                "gradeSurface": 6.97,
                "gradeStructure": 8.22,
            },
        },
        {
            "id": "d80d1072-1736-4452-b3ad-c604cd53a12f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245276293069955,
                        48.59881444192839,
                    ],
                    [
                        2.245235480005451,
                        48.59882317878818,
                    ],
                    [
                        2.245167544627809,
                        48.59883174366248,
                    ],
                    [
                        2.245099738879783,
                        48.59883131370682,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1644,
                    "end": 1657,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 3.61,
                "gradeSurface": 3.06,
                "gradeStructure": 4.05,
            },
        },
        {
            "id": "f673597f-630b-4f82-9ffa-ed0d5bab8db6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234371997625077,
                        48.600678869445545,
                    ],
                    [
                        2.234291022503224,
                        48.60065136181902,
                    ],
                    [
                        2.234210178992939,
                        48.60061485937072,
                    ],
                    [
                        2.234169888813415,
                        48.60058761335937,
                    ],
                    [
                        2.234116037096767,
                        48.600560280088466,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1657,
                    "end": 1680,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.56,
                "gradeSurface": 6.26,
                "gradeStructure": 6.8,
            },
        },
        {
            "id": "0031a6cd-afed-4e19-9822-3262053f6c5f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239038401376328,
                        48.599692284449745,
                    ],
                    [
                        2.238374672944726,
                        48.59977799613042,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1680,
                    "end": 1730,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.08,
                "gradeSurface": 5.87,
                "gradeStructure": 6.24,
            },
        },
        {
            "id": "5913ad86-0a2a-4341-8480-f3fd2d43fedc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238374672944726,
                        48.59977799613042,
                    ],
                    [
                        2.237710942340701,
                        48.599863704104685,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1730,
                    "end": 1780,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.3,
                "gradeSurface": 3.82,
                "gradeStructure": 4.7,
            },
        },
        {
            "id": "9b0d7b85-fade-4d17-9758-e6daadaf007e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237710942340701,
                        48.599863704104685,
                    ],
                    [
                        2.237312702935555,
                        48.599915127110165,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1780,
                    "end": 1810,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.63,
                "gradeSurface": 2.52,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "54fa4e0d-6063-4a81-a5e1-46d3e6f95425",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243238222615819,
                        48.59907137196787,
                    ],
                    [
                        2.243020593236649,
                        48.59911496639258,
                    ],
                    [
                        2.242588673904108,
                        48.59919916301417,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1810,
                    "end": 1860,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.38,
                "gradeSurface": 4.18,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "4c2df4e4-6474-4012-ab68-af280582dd65",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.242588673904108,
                        48.59919916301417,
                    ],
                    [
                        2.242394955547445,
                        48.59923692509447,
                    ],
                    [
                        2.24192991169567,
                        48.59930306603167,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1860,
                    "end": 1910,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 7.14,
                "gradeSurface": 4.03,
                "gradeStructure": 9.7,
            },
        },
        {
            "id": "b657bb98-e788-4de3-a90f-66f45528a79b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24192991169567,
                        48.59930306603167,
                    ],
                    [
                        2.24172902179892,
                        48.59933163701516,
                    ],
                    [
                        2.241660954944716,
                        48.59934919466858,
                    ],
                    [
                        2.24157945702026,
                        48.5993576711042,
                    ],
                    [
                        2.241511520351494,
                        48.599366233888425,
                    ],
                    [
                        2.241443583660515,
                        48.599374796633825,
                    ],
                    [
                        2.241362085661038,
                        48.59938327292042,
                    ],
                    [
                        2.241294018616715,
                        48.599400830363315,
                    ],
                    [
                        2.241271705315643,
                        48.599403642693794,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1910,
                    "end": 1960,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.31,
                "gradeSurface": 3.41,
                "gradeStructure": 5.05,
            },
        },
        {
            "id": "bcf02247-952d-4588-ab39-4b8ea6b943bc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241271705315643,
                        48.599403642693794,
                    ],
                    [
                        2.241226081845299,
                        48.599409392984334,
                    ],
                    [
                        2.241158145051668,
                        48.59941795556659,
                    ],
                    [
                        2.241090208235824,
                        48.59942651810995,
                    ],
                    [
                        2.241022271397766,
                        48.599435080614505,
                    ],
                    [
                        2.240954334537494,
                        48.59944364308023,
                    ],
                    [
                        2.240886397655007,
                        48.59945220550715,
                    ],
                    [
                        2.240764215528945,
                        48.59946042195228,
                    ],
                    [
                        2.240641902945548,
                        48.59947763305355,
                    ],
                    [
                        2.240605453323381,
                        48.599483430575745,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1960,
                    "end": 2010,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 4.7,
                "gradeSurface": 2.55,
                "gradeStructure": 6.46,
            },
        },
        {
            "id": "7ee26347-2565-4e57-a0d8-03f2a7ff231c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.240605453323381,
                        48.599483430575745,
                    ],
                    [
                        2.240587527279526,
                        48.59948628181162,
                    ],
                    [
                        2.240478775893605,
                        48.5995035792531,
                    ],
                    [
                        2.240356463115618,
                        48.59952079006051,
                    ],
                    [
                        2.240234150257242,
                        48.59953800074201,
                    ],
                    [
                        2.239941141867376,
                        48.59957095549432,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2010,
                    "end": 2060,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.56,
                "gradeSurface": 8.05,
                "gradeStructure": 5.33,
            },
        },
        {
            "id": "83ead341-342d-41ba-bfa3-581cb80103c9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239941141867376,
                        48.59957095549432,
                    ],
                    [
                        2.239704344575991,
                        48.5995975876076,
                    ],
                    [
                        2.239310151227137,
                        48.59965803873416,
                    ],
                    [
                        2.239228652441652,
                        48.599666513558326,
                    ],
                    [
                        2.239038401376328,
                        48.599692284449745,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2060,
                    "end": 2128,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.27,
                "gradeSurface": 8.12,
                "gradeStructure": 8.39,
            },
        },
        {
            "id": "2b7f6210-46c1-46ab-9099-3455d350bdfd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23276001119596,
                        48.600542552994376,
                    ],
                    [
                        2.23239292589501,
                        48.600603155968045,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2128,
                    "end": 2156,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 8.5,
                "gradeSurface": 8.5,
                "gradeStructure": 8.5,
            },
        },
        {
            "id": "f232ab51-c6e3-44c8-80a3-29b7b82036f7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23239292589501,
                        48.600603155968045,
                    ],
                    [
                        2.231728075920977,
                        48.600686897092686,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2156,
                    "end": 2206,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 5.81,
                "gradeSurface": 5.46,
                "gradeStructure": 6.1,
            },
        },
        {
            "id": "bb40aff8-e827-4a0f-9a2e-5ded5b59327d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231728075920977,
                        48.600686897092686,
                    ],
                    [
                        2.231063223820842,
                        48.600770634498744,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2206,
                    "end": 2256,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 6.88,
                "gradeSurface": 6.14,
                "gradeStructure": 7.5,
            },
        },
        {
            "id": "9db8cf83-8a70-4efb-b36c-ee82cf2dbfb8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231063223820842,
                        48.600770634498744,
                    ],
                    [
                        2.230694424586443,
                        48.60081708264623,
                    ],
                    [
                        2.230398691918322,
                        48.60085547694369,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2256,
                    "end": 2306,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.38,
                "gradeSurface": 8.64,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "464f913e-343c-4c2b-8067-94ea3adcebdf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230398691918322,
                        48.60085547694369,
                    ],
                    [
                        2.23023240338809,
                        48.6008770654562,
                    ],
                    [
                        2.230205280079046,
                        48.600876890076265,
                    ],
                    [
                        2.229960641277874,
                        48.600911290416185,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2306,
                    "end": 2339,
                },
            ],
            "properties": {
                "layer": "c7a0a089-4fd4-4b03-951c-61e6cca636c0",
                "gradeGlobal": 9.12,
                "gradeSurface": 8.06,
                "gradeStructure": 10.0,
            },
        },
    ],
}

export const networkGradingCD91Small2024 = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "55735c2a-0eb4-4e7d-8cfd-107f26958ea8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.240007382242808,
                        48.591051860980855,
                    ],
                    [
                        2.24068807655684,
                        48.591054406162506,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 157,
                    "end": 207,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "d2685f82-a651-472c-b660-899f1fe18aad",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225015677677535,
                        48.59036507637893,
                    ],
                    [
                        2.225267381442702,
                        48.59039999905972,
                    ],
                    [
                        2.225443567781628,
                        48.59040654313984,
                    ],
                    [
                        2.225621096760776,
                        48.590413995263745,
                    ],
                    [
                        2.225687484151923,
                        48.59040929736768,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 0,
                    "end": 50,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.69,
                "gradeSurface": 1.38,
                "gradeStructure": 5.59,
            },
        },
        {
            "id": "b7776282-2596-4707-8441-9dafd4256f33",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225687484151923,
                        48.59040929736768,
                    ],
                    [
                        2.226307962941572,
                        48.59036538742608,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 50,
                    "end": 96,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.96,
                "gradeSurface": 3.45,
                "gradeStructure": 4.38,
            },
        },
        {
            "id": "ce21c8f8-0fd5-4ad4-bf47-c4d9844f5e66",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227016415923118,
                        48.59032411238071,
                    ],
                    [
                        2.227046218994584,
                        48.5903261050659,
                    ],
                    [
                        2.227243834184816,
                        48.59035077703056,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 96,
                    "end": 113,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.11,
                "gradeSurface": 3.61,
                "gradeStructure": 4.52,
            },
        },
        {
            "id": "8d017573-0f69-4ff9-9751-03e5b024fd1b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245281025038835,
                        48.58926289245718,
                    ],
                    [
                        2.245443851200478,
                        48.588878909513916,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 113,
                    "end": 157,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.09,
                "gradeSurface": 3.59,
                "gradeStructure": 4.5,
            },
        },
        {
            "id": "84763734-ef8d-4eb5-a3c6-9f6b55b99b37",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24068807655684,
                        48.591054406162506,
                    ],
                    [
                        2.241028423738649,
                        48.591055677293575,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 207,
                    "end": 232,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 7.58,
                "gradeSurface": 7.37,
                "gradeStructure": 7.75,
            },
        },
        {
            "id": "d548814f-be5c-4c60-9491-c82f789b574b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266503486283121,
                        48.59527601958153,
                    ],
                    [
                        2.266605163071593,
                        48.59527844518431,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 232,
                    "end": 240,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 1.37,
                "gradeSurface": 3.05,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "d21da1ca-a74b-499c-9636-25b238e5fb44",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.265178429598887,
                        48.59499348094964,
                    ],
                    [
                        2.265554040651321,
                        48.59509295171955,
                    ],
                    [
                        2.265668873703221,
                        48.595124245276686,
                    ],
                    [
                        2.265808563447852,
                        48.59515827386706,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 240,
                    "end": 290,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "10a68a96-2493-44c0-89a2-65128cf216b0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.265808563447852,
                        48.59515827386706,
                    ],
                    [
                        2.265899984492156,
                        48.5951805440306,
                    ],
                    [
                        2.266150256571084,
                        48.595224366594614,
                    ],
                    [
                        2.266335628808611,
                        48.595254295222354,
                    ],
                    [
                        2.266503486283121,
                        48.59527601958153,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 290,
                    "end": 343,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "3d2b7fac-6ff5-43ab-bfea-d17ba9768f73",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252364117327363,
                        48.5891150878087,
                    ],
                    [
                        2.252809846590139,
                        48.58952269217839,
                    ],
                    [
                        2.252947183755751,
                        48.58959102180422,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 343,
                    "end": 412,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.46,
                "gradeSurface": 2.09,
                "gradeStructure": 4.58,
            },
        },
        {
            "id": "1e28b79a-adc9-4f77-b407-e333abfab5a6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245647367592548,
                        48.588399829839354,
                    ],
                    [
                        2.245884585655773,
                        48.58784000195692,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 412,
                    "end": 477,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.66,
                "gradeSurface": 2.59,
                "gradeStructure": 4.54,
            },
        },
        {
            "id": "50d0d1b6-9776-4c1c-92da-2061347a3672",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260037981848081,
                        48.591970588370955,
                    ],
                    [
                        2.260195750208991,
                        48.592033639020954,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 477,
                    "end": 491,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.82,
                "gradeSurface": 1.33,
                "gradeStructure": 4.04,
            },
        },
        {
            "id": "fa889ae3-5f32-49a5-9a56-443d0e319420",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.257958336814422,
                        48.59116602435011,
                    ],
                    [
                        2.258236407759133,
                        48.59125591499803,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 491,
                    "end": 514,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.95,
                "gradeSurface": 3.28,
                "gradeStructure": 6.32,
            },
        },
        {
            "id": "fbcf4b05-e717-4a2a-a153-9accadedb49d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.259706264016828,
                        48.59183808883618,
                    ],
                    [
                        2.260037981848081,
                        48.591970588370955,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 514,
                    "end": 543,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.68,
                "gradeSurface": 9.31,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "8ad838bc-4023-44a6-a9d5-d66bfbe6a2b3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243393046767975,
                        48.59135859321727,
                    ],
                    [
                        2.244331143087355,
                        48.591472500609314,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 543,
                    "end": 613,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "ddd57bb0-3b86-410e-b66a-cc88ea9f6458",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.262359016053809,
                        48.5931643232664,
                    ],
                    [
                        2.2626585117207,
                        48.59346753263306,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 613,
                    "end": 653,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "1255decf-e05c-4273-8d32-bca2d89db78e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264105386710302,
                        48.59463152505042,
                    ],
                    [
                        2.264197254166367,
                        48.594656381135636,
                    ],
                    [
                        2.264361575647428,
                        48.59473655823509,
                    ],
                    [
                        2.2644719728534,
                        48.594793912856424,
                    ],
                    [
                        2.264590594797886,
                        48.59484502124902,
                    ],
                    [
                        2.264673358435388,
                        48.59486768483097,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 653,
                    "end": 703,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 8.51,
                "gradeSurface": 7.65,
                "gradeStructure": 9.21,
            },
        },
        {
            "id": "04f3a79b-76bd-439e-aa30-74362756e654",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264673358435388,
                        48.59486768483097,
                    ],
                    [
                        2.264728390608184,
                        48.594882754523915,
                    ],
                    [
                        2.265178429598887,
                        48.59499348094964,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 703,
                    "end": 743,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.54,
                "gradeSurface": 0.0,
                "gradeStructure": 8.27,
            },
        },
        {
            "id": "fb1cb71b-d193-406f-b83e-83c8e61c0ea9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251634370899419,
                        48.587517366133646,
                    ],
                    [
                        2.251723316164496,
                        48.58774551628873,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 743,
                    "end": 769,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "03636116-d3fc-401e-a724-77756e1af17b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.270071032374565,
                        48.59540679590989,
                    ],
                    [
                        2.270745766254636,
                        48.595383377286986,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 769,
                    "end": 819,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "8be3889b-10ab-4cca-b3ef-72010eb97beb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.270745766254636,
                        48.595383377286986,
                    ],
                    [
                        2.271568940771172,
                        48.59535480138738,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 819,
                    "end": 880,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5487d41c-3d11-41e1-a680-19e2b1cb4b93",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244331143087355,
                        48.591472500609314,
                    ],
                    [
                        2.244551560390845,
                        48.59095125133485,
                    ],
                    [
                        2.244559324153404,
                        48.59093276322005,
                    ],
                    [
                        2.24456700727779,
                        48.59091446711807,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 880,
                    "end": 944,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.07,
                "gradeSurface": 1.93,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "87364c51-941b-4b55-9c25-64325432d382",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245443851200478,
                        48.588878909513916,
                    ],
                    [
                        2.245647367592548,
                        48.588399829839354,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 944,
                    "end": 999,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.56,
                "gradeSurface": 0.13,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "37445645-037f-465d-9e53-0329cdbce419",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244621742678838,
                        48.590786176379915,
                    ],
                    [
                        2.244749474864165,
                        48.59048563186046,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 999,
                    "end": 1034,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.95,
                "gradeSurface": 0.0,
                "gradeStructure": 7.18,
            },
        },
        {
            "id": "5ad96aec-68ac-42ca-aa9d-78843e76ee2c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233311930114299,
                        48.59109887871172,
                    ],
                    [
                        2.233625567115902,
                        48.591072112341124,
                    ],
                    [
                        2.233827966425581,
                        48.591048227437646,
                    ],
                    [
                        2.233981196747314,
                        48.591029648654974,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1034,
                    "end": 1084,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "1b726202-83f1-43ee-bcd4-333063d698e8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233981196747314,
                        48.591029648654974,
                    ],
                    [
                        2.234546584455256,
                        48.5909610950254,
                    ],
                    [
                        2.234649226743283,
                        48.590957177517325,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1084,
                    "end": 1134,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 1.67,
                "gradeSurface": 3.72,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "0ca15f6a-fac7-469e-9873-d408255eecd9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234649226743283,
                        48.590957177517325,
                    ],
                    [
                        2.234849149363289,
                        48.59094954689306,
                    ],
                    [
                        2.235393236627949,
                        48.59092785534854,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1134,
                    "end": 1189,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 1.56,
                "gradeSurface": 3.47,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "43f3df52-9e42-4d82-b3cb-27f6a98e0c92",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218411516972762,
                        48.5893872386649,
                    ],
                    [
                        2.218587873483341,
                        48.589382099617815,
                    ],
                    [
                        2.219083755613285,
                        48.589421112669164,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1189,
                    "end": 1239,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 8.06,
                "gradeSurface": 7.89,
                "gradeStructure": 8.19,
            },
        },
        {
            "id": "50b2cccc-a335-4fa7-8691-c641ecbbe14c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.219083755613285,
                        48.589421112669164,
                    ],
                    [
                        2.21929893900858,
                        48.589438041355365,
                    ],
                    [
                        2.219710894959912,
                        48.589456035471116,
                    ],
                    [
                        2.21975714312672,
                        48.58945404859105,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1239,
                    "end": 1289,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.64,
                "gradeSurface": 1.29,
                "gradeStructure": 7.39,
            },
        },
        {
            "id": "999830c0-cfc0-416e-9fa4-2f6dbade12f0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.21975714312672,
                        48.58945404859105,
                    ],
                    [
                        2.220037900239427,
                        48.589441986519525,
                    ],
                    [
                        2.220308119576359,
                        48.589416769840554,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1289,
                    "end": 1330,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.73,
                "gradeSurface": 0.0,
                "gradeStructure": 4.97,
            },
        },
        {
            "id": "ad21eacb-432f-4a27-862f-3cea9f7a0a61",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.268101259803128,
                        48.59545317261242,
                    ],
                    [
                        2.268328972528086,
                        48.595461768968306,
                    ],
                    [
                        2.268779739732923,
                        48.59544418179753,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1330,
                    "end": 1380,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "f7668fbd-d86c-44ec-800d-02b43410640d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.268779739732923,
                        48.59544418179753,
                    ],
                    [
                        2.268826950025991,
                        48.595442339737346,
                    ],
                    [
                        2.269458691924116,
                        48.59542417215121,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1380,
                    "end": 1430,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "54c4e373-2ced-4144-b493-a12bcb545cb3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.269458691924116,
                        48.59542417215121,
                    ],
                    [
                        2.269961143770789,
                        48.59540972027586,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1430,
                    "end": 1467,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.01,
                "gradeSurface": 5.67,
                "gradeStructure": 6.29,
            },
        },
        {
            "id": "044a1eaf-d460-40d6-a81a-fd26ed6c7973",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24456700727779,
                        48.59091446711807,
                    ],
                    [
                        2.244595059451889,
                        48.590849876314,
                    ],
                    [
                        2.244621742678838,
                        48.590786176379915,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1467,
                    "end": 1482,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.22,
                "gradeSurface": 2.69,
                "gradeStructure": 1.84,
            },
        },
        {
            "id": "18462783-bada-4542-8099-e83c5303abca",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24992923168966,
                        48.58737799395477,
                    ],
                    [
                        2.250307106215488,
                        48.587408261418275,
                    ],
                    [
                        2.250453380342712,
                        48.58741997753347,
                    ],
                    [
                        2.250600747603817,
                        48.587431781018196,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1482,
                    "end": 1532,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.53,
                "gradeSurface": 3.54,
                "gradeStructure": 7.16,
            },
        },
        {
            "id": "fb910237-f861-4b41-9feb-41016fbcbe7d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.250600747603817,
                        48.587431781018196,
                    ],
                    [
                        2.250855634529942,
                        48.587452195922815,
                    ],
                    [
                        2.251114313509566,
                        48.58747361437618,
                    ],
                    [
                        2.251272020066682,
                        48.58748688227337,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1532,
                    "end": 1582,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.76,
                "gradeSurface": 3.98,
                "gradeStructure": 7.21,
            },
        },
        {
            "id": "45d121a7-b8d4-427b-90d0-0d8c97e8e9be",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251272020066682,
                        48.58748688227337,
                    ],
                    [
                        2.251634370899419,
                        48.587517366133646,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1582,
                    "end": 1609,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.59,
                "gradeSurface": 3.05,
                "gradeStructure": 4.04,
            },
        },
        {
            "id": "661c04ad-2d7a-4c30-bb57-ac86d082c9a7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272927829586205,
                        48.59515710966892,
                    ],
                    [
                        2.272964292413546,
                        48.59516812710916,
                    ],
                    [
                        2.272989919542462,
                        48.59517817879916,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1609,
                    "end": 1614,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.59,
                "gradeSurface": 3.05,
                "gradeStructure": 4.04,
            },
        },
        {
            "id": "05cbeed6-3177-4ddb-bd62-2ac1e60e7b86",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.254489680129126,
                        48.590111647306394,
                    ],
                    [
                        2.255100240186535,
                        48.59030890899787,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1614,
                    "end": 1664,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.82,
                "gradeSurface": 3.86,
                "gradeStructure": 7.42,
            },
        },
        {
            "id": "4427e72f-ec39-4688-b806-27501cf15958",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.255100240186535,
                        48.59030890899787,
                    ],
                    [
                        2.255710804842384,
                        48.590506167525675,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1664,
                    "end": 1714,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.55,
                "gradeSurface": 0.0,
                "gradeStructure": 1.0,
            },
        },
        {
            "id": "a539b8b2-3997-4779-94e9-13207ef7c57f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.255710804842384,
                        48.590506167525675,
                    ],
                    [
                        2.256025772580531,
                        48.590607924091245,
                    ],
                    [
                        2.256096011319825,
                        48.5906272539886,
                    ],
                    [
                        2.256328552764937,
                        48.59069294689583,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1714,
                    "end": 1764,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.85,
                "gradeSurface": 0.0,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "e803a20d-a864-4a7e-8067-6e476265bd87",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.256328552764937,
                        48.59069294689583,
                    ],
                    [
                        2.256953384925944,
                        48.59086945876026,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1764,
                    "end": 1814,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "77d95177-d2a7-4e66-afb2-ab498c59d471",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.256953384925944,
                        48.59086945876026,
                    ],
                    [
                        2.257578221297905,
                        48.591045967319275,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1814,
                    "end": 1864,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.64,
                "gradeSurface": 9.2,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "cf9826a0-f97d-4764-b0fa-b743c2141525",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.257578221297905,
                        48.591045967319275,
                    ],
                    [
                        2.257633013725251,
                        48.59106144528901,
                    ],
                    [
                        2.257870597170255,
                        48.591137590796926,
                    ],
                    [
                        2.257958336814422,
                        48.59116602435011,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1864,
                    "end": 1895,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.58,
                "gradeSurface": 3.46,
                "gradeStructure": 5.5,
            },
        },
        {
            "id": "5a690467-9547-4f47-8e6c-b51bbef4a0c5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227502345364066,
                        48.590383940033504,
                    ],
                    [
                        2.227699762059131,
                        48.590422103490546,
                    ],
                    [
                        2.228142265631698,
                        48.59052860396992,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1895,
                    "end": 1945,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.2,
                "gradeSurface": 0.0,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "664af956-4343-4a56-94ea-faec130a4efd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228142265631698,
                        48.59052860396992,
                    ],
                    [
                        2.228994247297491,
                        48.59073365057348,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1945,
                    "end": 2012,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.15,
                "gradeSurface": 0.0,
                "gradeStructure": 5.73,
            },
        },
        {
            "id": "ec74e9da-42a9-476d-89d2-2987cb77589c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.2626585117207,
                        48.59346753263306,
                    ],
                    [
                        2.263027905109332,
                        48.59384650422117,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2012,
                    "end": 2062,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 7.15,
                "gradeSurface": 3.66,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "fe08ba86-813c-491c-a2cd-73db95abe67a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263027905109332,
                        48.59384650422117,
                    ],
                    [
                        2.26319854091862,
                        48.59402156225961,
                    ],
                    [
                        2.263397303849292,
                        48.59422547454296,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2062,
                    "end": 2112,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "4543dccd-5130-4c66-aa64-5ecd8d5a02d5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263397303849292,
                        48.59422547454296,
                    ],
                    [
                        2.263527440420881,
                        48.59435898150543,
                    ],
                    [
                        2.263584825309199,
                        48.59442500460388,
                    ],
                    [
                        2.263665464761674,
                        48.59447677852108,
                    ],
                    [
                        2.263769358781453,
                        48.5945143032116,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2112,
                    "end": 2155,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.46,
                "gradeSurface": 2.13,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "32b2d509-665d-48fb-8d9d-920d92a15fa7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238252460816152,
                        48.59115938573596,
                    ],
                    [
                        2.238549471634791,
                        48.59115678832945,
                    ],
                    [
                        2.238690748282967,
                        48.591139700678184,
                    ],
                    [
                        2.238926555919239,
                        48.591114234458864,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2155,
                    "end": 2205,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.83,
                "gradeSurface": 10.0,
                "gradeStructure": 9.7,
            },
        },
        {
            "id": "3f5cc579-6aeb-4004-bcb7-7f96127e341a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238926555919239,
                        48.591114234458864,
                    ],
                    [
                        2.239327730410625,
                        48.591070908192705,
                    ],
                    [
                        2.239600068523258,
                        48.5910574084972,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2205,
                    "end": 2255,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.86,
                "gradeSurface": 10.0,
                "gradeStructure": 9.76,
            },
        },
        {
            "id": "220b73a3-6498-41b7-8ac5-8d0289c4bddb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239600068523258,
                        48.5910574084972,
                    ],
                    [
                        2.239745691098462,
                        48.591050189783566,
                    ],
                    [
                        2.240007382242808,
                        48.591051860980855,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2255,
                    "end": 2285,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "25504b2a-fc07-43f5-a957-a0c72eef2a90",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272815998691309,
                        48.595202304172915,
                    ],
                    [
                        2.27282017916184,
                        48.59519423366146,
                    ],
                    [
                        2.272836688735993,
                        48.595177242854874,
                    ],
                    [
                        2.272858572446654,
                        48.59516388311822,
                    ],
                    [
                        2.272884424321541,
                        48.59515774410491,
                    ],
                    [
                        2.272915625367478,
                        48.59515703512555,
                    ],
                    [
                        2.272927829586205,
                        48.59515710966892,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2285,
                    "end": 2296,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "8f0a1219-81e1-4383-86a7-1f1d023d5b88",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252072967855186,
                        48.588613098850956,
                    ],
                    [
                        2.252162199402648,
                        48.588821459755245,
                    ],
                    [
                        2.252232480474539,
                        48.58893254817359,
                    ],
                    [
                        2.252304092029148,
                        48.58904544403208,
                    ],
                    [
                        2.252364117327363,
                        48.5891150878087,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2296,
                    "end": 2356,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.14,
                "gradeSurface": 0.0,
                "gradeStructure": 5.71,
            },
        },
        {
            "id": "460624b0-8424-4c0e-8042-f7d3cdf4db43",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241028423738649,
                        48.591055677293575,
                    ],
                    [
                        2.241248016499596,
                        48.591061575153915,
                    ],
                    [
                        2.241705579884587,
                        48.59108762214054,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2356,
                    "end": 2406,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 7.67,
                "gradeSurface": 7.47,
                "gradeStructure": 7.83,
            },
        },
        {
            "id": "aa71a337-28a6-4477-8cdd-3f25edec8692",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241705579884587,
                        48.59108762214054,
                    ],
                    [
                        2.241781855714192,
                        48.59109196400036,
                    ],
                    [
                        2.242361286771423,
                        48.5912014125185,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2406,
                    "end": 2456,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.69,
                "gradeSurface": 0.0,
                "gradeStructure": 4.89,
            },
        },
        {
            "id": "ca06e2b8-49e4-4394-935f-f982b4abf386",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.242361286771423,
                        48.5912014125185,
                    ],
                    [
                        2.242447209285567,
                        48.591217642110514,
                    ],
                    [
                        2.242970714367253,
                        48.591306431697376,
                    ],
                    [
                        2.243019218855413,
                        48.591312422481565,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2456,
                    "end": 2506,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 8.32,
                "gradeSurface": 6.28,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "3dd58265-52e2-446f-bdde-504880cf89de",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243019218855413,
                        48.591312422481565,
                    ],
                    [
                        2.243393046767975,
                        48.59135859321727,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2506,
                    "end": 2534,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 8.84,
                "gradeSurface": 7.42,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "56f27615-9dd1-4c17-b9ed-079961e59492",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260670350036127,
                        48.59222729554731,
                    ],
                    [
                        2.260933261997554,
                        48.59233507688387,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2534,
                    "end": 2557,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.98,
                "gradeSurface": 6.69,
                "gradeStructure": 5.41,
            },
        },
        {
            "id": "79cf48d7-3c70-46ec-a8fb-70af5eaba0f8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.262097744337868,
                        48.59293961207076,
                    ],
                    [
                        2.26225338931049,
                        48.593057520135886,
                    ],
                    [
                        2.262359016053809,
                        48.5931643232664,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2557,
                    "end": 2589,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "032f77aa-74d0-4bfd-9bfa-9aa9a497851b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230987966507441,
                        48.59098673327829,
                    ],
                    [
                        2.23158324720736,
                        48.59108053465442,
                    ],
                    [
                        2.231800061224829,
                        48.591090929854175,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2589,
                    "end": 2650,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.85,
                "gradeSurface": 3.97,
                "gradeStructure": 7.39,
            },
        },
        {
            "id": "9d1e8a17-935c-4709-b2a6-c7e6ad946071",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.267079533202802,
                        48.59529845852845,
                    ],
                    [
                        2.267242004769074,
                        48.595317449996585,
                    ],
                    [
                        2.267737579492773,
                        48.595398947625,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2650,
                    "end": 2700,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "d892aa8b-20fa-47b1-9826-5be1f901b4bf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.267737579492773,
                        48.595398947625,
                    ],
                    [
                        2.26781027894929,
                        48.59541090290887,
                    ],
                    [
                        2.268094542494381,
                        48.59544863350431,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2700,
                    "end": 2727,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.04,
                "gradeSurface": 7.87,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "3857b416-a44e-4667-875e-a3e0ebcdb9d2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231800061224829,
                        48.591090929854175,
                    ],
                    [
                        2.232057684580599,
                        48.59109259227569,
                    ],
                    [
                        2.232478541470151,
                        48.59109923995767,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2727,
                    "end": 2777,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "a4fcdc3d-47ec-4b07-9fc7-369be79722b0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.232478541470151,
                        48.59109923995767,
                    ],
                    [
                        2.233020250821476,
                        48.59110779438247,
                    ],
                    [
                        2.23330525587662,
                        48.59109163917423,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2777,
                    "end": 2838,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "239bc0e3-f159-445d-a1ae-db7135fb0985",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264026288555832,
                        48.594470014350414,
                    ],
                    [
                        2.264053282271756,
                        48.59447917686966,
                    ],
                    [
                        2.264080023233878,
                        48.594506329069546,
                    ],
                    [
                        2.264093330535625,
                        48.594524402588775,
                    ],
                    [
                        2.264106511469352,
                        48.59455147094932,
                    ],
                    [
                        2.264119692416701,
                        48.59457853930782,
                    ],
                    [
                        2.264119439666555,
                        48.59459652899354,
                    ],
                    [
                        2.264105626827692,
                        48.5946144348495,
                    ],
                    [
                        2.264105500450138,
                        48.594623429692135,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2838,
                    "end": 2858,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.7,
                "gradeSurface": 10.0,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "ea1c3f18-2991-45a6-ad53-772e4addbbe4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235382428748355,
                        48.59092508721546,
                    ],
                    [
                        2.236047611165768,
                        48.590875384345246,
                    ],
                    [
                        2.23605657486973,
                        48.59087544187699,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2858,
                    "end": 2908,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "58abcb2f-3eaa-4600-923e-43825c414583",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23605657486973,
                        48.59087544187699,
                    ],
                    [
                        2.236223879142496,
                        48.59087651556196,
                    ],
                    [
                        2.236670804338019,
                        48.59091536530092,
                    ],
                    [
                        2.237036244598194,
                        48.590962686744255,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2908,
                    "end": 2981,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "8c76b72b-2aa1-4c6d-a6a1-0482b65eb2ed",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245082295523098,
                        48.58969162576154,
                    ],
                    [
                        2.245278339223788,
                        48.589261076289795,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2981,
                    "end": 3031,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.2,
                "gradeSurface": 0.0,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "16dcd91e-57ec-4dc1-981a-361ab7d6df6e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222677507175272,
                        48.58993422792878,
                    ],
                    [
                        2.223055953105312,
                        48.59001766020523,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3031,
                    "end": 3060,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.63,
                "gradeSurface": 6.1,
                "gradeStructure": 3.43,
            },
        },
        {
            "id": "485eaee1-abab-4eb4-ba17-66e8257fa159",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223055953105312,
                        48.59001766020523,
                    ],
                    [
                        2.223150331327859,
                        48.5900542590406,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3060,
                    "end": 3068,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "b1e69eef-a32d-4ae3-adcf-14a11f13fa8f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244745472013664,
                        48.59048110862366,
                    ],
                    [
                        2.244927793207683,
                        48.59005000558732,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3068,
                    "end": 3118,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.83,
                "gradeSurface": 4.0,
                "gradeStructure": 3.7,
            },
        },
        {
            "id": "e56e9837-53c9-4ed6-ab51-1cf8303bf0d3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244927793207683,
                        48.59005000558732,
                    ],
                    [
                        2.245026245404668,
                        48.58981720976851,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3118,
                    "end": 3145,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5b5f2650-e261-442a-8e75-eff7b60ab5cf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245881951688872,
                        48.58783458785189,
                    ],
                    [
                        2.245937997634592,
                        48.587709003238885,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3145,
                    "end": 3160,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 7.06,
                "gradeSurface": 6.81,
                "gradeStructure": 7.27,
            },
        },
        {
            "id": "bc226479-7cd9-47f4-a378-0e8ed77f41ce",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245026245404668,
                        48.58981720976851,
                    ],
                    [
                        2.245082295523098,
                        48.58969162576154,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3160,
                    "end": 3175,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.59,
                "gradeSurface": 3.05,
                "gradeStructure": 4.04,
            },
        },
        {
            "id": "fa4a6a47-6859-455c-8b26-464e7f0fb364",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.271567609761538,
                        48.595352994120354,
                    ],
                    [
                        2.271798634957881,
                        48.5953184251789,
                    ],
                    [
                        2.271920802543982,
                        48.5953101768557,
                    ],
                    [
                        2.272042970091606,
                        48.59530192840703,
                    ],
                    [
                        2.272151702320987,
                        48.59528460207387,
                    ],
                    [
                        2.272237911739468,
                        48.59527798869603,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3175,
                    "end": 3225,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.59,
                "gradeSurface": 3.05,
                "gradeStructure": 4.04,
            },
        },
        {
            "id": "774e4df1-bb7e-4001-8616-0d6c2f504eed",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.229653908522265,
                        48.590789807933845,
                    ],
                    [
                        2.229892060330787,
                        48.59081772537327,
                    ],
                    [
                        2.230315640927514,
                        48.590883050240045,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4178,
                    "end": 4228,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 7.48,
                "gradeSurface": 7.27,
                "gradeStructure": 7.66,
            },
        },
        {
            "id": "766f58aa-70fa-4deb-b2fb-7b0e66a1f01f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272237911739468,
                        48.59527798869603,
                    ],
                    [
                        2.272260309499734,
                        48.595276270487524,
                    ],
                    [
                        2.272355481335445,
                        48.595258861065766,
                    ],
                    [
                        2.2724640884284,
                        48.59525052929332,
                    ],
                    [
                        2.27255913522231,
                        48.595242114555255,
                    ],
                    [
                        2.272640496809923,
                        48.595242611731074,
                    ],
                    [
                        2.272708547937443,
                        48.59522503630746,
                    ],
                    [
                        2.272762913871227,
                        48.59521637284879,
                    ],
                    [
                        2.27280384441629,
                        48.59519863167907,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3225,
                    "end": 3268,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 1.89,
                "gradeSurface": 4.0,
                "gradeStructure": 0.17,
            },
        },
        {
            "id": "17c550b7-cf22-4c8f-8576-413fc50e9442",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.269953057738655,
                        48.59540607244044,
                    ],
                    [
                        2.270061540162361,
                        48.59540673770405,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3268,
                    "end": 3276,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.58,
                "gradeSurface": 10.0,
                "gradeStructure": 9.25,
            },
        },
        {
            "id": "0c55e55e-c67a-4f4f-be1d-a6219dc9b18d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.248710336183967,
                        48.58727675084185,
                    ],
                    [
                        2.249380086733255,
                        48.587330403575216,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3276,
                    "end": 3326,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 8.83,
                "gradeSurface": 7.71,
                "gradeStructure": 9.76,
            },
        },
        {
            "id": "e24b9622-fa56-4392-89cb-cc5a149cd824",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.249380086733255,
                        48.587330403575216,
                    ],
                    [
                        2.249929283207345,
                        48.58737439600259,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3326,
                    "end": 3367,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.53,
                "gradeSurface": 2.29,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "4ebb330c-6603-46cd-8c96-7d572123485c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246949973397527,
                        48.5871126988657,
                    ],
                    [
                        2.247044621653368,
                        48.58713128908192,
                    ],
                    [
                        2.247125841122561,
                        48.58714079857906,
                    ],
                    [
                        2.247619504887908,
                        48.58718315807548,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3367,
                    "end": 3417,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.9,
                "gradeSurface": 0.0,
                "gradeStructure": 5.27,
            },
        },
        {
            "id": "0d9eed72-beb9-4aa1-ac05-6132769e8c15",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.247619504887908,
                        48.58718315807548,
                    ],
                    [
                        2.248292857079386,
                        48.58724093256982,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3417,
                    "end": 3467,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.82,
                "gradeSurface": 0.0,
                "gradeStructure": 6.94,
            },
        },
        {
            "id": "48850b4e-51a1-413c-b1a7-f1869e6020f3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.248292857079386,
                        48.58724093256982,
                    ],
                    [
                        2.248710336183967,
                        48.58727675084185,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3467,
                    "end": 3498,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.65,
                "gradeSurface": 9.22,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "f8fbb813-cd74-490a-ae9c-35cf630c89d0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220488075110151,
                        48.58944313653914,
                    ],
                    [
                        2.220663401156132,
                        48.58950725462925,
                    ],
                    [
                        2.220933638614959,
                        48.589571993734936,
                    ],
                    [
                        2.221117383369897,
                        48.5896037486121,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3498,
                    "end": 3548,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.47,
                "gradeSurface": 2.83,
                "gradeStructure": 5.8,
            },
        },
        {
            "id": "cfdb4f87-96b8-4a7f-a424-fc9b4e958766",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221117383369897,
                        48.5896037486121,
                    ],
                    [
                        2.221312346577803,
                        48.589637441915784,
                    ],
                    [
                        2.22174515683694,
                        48.589712238356995,
                    ],
                    [
                        2.221772144569008,
                        48.589718664177546,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3548,
                    "end": 3598,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.28,
                "gradeSurface": 2.56,
                "gradeStructure": 2.05,
            },
        },
        {
            "id": "817c814e-fa0f-4f8a-80a0-17dea8b19823",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221772144569008,
                        48.589718664177546,
                    ],
                    [
                        2.222677507175272,
                        48.58993422792878,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3598,
                    "end": 3669,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.86,
                "gradeSurface": 3.84,
                "gradeStructure": 2.05,
            },
        },
        {
            "id": "031868e6-b702-4026-9b8a-5016b4d573f5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220298655284184,
                        48.58941490869979,
                    ],
                    [
                        2.220299190832285,
                        48.58937892936736,
                    ],
                    [
                        2.22029945860606,
                        48.58936093970086,
                    ],
                    [
                        2.220313285031424,
                        48.589343038853904,
                    ],
                    [
                        2.220327111447339,
                        48.58932513800515,
                    ],
                    [
                        2.220367921264103,
                        48.589316409617226,
                    ],
                    [
                        2.220422289710097,
                        48.58930777002277,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3669,
                    "end": 3687,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "d945b0c3-ce14-42b7-a827-6cda126cfb3c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223150331327859,
                        48.5900542590406,
                    ],
                    [
                        2.223542204409086,
                        48.59014677308882,
                    ],
                    [
                        2.223799023130384,
                        48.590202422372286,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3687,
                    "end": 3738,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 1.57,
                "gradeSurface": 1.56,
                "gradeStructure": 1.58,
            },
        },
        {
            "id": "8904fd8e-b7fb-49b0-b54d-356e017937f4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246303195641254,
                        48.58682973813434,
                    ],
                    [
                        2.246437482165626,
                        48.5869205454496,
                    ],
                    [
                        2.246558599179702,
                        48.586984282138495,
                    ],
                    [
                        2.246679845839838,
                        48.58703902382132,
                    ],
                    [
                        2.246801222083986,
                        48.58708477049887,
                    ],
                    [
                        2.246949973397527,
                        48.5871126988657,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3738,
                    "end": 3796,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.35,
                "gradeSurface": 3.81,
                "gradeStructure": 1.16,
            },
        },
        {
            "id": "b1ea4e11-3282-457f-981a-3dcf9f5a9b17",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245937997634592,
                        48.587709003238885,
                    ],
                    [
                        2.246148620588371,
                        48.58720657851872,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3796,
                    "end": 3854,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.24,
                "gradeSurface": 1.72,
                "gradeStructure": 6.3,
            },
        },
        {
            "id": "90b9ef68-3a20-4363-a290-f62be46320c7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260195851843144,
                        48.59202644313497,
                    ],
                    [
                        2.260667638143122,
                        48.592227278702936,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3854,
                    "end": 3895,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.25,
                "gradeSurface": 0.0,
                "gradeStructure": 4.1,
            },
        },
        {
            "id": "fdbd4ec5-6ea5-497f-9f4e-aa2d61a2fa3a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246148620588371,
                        48.58720657851872,
                    ],
                    [
                        2.246135709672301,
                        48.587161518252096,
                    ],
                    [
                        2.246136227452157,
                        48.587125538739265,
                    ],
                    [
                        2.246123187119717,
                        48.58708947334693,
                    ],
                    [
                        2.246083160054717,
                        48.58704424130872,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3895,
                    "end": 3914,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.45,
                "gradeSurface": 0.0,
                "gradeStructure": 4.46,
            },
        },
        {
            "id": "7ca4304f-507d-4401-80a2-1e121d4afe15",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246031128522139,
                        48.586890984811696,
                    ],
                    [
                        2.246126552727344,
                        48.5868556064813,
                    ],
                    [
                        2.246303195641254,
                        48.58682973813434,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3914,
                    "end": 3935,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "97ed1dad-bd1e-4928-9eb0-54c5544fae6a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263768015418775,
                        48.59451339534038,
                    ],
                    [
                        2.263781954779235,
                        48.59448649467893,
                    ],
                    [
                        2.26382288779563,
                        48.59446875658675,
                    ],
                    [
                        2.26387738083015,
                        48.59445110233729,
                    ],
                    [
                        2.263918060967959,
                        48.59445135389924,
                    ],
                    [
                        2.263985734800781,
                        48.59446076798191,
                    ],
                    [
                        2.264026288555832,
                        48.594470014350414,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3935,
                    "end": 3958,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "9df6552c-df83-4a26-90b0-77039b3d8836",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223799023130384,
                        48.590202422372286,
                    ],
                    [
                        2.224110211212378,
                        48.590249429826805,
                    ],
                    [
                        2.224489194381047,
                        48.590296878202295,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3958,
                    "end": 4010,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "dd753baa-b45d-4417-8d30-b8a02adeaa3a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260991288624389,
                        48.59235522757761,
                    ],
                    [
                        2.261409223236956,
                        48.59252873883574,
                    ],
                    [
                        2.261570923309089,
                        48.59260170721559,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4010,
                    "end": 4061,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "c80015cd-8453-45d6-98fc-86c839f1fbb8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251713825361156,
                        48.58774545661947,
                    ],
                    [
                        2.251752829966686,
                        48.587862645772354,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4061,
                    "end": 4074,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "7d52adea-ae5a-416a-b654-314976690e9a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.261570923309089,
                        48.59260170721559,
                    ],
                    [
                        2.261705631451685,
                        48.592665512312514,
                    ],
                    [
                        2.261853519240199,
                        48.59275638590341,
                    ],
                    [
                        2.262095057751275,
                        48.59293779628834,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4074,
                    "end": 4128,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "76a99b3c-b5e7-4136-bbf9-c12fdffe4444",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228984795698721,
                        48.59073089064207,
                    ],
                    [
                        2.229079444114002,
                        48.5907494950892,
                    ],
                    [
                        2.229269005846819,
                        48.59076871409082,
                    ],
                    [
                        2.229648394300119,
                        48.59078916152099,
                    ],
                    [
                        2.229653908522265,
                        48.590789807933845,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4128,
                    "end": 4178,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "861a555e-2a90-4358-b115-fdfe1775e8ec",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230315640927514,
                        48.590883050240045,
                    ],
                    [
                        2.230987966507441,
                        48.59098673327829,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4228,
                    "end": 4279,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.65,
                "gradeSurface": 2.56,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "966ed0c4-5d65-41bd-b502-8c515348e7f4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252935019347687,
                        48.5895882467518,
                    ],
                    [
                        2.253123944366242,
                        48.589652402117025,
                    ],
                    [
                        2.253164364236825,
                        48.589670647089456,
                    ],
                    [
                        2.253474806342719,
                        48.589771546990896,
                    ],
                    [
                        2.253540531234517,
                        48.58979290874217,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4279,
                    "end": 4329,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "39ec1862-f494-4163-acd7-8cb657dd3d9a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.253540531234517,
                        48.58979290874217,
                    ],
                    [
                        2.253785249644546,
                        48.58987244607428,
                    ],
                    [
                        2.254147997039421,
                        48.5899956238594,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4329,
                    "end": 4379,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "2d508c28-0a97-46a3-b78f-5704ce035ebb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.254147997039421,
                        48.5899956238594,
                    ],
                    [
                        2.254486981149475,
                        48.59011073083464,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4379,
                    "end": 4407,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5b11510d-625f-4b1b-96a3-ac5a2409ce70",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22724112239995,
                        48.590350759424624,
                    ],
                    [
                        2.227498344010716,
                        48.59037941621639,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4407,
                    "end": 4426,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "e59f2a28-9666-46d9-9577-c1145b3c0717",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260923871886935,
                        48.592327822064405,
                    ],
                    [
                        2.260991288624389,
                        48.59235522757761,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4426,
                    "end": 4432,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.2,
                "gradeSurface": 0.0,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "e1e2ba01-ab5f-42fa-ae2e-0246182f512b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237036244598194,
                        48.590962686744255,
                    ],
                    [
                        2.237442362854638,
                        48.591010267674186,
                    ],
                    [
                        2.237672344134869,
                        48.59104772356566,
                    ],
                    [
                        2.237696814345452,
                        48.59105253155738,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4432,
                    "end": 4482,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.53,
                "gradeSurface": 8.96,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "a366b593-af9e-49a6-954d-47031cf53799",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237696814345452,
                        48.59105253155738,
                    ],
                    [
                        2.238240257579361,
                        48.59115930763737,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4482,
                    "end": 4524,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.01,
                "gradeSurface": 0.0,
                "gradeStructure": 0.01,
            },
        },
        {
            "id": "a0c48f38-b60d-4fb7-bd3f-f4531ef71b52",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.258227005477598,
                        48.59124955944555,
                    ],
                    [
                        2.258810262595666,
                        48.59148165387914,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4524,
                    "end": 4574,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.74,
                "gradeSurface": 3.2,
                "gradeStructure": 4.17,
            },
        },
        {
            "id": "a9808263-5f01-4415-9a99-8b79b0f523eb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.258810262595666,
                        48.59148165387914,
                    ],
                    [
                        2.259393524882363,
                        48.591713745410765,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4574,
                    "end": 4624,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.56,
                "gradeSurface": 5.18,
                "gradeStructure": 5.87,
            },
        },
        {
            "id": "73905d11-e830-48d9-86c9-5626a59d1cd4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.259393524882363,
                        48.591713745410765,
                    ],
                    [
                        2.259696823314087,
                        48.59183443186032,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4624,
                    "end": 4650,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.5,
                "gradeSurface": 2.22,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5e42db44-accb-4d14-83fe-4903750ac65e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246083160054717,
                        48.58704424130872,
                    ],
                    [
                        2.246031128522139,
                        48.586890984811696,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4650,
                    "end": 4667,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.48,
                "gradeSurface": 0.0,
                "gradeStructure": 4.52,
            },
        },
        {
            "id": "fd36d1b4-f355-490f-aec1-afe10664ecc1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224489194381047,
                        48.590296878202295,
                    ],
                    [
                        2.225003501262026,
                        48.59036319795697,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4667,
                    "end": 4706,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 2.49,
                "gradeSurface": 0.0,
                "gradeStructure": 4.53,
            },
        },
        {
            "id": "fef91fa4-ba18-4aaa-9fb1-b27394bcd3f2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266605175666583,
                        48.595277545700284,
                    ],
                    [
                        2.266781333185049,
                        48.59528762650184,
                    ],
                    [
                        2.266943930501376,
                        48.59529762353658,
                    ],
                    [
                        2.267079533202802,
                        48.59529845852845,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4706,
                    "end": 4741,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.84,
                "gradeSurface": 9.65,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "1d2e5473-9380-42ed-84c8-63dc3cd665f5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251752829966686,
                        48.587862645772354,
                    ],
                    [
                        2.251805264624431,
                        48.58798891501211,
                    ],
                    [
                        2.251935512824698,
                        48.588297946200406,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4741,
                    "end": 4791,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "c578398c-5454-461c-b136-686aa32b606b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251935512824698,
                        48.588297946200406,
                    ],
                    [
                        2.252067570134959,
                        48.58861126579551,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4791,
                    "end": 4827,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "81774937-c41a-4ea3-8773-88d897a17c10",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226305291013702,
                        48.59036267134899,
                    ],
                    [
                        2.22669889825379,
                        48.59033824215887,
                    ],
                    [
                        2.227011018903668,
                        48.590322278191614,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4827,
                    "end": 4879,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.0,
                "gradeSurface": 0.0,
                "gradeStructure": 5.45,
            },
        },
        {
            "id": "35a01f2b-f27d-4c04-8df2-c6cf57905b32",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220422289710097,
                        48.58930777002277,
                    ],
                    [
                        2.220462697921511,
                        48.589326026103784,
                    ],
                    [
                        2.220503106161091,
                        48.589344282170806,
                    ],
                    [
                        2.220502570753402,
                        48.58938026150509,
                    ],
                    [
                        2.220502035344977,
                        48.58941624083836,
                    ],
                    [
                        2.220488075110151,
                        48.58944313653914,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4879,
                    "end": 4897,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.59,
                "gradeSurface": 2.43,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "6c2ae2a2-0b08-4508-9150-0506adcdbc4f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22996734275581,
                        48.60091673113117,
                    ],
                    [
                        2.229304457835753,
                        48.60100350614399,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 795,
                    "end": 845,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.64,
                "gradeSurface": 9.2,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "0dbb6726-2737-4db3-a391-bce2f86e5458",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224207974722415,
                        48.601685346857366,
                    ],
                    [
                        2.223671254822997,
                        48.601751114400976,
                    ],
                    [
                        2.223542302396363,
                        48.60176763554701,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 0,
                    "end": 50,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.8,
                "gradeSurface": 2.89,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "7e6b07b5-43e0-46e5-8d3e-d23a2687f7e5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223542302396363,
                        48.60176763554701,
                    ],
                    [
                        2.223190204019645,
                        48.60181274497323,
                    ],
                    [
                        2.22287777028105,
                        48.60185389073562,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 50,
                    "end": 100,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.7,
                "gradeSurface": 2.66,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "f5610f1e-0793-4082-8950-c9d08be8cff0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22287777028105,
                        48.60185389073562,
                    ],
                    [
                        2.222213594408173,
                        48.60194135601107,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 100,
                    "end": 150,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "e8555cbe-133d-429b-95a2-d4963cd2ba8d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222213594408173,
                        48.60194135601107,
                    ],
                    [
                        2.221549416316835,
                        48.60202881757501,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 150,
                    "end": 200,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.06,
                "gradeSurface": 4.18,
                "gradeStructure": 5.78,
            },
        },
        {
            "id": "61562432-d415-439f-9d70-643e1348b5fd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221549416316835,
                        48.60202881757501,
                    ],
                    [
                        2.220938370513529,
                        48.602109278935814,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 200,
                    "end": 246,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 7.93,
                "gradeSurface": 7.75,
                "gradeStructure": 8.07,
            },
        },
        {
            "id": "59baf518-10af-4bce-b9cd-6b3c35e2ffa2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245671371299025,
                        48.59859745301532,
                    ],
                    [
                        2.245646585621087,
                        48.598623383239875,
                    ],
                    [
                        2.245623194897372,
                        48.5986466236143,
                    ],
                    [
                        2.245582045143288,
                        48.59867874705579,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 246,
                    "end": 257,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "c876653c-e0c8-450d-a82c-58990d647c74",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233311532827964,
                        48.60048313682141,
                    ],
                    [
                        2.233008583172303,
                        48.60051716786905,
                    ],
                    [
                        2.232800643205323,
                        48.600546413094996,
                    ],
                    [
                        2.232772098014974,
                        48.6005507269427,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 257,
                    "end": 297,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.07,
                "gradeSurface": 2.39,
                "gradeStructure": 5.45,
            },
        },
        {
            "id": "8b326229-06b0-4c5b-9951-1091bc976cd9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220938370513529,
                        48.602109278935814,
                    ],
                    [
                        2.220274452580064,
                        48.60219875136717,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 297,
                    "end": 347,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.6,
                "gradeSurface": 4.14,
                "gradeStructure": 4.98,
            },
        },
        {
            "id": "130899c1-9dac-4934-80eb-321ae078b87b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220274452580064,
                        48.60219875136717,
                    ],
                    [
                        2.219610532378096,
                        48.602288220089626,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 347,
                    "end": 397,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.47,
                "gradeSurface": 0.0,
                "gradeStructure": 0.85,
            },
        },
        {
            "id": "8e23db94-5f80-418c-8170-0ab4b59880e1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.219610532378096,
                        48.602288220089626,
                    ],
                    [
                        2.219225956778939,
                        48.602340042986896,
                    ],
                    [
                        2.219099561790573,
                        48.60235720523168,
                    ],
                    [
                        2.218946640418086,
                        48.602377784204585,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 397,
                    "end": 447,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.72,
                "gradeSurface": 2.71,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5aac96b0-d595-4290-a985-41bbbead0dfe",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218946640418086,
                        48.602377784204585,
                    ],
                    [
                        2.218282681005833,
                        48.602467132246126,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 447,
                    "end": 497,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 8.65,
                "gradeSurface": 7.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "23ebd1c4-5fc1-492b-921d-f78ce95c12ad",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218282681005833,
                        48.602467132246126,
                    ],
                    [
                        2.218277327671386,
                        48.60246785262015,
                    ],
                    [
                        2.217618202080539,
                        48.602554768590586,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 497,
                    "end": 547,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.77,
                "gradeSurface": 2.05,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "77dfcf1d-a9b8-430e-a8b6-a303e7dda195",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.217618202080539,
                        48.602554768590586,
                    ],
                    [
                        2.216953716727249,
                        48.60264238733721,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 547,
                    "end": 597,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "428ca100-f3ac-462d-81fa-d55e9be32b1e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.216953716727249,
                        48.60264238733721,
                    ],
                    [
                        2.216522834511342,
                        48.60269920113463,
                    ],
                    [
                        2.216288460862269,
                        48.60272731809688,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 597,
                    "end": 647,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.28,
                "gradeSurface": 2.4,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "7480c0dd-f4cb-44d3-b4d3-0ee12136644b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.216288460862269,
                        48.60272731809688,
                    ],
                    [
                        2.215755120975147,
                        48.602791299127105,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 647,
                    "end": 687,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.58,
                "gradeSurface": 3.98,
                "gradeStructure": 6.9,
            },
        },
        {
            "id": "cc2d08f3-df53-4ae9-ae8f-c0e5bf6f0a9e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245103768329297,
                        48.59883403794259,
                    ],
                    [
                        2.245061676900529,
                        48.59883736926856,
                    ],
                    [
                        2.24501691213587,
                        48.598837984941454,
                    ],
                    [
                        2.24496819573179,
                        48.59883047948265,
                    ],
                    [
                        2.24492356065399,
                        48.59882210032941,
                    ],
                    [
                        2.244884350048308,
                        48.598813755567264,
                    ],
                    [
                        2.244837067618137,
                        48.59880086178197,
                    ],
                    [
                        2.244758698362738,
                        48.59878057427097,
                    ],
                    [
                        2.24472905843712,
                        48.598766892806,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 687,
                    "end": 716,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.59,
                "gradeSurface": 3.05,
                "gradeStructure": 4.04,
            },
        },
        {
            "id": "07cb303d-e764-4d8f-9785-acc415de9b16",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235031286121271,
                        48.6005769593983,
                    ],
                    [
                        2.235014841414818,
                        48.60058854802445,
                    ],
                    [
                        2.2349478377561,
                        48.600625899023164,
                    ],
                    [
                        2.234887706792464,
                        48.600656997225784,
                    ],
                    [
                        2.234829155333361,
                        48.600672813011045,
                    ],
                    [
                        2.234736686662043,
                        48.60068931031034,
                    ],
                    [
                        2.234656554838362,
                        48.600696891238336,
                    ],
                    [
                        2.23455889806271,
                        48.60069716294671,
                    ],
                    [
                        2.23447355192483,
                        48.60069031724779,
                    ],
                    [
                        2.234381490752197,
                        48.60067893049698,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 716,
                    "end": 768,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.96,
                "gradeSurface": 4.79,
                "gradeStructure": 6.91,
            },
        },
        {
            "id": "c5d4afd4-26e2-4e95-ac7e-c7cb466d2d4b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245582045143288,
                        48.59867874705579,
                    ],
                    [
                        2.245551847893061,
                        48.59870374340352,
                    ],
                    [
                        2.245514857094177,
                        48.59872959624857,
                    ],
                    [
                        2.245479313082328,
                        48.59874916132229,
                    ],
                    [
                        2.24545612957295,
                        48.598758009994164,
                    ],
                    [
                        2.245431564023473,
                        48.598768649024194,
                    ],
                    [
                        2.245383866770249,
                        48.59878453878993,
                    ],
                    [
                        2.245381115661667,
                        48.598787220034914,
                    ],
                    [
                        2.245334774490483,
                        48.598803118377006,
                    ],
                    [
                        2.245283047718611,
                        48.59881628387391,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 768,
                    "end": 795,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.42,
                "gradeSurface": 9.25,
                "gradeStructure": 9.55,
            },
        },
        {
            "id": "c6177311-6368-412e-b3eb-c55e085b660a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.229304457835753,
                        48.60100350614399,
                    ],
                    [
                        2.228775574180139,
                        48.60107273685089,
                    ],
                    [
                        2.228608420765912,
                        48.60109504249216,
                    ],
                    [
                        2.228520084156046,
                        48.60110706394115,
                    ],
                    [
                        2.228405940491304,
                        48.60112161675078,
                    ],
                    [
                        2.228336643209723,
                        48.601130163214535,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 845,
                    "end": 918,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 8.04,
                "gradeSurface": 8.84,
                "gradeStructure": 7.39,
            },
        },
        {
            "id": "054c328d-0968-4d45-98c6-89a05be02834",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237312702935555,
                        48.599915127110165,
                    ],
                    [
                        2.23727201868392,
                        48.59991486645736,
                    ],
                    [
                        2.236973012266558,
                        48.599957928449186,
                    ],
                    [
                        2.236850697275528,
                        48.599975135648336,
                    ],
                    [
                        2.236648670994768,
                        48.599996906910434,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 918,
                    "end": 968,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "ca5ef70e-9e21-46f7-a1ea-06b64276cbe7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.236648670994768,
                        48.599996906910434,
                    ],
                    [
                        2.236456760069837,
                        48.60001758776157,
                    ],
                    [
                        2.236361567663236,
                        48.60003496841316,
                    ],
                    [
                        2.235983215633873,
                        48.60007531863885,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 968,
                    "end": 1018,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.83,
                "gradeSurface": 10.0,
                "gradeStructure": 9.7,
            },
        },
        {
            "id": "fa27e476-800e-4ecb-a2cc-9ad330dc8397",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235983215633873,
                        48.60007531863885,
                    ],
                    [
                        2.235804629626861,
                        48.60009436391608,
                    ],
                    [
                        2.235723129595221,
                        48.60010283633747,
                    ],
                    [
                        2.235614375322645,
                        48.60012012932657,
                    ],
                    [
                        2.235519051149617,
                        48.60014650407417,
                    ],
                    [
                        2.235423595563605,
                        48.60018187351556,
                    ],
                    [
                        2.235355131504332,
                        48.600226411862465,
                    ],
                    [
                        2.235176598965976,
                        48.60037819044877,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1018,
                    "end": 1090,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "afa1e8db-9eb0-496b-99c7-cfad3d6fd8f8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244720934729476,
                        48.598765941704286,
                    ],
                    [
                        2.244572151507557,
                        48.59873801081761,
                    ],
                    [
                        2.244504345885771,
                        48.59873758052278,
                    ],
                    [
                        2.244436540265101,
                        48.59873715018934,
                    ],
                    [
                        2.244368604868397,
                        48.5987457146083,
                    ],
                    [
                        2.244273547193098,
                        48.59875410681333,
                    ],
                    [
                        2.244219043075124,
                        48.59877175202597,
                    ],
                    [
                        2.244150847968668,
                        48.598798305901475,
                    ],
                    [
                        2.244077703312295,
                        48.59882198569624,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1090,
                    "end": 1140,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "be400114-34f3-4129-9415-d0852bef066b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244077703312295,
                        48.59882198569624,
                    ],
                    [
                        2.244069091646932,
                        48.59882477362575,
                    ],
                    [
                        2.243987335242563,
                        48.5988512412933,
                    ],
                    [
                        2.243892017596039,
                        48.5988776227723,
                    ],
                    [
                        2.243238222615819,
                        48.59907137196787,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1140,
                    "end": 1208,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "1a898b7a-aa9c-4af4-ae94-782f19e2d6e2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235176598965976,
                        48.60037819044877,
                    ],
                    [
                        2.235148424980801,
                        48.60044997434405,
                    ],
                    [
                        2.235120776397827,
                        48.60048577915673,
                    ],
                    [
                        2.235093390526168,
                        48.60050359442633,
                    ],
                    [
                        2.235065610497883,
                        48.60054839399227,
                    ],
                    [
                        2.235024531606149,
                        48.60057511687376,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1208,
                    "end": 1233,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "9cc4118f-e498-4f84-abc4-b0bc91c374ba",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245704591021183,
                        48.59826842397631,
                    ],
                    [
                        2.245731194862849,
                        48.59830457500901,
                    ],
                    [
                        2.24573054715197,
                        48.59834954898687,
                    ],
                    [
                        2.245743590017591,
                        48.59838561409044,
                    ],
                    [
                        2.24574307185669,
                        48.59842159327055,
                    ],
                    [
                        2.24572886309848,
                        48.59846648132159,
                    ],
                    [
                        2.245714783861307,
                        48.598502374575084,
                    ],
                    [
                        2.245700704604838,
                        48.59853826782582,
                    ],
                    [
                        2.245672934692484,
                        48.59858306993856,
                    ],
                    [
                        2.245659244051239,
                        48.598591978801664,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1233,
                    "end": 1271,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.21,
                "gradeSurface": 3.97,
                "gradeStructure": 4.4,
            },
        },
        {
            "id": "4215791a-f73b-4b86-afcc-5eb3d0bc90a2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234116037096767,
                        48.600560280088466,
                    ],
                    [
                        2.233994509140937,
                        48.60052351574594,
                    ],
                    [
                        2.23389984131605,
                        48.60050491535585,
                    ],
                    [
                        2.233832296677201,
                        48.600486489450034,
                    ],
                    [
                        2.23377818204015,
                        48.60047714555756,
                    ],
                    [
                        2.233710505868008,
                        48.600467714348966,
                    ],
                    [
                        2.233534468949251,
                        48.60044858988615,
                    ],
                    [
                        2.233303395893349,
                        48.600483084418464,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1271,
                    "end": 1333,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 9.5,
                "gradeSurface": 8.9,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "d6cf58f2-c7d7-4fb0-9982-f07de5b6b786",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228329928620117,
                        48.601125621884286,
                    ],
                    [
                        2.227663944431005,
                        48.60120948144517,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1333,
                    "end": 1383,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5a407cfe-e401-4ad0-ba0c-86014f1ff230",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227663944431005,
                        48.60120948144517,
                    ],
                    [
                        2.227446692231247,
                        48.60123683659587,
                    ],
                    [
                        2.227000854070305,
                        48.6013026541436,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1383,
                    "end": 1433,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.42,
                "gradeSurface": 2.05,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "a434e204-330f-4550-bbe6-e1d969242e58",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227000854070305,
                        48.6013026541436,
                    ],
                    [
                        2.226454426488252,
                        48.60138331885256,
                    ],
                    [
                        2.226338604665911,
                        48.60139857394609,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1433,
                    "end": 1483,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "e634575b-4b80-411f-9328-ae263e09a3a0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226338604665911,
                        48.60139857394609,
                    ],
                    [
                        2.225673704321897,
                        48.60148614677029,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1483,
                    "end": 1533,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "c2cfd1dc-de58-4949-95fa-d9eaa12a24ac",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225673704321897,
                        48.60148614677029,
                    ],
                    [
                        2.225217776375166,
                        48.6015461938982,
                    ],
                    [
                        2.225008984170844,
                        48.601574318304145,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1533,
                    "end": 1583,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "7bc5b335-6b79-41cb-9c5c-a46ddf9be3c9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225008984170844,
                        48.601574318304145,
                    ],
                    [
                        2.224198508074539,
                        48.60168348604533,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1583,
                    "end": 1644,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.82,
                "gradeSurface": 4.12,
                "gradeStructure": 7.22,
            },
        },
        {
            "id": "dd95926a-6bb3-4f6c-8ac8-b75be1a07ad6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245276293069955,
                        48.59881444192839,
                    ],
                    [
                        2.245235480005451,
                        48.59882317878818,
                    ],
                    [
                        2.245167544627809,
                        48.59883174366248,
                    ],
                    [
                        2.245099738879783,
                        48.59883131370682,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1644,
                    "end": 1657,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.59,
                "gradeSurface": 3.05,
                "gradeStructure": 4.04,
            },
        },
        {
            "id": "c5757e28-cecb-4362-bd9f-b435ae91d365",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234371997625077,
                        48.600678869445545,
                    ],
                    [
                        2.234291022503224,
                        48.60065136181902,
                    ],
                    [
                        2.234210178992939,
                        48.60061485937072,
                    ],
                    [
                        2.234169888813415,
                        48.60058761335937,
                    ],
                    [
                        2.234116037096767,
                        48.600560280088466,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1657,
                    "end": 1680,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.84,
                "gradeSurface": 5.49,
                "gradeStructure": 6.13,
            },
        },
        {
            "id": "4ff644c1-39a0-4b59-9065-2027849deeb1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239038401376328,
                        48.599692284449745,
                    ],
                    [
                        2.238374672944726,
                        48.59977799613042,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1680,
                    "end": 1730,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.62,
                "gradeSurface": 0.33,
                "gradeStructure": 0.85,
            },
        },
        {
            "id": "24d542d1-d1a8-429f-bae0-220a357f19ee",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238374672944726,
                        48.59977799613042,
                    ],
                    [
                        2.237710942340701,
                        48.599863704104685,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1730,
                    "end": 1780,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 4.12,
                "gradeSurface": 3.62,
                "gradeStructure": 4.53,
            },
        },
        {
            "id": "8fae5215-326b-45dc-922a-ca49e7a55a18",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237710942340701,
                        48.599863704104685,
                    ],
                    [
                        2.237312702935555,
                        48.599915127110165,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1780,
                    "end": 1810,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.57,
                "gradeSurface": 2.39,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "d1f22df1-8df6-4173-abfa-d433caaecb45",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243238222615819,
                        48.59907137196787,
                    ],
                    [
                        2.243020593236649,
                        48.59911496639258,
                    ],
                    [
                        2.242588673904108,
                        48.59919916301417,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1810,
                    "end": 1860,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 7.15,
                "gradeSurface": 3.66,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "69c800d6-2f55-4a2a-bd84-fa9d0df62564",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.242588673904108,
                        48.59919916301417,
                    ],
                    [
                        2.242394955547445,
                        48.59923692509447,
                    ],
                    [
                        2.24192991169567,
                        48.59930306603167,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1860,
                    "end": 1910,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 6.93,
                "gradeSurface": 3.54,
                "gradeStructure": 9.7,
            },
        },
        {
            "id": "1897f689-685d-4686-8fd8-67c2b998096b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24192991169567,
                        48.59930306603167,
                    ],
                    [
                        2.24172902179892,
                        48.59933163701516,
                    ],
                    [
                        2.241660954944716,
                        48.59934919466858,
                    ],
                    [
                        2.24157945702026,
                        48.5993576711042,
                    ],
                    [
                        2.241511520351494,
                        48.599366233888425,
                    ],
                    [
                        2.241443583660515,
                        48.599374796633825,
                    ],
                    [
                        2.241362085661038,
                        48.59938327292042,
                    ],
                    [
                        2.241294018616715,
                        48.599400830363315,
                    ],
                    [
                        2.241271705315643,
                        48.599403642693794,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1910,
                    "end": 1960,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.27,
                "gradeSurface": 1.41,
                "gradeStructure": 4.8,
            },
        },
        {
            "id": "6c6a5bb1-cd26-46e4-a65c-9310dbee68c2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241271705315643,
                        48.599403642693794,
                    ],
                    [
                        2.241226081845299,
                        48.599409392984334,
                    ],
                    [
                        2.241158145051668,
                        48.59941795556659,
                    ],
                    [
                        2.241090208235824,
                        48.59942651810995,
                    ],
                    [
                        2.241022271397766,
                        48.599435080614505,
                    ],
                    [
                        2.240954334537494,
                        48.59944364308023,
                    ],
                    [
                        2.240886397655007,
                        48.59945220550715,
                    ],
                    [
                        2.240764215528945,
                        48.59946042195228,
                    ],
                    [
                        2.240641902945548,
                        48.59947763305355,
                    ],
                    [
                        2.240605453323381,
                        48.599483430575745,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1960,
                    "end": 2010,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 3.57,
                "gradeSurface": 0.75,
                "gradeStructure": 5.87,
            },
        },
        {
            "id": "c322a1d0-191b-4eea-8b84-4398a66f6234",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.240605453323381,
                        48.599483430575745,
                    ],
                    [
                        2.240587527279526,
                        48.59948628181162,
                    ],
                    [
                        2.240478775893605,
                        48.5995035792531,
                    ],
                    [
                        2.240356463115618,
                        48.59952079006051,
                    ],
                    [
                        2.240234150257242,
                        48.59953800074201,
                    ],
                    [
                        2.239941141867376,
                        48.59957095549432,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2010,
                    "end": 2060,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 7.1,
                "gradeSurface": 6.85,
                "gradeStructure": 7.3,
            },
        },
        {
            "id": "9cf398fc-fb32-456f-a4e1-8b17bdb75fbf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239941141867376,
                        48.59957095549432,
                    ],
                    [
                        2.239704344575991,
                        48.5995975876076,
                    ],
                    [
                        2.239310151227137,
                        48.59965803873416,
                    ],
                    [
                        2.239228652441652,
                        48.599666513558326,
                    ],
                    [
                        2.239038401376328,
                        48.599692284449745,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2060,
                    "end": 2128,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 7.14,
                "gradeSurface": 6.9,
                "gradeStructure": 7.34,
            },
        },
        {
            "id": "535e0e42-66e8-4c3d-819a-4c34579ea3e1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23276001119596,
                        48.600542552994376,
                    ],
                    [
                        2.23239292589501,
                        48.600603155968045,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2128,
                    "end": 2156,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "954b4513-caa7-4706-8ab2-97f70ac16fdc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23239292589501,
                        48.600603155968045,
                    ],
                    [
                        2.231728075920977,
                        48.600686897092686,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2156,
                    "end": 2206,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 5.27,
                "gradeSurface": 4.87,
                "gradeStructure": 5.6,
            },
        },
        {
            "id": "8b3b2dd8-9f34-4cad-b55f-c3c3742d7ef8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231728075920977,
                        48.600686897092686,
                    ],
                    [
                        2.231063223820842,
                        48.600770634498744,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2206,
                    "end": 2256,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "de7cc98c-9e99-496c-baae-128d18a2744b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231063223820842,
                        48.600770634498744,
                    ],
                    [
                        2.230694424586443,
                        48.60081708264623,
                    ],
                    [
                        2.230398691918322,
                        48.60085547694369,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2256,
                    "end": 2306,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "d4d09680-1801-4b19-9591-adb72bb4d50e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230398691918322,
                        48.60085547694369,
                    ],
                    [
                        2.23023240338809,
                        48.6008770654562,
                    ],
                    [
                        2.230205280079046,
                        48.600876890076265,
                    ],
                    [
                        2.229960641277874,
                        48.600911290416185,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2306,
                    "end": 2339,
                },
            ],
            "properties": {
                "layer": "b5e75c38-6e04-4150-a241-21bc0b27bb4b",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
    ],
}

export const networkGradingCD91Small2025 = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "cac21623-f71c-4eb3-bdde-52d0784919ef",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224207974722415,
                        48.601685346857366,
                    ],
                    [
                        2.223671254822997,
                        48.601751114400976,
                    ],
                    [
                        2.223542302396363,
                        48.60176763554701,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 0,
                    "end": 50,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.53,
                "gradeSurface": 2.29,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "47c4b23a-e615-4b2f-8c38-d0aec0b3563d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223542302396363,
                        48.60176763554701,
                    ],
                    [
                        2.223190204019645,
                        48.60181274497323,
                    ],
                    [
                        2.22287777028105,
                        48.60185389073562,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 50,
                    "end": 100,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.53,
                "gradeSurface": 2.3,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "14a9167f-51cc-4f62-9829-d80740038572",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22287777028105,
                        48.60185389073562,
                    ],
                    [
                        2.222213594408173,
                        48.60194135601107,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 100,
                    "end": 150,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "b5b63ea1-64e3-4e4b-bd79-0ebea1d86d88",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222213594408173,
                        48.60194135601107,
                    ],
                    [
                        2.221549416316835,
                        48.60202881757501,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 150,
                    "end": 200,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.51,
                "gradeSurface": 3.59,
                "gradeStructure": 5.27,
            },
        },
        {
            "id": "d65c150a-6fed-466f-a51d-60ccfc8bcb55",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221549416316835,
                        48.60202881757501,
                    ],
                    [
                        2.220938370513529,
                        48.602109278935814,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 200,
                    "end": 246,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "ba179bc4-2423-46a9-9e0c-1badce549aae",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245671371299025,
                        48.59859745301532,
                    ],
                    [
                        2.245646585621087,
                        48.598623383239875,
                    ],
                    [
                        2.245623194897372,
                        48.5986466236143,
                    ],
                    [
                        2.245582045143288,
                        48.59867874705579,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 246,
                    "end": 257,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "a0084f1c-6751-43db-ba0d-ab242f8e0b10",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233311532827964,
                        48.60048313682141,
                    ],
                    [
                        2.233008583172303,
                        48.60051716786905,
                    ],
                    [
                        2.232800643205323,
                        48.600546413094996,
                    ],
                    [
                        2.232772098014974,
                        48.6005507269427,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 257,
                    "end": 297,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.73,
                "gradeSurface": 2.02,
                "gradeStructure": 5.13,
            },
        },
        {
            "id": "0273ba2a-0f2a-4e2f-ba9e-f6e9b08f2052",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220938370513529,
                        48.602109278935814,
                    ],
                    [
                        2.220274452580064,
                        48.60219875136717,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 297,
                    "end": 347,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.37,
                "gradeSurface": 3.89,
                "gradeStructure": 4.76,
            },
        },
        {
            "id": "84ab713e-0b3e-46cc-9020-ff4a8c9ff6f5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220274452580064,
                        48.60219875136717,
                    ],
                    [
                        2.219610532378096,
                        48.602288220089626,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 347,
                    "end": 397,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.12,
                "gradeSurface": 0.0,
                "gradeStructure": 0.22,
            },
        },
        {
            "id": "946fee94-8935-4c5c-9691-e125e3254640",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.219610532378096,
                        48.602288220089626,
                    ],
                    [
                        2.219225956778939,
                        48.602340042986896,
                    ],
                    [
                        2.219099561790573,
                        48.60235720523168,
                    ],
                    [
                        2.218946640418086,
                        48.602377784204585,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 397,
                    "end": 447,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.62,
                "gradeSurface": 2.5,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "7af33590-cd42-407f-845a-1497b35f7bf1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218946640418086,
                        48.602377784204585,
                    ],
                    [
                        2.218282681005833,
                        48.602467132246126,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 447,
                    "end": 497,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "114cfff8-b0e4-415d-95c5-fcf31c3760be",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218282681005833,
                        48.602467132246126,
                    ],
                    [
                        2.218277327671386,
                        48.60246785262015,
                    ],
                    [
                        2.217618202080539,
                        48.602554768590586,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 497,
                    "end": 547,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.77,
                "gradeSurface": 2.04,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "a3174d30-d7e5-4c99-b151-3737721ff7ad",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.217618202080539,
                        48.602554768590586,
                    ],
                    [
                        2.216953716727249,
                        48.60264238733721,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 547,
                    "end": 597,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "814492cc-d2d6-46bd-8159-f92d4a93ed51",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.216953716727249,
                        48.60264238733721,
                    ],
                    [
                        2.216522834511342,
                        48.60269920113463,
                    ],
                    [
                        2.216288460862269,
                        48.60272731809688,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 597,
                    "end": 647,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.28,
                "gradeSurface": 2.4,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "b85f25ec-acb1-4314-9c07-e82c4a689495",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.216288460862269,
                        48.60272731809688,
                    ],
                    [
                        2.215755120975147,
                        48.602791299127105,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 647,
                    "end": 687,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.9,
                "gradeSurface": 3.24,
                "gradeStructure": 6.26,
            },
        },
        {
            "id": "c735f86d-b1b6-495d-849f-5cdbb2e21f5a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245103768329297,
                        48.59883403794259,
                    ],
                    [
                        2.245061676900529,
                        48.59883736926856,
                    ],
                    [
                        2.24501691213587,
                        48.598837984941454,
                    ],
                    [
                        2.24496819573179,
                        48.59883047948265,
                    ],
                    [
                        2.24492356065399,
                        48.59882210032941,
                    ],
                    [
                        2.244884350048308,
                        48.598813755567264,
                    ],
                    [
                        2.244837067618137,
                        48.59880086178197,
                    ],
                    [
                        2.244758698362738,
                        48.59878057427097,
                    ],
                    [
                        2.24472905843712,
                        48.598766892806,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 687,
                    "end": 716,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.58,
                "gradeSurface": 3.04,
                "gradeStructure": 4.03,
            },
        },
        {
            "id": "1c184813-9872-405b-9243-f3532e8a06a4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235031286121271,
                        48.6005769593983,
                    ],
                    [
                        2.235014841414818,
                        48.60058854802445,
                    ],
                    [
                        2.2349478377561,
                        48.600625899023164,
                    ],
                    [
                        2.234887706792464,
                        48.600656997225784,
                    ],
                    [
                        2.234829155333361,
                        48.600672813011045,
                    ],
                    [
                        2.234736686662043,
                        48.60068931031034,
                    ],
                    [
                        2.234656554838362,
                        48.600696891238336,
                    ],
                    [
                        2.23455889806271,
                        48.60069716294671,
                    ],
                    [
                        2.23447355192483,
                        48.60069031724779,
                    ],
                    [
                        2.234381490752197,
                        48.60067893049698,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 716,
                    "end": 768,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 5.27,
                "gradeSurface": 4.04,
                "gradeStructure": 6.27,
            },
        },
        {
            "id": "327f60d4-a728-422c-92b7-b156405fa691",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245582045143288,
                        48.59867874705579,
                    ],
                    [
                        2.245551847893061,
                        48.59870374340352,
                    ],
                    [
                        2.245514857094177,
                        48.59872959624857,
                    ],
                    [
                        2.245479313082328,
                        48.59874916132229,
                    ],
                    [
                        2.24545612957295,
                        48.598758009994164,
                    ],
                    [
                        2.245431564023473,
                        48.598768649024194,
                    ],
                    [
                        2.245383866770249,
                        48.59878453878993,
                    ],
                    [
                        2.245381115661667,
                        48.598787220034914,
                    ],
                    [
                        2.245334774490483,
                        48.598803118377006,
                    ],
                    [
                        2.245283047718611,
                        48.59881628387391,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 768,
                    "end": 795,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.42,
                "gradeSurface": 9.25,
                "gradeStructure": 9.55,
            },
        },
        {
            "id": "18db6c28-3683-4f5b-9443-222bd1cf2038",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22996734275581,
                        48.60091673113117,
                    ],
                    [
                        2.229304457835753,
                        48.60100350614399,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 795,
                    "end": 845,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.64,
                "gradeSurface": 9.2,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "1808ea3d-fce3-4ae0-9cdd-21d8ab4eb42c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.229304457835753,
                        48.60100350614399,
                    ],
                    [
                        2.228775574180139,
                        48.60107273685089,
                    ],
                    [
                        2.228608420765912,
                        48.60109504249216,
                    ],
                    [
                        2.228520084156046,
                        48.60110706394115,
                    ],
                    [
                        2.228405940491304,
                        48.60112161675078,
                    ],
                    [
                        2.228336643209723,
                        48.601130163214535,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 845,
                    "end": 918,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.48,
                "gradeSurface": 8.84,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "1e5fd1c7-c3f4-454a-9c5f-6790daaa73a8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237312702935555,
                        48.599915127110165,
                    ],
                    [
                        2.23727201868392,
                        48.59991486645736,
                    ],
                    [
                        2.236973012266558,
                        48.599957928449186,
                    ],
                    [
                        2.236850697275528,
                        48.599975135648336,
                    ],
                    [
                        2.236648670994768,
                        48.599996906910434,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 918,
                    "end": 968,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "91f414e1-28f2-4f48-bf3c-6dd6c7c8c751",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.236648670994768,
                        48.599996906910434,
                    ],
                    [
                        2.236456760069837,
                        48.60001758776157,
                    ],
                    [
                        2.236361567663236,
                        48.60003496841316,
                    ],
                    [
                        2.235983215633873,
                        48.60007531863885,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 968,
                    "end": 1018,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.83,
                "gradeSurface": 10.0,
                "gradeStructure": 9.7,
            },
        },
        {
            "id": "e6a31e62-a9fb-41a0-8c4a-4b3eaf84431c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235983215633873,
                        48.60007531863885,
                    ],
                    [
                        2.235804629626861,
                        48.60009436391608,
                    ],
                    [
                        2.235723129595221,
                        48.60010283633747,
                    ],
                    [
                        2.235614375322645,
                        48.60012012932657,
                    ],
                    [
                        2.235519051149617,
                        48.60014650407417,
                    ],
                    [
                        2.235423595563605,
                        48.60018187351556,
                    ],
                    [
                        2.235355131504332,
                        48.600226411862465,
                    ],
                    [
                        2.235176598965976,
                        48.60037819044877,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1018,
                    "end": 1090,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "63436289-4179-4a23-9bec-8e4c315c25c4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244720934729476,
                        48.598765941704286,
                    ],
                    [
                        2.244572151507557,
                        48.59873801081761,
                    ],
                    [
                        2.244504345885771,
                        48.59873758052278,
                    ],
                    [
                        2.244436540265101,
                        48.59873715018934,
                    ],
                    [
                        2.244368604868397,
                        48.5987457146083,
                    ],
                    [
                        2.244273547193098,
                        48.59875410681333,
                    ],
                    [
                        2.244219043075124,
                        48.59877175202597,
                    ],
                    [
                        2.244150847968668,
                        48.598798305901475,
                    ],
                    [
                        2.244077703312295,
                        48.59882198569624,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1090,
                    "end": 1140,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "60ed8f3d-820a-463c-95d2-838bb962dee5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244077703312295,
                        48.59882198569624,
                    ],
                    [
                        2.244069091646932,
                        48.59882477362575,
                    ],
                    [
                        2.243987335242563,
                        48.5988512412933,
                    ],
                    [
                        2.243892017596039,
                        48.5988776227723,
                    ],
                    [
                        2.243238222615819,
                        48.59907137196787,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1140,
                    "end": 1208,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "94448ff9-08f1-4b9c-b856-ea16d2d3df7c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235176598965976,
                        48.60037819044877,
                    ],
                    [
                        2.235148424980801,
                        48.60044997434405,
                    ],
                    [
                        2.235120776397827,
                        48.60048577915673,
                    ],
                    [
                        2.235093390526168,
                        48.60050359442633,
                    ],
                    [
                        2.235065610497883,
                        48.60054839399227,
                    ],
                    [
                        2.235024531606149,
                        48.60057511687376,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1208,
                    "end": 1233,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "e150a440-c4dd-48f8-9e57-3cdb92a5bbcb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245704591021183,
                        48.59826842397631,
                    ],
                    [
                        2.245731194862849,
                        48.59830457500901,
                    ],
                    [
                        2.24573054715197,
                        48.59834954898687,
                    ],
                    [
                        2.245743590017591,
                        48.59838561409044,
                    ],
                    [
                        2.24574307185669,
                        48.59842159327055,
                    ],
                    [
                        2.24572886309848,
                        48.59846648132159,
                    ],
                    [
                        2.245714783861307,
                        48.598502374575084,
                    ],
                    [
                        2.245700704604838,
                        48.59853826782582,
                    ],
                    [
                        2.245672934692484,
                        48.59858306993856,
                    ],
                    [
                        2.245659244051239,
                        48.598591978801664,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1233,
                    "end": 1271,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.4,
                "gradeSurface": 3.1,
                "gradeStructure": 3.65,
            },
        },
        {
            "id": "cbfc1542-98e0-418f-9982-2d05726964c6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234116037096767,
                        48.600560280088466,
                    ],
                    [
                        2.233994509140937,
                        48.60052351574594,
                    ],
                    [
                        2.23389984131605,
                        48.60050491535585,
                    ],
                    [
                        2.233832296677201,
                        48.600486489450034,
                    ],
                    [
                        2.23377818204015,
                        48.60047714555756,
                    ],
                    [
                        2.233710505868008,
                        48.600467714348966,
                    ],
                    [
                        2.233534468949251,
                        48.60044858988615,
                    ],
                    [
                        2.233303395893349,
                        48.600483084418464,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1271,
                    "end": 1333,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.5,
                "gradeSurface": 8.9,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "c07bbc8a-a3c7-4364-8495-b50978ecb6b5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228329928620117,
                        48.601125621884286,
                    ],
                    [
                        2.227663944431005,
                        48.60120948144517,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1333,
                    "end": 1383,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "37198305-d1c8-4e51-b9a3-dbe0b73bb420",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227663944431005,
                        48.60120948144517,
                    ],
                    [
                        2.227446692231247,
                        48.60123683659587,
                    ],
                    [
                        2.227000854070305,
                        48.6013026541436,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1383,
                    "end": 1433,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.42,
                "gradeSurface": 2.04,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "4fb1d25b-75de-458a-b1ee-faa5ada4a959",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227000854070305,
                        48.6013026541436,
                    ],
                    [
                        2.226454426488252,
                        48.60138331885256,
                    ],
                    [
                        2.226338604665911,
                        48.60139857394609,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1433,
                    "end": 1483,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "efcc8c87-5218-4c2a-bb2f-c81a23aac772",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226338604665911,
                        48.60139857394609,
                    ],
                    [
                        2.225673704321897,
                        48.60148614677029,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1483,
                    "end": 1533,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "ea7f2878-8389-4c85-8b9f-8d70ac23e150",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225673704321897,
                        48.60148614677029,
                    ],
                    [
                        2.225217776375166,
                        48.6015461938982,
                    ],
                    [
                        2.225008984170844,
                        48.601574318304145,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1533,
                    "end": 1583,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5f622d28-93b2-43a9-a955-a9288b2ea07a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225008984170844,
                        48.601574318304145,
                    ],
                    [
                        2.224198508074539,
                        48.60168348604533,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1583,
                    "end": 1644,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "d6643f4e-4fd3-42b1-8dc6-0bbd9ee1e407",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245276293069955,
                        48.59881444192839,
                    ],
                    [
                        2.245235480005451,
                        48.59882317878818,
                    ],
                    [
                        2.245167544627809,
                        48.59883174366248,
                    ],
                    [
                        2.245099738879783,
                        48.59883131370682,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1644,
                    "end": 1657,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.58,
                "gradeSurface": 3.04,
                "gradeStructure": 4.03,
            },
        },
        {
            "id": "123e8da6-b477-41fe-8ab2-24162a54f936",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234371997625077,
                        48.600678869445545,
                    ],
                    [
                        2.234291022503224,
                        48.60065136181902,
                    ],
                    [
                        2.234210178992939,
                        48.60061485937072,
                    ],
                    [
                        2.234169888813415,
                        48.60058761335937,
                    ],
                    [
                        2.234116037096767,
                        48.600560280088466,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1657,
                    "end": 1680,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "b057a3e7-c061-4f10-9cfa-c07b2bcc1a51",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239038401376328,
                        48.599692284449745,
                    ],
                    [
                        2.238374672944726,
                        48.59977799613042,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1680,
                    "end": 1730,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.12,
                "gradeSurface": 0.0,
                "gradeStructure": 0.22,
            },
        },
        {
            "id": "5b8e540f-2f6c-405b-a231-721b0cbfcc46",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238374672944726,
                        48.59977799613042,
                    ],
                    [
                        2.237710942340701,
                        48.599863704104685,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1730,
                    "end": 1780,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.99,
                "gradeSurface": 3.48,
                "gradeStructure": 4.41,
            },
        },
        {
            "id": "a191a32e-ac45-4683-b1e6-36d369a7e8ab",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237710942340701,
                        48.599863704104685,
                    ],
                    [
                        2.237312702935555,
                        48.599915127110165,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1780,
                    "end": 1810,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.53,
                "gradeSurface": 2.3,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "e2a4de1e-eb3f-46cc-bb6d-3e7e94cde993",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243238222615819,
                        48.59907137196787,
                    ],
                    [
                        2.243020593236649,
                        48.59911496639258,
                    ],
                    [
                        2.242588673904108,
                        48.59919916301417,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1810,
                    "end": 1860,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.98,
                "gradeSurface": 3.3,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "efa34350-e37f-4957-a8a5-47d6920fc04f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.242588673904108,
                        48.59919916301417,
                    ],
                    [
                        2.242394955547445,
                        48.59923692509447,
                    ],
                    [
                        2.24192991169567,
                        48.59930306603167,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1860,
                    "end": 1910,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.77,
                "gradeSurface": 3.2,
                "gradeStructure": 9.7,
            },
        },
        {
            "id": "4d3e03d8-8559-4005-b504-6a42fa5922e8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24192991169567,
                        48.59930306603167,
                    ],
                    [
                        2.24172902179892,
                        48.59933163701516,
                    ],
                    [
                        2.241660954944716,
                        48.59934919466858,
                    ],
                    [
                        2.24157945702026,
                        48.5993576711042,
                    ],
                    [
                        2.241511520351494,
                        48.599366233888425,
                    ],
                    [
                        2.241443583660515,
                        48.599374796633825,
                    ],
                    [
                        2.241362085661038,
                        48.59938327292042,
                    ],
                    [
                        2.241294018616715,
                        48.599400830363315,
                    ],
                    [
                        2.241271705315643,
                        48.599403642693794,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1910,
                    "end": 1960,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.54,
                "gradeSurface": 0.0,
                "gradeStructure": 4.62,
            },
        },
        {
            "id": "3c03b1f1-79b2-44e0-8464-83519081ba3d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241271705315643,
                        48.599403642693794,
                    ],
                    [
                        2.241226081845299,
                        48.599409392984334,
                    ],
                    [
                        2.241158145051668,
                        48.59941795556659,
                    ],
                    [
                        2.241090208235824,
                        48.59942651810995,
                    ],
                    [
                        2.241022271397766,
                        48.599435080614505,
                    ],
                    [
                        2.240954334537494,
                        48.59944364308023,
                    ],
                    [
                        2.240886397655007,
                        48.59945220550715,
                    ],
                    [
                        2.240764215528945,
                        48.59946042195228,
                    ],
                    [
                        2.240641902945548,
                        48.59947763305355,
                    ],
                    [
                        2.240605453323381,
                        48.599483430575745,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 1960,
                    "end": 2010,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "3ec17a18-654f-4127-80ef-8a4fbbb80308",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.240605453323381,
                        48.599483430575745,
                    ],
                    [
                        2.240587527279526,
                        48.59948628181162,
                    ],
                    [
                        2.240478775893605,
                        48.5995035792531,
                    ],
                    [
                        2.240356463115618,
                        48.59952079006051,
                    ],
                    [
                        2.240234150257242,
                        48.59953800074201,
                    ],
                    [
                        2.239941141867376,
                        48.59957095549432,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2010,
                    "end": 2060,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "3a6ce2c6-74f3-4397-bae2-927aa7ddf03c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239941141867376,
                        48.59957095549432,
                    ],
                    [
                        2.239704344575991,
                        48.5995975876076,
                    ],
                    [
                        2.239310151227137,
                        48.59965803873416,
                    ],
                    [
                        2.239228652441652,
                        48.599666513558326,
                    ],
                    [
                        2.239038401376328,
                        48.599692284449745,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2060,
                    "end": 2128,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "bb74bd2b-64a1-4408-9c20-fbff9593c4b5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23276001119596,
                        48.600542552994376,
                    ],
                    [
                        2.23239292589501,
                        48.600603155968045,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2128,
                    "end": 2156,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "a2557e4e-ca71-4757-afc6-9b15a5d28127",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23239292589501,
                        48.600603155968045,
                    ],
                    [
                        2.231728075920977,
                        48.600686897092686,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2156,
                    "end": 2206,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.89,
                "gradeSurface": 4.46,
                "gradeStructure": 5.25,
            },
        },
        {
            "id": "36fbd1ee-e359-4aff-9171-53331d20043f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231728075920977,
                        48.600686897092686,
                    ],
                    [
                        2.231063223820842,
                        48.600770634498744,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2206,
                    "end": 2256,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "56e0bc1c-7704-456e-836a-d3ae6b3d941c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231063223820842,
                        48.600770634498744,
                    ],
                    [
                        2.230694424586443,
                        48.60081708264623,
                    ],
                    [
                        2.230398691918322,
                        48.60085547694369,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2256,
                    "end": 2306,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "fbb1e582-77ec-4da4-aec0-9571329e68b6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230398691918322,
                        48.60085547694369,
                    ],
                    [
                        2.23023240338809,
                        48.6008770654562,
                    ],
                    [
                        2.230205280079046,
                        48.600876890076265,
                    ],
                    [
                        2.229960641277874,
                        48.600911290416185,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0097",
                    "start": 2306,
                    "end": 2339,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "f611de3c-e66f-4d87-b6dc-ae9cfb3a4954",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.240007382242808,
                        48.591051860980855,
                    ],
                    [
                        2.24068807655684,
                        48.591054406162506,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 157,
                    "end": 207,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "51f2ed1b-8cc9-4c44-ba2a-287cd7f7b2ae",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225015677677535,
                        48.59036507637893,
                    ],
                    [
                        2.225267381442702,
                        48.59039999905972,
                    ],
                    [
                        2.225443567781628,
                        48.59040654313984,
                    ],
                    [
                        2.225621096760776,
                        48.590413995263745,
                    ],
                    [
                        2.225687484151923,
                        48.59040929736768,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 0,
                    "end": 50,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.81,
                "gradeSurface": 0.4,
                "gradeStructure": 4.79,
            },
        },
        {
            "id": "47fce0f9-7c19-4a51-979f-396a36eed616",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.225687484151923,
                        48.59040929736768,
                    ],
                    [
                        2.226307962941572,
                        48.59036538742608,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 50,
                    "end": 96,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.87,
                "gradeSurface": 3.35,
                "gradeStructure": 4.3,
            },
        },
        {
            "id": "f1b9f4c6-bfdc-4e5a-9592-29b4d37eb17e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227016415923118,
                        48.59032411238071,
                    ],
                    [
                        2.227046218994584,
                        48.5903261050659,
                    ],
                    [
                        2.227243834184816,
                        48.59035077703056,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 96,
                    "end": 113,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.99,
                "gradeSurface": 3.47,
                "gradeStructure": 4.4,
            },
        },
        {
            "id": "2cba539b-fea9-4188-be5a-05a47dbdd462",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245281025038835,
                        48.58926289245718,
                    ],
                    [
                        2.245443851200478,
                        48.588878909513916,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 113,
                    "end": 157,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.97,
                "gradeSurface": 3.46,
                "gradeStructure": 4.39,
            },
        },
        {
            "id": "77f63ce5-ea0c-418f-b85c-a0344354af38",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24068807655684,
                        48.591054406162506,
                    ],
                    [
                        2.241028423738649,
                        48.591055677293575,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 207,
                    "end": 232,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "c1c98529-5842-4d25-bd68-25b31b5ea0df",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266503486283121,
                        48.59527601958153,
                    ],
                    [
                        2.266605163071593,
                        48.59527844518431,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 232,
                    "end": 240,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 1.36,
                "gradeSurface": 3.04,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "8d5c4b52-5ffc-47bd-b497-0c577bb67193",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.265178429598887,
                        48.59499348094964,
                    ],
                    [
                        2.265554040651321,
                        48.59509295171955,
                    ],
                    [
                        2.265668873703221,
                        48.595124245276686,
                    ],
                    [
                        2.265808563447852,
                        48.59515827386706,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 240,
                    "end": 290,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "64c1cea2-02e3-4765-b0aa-8dcceb84ac0f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.265808563447852,
                        48.59515827386706,
                    ],
                    [
                        2.265899984492156,
                        48.5951805440306,
                    ],
                    [
                        2.266150256571084,
                        48.595224366594614,
                    ],
                    [
                        2.266335628808611,
                        48.595254295222354,
                    ],
                    [
                        2.266503486283121,
                        48.59527601958153,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 290,
                    "end": 343,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "ed19e6a1-16ae-410b-a5fe-67f61aadb116",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252364117327363,
                        48.5891150878087,
                    ],
                    [
                        2.252809846590139,
                        48.58952269217839,
                    ],
                    [
                        2.252947183755751,
                        48.58959102180422,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 343,
                    "end": 412,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.32,
                "gradeSurface": 1.95,
                "gradeStructure": 4.45,
            },
        },
        {
            "id": "c08be871-2068-4f84-81d0-5aefc60cfc54",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245647367592548,
                        48.588399829839354,
                    ],
                    [
                        2.245884585655773,
                        48.58784000195692,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 412,
                    "end": 477,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.54,
                "gradeSurface": 2.45,
                "gradeStructure": 4.42,
            },
        },
        {
            "id": "46c4fe15-19ca-4461-9da1-6a27a84da046",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260037981848081,
                        48.591970588370955,
                    ],
                    [
                        2.260195750208991,
                        48.592033639020954,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 477,
                    "end": 491,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.81,
                "gradeSurface": 1.32,
                "gradeStructure": 4.03,
            },
        },
        {
            "id": "1d996559-a7d8-4227-86d2-f167f2976037",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.257958336814422,
                        48.59116602435011,
                    ],
                    [
                        2.258236407759133,
                        48.59125591499803,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 491,
                    "end": 514,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.4,
                "gradeSurface": 2.68,
                "gradeStructure": 5.81,
            },
        },
        {
            "id": "b7a19d8a-ca6b-4c93-aac4-ef3ae150bf36",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.259706264016828,
                        48.59183808883618,
                    ],
                    [
                        2.260037981848081,
                        48.591970588370955,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 514,
                    "end": 543,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.68,
                "gradeSurface": 9.31,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "1501ce04-1c17-43e2-812f-9a179ddd1331",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243393046767975,
                        48.59135859321727,
                    ],
                    [
                        2.244331143087355,
                        48.591472500609314,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 543,
                    "end": 613,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "7c21aaea-98e9-4546-b7af-5bacddac7dae",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.262359016053809,
                        48.5931643232664,
                    ],
                    [
                        2.2626585117207,
                        48.59346753263306,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 613,
                    "end": 653,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "04a47b7c-d289-4e4d-952a-9e232e017616",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264105386710302,
                        48.59463152505042,
                    ],
                    [
                        2.264197254166367,
                        48.594656381135636,
                    ],
                    [
                        2.264361575647428,
                        48.59473655823509,
                    ],
                    [
                        2.2644719728534,
                        48.594793912856424,
                    ],
                    [
                        2.264590594797886,
                        48.59484502124902,
                    ],
                    [
                        2.264673358435388,
                        48.59486768483097,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 653,
                    "end": 703,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "32fe7aaf-5537-4614-a6bd-f46c6e18333f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264673358435388,
                        48.59486768483097,
                    ],
                    [
                        2.264728390608184,
                        48.594882754523915,
                    ],
                    [
                        2.265178429598887,
                        48.59499348094964,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 703,
                    "end": 743,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.5,
                "gradeSurface": 0.0,
                "gradeStructure": 8.19,
            },
        },
        {
            "id": "a4a27ffa-c4b1-4b53-b73b-683f8978bb02",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251634370899419,
                        48.587517366133646,
                    ],
                    [
                        2.251723316164496,
                        48.58774551628873,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 743,
                    "end": 769,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "ebd95cd0-1de3-4917-8357-57c7b1d44799",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.270071032374565,
                        48.59540679590989,
                    ],
                    [
                        2.270745766254636,
                        48.595383377286986,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 769,
                    "end": 819,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "df290a1e-cd34-4a16-aea1-bff2ea5f648c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.270745766254636,
                        48.595383377286986,
                    ],
                    [
                        2.271568940771172,
                        48.59535480138738,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 819,
                    "end": 880,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "d65f7a29-ab9e-4296-a7b0-22abf6870456",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244331143087355,
                        48.591472500609314,
                    ],
                    [
                        2.244551560390845,
                        48.59095125133485,
                    ],
                    [
                        2.244559324153404,
                        48.59093276322005,
                    ],
                    [
                        2.24456700727779,
                        48.59091446711807,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 880,
                    "end": 944,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.07,
                "gradeSurface": 1.93,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "d05d9789-99e9-44bf-ab0f-da05d5b1cfba",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245443851200478,
                        48.588878909513916,
                    ],
                    [
                        2.245647367592548,
                        48.588399829839354,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 944,
                    "end": 999,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 5.5,
                "gradeSurface": 0.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "3c1b584c-a714-401d-b89d-e3a6fd768db1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244621742678838,
                        48.590786176379915,
                    ],
                    [
                        2.244749474864165,
                        48.59048563186046,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 999,
                    "end": 1034,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.56,
                "gradeSurface": 0.0,
                "gradeStructure": 6.48,
            },
        },
        {
            "id": "6bce4380-2e2d-4d2d-95ee-38e846ae46e6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233311930114299,
                        48.59109887871172,
                    ],
                    [
                        2.233625567115902,
                        48.591072112341124,
                    ],
                    [
                        2.233827966425581,
                        48.591048227437646,
                    ],
                    [
                        2.233981196747314,
                        48.591029648654974,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1034,
                    "end": 1084,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "12ecd2b4-e69c-4208-99b5-e8ae587a68ae",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.233981196747314,
                        48.591029648654974,
                    ],
                    [
                        2.234546584455256,
                        48.5909610950254,
                    ],
                    [
                        2.234649226743283,
                        48.590957177517325,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1084,
                    "end": 1134,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 1.6,
                "gradeSurface": 3.56,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "14fd275b-788f-412b-a8d7-5724e179c234",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.234649226743283,
                        48.590957177517325,
                    ],
                    [
                        2.234849149363289,
                        48.59094954689306,
                    ],
                    [
                        2.235393236627949,
                        48.59092785534854,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1134,
                    "end": 1189,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 1.51,
                "gradeSurface": 3.36,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "a56913f2-4b37-40e9-8819-c316be88bc3e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.218411516972762,
                        48.5893872386649,
                    ],
                    [
                        2.218587873483341,
                        48.589382099617815,
                    ],
                    [
                        2.219083755613285,
                        48.589421112669164,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1189,
                    "end": 1239,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "ef03dc42-f7cf-4052-be8d-a8e7542478bf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.219083755613285,
                        48.589421112669164,
                    ],
                    [
                        2.21929893900858,
                        48.589438041355365,
                    ],
                    [
                        2.219710894959912,
                        48.589456035471116,
                    ],
                    [
                        2.21975714312672,
                        48.58945404859105,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1239,
                    "end": 1289,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.65,
                "gradeSurface": 0.0,
                "gradeStructure": 6.64,
            },
        },
        {
            "id": "e8bcea50-8e48-40a7-8f44-426d6be2d8dd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.21975714312672,
                        48.58945404859105,
                    ],
                    [
                        2.220037900239427,
                        48.589441986519525,
                    ],
                    [
                        2.220308119576359,
                        48.589416769840554,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1289,
                    "end": 1330,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.62,
                "gradeSurface": 0.0,
                "gradeStructure": 4.76,
            },
        },
        {
            "id": "7aa3eae6-b284-48ed-8892-55f035563d9d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.268101259803128,
                        48.59545317261242,
                    ],
                    [
                        2.268328972528086,
                        48.595461768968306,
                    ],
                    [
                        2.268779739732923,
                        48.59544418179753,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1330,
                    "end": 1380,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "d241e918-6899-4c8c-8e05-9200d706b04d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.268779739732923,
                        48.59544418179753,
                    ],
                    [
                        2.268826950025991,
                        48.595442339737346,
                    ],
                    [
                        2.269458691924116,
                        48.59542417215121,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1380,
                    "end": 1430,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "606d5034-8f77-43e3-8f31-a638734448d3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.269458691924116,
                        48.59542417215121,
                    ],
                    [
                        2.269961143770789,
                        48.59540972027586,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1430,
                    "end": 1467,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "9e9014e4-6324-4802-b022-8164e08028f0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24456700727779,
                        48.59091446711807,
                    ],
                    [
                        2.244595059451889,
                        48.590849876314,
                    ],
                    [
                        2.244621742678838,
                        48.590786176379915,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1467,
                    "end": 1482,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 1.58,
                "gradeSurface": 2.54,
                "gradeStructure": 0.8,
            },
        },
        {
            "id": "ba0422a5-7ed9-4e8e-b665-53d0053ee44b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.24992923168966,
                        48.58737799395477,
                    ],
                    [
                        2.250307106215488,
                        48.587408261418275,
                    ],
                    [
                        2.250453380342712,
                        48.58741997753347,
                    ],
                    [
                        2.250600747603817,
                        48.587431781018196,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1482,
                    "end": 1532,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.89,
                "gradeSurface": 3.2,
                "gradeStructure": 6.27,
            },
        },
        {
            "id": "c2aaf93c-f42f-48ad-bc33-284ccab497bf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.250600747603817,
                        48.587431781018196,
                    ],
                    [
                        2.250855634529942,
                        48.587452195922815,
                    ],
                    [
                        2.251114313509566,
                        48.58747361437618,
                    ],
                    [
                        2.251272020066682,
                        48.58748688227337,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1532,
                    "end": 1582,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "a8126fbf-bc43-4d49-9481-9f0f347cb3aa",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251272020066682,
                        48.58748688227337,
                    ],
                    [
                        2.251634370899419,
                        48.587517366133646,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1582,
                    "end": 1609,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.58,
                "gradeSurface": 3.04,
                "gradeStructure": 4.03,
            },
        },
        {
            "id": "1c96e959-a923-49bc-9e0b-8f14e458aa09",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272927829586205,
                        48.59515710966892,
                    ],
                    [
                        2.272964292413546,
                        48.59516812710916,
                    ],
                    [
                        2.272989919542462,
                        48.59517817879916,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1609,
                    "end": 1614,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "753c2a0b-c380-4dde-820b-802ef355040b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.254489680129126,
                        48.590111647306394,
                    ],
                    [
                        2.255100240186535,
                        48.59030890899787,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1614,
                    "end": 1664,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.89,
                "gradeSurface": 2.79,
                "gradeStructure": 6.61,
            },
        },
        {
            "id": "27d8beca-1366-461a-b894-f0e816575de9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.255100240186535,
                        48.59030890899787,
                    ],
                    [
                        2.255710804842384,
                        48.590506167525675,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1664,
                    "end": 1714,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.08,
                "gradeSurface": 0.0,
                "gradeStructure": 0.16,
            },
        },
        {
            "id": "8f7d1873-bcc7-4b49-b979-312d62fdb6e5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.255710804842384,
                        48.590506167525675,
                    ],
                    [
                        2.256025772580531,
                        48.590607924091245,
                    ],
                    [
                        2.256096011319825,
                        48.5906272539886,
                    ],
                    [
                        2.256328552764937,
                        48.59069294689583,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1714,
                    "end": 1764,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.85,
                "gradeSurface": 0.0,
                "gradeStructure": 7.0,
            },
        },
        {
            "id": "bd906abf-bf15-43fa-b211-b2800b83deae",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.256328552764937,
                        48.59069294689583,
                    ],
                    [
                        2.256953384925944,
                        48.59086945876026,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1764,
                    "end": 1814,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "85b139b4-02db-4619-9ad5-7b3d92d80fc7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.256953384925944,
                        48.59086945876026,
                    ],
                    [
                        2.257578221297905,
                        48.591045967319275,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1814,
                    "end": 1864,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.64,
                "gradeSurface": 9.2,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "61c91f84-6d08-49a3-a9b0-4fcacef79f2f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.257578221297905,
                        48.591045967319275,
                    ],
                    [
                        2.257633013725251,
                        48.59106144528901,
                    ],
                    [
                        2.257870597170255,
                        48.591137590796926,
                    ],
                    [
                        2.257958336814422,
                        48.59116602435011,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1864,
                    "end": 1895,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.29,
                "gradeSurface": 2.82,
                "gradeStructure": 5.5,
            },
        },
        {
            "id": "fbc8501a-22e6-4946-b0c5-7912bdce2312",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.227502345364066,
                        48.590383940033504,
                    ],
                    [
                        2.227699762059131,
                        48.590422103490546,
                    ],
                    [
                        2.228142265631698,
                        48.59052860396992,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1895,
                    "end": 1945,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.2,
                "gradeSurface": 0.0,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "ea7988da-2ce2-46f9-b963-25233849a35f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228142265631698,
                        48.59052860396992,
                    ],
                    [
                        2.228994247297491,
                        48.59073365057348,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 1945,
                    "end": 2012,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.94,
                "gradeSurface": 0.0,
                "gradeStructure": 5.35,
            },
        },
        {
            "id": "18f95a6b-1800-47f3-a851-1fbde3bf74ea",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.2626585117207,
                        48.59346753263306,
                    ],
                    [
                        2.263027905109332,
                        48.59384650422117,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2012,
                    "end": 2062,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.98,
                "gradeSurface": 3.3,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "84767528-b631-4191-8b61-29ed56686700",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263027905109332,
                        48.59384650422117,
                    ],
                    [
                        2.26319854091862,
                        48.59402156225961,
                    ],
                    [
                        2.263397303849292,
                        48.59422547454296,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2062,
                    "end": 2112,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "df01e8b7-d416-4197-84bb-89a3637dd6a2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263397303849292,
                        48.59422547454296,
                    ],
                    [
                        2.263527440420881,
                        48.59435898150543,
                    ],
                    [
                        2.263584825309199,
                        48.59442500460388,
                    ],
                    [
                        2.263665464761674,
                        48.59447677852108,
                    ],
                    [
                        2.263769358781453,
                        48.5945143032116,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2112,
                    "end": 2155,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.44,
                "gradeSurface": 2.1,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "4a835e4c-68c7-4f19-ab4d-88c148480fb2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238252460816152,
                        48.59115938573596,
                    ],
                    [
                        2.238549471634791,
                        48.59115678832945,
                    ],
                    [
                        2.238690748282967,
                        48.591139700678184,
                    ],
                    [
                        2.238926555919239,
                        48.591114234458864,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2155,
                    "end": 2205,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.83,
                "gradeSurface": 10.0,
                "gradeStructure": 9.7,
            },
        },
        {
            "id": "6ecb3a42-55dd-411f-8374-bb123b2b21dc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.238926555919239,
                        48.591114234458864,
                    ],
                    [
                        2.239327730410625,
                        48.591070908192705,
                    ],
                    [
                        2.239600068523258,
                        48.5910574084972,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2205,
                    "end": 2255,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.86,
                "gradeSurface": 10.0,
                "gradeStructure": 9.76,
            },
        },
        {
            "id": "ffd4401b-e72f-4c41-bf07-82b6ae1d5f3b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.239600068523258,
                        48.5910574084972,
                    ],
                    [
                        2.239745691098462,
                        48.591050189783566,
                    ],
                    [
                        2.240007382242808,
                        48.591051860980855,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2255,
                    "end": 2285,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "80b5ec30-a6f8-4a58-bd31-8a88b68acbe0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272815998691309,
                        48.595202304172915,
                    ],
                    [
                        2.27282017916184,
                        48.59519423366146,
                    ],
                    [
                        2.272836688735993,
                        48.595177242854874,
                    ],
                    [
                        2.272858572446654,
                        48.59516388311822,
                    ],
                    [
                        2.272884424321541,
                        48.59515774410491,
                    ],
                    [
                        2.272915625367478,
                        48.59515703512555,
                    ],
                    [
                        2.272927829586205,
                        48.59515710966892,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2285,
                    "end": 2296,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "5dbbff84-5906-48f6-a024-a6a463e9ae2a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252072967855186,
                        48.588613098850956,
                    ],
                    [
                        2.252162199402648,
                        48.588821459755245,
                    ],
                    [
                        2.252232480474539,
                        48.58893254817359,
                    ],
                    [
                        2.252304092029148,
                        48.58904544403208,
                    ],
                    [
                        2.252364117327363,
                        48.5891150878087,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2296,
                    "end": 2356,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.93,
                "gradeSurface": 0.0,
                "gradeStructure": 5.33,
            },
        },
        {
            "id": "5e3daf7e-a013-414c-a219-6c305c56077e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241028423738649,
                        48.591055677293575,
                    ],
                    [
                        2.241248016499596,
                        48.591061575153915,
                    ],
                    [
                        2.241705579884587,
                        48.59108762214054,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2356,
                    "end": 2406,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "9e192764-5d0d-4c19-bfce-f0664929c486",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.241705579884587,
                        48.59108762214054,
                    ],
                    [
                        2.241781855714192,
                        48.59109196400036,
                    ],
                    [
                        2.242361286771423,
                        48.5912014125185,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2406,
                    "end": 2456,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.58,
                "gradeSurface": 0.0,
                "gradeStructure": 4.69,
            },
        },
        {
            "id": "6f1ea462-ff51-4267-bbcc-0798f99d0c5e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.242361286771423,
                        48.5912014125185,
                    ],
                    [
                        2.242447209285567,
                        48.591217642110514,
                    ],
                    [
                        2.242970714367253,
                        48.591306431697376,
                    ],
                    [
                        2.243019218855413,
                        48.591312422481565,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2456,
                    "end": 2506,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "a2b56880-62d8-47e9-8c21-68b5725d1b26",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.243019218855413,
                        48.591312422481565,
                    ],
                    [
                        2.243393046767975,
                        48.59135859321727,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2506,
                    "end": 2534,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "05a6642c-0ab8-4690-b7fa-24832bb15fec",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260670350036127,
                        48.59222729554731,
                    ],
                    [
                        2.260933261997554,
                        48.59233507688387,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2534,
                    "end": 2557,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 5.77,
                "gradeSurface": 6.69,
                "gradeStructure": 5.01,
            },
        },
        {
            "id": "29f0d22f-d6d9-4c5b-88a8-ca8df0f80b03",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.262097744337868,
                        48.59293961207076,
                    ],
                    [
                        2.26225338931049,
                        48.593057520135886,
                    ],
                    [
                        2.262359016053809,
                        48.5931643232664,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2557,
                    "end": 2589,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "cf35e0dd-4bdc-4918-b94f-baad096cafcf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230987966507441,
                        48.59098673327829,
                    ],
                    [
                        2.23158324720736,
                        48.59108053465442,
                    ],
                    [
                        2.231800061224829,
                        48.591090929854175,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2589,
                    "end": 2650,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.99,
                "gradeSurface": 3.22,
                "gradeStructure": 6.44,
            },
        },
        {
            "id": "5f6172bc-85ec-43d1-bd4c-d10068abe948",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.267079533202802,
                        48.59529845852845,
                    ],
                    [
                        2.267242004769074,
                        48.595317449996585,
                    ],
                    [
                        2.267737579492773,
                        48.595398947625,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2650,
                    "end": 2700,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "ca385b39-7e10-4411-a94f-96c67f51cfdb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.267737579492773,
                        48.595398947625,
                    ],
                    [
                        2.26781027894929,
                        48.59541090290887,
                    ],
                    [
                        2.268094542494381,
                        48.59544863350431,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2700,
                    "end": 2727,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 8.85,
                "gradeSurface": 7.46,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "7375d6d7-20f1-4fe4-878d-d6e46c0afd9b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.231800061224829,
                        48.591090929854175,
                    ],
                    [
                        2.232057684580599,
                        48.59109259227569,
                    ],
                    [
                        2.232478541470151,
                        48.59109923995767,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2727,
                    "end": 2777,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "80ea379c-34b9-4531-9dcc-9da0f380fb05",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.232478541470151,
                        48.59109923995767,
                    ],
                    [
                        2.233020250821476,
                        48.59110779438247,
                    ],
                    [
                        2.23330525587662,
                        48.59109163917423,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2777,
                    "end": 2838,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "8cf1fe37-ef60-4444-9658-2efa9cb76193",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.264026288555832,
                        48.594470014350414,
                    ],
                    [
                        2.264053282271756,
                        48.59447917686966,
                    ],
                    [
                        2.264080023233878,
                        48.594506329069546,
                    ],
                    [
                        2.264093330535625,
                        48.594524402588775,
                    ],
                    [
                        2.264106511469352,
                        48.59455147094932,
                    ],
                    [
                        2.264119692416701,
                        48.59457853930782,
                    ],
                    [
                        2.264119439666555,
                        48.59459652899354,
                    ],
                    [
                        2.264105626827692,
                        48.5946144348495,
                    ],
                    [
                        2.264105500450138,
                        48.594623429692135,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2838,
                    "end": 2858,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "56627be5-3371-42fe-830c-6991b885db1e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.235382428748355,
                        48.59092508721546,
                    ],
                    [
                        2.236047611165768,
                        48.590875384345246,
                    ],
                    [
                        2.23605657486973,
                        48.59087544187699,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2858,
                    "end": 2908,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "810d98a2-d33d-4cea-bef9-e1662a9a0757",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.23605657486973,
                        48.59087544187699,
                    ],
                    [
                        2.236223879142496,
                        48.59087651556196,
                    ],
                    [
                        2.236670804338019,
                        48.59091536530092,
                    ],
                    [
                        2.237036244598194,
                        48.590962686744255,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2908,
                    "end": 2981,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "617473e7-9e8d-4ac7-a3e9-9eb681fc7eb6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245082295523098,
                        48.58969162576154,
                    ],
                    [
                        2.245278339223788,
                        48.589261076289795,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 2981,
                    "end": 3031,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.2,
                "gradeSurface": 0.0,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "d6831ff5-82e4-4d38-a3a7-5837e835f90a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.222677507175272,
                        48.58993422792878,
                    ],
                    [
                        2.223055953105312,
                        48.59001766020523,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3031,
                    "end": 3060,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 4.05,
                "gradeSurface": 5.2,
                "gradeStructure": 3.12,
            },
        },
        {
            "id": "49377e4e-ab72-4e72-a642-11247a10b1a8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223055953105312,
                        48.59001766020523,
                    ],
                    [
                        2.223150331327859,
                        48.5900542590406,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3060,
                    "end": 3068,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "ab19d820-fa0c-4cb2-8366-d6661dc692b9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244745472013664,
                        48.59048110862366,
                    ],
                    [
                        2.244927793207683,
                        48.59005000558732,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3068,
                    "end": 3118,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.83,
                "gradeSurface": 4.0,
                "gradeStructure": 3.7,
            },
        },
        {
            "id": "92e73f26-f8a4-4e78-941a-50e1f99bd4f5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.244927793207683,
                        48.59005000558732,
                    ],
                    [
                        2.245026245404668,
                        48.58981720976851,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3118,
                    "end": 3145,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "bc4f0e0a-150c-4f16-8c67-638b16492914",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245881951688872,
                        48.58783458785189,
                    ],
                    [
                        2.245937997634592,
                        48.587709003238885,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3145,
                    "end": 3160,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "75f56902-87fd-4a19-90c6-f6613dd161b5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245026245404668,
                        48.58981720976851,
                    ],
                    [
                        2.245082295523098,
                        48.58969162576154,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3160,
                    "end": 3175,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.58,
                "gradeSurface": 3.04,
                "gradeStructure": 4.03,
            },
        },
        {
            "id": "23ffb5e4-6783-47d3-8cd3-7c6b4e2d69d1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.271567609761538,
                        48.595352994120354,
                    ],
                    [
                        2.271798634957881,
                        48.5953184251789,
                    ],
                    [
                        2.271920802543982,
                        48.5953101768557,
                    ],
                    [
                        2.272042970091606,
                        48.59530192840703,
                    ],
                    [
                        2.272151702320987,
                        48.59528460207387,
                    ],
                    [
                        2.272237911739468,
                        48.59527798869603,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3175,
                    "end": 3225,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.58,
                "gradeSurface": 3.04,
                "gradeStructure": 4.03,
            },
        },
        {
            "id": "c9c513e8-871e-4986-8080-75740a7f3921",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.229653908522265,
                        48.590789807933845,
                    ],
                    [
                        2.229892060330787,
                        48.59081772537327,
                    ],
                    [
                        2.230315640927514,
                        48.590883050240045,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4178,
                    "end": 4228,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "c04ac5e5-c74a-458e-ba57-d9c7e27b7397",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.272237911739468,
                        48.59527798869603,
                    ],
                    [
                        2.272260309499734,
                        48.595276270487524,
                    ],
                    [
                        2.272355481335445,
                        48.595258861065766,
                    ],
                    [
                        2.2724640884284,
                        48.59525052929332,
                    ],
                    [
                        2.27255913522231,
                        48.595242114555255,
                    ],
                    [
                        2.272640496809923,
                        48.595242611731074,
                    ],
                    [
                        2.272708547937443,
                        48.59522503630746,
                    ],
                    [
                        2.272762913871227,
                        48.59521637284879,
                    ],
                    [
                        2.27280384441629,
                        48.59519863167907,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3225,
                    "end": 3268,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 1.8,
                "gradeSurface": 4.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "9730a56e-d1dd-4613-b72d-72a1e7c05ade",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.269953057738655,
                        48.59540607244044,
                    ],
                    [
                        2.270061540162361,
                        48.59540673770405,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3268,
                    "end": 3276,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.58,
                "gradeSurface": 10.0,
                "gradeStructure": 9.25,
            },
        },
        {
            "id": "625150d2-3e51-4cea-93fe-0a8e82163206",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.248710336183967,
                        48.58727675084185,
                    ],
                    [
                        2.249380086733255,
                        48.587330403575216,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3276,
                    "end": 3326,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 8.27,
                "gradeSurface": 6.45,
                "gradeStructure": 9.76,
            },
        },
        {
            "id": "e80fcadf-eadb-4575-a148-1ddd14d53c56",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.249380086733255,
                        48.587330403575216,
                    ],
                    [
                        2.249929283207345,
                        48.58737439600259,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3326,
                    "end": 3367,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.5,
                "gradeSurface": 2.22,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "f661934f-af32-4823-bb92-5dcee1a2eaad",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246949973397527,
                        48.5871126988657,
                    ],
                    [
                        2.247044621653368,
                        48.58713128908192,
                    ],
                    [
                        2.247125841122561,
                        48.58714079857906,
                    ],
                    [
                        2.247619504887908,
                        48.58718315807548,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3367,
                    "end": 3417,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.71,
                "gradeSurface": 0.0,
                "gradeStructure": 4.94,
            },
        },
        {
            "id": "e8f84c30-76c9-4bf2-916f-d4f411697d0b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.247619504887908,
                        48.58718315807548,
                    ],
                    [
                        2.248292857079386,
                        48.58724093256982,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3417,
                    "end": 3467,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.46,
                "gradeSurface": 0.0,
                "gradeStructure": 6.29,
            },
        },
        {
            "id": "7bed95db-4c69-4294-a63b-559cd2ee63f2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.248292857079386,
                        48.58724093256982,
                    ],
                    [
                        2.248710336183967,
                        48.58727675084185,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3467,
                    "end": 3498,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.65,
                "gradeSurface": 9.22,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "13a64b22-bff2-4cf0-8e94-01833929d358",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220488075110151,
                        48.58944313653914,
                    ],
                    [
                        2.220663401156132,
                        48.58950725462925,
                    ],
                    [
                        2.220933638614959,
                        48.589571993734936,
                    ],
                    [
                        2.221117383369897,
                        48.5896037486121,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3498,
                    "end": 3548,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.92,
                "gradeSurface": 2.65,
                "gradeStructure": 4.97,
            },
        },
        {
            "id": "2c0adf0b-9b40-4dce-a1fb-6210044fd7f6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221117383369897,
                        48.5896037486121,
                    ],
                    [
                        2.221312346577803,
                        48.589637441915784,
                    ],
                    [
                        2.22174515683694,
                        48.589712238356995,
                    ],
                    [
                        2.221772144569008,
                        48.589718664177546,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3548,
                    "end": 3598,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 1.83,
                "gradeSurface": 1.56,
                "gradeStructure": 2.04,
            },
        },
        {
            "id": "56f02dbb-b0fe-4b13-bfbb-c638613bd0ba",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.221772144569008,
                        48.589718664177546,
                    ],
                    [
                        2.222677507175272,
                        48.58993422792878,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3598,
                    "end": 3669,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.67,
                "gradeSurface": 3.43,
                "gradeStructure": 2.04,
            },
        },
        {
            "id": "ae4c6e62-ecff-4041-ae54-631a21c4661c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220298655284184,
                        48.58941490869979,
                    ],
                    [
                        2.220299190832285,
                        48.58937892936736,
                    ],
                    [
                        2.22029945860606,
                        48.58936093970086,
                    ],
                    [
                        2.220313285031424,
                        48.589343038853904,
                    ],
                    [
                        2.220327111447339,
                        48.58932513800515,
                    ],
                    [
                        2.220367921264103,
                        48.589316409617226,
                    ],
                    [
                        2.220422289710097,
                        48.58930777002277,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3669,
                    "end": 3687,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "f7c1cb31-e12a-4ef9-9a43-05994738acf1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223150331327859,
                        48.5900542590406,
                    ],
                    [
                        2.223542204409086,
                        48.59014677308882,
                    ],
                    [
                        2.223799023130384,
                        48.590202422372286,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3687,
                    "end": 3738,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 1.02,
                "gradeSurface": 1.56,
                "gradeStructure": 0.57,
            },
        },
        {
            "id": "75fa4b67-58c3-4465-b11c-497950df89dc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246303195641254,
                        48.58682973813434,
                    ],
                    [
                        2.246437482165626,
                        48.5869205454496,
                    ],
                    [
                        2.246558599179702,
                        48.586984282138495,
                    ],
                    [
                        2.246679845839838,
                        48.58703902382132,
                    ],
                    [
                        2.246801222083986,
                        48.58708477049887,
                    ],
                    [
                        2.246949973397527,
                        48.5871126988657,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3738,
                    "end": 3796,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 1.57,
                "gradeSurface": 3.5,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "246576d9-3f4f-4ec1-a387-313b4ffda0b9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.245937997634592,
                        48.587709003238885,
                    ],
                    [
                        2.246148620588371,
                        48.58720657851872,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3796,
                    "end": 3854,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.19,
                "gradeSurface": 0.0,
                "gradeStructure": 5.8,
            },
        },
        {
            "id": "02c223c9-d3b9-4b93-9adf-a00301e7109c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260195851843144,
                        48.59202644313497,
                    ],
                    [
                        2.260667638143122,
                        48.592227278702936,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3854,
                    "end": 3895,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.24,
                "gradeSurface": 0.0,
                "gradeStructure": 4.08,
            },
        },
        {
            "id": "12bbc8ae-00d0-473c-83c9-50dfabc595cf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246148620588371,
                        48.58720657851872,
                    ],
                    [
                        2.246135709672301,
                        48.587161518252096,
                    ],
                    [
                        2.246136227452157,
                        48.587125538739265,
                    ],
                    [
                        2.246123187119717,
                        48.58708947334693,
                    ],
                    [
                        2.246083160054717,
                        48.58704424130872,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3895,
                    "end": 3914,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.4,
                "gradeSurface": 0.0,
                "gradeStructure": 4.36,
            },
        },
        {
            "id": "a11861a7-032b-4268-aa97-86a2c017faa0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246031128522139,
                        48.586890984811696,
                    ],
                    [
                        2.246126552727344,
                        48.5868556064813,
                    ],
                    [
                        2.246303195641254,
                        48.58682973813434,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3914,
                    "end": 3935,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "91849674-e437-4d4b-b40f-78edea8cfc39",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.263768015418775,
                        48.59451339534038,
                    ],
                    [
                        2.263781954779235,
                        48.59448649467893,
                    ],
                    [
                        2.26382288779563,
                        48.59446875658675,
                    ],
                    [
                        2.26387738083015,
                        48.59445110233729,
                    ],
                    [
                        2.263918060967959,
                        48.59445135389924,
                    ],
                    [
                        2.263985734800781,
                        48.59446076798191,
                    ],
                    [
                        2.264026288555832,
                        48.594470014350414,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3935,
                    "end": 3958,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "a9cc8835-5582-41e2-a3ac-70fd2e868bfc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.223799023130384,
                        48.590202422372286,
                    ],
                    [
                        2.224110211212378,
                        48.590249429826805,
                    ],
                    [
                        2.224489194381047,
                        48.590296878202295,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 3958,
                    "end": 4010,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "585fb02b-6bda-4d5d-98b8-d0be65cc4405",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260991288624389,
                        48.59235522757761,
                    ],
                    [
                        2.261409223236956,
                        48.59252873883574,
                    ],
                    [
                        2.261570923309089,
                        48.59260170721559,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4010,
                    "end": 4061,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "bd21b08b-1604-43da-be55-a2333c43bf7f",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251713825361156,
                        48.58774545661947,
                    ],
                    [
                        2.251752829966686,
                        48.587862645772354,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4061,
                    "end": 4074,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "b0ba1e4c-a439-4be4-b49c-2c4645669303",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.261570923309089,
                        48.59260170721559,
                    ],
                    [
                        2.261705631451685,
                        48.592665512312514,
                    ],
                    [
                        2.261853519240199,
                        48.59275638590341,
                    ],
                    [
                        2.262095057751275,
                        48.59293779628834,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4074,
                    "end": 4128,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "b22e59bc-3ef7-4127-957b-cf515bb39e64",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.228984795698721,
                        48.59073089064207,
                    ],
                    [
                        2.229079444114002,
                        48.5907494950892,
                    ],
                    [
                        2.229269005846819,
                        48.59076871409082,
                    ],
                    [
                        2.229648394300119,
                        48.59078916152099,
                    ],
                    [
                        2.229653908522265,
                        48.590789807933845,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4128,
                    "end": 4178,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "b61a0020-125b-4f73-afa4-ecf77393cc63",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.230315640927514,
                        48.590883050240045,
                    ],
                    [
                        2.230987966507441,
                        48.59098673327829,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4228,
                    "end": 4279,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.34,
                "gradeSurface": 1.87,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "3974f6b4-6f75-421e-a267-0d9723fd538c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.252935019347687,
                        48.5895882467518,
                    ],
                    [
                        2.253123944366242,
                        48.589652402117025,
                    ],
                    [
                        2.253164364236825,
                        48.589670647089456,
                    ],
                    [
                        2.253474806342719,
                        48.589771546990896,
                    ],
                    [
                        2.253540531234517,
                        48.58979290874217,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4279,
                    "end": 4329,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "7ed13b12-bfaa-4221-b18d-07f1ced67bce",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.253540531234517,
                        48.58979290874217,
                    ],
                    [
                        2.253785249644546,
                        48.58987244607428,
                    ],
                    [
                        2.254147997039421,
                        48.5899956238594,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4329,
                    "end": 4379,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "78d8bd94-8bff-4af4-be8c-053b0d795375",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.254147997039421,
                        48.5899956238594,
                    ],
                    [
                        2.254486981149475,
                        48.59011073083464,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4379,
                    "end": 4407,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "452623ef-3c2b-419b-8df2-9c3ee9fc9d0e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.22724112239995,
                        48.590350759424624,
                    ],
                    [
                        2.227498344010716,
                        48.59037941621639,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4407,
                    "end": 4426,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "f7c06f5c-5bc6-4263-8c6d-813fec2b6bec",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.260923871886935,
                        48.592327822064405,
                    ],
                    [
                        2.260991288624389,
                        48.59235522757761,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4426,
                    "end": 4432,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.2,
                "gradeSurface": 0.0,
                "gradeStructure": 4.0,
            },
        },
        {
            "id": "8d54250e-26e7-451f-99df-fb225ccb8684",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237036244598194,
                        48.590962686744255,
                    ],
                    [
                        2.237442362854638,
                        48.591010267674186,
                    ],
                    [
                        2.237672344134869,
                        48.59104772356566,
                    ],
                    [
                        2.237696814345452,
                        48.59105253155738,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4432,
                    "end": 4482,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.53,
                "gradeSurface": 8.96,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "fc5b0b11-b337-4c9e-8b18-dd4414052b4c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.237696814345452,
                        48.59105253155738,
                    ],
                    [
                        2.238240257579361,
                        48.59115930763737,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4482,
                    "end": 4524,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "801e8d34-67d4-49dd-b283-85caa2d73b24",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.258227005477598,
                        48.59124955944555,
                    ],
                    [
                        2.258810262595666,
                        48.59148165387914,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4524,
                    "end": 4574,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 3.69,
                "gradeSurface": 3.16,
                "gradeStructure": 4.13,
            },
        },
        {
            "id": "2eab8df6-cb53-4459-8a07-000daf1d5bcb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.258810262595666,
                        48.59148165387914,
                    ],
                    [
                        2.259393524882363,
                        48.591713745410765,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4574,
                    "end": 4624,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "3152ff92-bbc7-4dcf-8f9d-12b2619c0ebb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.259393524882363,
                        48.591713745410765,
                    ],
                    [
                        2.259696823314087,
                        48.59183443186032,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4624,
                    "end": 4650,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.48,
                "gradeSurface": 2.17,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "9bb33bd1-36a0-4057-9014-0eaf377b8965",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.246083160054717,
                        48.58704424130872,
                    ],
                    [
                        2.246031128522139,
                        48.586890984811696,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4650,
                    "end": 4667,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.42,
                "gradeSurface": 0.0,
                "gradeStructure": 4.4,
            },
        },
        {
            "id": "cf4828ab-a72e-4074-8814-48db52259e3d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.224489194381047,
                        48.590296878202295,
                    ],
                    [
                        2.225003501262026,
                        48.59036319795697,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4667,
                    "end": 4706,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.18,
                "gradeSurface": 0.0,
                "gradeStructure": 3.97,
            },
        },
        {
            "id": "cf7255fb-7e16-4ebb-b91e-21105ae20d4c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.266605175666583,
                        48.595277545700284,
                    ],
                    [
                        2.266781333185049,
                        48.59528762650184,
                    ],
                    [
                        2.266943930501376,
                        48.59529762353658,
                    ],
                    [
                        2.267079533202802,
                        48.59529845852845,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4706,
                    "end": 4741,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 9.84,
                "gradeSurface": 9.65,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "12b2558d-ca59-4f49-a15c-7a0d9941a7a5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251752829966686,
                        48.587862645772354,
                    ],
                    [
                        2.251805264624431,
                        48.58798891501211,
                    ],
                    [
                        2.251935512824698,
                        48.588297946200406,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4741,
                    "end": 4791,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "f1ace0e4-a1f2-456f-8962-4e4605fe9796",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.251935512824698,
                        48.588297946200406,
                    ],
                    [
                        2.252067570134959,
                        48.58861126579551,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4791,
                    "end": 4827,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "fc36a28b-fd10-4240-b7f9-95a8535ddb6d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.226305291013702,
                        48.59036267134899,
                    ],
                    [
                        2.22669889825379,
                        48.59033824215887,
                    ],
                    [
                        2.227011018903668,
                        48.590322278191614,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4827,
                    "end": 4879,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 2.82,
                "gradeSurface": 0.0,
                "gradeStructure": 5.13,
            },
        },
        {
            "id": "a71784fc-effa-4cfd-ae50-9f2f8c031636",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        2.220422289710097,
                        48.58930777002277,
                    ],
                    [
                        2.220462697921511,
                        48.589326026103784,
                    ],
                    [
                        2.220503106161091,
                        48.589344282170806,
                    ],
                    [
                        2.220502570753402,
                        48.58938026150509,
                    ],
                    [
                        2.220502035344977,
                        48.58941624083836,
                    ],
                    [
                        2.220488075110151,
                        48.58944313653914,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "91 D0152",
                    "start": 4879,
                    "end": 4897,
                },
            ],
            "properties": {
                "layer": "7483628c-6464-4c85-9699-2d715c129727",
                "gradeGlobal": 6.53,
                "gradeSurface": 2.29,
                "gradeStructure": 10.0,
            },
        },
    ],
}

export const networkGradingMulhouse2024 = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "7306d7c6-3a8b-4077-8ba0-9db8d70d8465",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344920534197992,
                        47.754581347838474,
                    ],
                    [
                        7.34485641593329,
                        47.75482591204205,
                    ],
                    [
                        7.344790901527912,
                        47.75519911419621,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 0,
                    "end": 69,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 5.94,
                "gradeSurface": 5.11,
                "gradeStructure": 6.61,
            },
        },
        {
            "id": "35a4ced4-74f6-491f-92ef-d9c609f834b5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342288147055242,
                        47.75802012616554,
                    ],
                    [
                        7.34187303606848,
                        47.75836582390657,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 69,
                    "end": 118,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "e6ba9f36-0556-4fb3-93da-4954973f73c1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344368004977621,
                        47.75598709823544,
                    ],
                    [
                        7.344098436997749,
                        47.75649680846129,
                    ],
                    [
                        7.343952115562578,
                        47.75661383264499,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 118,
                    "end": 195,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "df0027f1-854a-4c51-8c5c-5da19508b111",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343952115562578,
                        47.75661383264499,
                    ],
                    [
                        7.342968695799731,
                        47.75745384861439,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 195,
                    "end": 314,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "3228efac-d7ea-480d-ae58-9577889a81a2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342968695799731,
                        47.75745384861439,
                    ],
                    [
                        7.342288147055242,
                        47.75802012616554,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 314,
                    "end": 395,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "c06e8cea-ce77-499d-9d38-0d40470e7caa",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34187303606848,
                        47.75836582390657,
                    ],
                    [
                        7.340770903108702,
                        47.7592930330377,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 395,
                    "end": 527,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 1.66,
                "gradeSurface": 0.0,
                "gradeStructure": 3.01,
            },
        },
        {
            "id": "6221e942-0f1c-42b6-b77e-91145fa3d08e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.340770903108702,
                        47.7592930330377,
                    ],
                    [
                        7.339725616403063,
                        47.76016645798436,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 527,
                    "end": 652,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.44,
                "gradeSurface": 0.0,
                "gradeStructure": 0.8,
            },
        },
        {
            "id": "692a9d10-1934-4008-bb18-a27864d0c2f5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.339725616403063,
                        47.76016645798436,
                    ],
                    [
                        7.339492505724596,
                        47.76036608771376,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 652,
                    "end": 680,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 2.58,
                "gradeSurface": 1.07,
                "gradeStructure": 3.82,
            },
        },
        {
            "id": "83dda0f4-cde0-4ff9-80a6-cf71dc10ccde",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344790901527912,
                        47.75519911419621,
                    ],
                    [
                        7.344368004977621,
                        47.75598709823544,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 680,
                    "end": 773,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 7.63,
                "gradeSurface": 7.14,
                "gradeStructure": 8.02,
            },
        },
        {
            "id": "b7a36971-e9bc-4c61-8b00-83a578ab0aa2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.346037345455459,
                        47.75817922448468,
                    ],
                    [
                        7.345059265337103,
                        47.75888981274631,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 0,
                    "end": 108,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "f296694e-d206-4068-b56c-5beaf636dea6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344072404198844,
                        47.75961153628929,
                    ],
                    [
                        7.343218199045569,
                        47.76024375611979,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 108,
                    "end": 203,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "8874fb38-2146-4955-af10-e30e31b293c1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.33964265787727,
                        47.76183943164458,
                    ],
                    [
                        7.338895794907415,
                        47.762145495633405,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 203,
                    "end": 268,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "66f4aeb7-0ab6-4f4b-8d61-41d3e51c17ee",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.348493093724352,
                        47.756647999452845,
                    ],
                    [
                        7.347622772086281,
                        47.75705667247242,
                    ],
                    [
                        7.347395678038718,
                        47.75719791106025,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 268,
                    "end": 371,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 6.38,
                "gradeSurface": 4.96,
                "gradeStructure": 7.55,
            },
        },
        {
            "id": "bff16a97-cd82-4157-abbe-6c68419c9acd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.347395678038718,
                        47.75719791106025,
                    ],
                    [
                        7.346037345455459,
                        47.75817922448468,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 371,
                    "end": 520,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "62448300-ea6c-4c3c-a9db-56491461bf48",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343218199045569,
                        47.76024375611979,
                    ],
                    [
                        7.343104533120027,
                        47.760327879516836,
                    ],
                    [
                        7.342936460292256,
                        47.76048979168835,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 520,
                    "end": 555,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "f84128a9-2ec4-4ec5-a75c-55b37f63eb97",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342936460292256,
                        47.76048979168835,
                    ],
                    [
                        7.342689479836517,
                        47.76058846997528,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 555,
                    "end": 577,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "567a0a14-cc71-47f3-b50b-a2cbfadb3022",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34201514280675,
                        47.76087210471178,
                    ],
                    [
                        7.34039487131051,
                        47.76153433928608,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 577,
                    "end": 719,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "138897dd-eae2-4e10-ade3-95b47078b72e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.33816549336465,
                        47.76243782644778,
                    ],
                    [
                        7.337516373269059,
                        47.76270798867759,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 719,
                    "end": 776,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "9a23567e-bd0a-4a31-ac27-4533b9eb579a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.337516373269059,
                        47.76270798867759,
                    ],
                    [
                        7.337239635993132,
                        47.7628233697562,
                    ],
                    [
                        7.337146695122533,
                        47.76287457391896,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 776,
                    "end": 809,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.75,
                "gradeSurface": 0.0,
                "gradeStructure": 1.37,
            },
        },
        {
            "id": "737c2a2a-31ee-4e76-bbdd-6968cc9d95b9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.349585177747567,
                        47.75612705417141,
                    ],
                    [
                        7.348493093724352,
                        47.756647999452845,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 809,
                    "end": 909,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.7,
                "gradeSurface": 0.0,
                "gradeStructure": 1.28,
            },
        },
        {
            "id": "627abfab-9424-4ca1-8198-c2f4d3aa608b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.345059265337103,
                        47.75888981274631,
                    ],
                    [
                        7.344072404198844,
                        47.75961153628929,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 909,
                    "end": 1018,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "a63bd046-05f5-4168-98c8-b7d048227b79",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342689479836517,
                        47.76058846997528,
                    ],
                    [
                        7.34201514280675,
                        47.76087210471178,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1018,
                    "end": 1078,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "852c1464-d0d1-4fe4-8411-577cd22283a9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34039487131051,
                        47.76153433928608,
                    ],
                    [
                        7.33964265787727,
                        47.76183943164458,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1078,
                    "end": 1144,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "7b6be83a-20c8-48ca-88c6-83ea9916fa64",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.338895794907415,
                        47.762145495633405,
                    ],
                    [
                        7.33816549336465,
                        47.76243782644778,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1144,
                    "end": 1208,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "e60bd2d7-ce45-4719-a6be-60f9829e99e7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.337146695122533,
                        47.76287457391896,
                    ],
                    [
                        7.336851017719185,
                        47.763038366276376,
                    ],
                    [
                        7.335347119338507,
                        47.76371997024808,
                    ],
                    [
                        7.334951610572595,
                        47.7638946902736,
                    ],
                    [
                        7.334724593112584,
                        47.76399149344378,
                    ],
                    [
                        7.334588267515528,
                        47.76403123378715,
                    ],
                    [
                        7.334433625096522,
                        47.76406331777056,
                    ],
                    [
                        7.334259477037823,
                        47.76407350234034,
                    ],
                    [
                        7.334093921265368,
                        47.76405952312465,
                    ],
                    [
                        7.333926382316668,
                        47.76402178141674,
                    ],
                    [
                        7.332132757018695,
                        47.76334966903256,
                    ],
                    [
                        7.331924444837713,
                        47.76327895791486,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1208,
                    "end": 1661,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 0.62,
                "gradeSurface": 0.0,
                "gradeStructure": 1.13,
            },
        },
        {
            "id": "0ffba993-8864-4101-8998-5f883620dedd",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325680226789657,
                        47.74249684148023,
                    ],
                    [
                        7.325808186683313,
                        47.74257521741361,
                    ],
                    [
                        7.325878145922379,
                        47.742664394115735,
                    ],
                    [
                        7.325946232489619,
                        47.743007105672085,
                    ],
                    [
                        7.326069505735718,
                        47.74361554137933,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 0,
                    "end": 131,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 4.65,
                "gradeSurface": 0.0,
                "gradeStructure": 8.46,
            },
        },
        {
            "id": "fd990e9b-ce22-49ca-9c03-2d1509305239",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325680226789657,
                        47.74249684148023,
                    ],
                    [
                        7.325727932583368,
                        47.74240330606903,
                    ],
                    [
                        7.325719995952639,
                        47.74227912250187,
                    ],
                    [
                        7.325581132189772,
                        47.741633656309915,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 131,
                    "end": 228,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 5.91,
                "gradeSurface": 3.37,
                "gradeStructure": 8.0,
            },
        },
        {
            "id": "4c69692d-17ee-42f2-b4df-746bf54bfd93",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.322189333717003,
                        47.73514674026938,
                    ],
                    [
                        7.322047183273413,
                        47.73483809215277,
                    ],
                    [
                        7.321815467068918,
                        47.734516573674014,
                    ],
                    [
                        7.321725342383214,
                        47.73442267210218,
                    ],
                    [
                        7.321493462809912,
                        47.73398675620143,
                    ],
                    [
                        7.321258983424197,
                        47.73357476671172,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 228,
                    "end": 417,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "8224d8ec-95b7-4b88-9824-feb199235964",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325581132189772,
                        47.741633656309915,
                    ],
                    [
                        7.325470639633684,
                        47.741164157079105,
                    ],
                    [
                        7.325428668224927,
                        47.74105627161237,
                    ],
                    [
                        7.325349342210902,
                        47.740928828652066,
                    ],
                    [
                        7.325080372462999,
                        47.740661410177324,
                    ],
                    [
                        7.324487261047791,
                        47.740198984338996,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 417,
                    "end": 601,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": 1.61,
                "gradeSurface": 0.0,
                "gradeStructure": 2.94,
            },
        },
        {
            "id": "c8a74fc6-4ae7-44c7-9584-b8abc8640a17",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.324487261047791,
                        47.740198984338996,
                    ],
                    [
                        7.324379490183967,
                        47.740088852136736,
                    ],
                    [
                        7.323792148359107,
                        47.739482171341336,
                    ],
                    [
                        7.323314661097879,
                        47.738916030077824,
                    ],
                    [
                        7.322940302825788,
                        47.73831928921152,
                    ],
                    [
                        7.322751776818433,
                        47.73789338184997,
                    ],
                    [
                        7.32261537100977,
                        47.737458086671424,
                    ],
                    [
                        7.322511922854964,
                        47.73699754407101,
                    ],
                    [
                        7.322447223058764,
                        47.73593448321934,
                    ],
                    [
                        7.322343882400412,
                        47.73551209790854,
                    ],
                    [
                        7.322189333717003,
                        47.73514674026938,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 601,
                    "end": 1199,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "86641cf9-2024-4a83-ac6b-a62239f496bf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.326439423271951,
                        47.745177686946136,
                    ],
                    [
                        7.326331549283291,
                        47.74485014945077,
                    ],
                    [
                        7.326295585076954,
                        47.74450861728243,
                    ],
                    [
                        7.326250823138051,
                        47.74423352537577,
                    ],
                    [
                        7.326213036991712,
                        47.74407250046104,
                    ],
                    [
                        7.326049938091111,
                        47.74384568404792,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 1199,
                    "end": 1351,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "d1ea7ca5-4c6d-4556-8694-003b3c9a27e2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.311806809056158,
                        47.74149466393196,
                    ],
                    [
                        7.310957485995646,
                        47.740969074627564,
                    ],
                    [
                        7.310160772599191,
                        47.7406063717104,
                    ],
                    [
                        7.309324318264648,
                        47.74027964905124,
                    ],
                    [
                        7.308904642504877,
                        47.74011656545827,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE DU CLAIRON",
                    "start": 0,
                    "end": 267,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "2604f97c-840f-46a5-b3b5-9fffb7977250",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.308904642504877,
                        47.74011656545827,
                    ],
                    [
                        7.308719752684468,
                        47.74004942802557,
                    ],
                    [
                        7.307906814758471,
                        47.73965980108618,
                    ],
                    [
                        7.307663653444101,
                        47.73952880865411,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE DU CLAIRON",
                    "start": 267,
                    "end": 381,
                },
            ],
            "properties": {
                "layer": "e71b0dbe-6886-44d7-addf-e030f4ac1038",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
    ],
}

export const networkGradingMulhouse2025 = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "adfdc21f-9a58-4e41-b2b7-4657d5b58c35",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344920534197992,
                        47.754581347838474,
                    ],
                    [
                        7.34485641593329,
                        47.75482591204205,
                    ],
                    [
                        7.344790901527912,
                        47.75519911419621,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 0,
                    "end": 69,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 7.24,
                "gradeSurface": 6.68,
                "gradeStructure": 7.7,
            },
        },
        {
            "id": "29676f27-1345-40f0-b71c-9d24ac34c506",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342288147055242,
                        47.75802012616554,
                    ],
                    [
                        7.34187303606848,
                        47.75836582390657,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 69,
                    "end": 118,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "069f5bcf-a552-4073-bad4-9cdb0429a7e2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344368004977621,
                        47.75598709823544,
                    ],
                    [
                        7.344098436997749,
                        47.75649680846129,
                    ],
                    [
                        7.343952115562578,
                        47.75661383264499,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 118,
                    "end": 195,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "005193ac-b574-4b90-92cb-a7d3334792a0",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343952115562578,
                        47.75661383264499,
                    ],
                    [
                        7.342968695799731,
                        47.75745384861439,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 195,
                    "end": 314,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.15,
                "gradeSurface": 0.33,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "e882d537-1f13-46bb-9cf4-4d63c3f83cb3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342968695799731,
                        47.75745384861439,
                    ],
                    [
                        7.342288147055242,
                        47.75802012616554,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 314,
                    "end": 395,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "ac308de3-c6d4-4b50-98be-fa8ae98ff2a7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34187303606848,
                        47.75836582390657,
                    ],
                    [
                        7.340770903108702,
                        47.7592930330377,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 395,
                    "end": 527,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 2.72,
                "gradeSurface": 1.33,
                "gradeStructure": 3.86,
            },
        },
        {
            "id": "100c63ef-0e1e-4748-96c0-167dd682d77e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.340770903108702,
                        47.7592930330377,
                    ],
                    [
                        7.339725616403063,
                        47.76016645798436,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 527,
                    "end": 652,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 1.35,
                "gradeSurface": 0.74,
                "gradeStructure": 1.85,
            },
        },
        {
            "id": "242958e9-d461-47b9-a647-6a53151b38d6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.339725616403063,
                        47.76016645798436,
                    ],
                    [
                        7.339492505724596,
                        47.76036608771376,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 652,
                    "end": 680,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 3.23,
                "gradeSurface": 1.85,
                "gradeStructure": 4.36,
            },
        },
        {
            "id": "33a12781-d8dc-4fbc-9156-669a1c9b19e6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344790901527912,
                        47.75519911419621,
                    ],
                    [
                        7.344368004977621,
                        47.75598709823544,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 680,
                    "end": 773,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 9.25,
                "gradeSurface": 9.1,
                "gradeStructure": 9.37,
            },
        },
        {
            "id": "02049341-eaff-4144-9247-528375d62fa2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325680226789657,
                        47.74249684148023,
                    ],
                    [
                        7.325808186683313,
                        47.74257521741361,
                    ],
                    [
                        7.325878145922379,
                        47.742664394115735,
                    ],
                    [
                        7.325946232489619,
                        47.743007105672085,
                    ],
                    [
                        7.326069505735718,
                        47.74361554137933,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 0,
                    "end": 131,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 4.89,
                "gradeSurface": 0.0,
                "gradeStructure": 8.9,
            },
        },
        {
            "id": "33711fef-1238-4fe9-8368-d6e480694a6b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325680226789657,
                        47.74249684148023,
                    ],
                    [
                        7.325727932583368,
                        47.74240330606903,
                    ],
                    [
                        7.325719995952639,
                        47.74227912250187,
                    ],
                    [
                        7.325581132189772,
                        47.741633656309915,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 131,
                    "end": 228,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 8.41,
                "gradeSurface": 7.28,
                "gradeStructure": 9.35,
            },
        },
        {
            "id": "a113a22e-079c-4030-a907-768830c6e445",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.322189333717003,
                        47.73514674026938,
                    ],
                    [
                        7.322047183273413,
                        47.73483809215277,
                    ],
                    [
                        7.321815467068918,
                        47.734516573674014,
                    ],
                    [
                        7.321725342383214,
                        47.73442267210218,
                    ],
                    [
                        7.321493462809912,
                        47.73398675620143,
                    ],
                    [
                        7.321258983424197,
                        47.73357476671172,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 228,
                    "end": 417,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "f4ea642e-8724-4bfe-b71f-77561fcee276",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325581132189772,
                        47.741633656309915,
                    ],
                    [
                        7.325470639633684,
                        47.741164157079105,
                    ],
                    [
                        7.325428668224927,
                        47.74105627161237,
                    ],
                    [
                        7.325349342210902,
                        47.740928828652066,
                    ],
                    [
                        7.325080372462999,
                        47.740661410177324,
                    ],
                    [
                        7.324487261047791,
                        47.740198984338996,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 417,
                    "end": 601,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 1.82,
                "gradeSurface": 0.0,
                "gradeStructure": 3.31,
            },
        },
        {
            "id": "e758e294-f0d8-4dbf-b2e1-c4c4887942fc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.324487261047791,
                        47.740198984338996,
                    ],
                    [
                        7.324379490183967,
                        47.740088852136736,
                    ],
                    [
                        7.323792148359107,
                        47.739482171341336,
                    ],
                    [
                        7.323314661097879,
                        47.738916030077824,
                    ],
                    [
                        7.322940302825788,
                        47.73831928921152,
                    ],
                    [
                        7.322751776818433,
                        47.73789338184997,
                    ],
                    [
                        7.32261537100977,
                        47.737458086671424,
                    ],
                    [
                        7.322511922854964,
                        47.73699754407101,
                    ],
                    [
                        7.322447223058764,
                        47.73593448321934,
                    ],
                    [
                        7.322343882400412,
                        47.73551209790854,
                    ],
                    [
                        7.322189333717003,
                        47.73514674026938,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 601,
                    "end": 1199,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "fb6b9ca1-ef01-4809-8660-5985a2032bd7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.326439423271951,
                        47.745177686946136,
                    ],
                    [
                        7.326331549283291,
                        47.74485014945077,
                    ],
                    [
                        7.326295585076954,
                        47.74450861728243,
                    ],
                    [
                        7.326250823138051,
                        47.74423352537577,
                    ],
                    [
                        7.326213036991712,
                        47.74407250046104,
                    ],
                    [
                        7.326049938091111,
                        47.74384568404792,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 1199,
                    "end": 1351,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "4c105992-6eec-4c3a-ae72-686527d992f9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.346037345455459,
                        47.75817922448468,
                    ],
                    [
                        7.345059265337103,
                        47.75888981274631,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 0,
                    "end": 108,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "c313e8e9-4bb2-4243-ac96-a1ab8faf4f03",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344072404198844,
                        47.75961153628929,
                    ],
                    [
                        7.343218199045569,
                        47.76024375611979,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 108,
                    "end": 203,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "5ec229dd-214e-497f-97ee-d19bc0994bc3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.33964265787727,
                        47.76183943164458,
                    ],
                    [
                        7.338895794907415,
                        47.762145495633405,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 203,
                    "end": 268,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "8a77f5b3-ee9f-4f73-8417-974c0881d359",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.348493093724352,
                        47.756647999452845,
                    ],
                    [
                        7.347622772086281,
                        47.75705667247242,
                    ],
                    [
                        7.347395678038718,
                        47.75719791106025,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 268,
                    "end": 371,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 7.9,
                "gradeSurface": 6.78,
                "gradeStructure": 8.81,
            },
        },
        {
            "id": "8e61c827-f9c9-416f-a270-aaddf8cfa40c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.347395678038718,
                        47.75719791106025,
                    ],
                    [
                        7.346037345455459,
                        47.75817922448468,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 371,
                    "end": 520,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 3.6,
                "gradeSurface": 2.98,
                "gradeStructure": 4.1,
            },
        },
        {
            "id": "9999e703-06cc-44b1-8c21-33005b333193",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343218199045569,
                        47.76024375611979,
                    ],
                    [
                        7.343104533120027,
                        47.760327879516836,
                    ],
                    [
                        7.342936460292256,
                        47.76048979168835,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 520,
                    "end": 555,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "2d8ab9d3-1a14-4384-aea6-9612c98c7593",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342936460292256,
                        47.76048979168835,
                    ],
                    [
                        7.342689479836517,
                        47.76058846997528,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 555,
                    "end": 577,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "df4daf1d-723e-4986-b719-df0a8e640ecc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34201514280675,
                        47.76087210471178,
                    ],
                    [
                        7.34039487131051,
                        47.76153433928608,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 577,
                    "end": 719,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "01896abc-f742-42a4-a2f8-f7e6516de2aa",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.33816549336465,
                        47.76243782644778,
                    ],
                    [
                        7.337516373269059,
                        47.76270798867759,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 719,
                    "end": 776,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "e8aa1f81-ad06-44a5-8be8-44fc4f23ed7c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.337516373269059,
                        47.76270798867759,
                    ],
                    [
                        7.337239635993132,
                        47.7628233697562,
                    ],
                    [
                        7.337146695122533,
                        47.76287457391896,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 776,
                    "end": 809,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 1.42,
                "gradeSurface": 0.0,
                "gradeStructure": 2.58,
            },
        },
        {
            "id": "22cbd5cf-22d4-4c19-8b96-99ae32e53a5b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.349585177747567,
                        47.75612705417141,
                    ],
                    [
                        7.348493093724352,
                        47.756647999452845,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 809,
                    "end": 909,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.73,
                "gradeSurface": 0.0,
                "gradeStructure": 1.34,
            },
        },
        {
            "id": "bc64e2ef-ee48-4b37-93c7-472b982a89ba",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.345059265337103,
                        47.75888981274631,
                    ],
                    [
                        7.344072404198844,
                        47.75961153628929,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 909,
                    "end": 1018,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "24ce8611-cd7c-4aa3-bcbc-0c87fc8360ce",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342689479836517,
                        47.76058846997528,
                    ],
                    [
                        7.34201514280675,
                        47.76087210471178,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1018,
                    "end": 1078,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "1708624c-cb35-454c-8896-c891522f07b2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34039487131051,
                        47.76153433928608,
                    ],
                    [
                        7.33964265787727,
                        47.76183943164458,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1078,
                    "end": 1144,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "501e854d-04c6-4227-ab91-80ae8c1ddb3e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.338895794907415,
                        47.762145495633405,
                    ],
                    [
                        7.33816549336465,
                        47.76243782644778,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1144,
                    "end": 1208,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "5118c08b-5165-446e-a54a-480805da976a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.337146695122533,
                        47.76287457391896,
                    ],
                    [
                        7.336851017719185,
                        47.763038366276376,
                    ],
                    [
                        7.335347119338507,
                        47.76371997024808,
                    ],
                    [
                        7.334951610572595,
                        47.7638946902736,
                    ],
                    [
                        7.334724593112584,
                        47.76399149344378,
                    ],
                    [
                        7.334588267515528,
                        47.76403123378715,
                    ],
                    [
                        7.334433625096522,
                        47.76406331777056,
                    ],
                    [
                        7.334259477037823,
                        47.76407350234034,
                    ],
                    [
                        7.334093921265368,
                        47.76405952312465,
                    ],
                    [
                        7.333926382316668,
                        47.76402178141674,
                    ],
                    [
                        7.332132757018695,
                        47.76334966903256,
                    ],
                    [
                        7.331924444837713,
                        47.76327895791486,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1208,
                    "end": 1661,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": 1.91,
                "gradeSurface": 0.0,
                "gradeStructure": 3.47,
            },
        },
        {
            "id": "3dd59d64-553c-405b-bc19-09b98c90dc62",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.311806809056158,
                        47.74149466393196,
                    ],
                    [
                        7.310957485995646,
                        47.740969074627564,
                    ],
                    [
                        7.310160772599191,
                        47.7406063717104,
                    ],
                    [
                        7.309324318264648,
                        47.74027964905124,
                    ],
                    [
                        7.308904642504877,
                        47.74011656545827,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE DU CLAIRON",
                    "start": 0,
                    "end": 267,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "fbc15083-acb6-4fcc-9587-d9d79fb516cf",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.308904642504877,
                        47.74011656545827,
                    ],
                    [
                        7.308719752684468,
                        47.74004942802557,
                    ],
                    [
                        7.307906814758471,
                        47.73965980108618,
                    ],
                    [
                        7.307663653444101,
                        47.73952880865411,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE DU CLAIRON",
                    "start": 267,
                    "end": 381,
                },
            ],
            "properties": {
                "layer": "02e1b240-d9f9-4557-b40e-1777437521f0",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
    ],
}

export const networkGradingMulhouse2026 = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "5a73fb3e-e682-49c4-b869-f745ebf4c903",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344920534197992,
                        47.754581347838474,
                    ],
                    [
                        7.34485641593329,
                        47.75482591204205,
                    ],
                    [
                        7.344790901527912,
                        47.75519911419621,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 0,
                    "end": 69,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 6.53,
                "gradeSurface": 5.82,
                "gradeStructure": 7.11,
            },
        },
        {
            "id": "4e02a04c-21be-4a69-9fd4-5fa74655b0a4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342288147055242,
                        47.75802012616554,
                    ],
                    [
                        7.34187303606848,
                        47.75836582390657,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 69,
                    "end": 118,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "1472871d-e0ed-4e01-ab14-0818080a8240",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344368004977621,
                        47.75598709823544,
                    ],
                    [
                        7.344098436997749,
                        47.75649680846129,
                    ],
                    [
                        7.343952115562578,
                        47.75661383264499,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 118,
                    "end": 195,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 10.0,
                "gradeSurface": 10.0,
                "gradeStructure": 10.0,
            },
        },
        {
            "id": "6d74a872-caff-423a-a795-c9ce6e2c4e7b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343952115562578,
                        47.75661383264499,
                    ],
                    [
                        7.342968695799731,
                        47.75745384861439,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 195,
                    "end": 314,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "c46cf603-9f4f-4790-9d40-10337dbc89c2",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342968695799731,
                        47.75745384861439,
                    ],
                    [
                        7.342288147055242,
                        47.75802012616554,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 314,
                    "end": 395,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "db0b0d9d-10d6-43d0-8664-a798b4d3c1e3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34187303606848,
                        47.75836582390657,
                    ],
                    [
                        7.340770903108702,
                        47.7592930330377,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 395,
                    "end": 527,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 1.87,
                "gradeSurface": 0.0,
                "gradeStructure": 3.4,
            },
        },
        {
            "id": "38085c96-7b34-4c64-b5ea-39599e278682",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.340770903108702,
                        47.7592930330377,
                    ],
                    [
                        7.339725616403063,
                        47.76016645798436,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 527,
                    "end": 652,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.7,
                "gradeSurface": 0.0,
                "gradeStructure": 1.27,
            },
        },
        {
            "id": "b95a7952-88aa-4ca7-a004-91cda8fc64eb",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.339725616403063,
                        47.76016645798436,
                    ],
                    [
                        7.339492505724596,
                        47.76036608771376,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 652,
                    "end": 680,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 2.88,
                "gradeSurface": 1.43,
                "gradeStructure": 4.06,
            },
        },
        {
            "id": "9e4e7576-0607-4b5c-ae88-f32db385d60c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344790901527912,
                        47.75519911419621,
                    ],
                    [
                        7.344368004977621,
                        47.75598709823544,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 680,
                    "end": 773,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 8.37,
                "gradeSurface": 8.03,
                "gradeStructure": 8.64,
            },
        },
        {
            "id": "77dc2cf8-4288-491e-ac4c-08c952fcc1ef",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.311806809056158,
                        47.74149466393196,
                    ],
                    [
                        7.310957485995646,
                        47.740969074627564,
                    ],
                    [
                        7.310160772599191,
                        47.7406063717104,
                    ],
                    [
                        7.309324318264648,
                        47.74027964905124,
                    ],
                    [
                        7.308904642504877,
                        47.74011656545827,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE DU CLAIRON",
                    "start": 0,
                    "end": 267,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "c552baf9-a28e-4970-b3f6-e50991a3af59",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.308904642504877,
                        47.74011656545827,
                    ],
                    [
                        7.308719752684468,
                        47.74004942802557,
                    ],
                    [
                        7.307906814758471,
                        47.73965980108618,
                    ],
                    [
                        7.307663653444101,
                        47.73952880865411,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE DU CLAIRON",
                    "start": 267,
                    "end": 381,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "85faca7c-54b7-41d8-b7dd-e40a809993a3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325680226789657,
                        47.74249684148023,
                    ],
                    [
                        7.325808186683313,
                        47.74257521741361,
                    ],
                    [
                        7.325878145922379,
                        47.742664394115735,
                    ],
                    [
                        7.325946232489619,
                        47.743007105672085,
                    ],
                    [
                        7.326069505735718,
                        47.74361554137933,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 0,
                    "end": 131,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 4.77,
                "gradeSurface": 0.0,
                "gradeStructure": 8.67,
            },
        },
        {
            "id": "43e02f41-edb7-47f4-af16-5b6d4ec49dab",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325680226789657,
                        47.74249684148023,
                    ],
                    [
                        7.325727932583368,
                        47.74240330606903,
                    ],
                    [
                        7.325719995952639,
                        47.74227912250187,
                    ],
                    [
                        7.325581132189772,
                        47.741633656309915,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 131,
                    "end": 228,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 7.05,
                "gradeSurface": 5.13,
                "gradeStructure": 8.61,
            },
        },
        {
            "id": "ae9335dc-ae6e-4ea1-b88e-59591d9663e7",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.322189333717003,
                        47.73514674026938,
                    ],
                    [
                        7.322047183273413,
                        47.73483809215277,
                    ],
                    [
                        7.321815467068918,
                        47.734516573674014,
                    ],
                    [
                        7.321725342383214,
                        47.73442267210218,
                    ],
                    [
                        7.321493462809912,
                        47.73398675620143,
                    ],
                    [
                        7.321258983424197,
                        47.73357476671172,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 228,
                    "end": 417,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "197cfdb0-44d9-41ed-90b9-e2151d93784e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.325581132189772,
                        47.741633656309915,
                    ],
                    [
                        7.325470639633684,
                        47.741164157079105,
                    ],
                    [
                        7.325428668224927,
                        47.74105627161237,
                    ],
                    [
                        7.325349342210902,
                        47.740928828652066,
                    ],
                    [
                        7.325080372462999,
                        47.740661410177324,
                    ],
                    [
                        7.324487261047791,
                        47.740198984338996,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 417,
                    "end": 601,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 1.71,
                "gradeSurface": 0.0,
                "gradeStructure": 3.11,
            },
        },
        {
            "id": "e8501b40-594f-4c1c-b8d3-b668ee78d113",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.324487261047791,
                        47.740198984338996,
                    ],
                    [
                        7.324379490183967,
                        47.740088852136736,
                    ],
                    [
                        7.323792148359107,
                        47.739482171341336,
                    ],
                    [
                        7.323314661097879,
                        47.738916030077824,
                    ],
                    [
                        7.322940302825788,
                        47.73831928921152,
                    ],
                    [
                        7.322751776818433,
                        47.73789338184997,
                    ],
                    [
                        7.32261537100977,
                        47.737458086671424,
                    ],
                    [
                        7.322511922854964,
                        47.73699754407101,
                    ],
                    [
                        7.322447223058764,
                        47.73593448321934,
                    ],
                    [
                        7.322343882400412,
                        47.73551209790854,
                    ],
                    [
                        7.322189333717003,
                        47.73514674026938,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 601,
                    "end": 1199,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "55ead055-4244-4cff-8a28-b149efeb2ccc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.326439423271951,
                        47.745177686946136,
                    ],
                    [
                        7.326331549283291,
                        47.74485014945077,
                    ],
                    [
                        7.326295585076954,
                        47.74450861728243,
                    ],
                    [
                        7.326250823138051,
                        47.74423352537577,
                    ],
                    [
                        7.326213036991712,
                        47.74407250046104,
                    ],
                    [
                        7.326049938091111,
                        47.74384568404792,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "QUAI DES CIGOGNES",
                    "start": 1199,
                    "end": 1351,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": null,
                "gradeSurface": null,
                "gradeStructure": null,
            },
        },
        {
            "id": "47485a5f-bd2a-4a95-baf3-64332d0204f4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.346037345455459,
                        47.75817922448468,
                    ],
                    [
                        7.345059265337103,
                        47.75888981274631,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 0,
                    "end": 108,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "3764a063-22cf-461c-9054-7b32d32c104d",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344072404198844,
                        47.75961153628929,
                    ],
                    [
                        7.343218199045569,
                        47.76024375611979,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 108,
                    "end": 203,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "8f053902-441f-4ecf-b26b-a0a905194f81",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.33964265787727,
                        47.76183943164458,
                    ],
                    [
                        7.338895794907415,
                        47.762145495633405,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 203,
                    "end": 268,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "f7f4a4e9-43e1-4353-b61b-fbeae2d26ca8",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.348493093724352,
                        47.756647999452845,
                    ],
                    [
                        7.347622772086281,
                        47.75705667247242,
                    ],
                    [
                        7.347395678038718,
                        47.75719791106025,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 268,
                    "end": 371,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 7.07,
                "gradeSurface": 5.79,
                "gradeStructure": 8.12,
            },
        },
        {
            "id": "b4a38dc3-e07d-40cf-811e-077b29f1d1dc",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.347395678038718,
                        47.75719791106025,
                    ],
                    [
                        7.346037345455459,
                        47.75817922448468,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 371,
                    "end": 520,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 1.34,
                "gradeSurface": 1.13,
                "gradeStructure": 1.51,
            },
        },
        {
            "id": "c0b37f23-9400-4b9c-acb4-f529c385faf4",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343218199045569,
                        47.76024375611979,
                    ],
                    [
                        7.343104533120027,
                        47.760327879516836,
                    ],
                    [
                        7.342936460292256,
                        47.76048979168835,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 520,
                    "end": 555,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "92ea1505-a6bb-447b-8879-7e836f619f2c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342936460292256,
                        47.76048979168835,
                    ],
                    [
                        7.342689479836517,
                        47.76058846997528,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 555,
                    "end": 577,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "24eed6b6-9020-459c-81a5-7856c46ba63c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34201514280675,
                        47.76087210471178,
                    ],
                    [
                        7.34039487131051,
                        47.76153433928608,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 577,
                    "end": 719,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "7be84717-14a9-4066-ab69-cdd3e248bd54",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.33816549336465,
                        47.76243782644778,
                    ],
                    [
                        7.337516373269059,
                        47.76270798867759,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 719,
                    "end": 776,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "9bbe7012-abcd-4d91-ab58-e123adcfeef9",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.337516373269059,
                        47.76270798867759,
                    ],
                    [
                        7.337239635993132,
                        47.7628233697562,
                    ],
                    [
                        7.337146695122533,
                        47.76287457391896,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 776,
                    "end": 809,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 1.05,
                "gradeSurface": 0.0,
                "gradeStructure": 1.92,
            },
        },
        {
            "id": "b5c951dc-0fab-491b-953b-ad69623cadf5",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.349585177747567,
                        47.75612705417141,
                    ],
                    [
                        7.348493093724352,
                        47.756647999452845,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 809,
                    "end": 909,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.72,
                "gradeSurface": 0.0,
                "gradeStructure": 1.31,
            },
        },
        {
            "id": "3c1ba7ba-b30a-4f01-9a9f-c3b98616bec1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.345059265337103,
                        47.75888981274631,
                    ],
                    [
                        7.344072404198844,
                        47.75961153628929,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 909,
                    "end": 1018,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "8a7db496-8e0b-48ad-a463-7dee0f506b46",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.342689479836517,
                        47.76058846997528,
                    ],
                    [
                        7.34201514280675,
                        47.76087210471178,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1018,
                    "end": 1078,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "6b38e197-7652-48c7-a8dd-9e5ebf1a427c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34039487131051,
                        47.76153433928608,
                    ],
                    [
                        7.33964265787727,
                        47.76183943164458,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1078,
                    "end": 1144,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "d491d8e8-d976-4c14-aa9e-3a7c92689f5c",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.338895794907415,
                        47.762145495633405,
                    ],
                    [
                        7.33816549336465,
                        47.76243782644778,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1144,
                    "end": 1208,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 0.0,
                "gradeSurface": 0.0,
                "gradeStructure": 0.0,
            },
        },
        {
            "id": "e9218efe-fafb-45be-8efb-aaefce92448a",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.337146695122533,
                        47.76287457391896,
                    ],
                    [
                        7.336851017719185,
                        47.763038366276376,
                    ],
                    [
                        7.335347119338507,
                        47.76371997024808,
                    ],
                    [
                        7.334951610572595,
                        47.7638946902736,
                    ],
                    [
                        7.334724593112584,
                        47.76399149344378,
                    ],
                    [
                        7.334588267515528,
                        47.76403123378715,
                    ],
                    [
                        7.334433625096522,
                        47.76406331777056,
                    ],
                    [
                        7.334259477037823,
                        47.76407350234034,
                    ],
                    [
                        7.334093921265368,
                        47.76405952312465,
                    ],
                    [
                        7.333926382316668,
                        47.76402178141674,
                    ],
                    [
                        7.332132757018695,
                        47.76334966903256,
                    ],
                    [
                        7.331924444837713,
                        47.76327895791486,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1208,
                    "end": 1661,
                },
            ],
            "properties": {
                "layer": "0253bcff-483f-4645-9e05-3d4a19107d40",
                "gradeGlobal": 1.2,
                "gradeSurface": 0.0,
                "gradeStructure": 2.19,
            },
        },
    ],
}



export const allNetworkGradings = {
    "c7a0a089-4fd4-4b03-951c-61e6cca636c0": networkGradingCD91Small2023,
    "b5e75c38-6e04-4150-a241-21bc0b27bb4b": networkGradingCD91Small2024,
    "7483628c-6464-4c85-9699-2d715c129727": networkGradingCD91Small2025,
    "e71b0dbe-6886-44d7-addf-e030f4ac1038": networkGradingMulhouse2024,
    "02e1b240-d9f9-4557-b40e-1777437521f0": networkGradingMulhouse2025,
    "0253bcff-483f-4645-9e05-3d4a19107d40": networkGradingMulhouse2026,
}