import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { formatNumber } from "../../utils/formatting"
import * as Styled from "./RoadworksList.styled"

export function RoadworksList(props) {
    const {
        roadworksItems,
        totalCost,
        currency,
        className,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const formattedTotalCost = formatNumber(totalCost)

    return (
        <>
            {totalCost && <Styled.TotalCostContainer>
                <Typography variant="H3">{t(I18N_NAMESPACE, "containers.roadworksList.totalCost")}</Typography>
                <Styled.TotalCost id={"roadworks-total-cost"}>{formattedTotalCost} {currency?.symbol}</Styled.TotalCost>
            </Styled.TotalCostContainer>}
            <Styled.Scrollable>
                <Styled.Grid className={className} container spacing={5} id="roadworks-list">
                    {roadworksItems.map((item, index) => {
                        return <Styled.GridItem
                            item={true}
                            key={`roadworks-card-${index}`}
                            xs={6}
                        >
                            {item}
                        </Styled.GridItem>
                    })}
                </Styled.Grid>
            </Styled.Scrollable>
        </>
    )
}

RoadworksList.propTypes = {
    roadworksItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalCost: PropTypes.number,
    currency: PropTypes.object,
    className: PropTypes.string,
}