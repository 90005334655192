import { sortTasks } from "./sortTasks"

export const constructTechniqueAcronym = (technique, taskTypes) => {
    if (technique && taskTypes) {
        const taskCodes = technique.code.split("_").map(taskCode => ({ taskType: taskCode }))
        const sortedTasksCodes = sortTasks(taskCodes, taskTypes).map(task => task.taskType)

        const taskAcronyms = sortedTasksCodes.map(code => taskTypes?.find(type => type.code === code).acronym)
        return taskAcronyms.join(" + ")
    }
    return null
}