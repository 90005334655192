import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import {
    l2rApiAxiosInstance as axiosInstance,
    onErrorFeedback,
    onSuccessFeedback,
    useAlertsDispatchContext,
} from "@l2r-front/l2r-query"

import { roadworksQueryKeys } from "../queries/queryKeys"


export function useTaskUpdate(config = {}) {
    return useTaskMutation(axiosInstance.patch, config)
}

export function useTaskCreate(config = {}) {
    return useTaskMutation(axiosInstance.post, config)
}

export function useTaskDelete(config = {}) {
    return useTaskMutation(axiosInstance.delete, config)
}

async function mutationFn(
    axiosFn,
    url,
    config,
    values,
) {
    const headers = config?.headers
    const response = await axiosFn(url, values, headers)
    return response.data
}

export function useTaskMutation(
    axiosFn,
    config = {},
) {

    const { setSnackbar } = useAlertsDispatchContext()
    const queryClient = useQueryClient()
    const { selectedNetwork } = useNetworksStateContext()

    return useMutation(
        async (value) => {
            const url = `roadwork/tasks/${value.uuid}/`
            return mutationFn(axiosFn, url, config, value)
        },
        {
            ...config,
            onSuccess: (data) => {
                const taskQueryKey = roadworksQueryKeys["task"](data?.uuid)
                taskQueryKey.push({ network: selectedNetwork.slug })
                queryClient.setQueryData(taskQueryKey, data)
                onSuccessFeedback(config, setSnackbar, data)
            },
            onError: () => onErrorFeedback(config, setSnackbar),
        })
}