import { useEffect } from "react"

import { layersZindexes } from "../constants/layers"
import { useMapStateContext } from "../contexts/MapContext"

function getLayersToDisplayAfter(layerId) {
    if (!Object.keys(layersZindexes).find(layerKey => layerKey === layerId)) {
        return []
    }

    const layerZindex = layersZindexes[layerId]
    const sortedLayersAfter = Object.entries(layersZindexes)
        .filter(([_, zIndex]) => zIndex < layerZindex)
        .sort(([_, zIndex1], [__, zIndex2]) => zIndex2 - zIndex1)
    return sortedLayersAfter.map(([key, _]) => key)
}

export function ordersLayers(layers, map) {
    layers.forEach(layerId => {
        const layer = map.getStyle().layers.find(layer => layer.id === layerId)
        if (layer) {
            const layersToDisplayAfter = getLayersToDisplayAfter(layerId)
            for (const otherLayerId of layersToDisplayAfter) {
                const layerToDisplayAfter = map.getStyle().layers.find(layer => layer.id === otherLayerId)
                if (layerToDisplayAfter) {
                    map.moveLayer(layerId, otherLayerId)
                }
            }
        }
    })
}

export function useOrderingLayers(layers) {
    const { mapRef } = useMapStateContext()

    useEffect(() => {
        if (mapRef) {
            const map = mapRef.getMap()
            const orderingLayerFunction = () => ordersLayers(layers, map)
            map.on("sourcedata", orderingLayerFunction)
            return () => map.off("sourcedata", orderingLayerFunction)
        }
    }, [mapRef, layers])
}