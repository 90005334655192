import { impactsOrder } from "../constants/impacts"

export function sortTasks(tasks, taskTypes) {
    return tasks?.sort((taskA, taskB) => {
        const taskTypeA = taskTypes?.find(taskType => taskType.code === taskA.taskType)
        const taskTypeB = taskTypes?.find(taskType => taskType.code === taskB.taskType)
        if (impactsOrder[taskTypeB.impact] !== impactsOrder[taskTypeA.impact]) {
            return impactsOrder[taskTypeB.impact] - impactsOrder[taskTypeA.impact]
        }
        return taskA.taskType.localeCompare(taskB.taskType)
    })
}