export const networkRoadworksMulhouse = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "60aa60d3-90bb-4c78-b061-795a4b331234",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343910796028951,
                        47.7566491276077,
                    ],
                    [
                        7.343001752430183,
                        47.75742561292399,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 200,
                    "end": 310,
                },
            ],
            "properties": {
                "layer": "59f3c3f9-add4-46c3-8890-d1c48473e0c1",
                "technique": {
                    "code": "PONTA",
                    "cost": 13024,
                    "impact": 10,
                },
                "status": 50,
                "year": 2023,
            },
        },
        {
            "id": "60aa60d3-90bb-4c78-b061-795a4b335364",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343910796028951,
                        47.7566491276077,
                    ],
                    [
                        7.343001752430183,
                        47.75742561292399,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 200,
                    "end": 310,
                },
            ],
            "properties": {
                "layer": "59f3c3f9-add4-46c3-8890-d1c48473e0c1",
                "technique": {
                    "code": "PONTA",
                    "cost": 1817.76,
                    "impact": 10,
                },
                "status": 100,
                "year": 2023,
            },
        },
        {
            "id": "4578e84f-c58c-4c7a-a375-a8dff3719d98",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343869476440546,
                        47.75668442255519,
                    ],
                    [
                        7.343043073168945,
                        47.757390318297254,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 205,
                    "end": 305,
                },
            ],
            "properties": {
                "layer": "59f3c3f9-add4-46c3-8890-d1c48473e0c1",
                "technique": {
                    "code": "PLR1",
                    "cost": 28399.14,
                    "impact": 30,
                },
                "status": 100,
                "year": 2023,
            },
        },
        {
            "id": "224ac500-d009-4f2e-b076-baed33af3244",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343828156797365,
                        47.756719717487364,
                    ],
                    [
                        7.343084393852928,
                        47.757355023655236,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 210,
                    "end": 300,
                },
            ],
            "properties": {
                "layer": "59f3c3f9-add4-46c3-8890-d1c48473e0c1",
                "technique": {
                    "code": "PLR2",
                    "cost": 2380.96,
                    "impact": 30,
                },
                "status": 50,
                "year": 2023,
            },
        },
        {
            "id": "52bcc7b8-6944-4b96-bb6f-3942ff3b08e1",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.34366287767685,
                        47.756860897063405,
                    ],
                    [
                        7.343249676041061,
                        47.75721384493445,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 230,
                    "end": 280,
                },
            ],
            "properties": {
                "layer": "59f3c3f9-add4-46c3-8890-d1c48473e0c1",
                "technique": {
                    "code": "PLS1",
                    "cost": 6261.42,
                    "impact": 40,
                },
                "status": 50,
                "year": 2023,
            },
        },
        {
            "id": "52a58cf0-b644-4363-ba8d-df1a74c68314",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343894268200163,
                        47.756663245588534,
                    ],
                    [
                        7.343018280732261,
                        47.75741149507512,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 202,
                    "end": 308,
                },
            ],
            "properties": {
                "layer": "59f3c3f9-add4-46c3-8890-d1c48473e0c1",
                "technique": {
                    "code": "PL1",
                    "cost": 6261.42,
                    "impact": 20,
                },
                "status": 50,
                "year": 2023,
            },
        },
        {
            "id": "a33c2cdb-48b7-4e43-ba7b-0957f2578123",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343745517346665,
                        47.756790307305934,
                    ],
                    [
                        7.343167035056553,
                        47.757284434325385,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 220,
                    "end": 290,
                },
            ],
            "properties": {
                "layer": "67eab29b-be48-4919-ab82-08c7e4fe88e2",
                "technique": {
                    "code": "PLR1",
                    "cost": 28518.0,
                    "impact": 30,
                },
                "status": 50,
                "year": 2024,
            },
        },
        {
            "id": "b0a73ec1-d286-4b0b-81a9-421327df336b",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344688063827798,
                        47.7591612877493,
                    ],
                    [
                        7.344235373937481,
                        47.7594923531782,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 950,
                    "end": 1000,
                },
            ],
            "properties": {
                "layer": "67eab29b-be48-4919-ab82-08c7e4fe88e2",
                "technique": {
                    "code": "PONTA",
                    "cost": 1817.76,
                    "impact": 10,
                },
                "status": 50,
                "year": 2024,
            },
        },
        {
            "id": "3a648b38-4be4-48af-952e-dcbee19f12d3",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.343580237787925,
                        47.75693148675978,
                    ],
                    [
                        7.343084393852928,
                        47.757355023655236,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 240,
                    "end": 300,
                },
            ],
            "properties": {
                "layer": "231f4e14-2bd2-453e-b2e2-648e8c4d46ca",
                "technique": {
                    "code": "PLR1",
                    "cost": 11956.0,
                    "impact": 30,
                },
                "status": 50,
                "year": 2025,
            },
        },
        {
            "id": "1fb8f896-c24c-4bf7-80d7-864bdb010268",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.348143939401197,
                        47.75681195182061,
                    ],
                    [
                        7.347816605270443,
                        47.756965656206305,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 300,
                    "end": 330,
                },
            ],
            "properties": {
                "layer": "231f4e14-2bd2-453e-b2e2-648e8c4d46ca",
                "technique": {
                    "code": "PL1",
                    "cost": 156.0,
                    "impact": 20,
                },
                "status": 50,
                "year": 2025,
            },
        },
        {
            "id": "63172b44-5ffb-4972-be33-5ff52f07e12e",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.344416450569155,
                        47.759359927223954,
                    ],
                    [
                        7.344235373937481,
                        47.7594923531782,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 980,
                    "end": 1000,
                },
            ],
            "properties": {
                "layer": "231f4e14-2bd2-453e-b2e2-648e8c4d46ca",
                "technique": {
                    "code": "PL1",
                    "cost": 1565.0,
                    "impact": 20,
                },
                "status": 50,
                "year": 2025,
            },
        },
        {
            "id": "79a002d8-57ef-4454-be48-8bb60a572050",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.341752707201354,
                        47.76097936959634,
                    ],
                    [
                        7.341182191367652,
                        47.76121255207691,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 600,
                    "end": 650,
                },
            ],
            "properties": {
                "layer": "831a65dd-8214-4e74-b4d4-29d069e512e8",
                "technique": {
                    "code": "PLR2_PLS1",
                    "cost": 458.0,
                    "impact": 30,
                },
                "status": 50,
                "year": 2026,
            },
        },
        {
            "id": "af83ebfc-0c51-4a51-9992-b8cea9612bb6",
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        7.340144134457306,
                        47.76163603728151,
                    ],
                    [
                        7.339916191032272,
                        47.76172848953378,
                    ],
                ],
            },
            "linearLocation": [
                {
                    "typeid": 1,
                    "road": "RUE LEFEBVRE",
                    "start": 1100,
                    "end": 1120,
                },
            ],
            "properties": {
                "layer": "831a65dd-8214-4e74-b4d4-29d069e512e8",
                "technique": {
                    "code": "PLS1",
                    "cost": 1458.0,
                    "impact": 40,
                },
                "status": 50,
                "year": 2026,
            },
        },
    ],
}

export const allNetworkRoadworks = {
    "095be615-a8ad-4c33-8e9c-c7612fbf6c9f": networkRoadworksMulhouse,
}
