import { useResolvedPath, useMatch } from "react-router-dom"

import { SidebarRouter } from "../../routers/SidebarRouter"
import { SvraiContentRouter } from "../../routers/SvraiContentRouter"
import * as Styled from "./SvraiDesktopLayout.styled"

export function SvraiDesktopLayout() {
    const detailsResolvedPath = useResolvedPath(":eventId/details")
    const matchDetails = useMatch(detailsResolvedPath.pathname)

    return (
        <Styled.Wrapper>
            <SidebarRouter />
            <SvraiContentRouter />
            <Styled.Map id="svraiMap" details={!!matchDetails} />
        </Styled.Wrapper>
    )
}