import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import * as Styled from "./RoadworkSectionSidebarContent.styled"

export function RoadworkSectionSidebarContent(props) {

    const { className } = props
    const isMobile = useIsMobileDevice()

    return <Styled.Container
        className={className}
        id="sidebar-technique-content-container"
        isMobile={isMobile}
    >
        <Styled.Header>
            <Styled.SegmentDetailInfo isMobile={isMobile} hideLength />
        </Styled.Header>
        <Styled.RoadworkDetailsForm />
    </Styled.Container>
}