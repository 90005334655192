export const imageCollectionMulhousePhoto = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "2cfdb022-6b32-4f21-979c-79cd827819ad",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340147916360933,
                    47.759813595480296,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 601,
                    "end": 602,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "193b6ef9-44c9-40cd-86ae-8220bf61f5a3",
            },
        },
        {
            "id": "a692d732-f1e5-45ba-ac18-493ad25e0ee8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340189727933408,
                    47.759778658512076,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 596,
                    "end": 597,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "60e26eed-292c-48d2-814b-c23b5b94f2ff",
            },
        },
        {
            "id": "61cf3954-5fe3-4118-a7e7-8dbc6ec2f4fa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340231539451016,
                    47.75974372152826,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 591,
                    "end": 592,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4379add9-9368-4407-bfe2-ef476bec02b5",
            },
        },
        {
            "id": "016407ba-acdd-4229-b493-a32b0094659b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340273350913753,
                    47.75970878452884,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 586,
                    "end": 587,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f66fc441-304b-436d-ae0c-702928caa4c3",
            },
        },
        {
            "id": "77b34a98-b06a-431a-84cd-a31bfc7af8d7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340315162321619,
                    47.759673847513795,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 581,
                    "end": 582,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8f3ca682-2be6-4e18-afe0-e0adda22026a",
            },
        },
        {
            "id": "f54d3935-c76c-4e2a-b0ec-70815bb55650",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340356973674614,
                    47.75963891048316,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 576,
                    "end": 577,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3a70f37c-8e07-4505-85a3-f61d90c9339c",
            },
        },
        {
            "id": "5ccd1b07-7b61-4bab-96a6-19ab9a1f29b9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340398784972734,
                    47.75960397343692,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 571,
                    "end": 572,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "09cba39c-cf8e-465b-9516-46d9b93540c4",
            },
        },
        {
            "id": "bc9010a1-931d-4ad9-8926-8adeb6177c7d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340440596215987,
                    47.75956903637505,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 566,
                    "end": 567,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ed50a12e-fa26-415b-acb5-9a6e919ef0d2",
            },
        },
        {
            "id": "e977a7ff-3fa6-4b71-95df-cc200aa2f7bd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34048240740437,
                    47.75953409929759,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 561,
                    "end": 562,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9ecd486d-2764-4ddc-90d6-ae95ca477b26",
            },
        },
        {
            "id": "624fe674-0129-47ed-8fd3-2b3c7b31b07d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340524218537877,
                    47.75949916220451,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 556,
                    "end": 557,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5f7e2801-b92a-4efa-8ea8-7c99c70eb9c7",
            },
        },
        {
            "id": "462704b7-3d32-4d90-b5cd-d33897bbb706",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340566029616518,
                    47.75946422509583,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 551,
                    "end": 552,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "db2edb5f-288f-4fc8-a98d-c5e843e7a8a1",
            },
        },
        {
            "id": "e642ced0-3b90-4890-b558-de277d3968a8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34060784064029,
                    47.75942928797154,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 546,
                    "end": 547,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e18a975d-b00c-4187-a6cf-23a2eb2f8586",
            },
        },
        {
            "id": "75061da5-66c9-4fc5-9b73-683f9319c1b2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340649651609187,
                    47.75939435083166,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 541,
                    "end": 542,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1f254898-1685-43c0-83b7-035cb58dad15",
            },
        },
        {
            "id": "20ac113a-2b38-42f2-941f-7fbe65245a47",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340691462523216,
                    47.75935941367616,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 536,
                    "end": 537,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e49374bc-e429-4fb4-80a6-338a8af36c23",
            },
        },
        {
            "id": "dc4e50aa-8bfb-4ab9-b9c3-ec8520be800e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340733273382376,
                    47.759324476505064,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 531,
                    "end": 532,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0f7dd41e-5f3b-4c3e-9b94-3dce5426e381",
            },
        },
        {
            "id": "e8b83830-8e47-4003-a90d-d3dc1cc97f53",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340775077927186,
                    47.75928952090239,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 526,
                    "end": 527,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8923f172-923a-45a9-b16b-ca5dad76695f",
            },
        },
        {
            "id": "b063179d-3a02-4a85-a636-e44d0acbd599",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340816826081743,
                    47.75925439954056,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 521,
                    "end": 522,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ba479e53-9dce-4fcc-8ab3-e31f24334aff",
            },
        },
        {
            "id": "4f238cf9-b90f-4a7c-b4fe-3f671c8b018b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340858574181221,
                    47.75921927816316,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 516,
                    "end": 517,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5728ef9e-34de-4669-a6c6-a6620bc3553e",
            },
        },
        {
            "id": "15707586-04e4-4eed-93a5-22c26c5415ab",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340900322225624,
                    47.7591841567702,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 511,
                    "end": 512,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9999a030-25e7-4987-9df0-ff4777cea3e7",
            },
        },
        {
            "id": "7e4024d7-c8cf-4cd5-b266-7f8a7d8302c1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340942070214951,
                    47.75914903536166,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 506,
                    "end": 507,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ebc8bc82-4ef7-4789-a454-ba87782acc10",
            },
        },
        {
            "id": "0afb763d-6bf5-4b9c-b3c5-d8c9911c16d2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340983818149196,
                    47.759113913937554,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 501,
                    "end": 502,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4e938c56-2e27-45d6-a919-492e0e6532ef",
            },
        },
        {
            "id": "007099cd-4f34-4723-896c-af4b7cdd2e52",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34102556602837,
                    47.759078792497874,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 496,
                    "end": 497,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "dbfa8576-c4df-4641-9b31-d042bee9c1e5",
            },
        },
        {
            "id": "2ca94935-5b18-423d-af47-b2b300f84eb7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341067313852466,
                    47.75904367104264,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 491,
                    "end": 492,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a81aaac0-a510-4d45-b636-24c8e1158a04",
            },
        },
        {
            "id": "fd8b6a3d-b18b-4d40-93c1-073fe7029ea2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341109061621489,
                    47.75900854957184,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 486,
                    "end": 487,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f1542d16-9c4c-4bf0-8d60-efcbcc67a379",
            },
        },
        {
            "id": "caffec93-f874-44df-8832-c982713416cd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341150809335433,
                    47.75897342808544,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 481,
                    "end": 482,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9a31cde3-e4cc-4592-8c1d-00c4b6275e3f",
            },
        },
        {
            "id": "2859dc04-dbf1-4676-a971-7f1b04665d48",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341192556994299,
                    47.758938306583495,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 476,
                    "end": 477,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ba22c7c6-36a4-45fc-85e7-bd668c715b47",
            },
        },
        {
            "id": "8236d2d0-13c3-40ff-adca-b4677c99c71b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341234304598092,
                    47.758903185065975,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 471,
                    "end": 472,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0c12ffae-25bc-4bb3-aa63-3f4ae48a5e6b",
            },
        },
        {
            "id": "c26ee365-cbf0-4879-956c-1bb06839aec8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341276052146809,
                    47.75886806353289,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 466,
                    "end": 467,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4c17c7d0-1504-4059-85fc-185cecf03a84",
            },
        },
        {
            "id": "39ce514e-c125-4eeb-9048-f4ef6cfd444d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341317799640449,
                    47.75883294198424,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 461,
                    "end": 462,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "13291e1a-01ca-41b0-89c2-33d490ee1372",
            },
        },
        {
            "id": "e437f47d-cb84-49bf-8542-385f749375d4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341359547079014,
                    47.75879782042,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 456,
                    "end": 457,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "37e75daa-b1e1-4e81-bb14-5091a98ef2b8",
            },
        },
        {
            "id": "100b1136-f232-48fd-aa5a-e65725188152",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341401294462504,
                    47.75876269884021,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 451,
                    "end": 452,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ee239d84-136b-4ddd-925a-3aa5c5e198a1",
            },
        },
        {
            "id": "8e3f839c-c247-4ac8-82d4-94e29e81a459",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341443041790918,
                    47.75872757724484,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 446,
                    "end": 447,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7019862a-428a-41e8-9444-a535a8720d35",
            },
        },
        {
            "id": "ce202cf9-815e-40b0-ac36-629bed7d6632",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341484789064254,
                    47.75869245563392,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 441,
                    "end": 442,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "391de0a9-dcb4-45a8-becd-bcbadc112223",
            },
        },
        {
            "id": "25152c56-5e99-41a9-adf6-69b5533c34e4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341526536282518,
                    47.75865733400741,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 436,
                    "end": 437,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5cf5d772-518a-405c-9289-3fe44e4e4bfb",
            },
        },
        {
            "id": "c5694ee2-774d-4ccc-ae87-6716f2b0dd06",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341568283445705,
                    47.758622212365324,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 431,
                    "end": 432,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c06ea911-6a49-44e9-94c9-13828535ca23",
            },
        },
        {
            "id": "b8cf2f20-c2c2-4cff-a0b0-1501c82aedfa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34161003055382,
                    47.7585870907077,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 426,
                    "end": 427,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "341550cf-417b-4d1e-be96-1adbf8fa5187",
            },
        },
        {
            "id": "201f4c07-917b-4079-95a6-495346a3d5d8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341651777606855,
                    47.758551969034485,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 421,
                    "end": 422,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "30a46d54-8d81-4d8e-9c39-dae55092db14",
            },
        },
        {
            "id": "18dd93cc-77f8-4fe9-a152-89944f9c2759",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341676825812243,
                    47.758530896023096,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 418,
                    "end": 419,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9c53363f-de60-407c-89fd-0cbb36cba591",
            },
        },
        {
            "id": "983205d3-92dc-40eb-a898-b4bbdd8f3337",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341701873997802,
                    47.758509823006094,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 415,
                    "end": 416,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1a9b1498-de35-4e12-b774-fa517e1c566c",
            },
        },
        {
            "id": "29a68b0b-bd7d-4ad2-9fba-f0a8fac85715",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341743620929676,
                    47.758474701298624,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 410,
                    "end": 411,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "22def666-0a43-44ff-b98b-bee6d298eb08",
            },
        },
        {
            "id": "cac69979-6cb3-4e24-9787-f4e565854d6f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341785367806474,
                    47.758439579575615,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 405,
                    "end": 406,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5785608c-5a79-4f08-b288-e58874c99820",
            },
        },
        {
            "id": "18a4d956-cfc1-47bd-a76b-751f426d0d94",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341827114628196,
                    47.75840445783701,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 400,
                    "end": 401,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "31d8a593-1a53-4fbf-99e9-a4a03b4a8b67",
            },
        },
        {
            "id": "8e0f8b1a-9f82-49f1-a0e8-3f0a87e9d868",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341877271922097,
                    47.75836229638637,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 117,
                    "end": 118,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fcf44daa-0747-4f00-a7c0-0db500c12757",
            },
        },
        {
            "id": "3c280e3a-9b9e-40eb-a175-2a8390b4b383",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341911158730822,
                    47.758334076218986,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 113,
                    "end": 114,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8c356dfe-aeef-4ea2-8500-1f68586083d8",
            },
        },
        {
            "id": "d3343b0b-44b9-4941-9553-54bc6f5e87f2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341945045503627,
                    47.758305856041346,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 109,
                    "end": 110,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d29bde2d-2b3a-4b68-a8da-656d2e704543",
            },
        },
        {
            "id": "b1c338a6-c352-4503-a1ca-6cafd1e60a82",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341987403919121,
                    47.758270580804876,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 104,
                    "end": 105,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4a9355fc-a17f-4178-bc6e-47df98fe1836",
            },
        },
        {
            "id": "b26c69da-a6b5-4185-b941-a15255075857",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342029762278489,
                    47.75823530555241,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 99,
                    "end": 100,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "110cd220-ebf8-4073-ae08-880170efe850",
            },
        },
        {
            "id": "656827f0-4b1b-437a-8cea-37be852a1baa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342072120581732,
                    47.75820003028392,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 94,
                    "end": 95,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "db928dcb-d5bf-414a-a751-7ca8d2d49622",
            },
        },
        {
            "id": "0052c55c-1c64-4b82-ad72-06c7f3e7563d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342122950471538,
                    47.7581576999406,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 88,
                    "end": 89,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "112a14cd-9dee-4848-bcee-fc05d538eeb8",
            },
        },
        {
            "id": "a85eb660-9b69-4485-862e-90dc84b1b60e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342165308651306,
                    47.75812242463687,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 83,
                    "end": 84,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8f205a5c-9c7e-42ed-a4ec-7cbc482be300",
            },
        },
        {
            "id": "218a6b68-259c-4fdb-9c28-6ea0c2e15c9c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342207666774947,
                    47.75808714931715,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 78,
                    "end": 79,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5d26b8d1-f300-4a17-8377-d992315d2678",
            },
        },
        {
            "id": "99ac17bd-34e0-418b-aeb9-f5d5c7af3cc9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34224155323345,
                    47.75805892904981,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 74,
                    "end": 75,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e2fa8b05-3519-4a6e-9079-a3d8ae912145",
            },
        },
        {
            "id": "a6cad06e-49eb-45ac-ba4b-ae9e97024ea6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342275439656033,
                    47.75803070877227,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 70,
                    "end": 71,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d625d0a2-a060-4346-9dc1-784943bba407",
            },
        },
        {
            "id": "9ce654f2-8013-4550-8a26-1a7a7cb8f408",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342325955698585,
                    47.75798866641002,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 390,
                    "end": 391,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "01212314-65a2-4614-917c-55e7436a2a97",
            },
        },
        {
            "id": "64e35c17-8ecb-434a-98c9-8d9b63ba25d1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342367965249902,
                    47.75795371111115,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 385,
                    "end": 386,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fc3b2d3f-e5a0-4581-b340-a417075a4110",
            },
        },
        {
            "id": "5b4372ca-ef56-4a72-9b97-61b7bedb2520",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342409974746059,
                    47.75791875579653,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 380,
                    "end": 381,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "41e9f3d7-13b4-466c-a9ad-d9d2d729982a",
            },
        },
        {
            "id": "756cccc4-c771-406f-ae19-8d65793886b9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342460386068637,
                    47.7578768093982,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 374,
                    "end": 375,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "74ad3069-f2e5-43bd-ba1a-c65292ae73d4",
            },
        },
        {
            "id": "8138b51d-2799-4cd7-99ee-b36e20e95f52",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342493993572901,
                    47.75784884512004,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 370,
                    "end": 371,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2bdaf687-e5d6-422e-91cb-391cc319a866",
            },
        },
        {
            "id": "c9cc9bfb-ff82-4566-8113-e08f8ffbcbbb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339496668430612,
                    47.76036252290144,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 679,
                    "end": 680,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "df2a9009-ca55-490c-9f2a-0b8bfef70832",
            },
        },
        {
            "id": "69945164-7aaf-42f4-8e11-628df04307cb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342536002903583,
                    47.757813889758175,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 365,
                    "end": 366,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1f6f8822-6690-4a8c-9606-a359eafa7f63",
            },
        },
        {
            "id": "c2964086-c426-430e-b4ac-cf6045d62494",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342578012179108,
                    47.75777893438055,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 360,
                    "end": 361,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a345ff52-386c-4013-a736-fc737d98cac9",
            },
        },
        {
            "id": "c7b920f2-dd54-4d4d-b1d4-3f7d494bc1fc",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342620021399477,
                    47.75774397898719,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 355,
                    "end": 356,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a10adba6-8333-4321-8e55-73e5efe47243",
            },
        },
        {
            "id": "d5aac50a-dada-4418-b355-78fa59672726",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342662030564687,
                    47.75770902357806,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 350,
                    "end": 351,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "998e7877-73d6-43e7-a3ec-ccf0e47d6096",
            },
        },
        {
            "id": "180fb8a7-8bb1-48c9-b518-3673e9b9f075",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34270403967474,
                    47.75767406815321,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 345,
                    "end": 346,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c25738d6-1db1-453f-8b71-3dbfc0003ab7",
            },
        },
        {
            "id": "f8b87819-2038-4b8f-bc24-ed64d5c670e2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342746048729635,
                    47.75763911271258,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 340,
                    "end": 341,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6dbaf4bb-1d23-4caf-8f3a-87763f49ff71",
            },
        },
        {
            "id": "b5985729-48ee-4ee0-b107-ae4dca8cdac7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342796459522702,
                    47.75759716616306,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 334,
                    "end": 335,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1303c62d-ddec-45c0-ab7b-4f7c3f2194a5",
            },
        },
        {
            "id": "7e2b2f41-495e-4225-98ab-fd3ccfeb0e62",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339521644655004,
                    47.76034113402418,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 676,
                    "end": 677,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ffc45b95-9125-4edc-b8b3-2f1738d793b6",
            },
        },
        {
            "id": "faf743c6-3195-442b-a73e-fd4472607f58",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342830066673954,
                    47.757569201784094,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 330,
                    "end": 331,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "cd97a75c-0208-436a-9abe-b74b7a2a1696",
            },
        },
        {
            "id": "d04f5fff-5421-4615-bbea-807046133749",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342872075563378,
                    47.757534246296245,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 325,
                    "end": 326,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e11b6c30-520d-4ae1-a9db-db97350ba5f9",
            },
        },
        {
            "id": "fcca61f5-8aa3-4a30-9188-d9521667758d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342914084397644,
                    47.75749929079263,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 320,
                    "end": 321,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ae5417ea-1603-4533-a26b-0484d503c4f0",
            },
        },
        {
            "id": "b9d3d06c-8332-4da5-9944-d4255d357b0b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342964494925956,
                    47.7574573441675,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 314,
                    "end": 315,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6d57dd73-e2da-4e15-b3d4-5d8fa9345a9b",
            },
        },
        {
            "id": "64121ea0-1ab6-4cf3-998d-b286af3b5cea",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342997620353294,
                    47.757429142385824,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 310,
                    "end": 311,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1e6109a2-8c88-42fe-a0e9-545fbb7099c2",
            },
        },
        {
            "id": "3e1ed6d6-8a8d-41d7-8083-f8eb94e984e2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343038941097535,
                    47.7573938477606,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 305,
                    "end": 306,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0db03ff9-ec2a-403e-977a-751ae950f9ae",
            },
        },
        {
            "id": "6a4d26b6-8c5a-4715-af1e-5ed995fbd146",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343080261786993,
                    47.75735855312012,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 300,
                    "end": 301,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c6585961-71ea-4bae-9885-f24291c95640",
            },
        },
        {
            "id": "0984faf0-853f-483f-b676-e150e232a7f1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339563271651056,
                    47.760305485883016,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 671,
                    "end": 672,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "882d8d7d-72ef-4d6a-ab94-85de2cfb3490",
            },
        },
        {
            "id": "12d55f15-d6b7-41a9-8531-fd5bdd9a8374",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343121582421675,
                    47.757323258464346,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 295,
                    "end": 296,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "594b2890-7fc3-468e-8734-6e10eb4ef630",
            },
        },
        {
            "id": "74c08e72-e388-4c97-9b44-0925b3164f04",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343162903001573,
                    47.757287963793324,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 290,
                    "end": 291,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "73868931-c499-4037-9194-a89d1af2167c",
            },
        },
        {
            "id": "ac921727-4ed1-4806-972b-8701ea60f418",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343204223526698,
                    47.75725266910702,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 285,
                    "end": 286,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "26aa6906-1cdc-4ef8-b3a3-52a867076dd7",
            },
        },
        {
            "id": "a4c0dca9-7bac-44be-b083-b15686a1b79c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34324554399704,
                    47.75721737440544,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 280,
                    "end": 281,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a79e0563-ac9b-4526-8d6d-0a12d704b29a",
            },
        },
        {
            "id": "86364d63-c425-4455-b836-2bcb1b1d8b50",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343286864412602,
                    47.75718207968858,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 275,
                    "end": 276,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4961f3c4-3e5d-4141-bd62-02f15692f2ab",
            },
        },
        {
            "id": "32a15857-ca86-49d6-9399-cf19b9e75783",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343328184773386,
                    47.757146784956454,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 270,
                    "end": 271,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7be4cda1-d224-4323-9468-edcb2fc0001a",
            },
        },
        {
            "id": "6b2e311a-7d6b-4651-af23-42d6fac87650",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339604898591371,
                    47.76026983772635,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 666,
                    "end": 667,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "444484eb-235f-4afa-be47-e2a3311140bc",
            },
        },
        {
            "id": "95e06412-f651-4077-b03f-159c06e8c495",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34336950507939,
                    47.75711149020907,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 265,
                    "end": 266,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7dd3711c-40e6-466c-b693-e59f0541ccb2",
            },
        },
        {
            "id": "36b91685-8aad-43a0-8f4b-b5ca4e31f4e6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343410825330612,
                    47.7570761954464,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 260,
                    "end": 261,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5b228a59-29b0-4d06-8af8-4d9b6a01c63d",
            },
        },
        {
            "id": "7f5f7d9b-66b6-4d86-a754-0c8ae833e010",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34345214552706,
                    47.75704090066847,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 255,
                    "end": 256,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0a67ba34-c90e-4ebc-9db0-4f1064cccd7f",
            },
        },
        {
            "id": "c8e4f546-9ceb-4acc-8951-f6cdc1c3de66",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343501729690487,
                    47.756998546914765,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 249,
                    "end": 250,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "36a30a64-51f9-4a33-84f8-13b5343b3f6d",
            },
        },
        {
            "id": "e69ed9be-fd03-4510-b7c5-f4003b3383b8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343534785755617,
                    47.756970311066766,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 245,
                    "end": 246,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "67bc8b31-a21b-49d4-adab-6c4901a8671f",
            },
        },
        {
            "id": "168ca7b3-00e6-4e94-b193-b2e9f16f23f9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343576105787725,
                    47.756935016243006,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 240,
                    "end": 241,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ca551679-5638-421d-86a1-cccec1766b75",
            },
        },
        {
            "id": "2bf69a18-425f-406c-9140-db95ed270427",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343617425765058,
                    47.75689972140399,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 235,
                    "end": 236,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3b4b68fb-d552-407f-a9cb-bfaf8482d5c6",
            },
        },
        {
            "id": "fc89c702-bb5c-45c5-ab0e-2f551e50aa80",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339646525475944,
                    47.760234189554176,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 661,
                    "end": 662,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7d03300b-3344-4da0-a2ff-91798bc00165",
            },
        },
        {
            "id": "ce825427-e149-45a1-aa3e-2b16b3dc697d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343658745687606,
                    47.756864426549676,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 230,
                    "end": 231,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5c3b7574-4fbd-41c7-b865-4bd77fb05b49",
            },
        },
        {
            "id": "794aedeb-a824-4f7c-8c9e-c80be45c8ecf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343700065555382,
                    47.75682913168011,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 225,
                    "end": 226,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "60b84746-75d9-4f82-80e1-b5ee6f63d43c",
            },
        },
        {
            "id": "165910bf-2cf6-4fb6-8171-0157d40e1c21",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343741385368377,
                    47.756793836795254,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 220,
                    "end": 221,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "243d4855-2fa5-4ef2-8791-c597219e35ad",
            },
        },
        {
            "id": "46017520-fed8-422b-9284-c47b0621ba75",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343782705126594,
                    47.75675854189513,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 215,
                    "end": 216,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e25cb369-46a9-4eef-a1af-bf80933e5710",
            },
        },
        {
            "id": "a01028db-8b58-4e40-9af4-b5da2f8fd5ac",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339688152304775,
                    47.76019854136651,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 656,
                    "end": 657,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3ff85b9c-3e6e-4840-af56-5bae4b6034a4",
            },
        },
        {
            "id": "8c6cf41f-e03d-4324-8be0-52170362b377",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343815760893728,
                    47.75673030596405,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 211,
                    "end": 212,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7ff55989-a0d0-41a6-8da8-c5d6e7de56a0",
            },
        },
        {
            "id": "fd0abf29-f3ab-49e4-8874-22d6092b976c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339729797618201,
                    47.76016296430401,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 651,
                    "end": 652,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "118ab202-c882-4caa-bca7-653fd5ab0cd9",
            },
        },
        {
            "id": "cde705d2-00c7-4782-8d01-00ec74d2a432",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339771609739398,
                    47.76012802749188,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 646,
                    "end": 647,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f2b8026a-6041-42d2-9081-29cb05cdb414",
            },
        },
        {
            "id": "07d6b8a0-22c9-4478-a260-e7744ae11ece",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339813421805721,
                    47.76009309066412,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 641,
                    "end": 642,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "690874e7-cead-4764-a84d-3ecd4d0906e4",
            },
        },
        {
            "id": "6df55576-6a82-4487-868e-d7f3d8a56118",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343848816625802,
                    47.75670207002319,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 207,
                    "end": 208,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f3bd95ee-a299-4d09-ae2d-1dcaa4fcbc16",
            },
        },
        {
            "id": "ec308314-a411-4d8a-9655-57b2dbb82f61",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343890136241596,
                    47.75666677508335,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 202,
                    "end": 203,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c756cf51-ae07-47cb-b14d-6c56e9bbfbc3",
            },
        },
        {
            "id": "6b756556-37a9-48f4-8612-e1f93b729701",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339855233817174,
                    47.76005815382077,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 636,
                    "end": 637,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "629d3267-f6c6-4c4b-bd62-3ee082fc832c",
            },
        },
        {
            "id": "c116f065-bf42-4c9b-a3db-05162e2dc2a8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339888683386829,
                    47.76003020433485,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 632,
                    "end": 633,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5a46aafd-12e9-4542-a94b-ea8973d14884",
            },
        },
        {
            "id": "2e85fd33-ffa5-4e5f-98d3-00ab6943b748",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339930495299512,
                    47.75999526746342,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 627,
                    "end": 628,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "91273fd9-ced3-4cf1-a69c-4e2651990ede",
            },
        },
        {
            "id": "3b9e563d-79fa-455c-9d45-b89c93569850",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339980669522301,
                    47.75995334319707,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 621,
                    "end": 622,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3df44388-aa63-4282-b4b5-6d960a195e48",
            },
        },
        {
            "id": "3b07ec77-e75f-466f-9a5e-7fd20d81e70c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340022481314268,
                    47.75991840629129,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 616,
                    "end": 617,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7f494fe7-fb60-4ad3-b5e6-8fd6c0b9fb10",
            },
        },
        {
            "id": "afce5ae4-5dd1-460a-bc99-f89ff195429d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340064293051359,
                    47.7598834693699,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 611,
                    "end": 612,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6a1bb337-52f4-4f80-9142-613d52dc5afb",
            },
        },
        {
            "id": "81ccbec8-a584-48d0-b5c5-4f276ebc3ca9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34010610473358,
                    47.7598485324329,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 606,
                    "end": 607,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8e270296-0b41-48dd-a318-e3bfa79e8619",
            },
        },
        {
            "id": "e3e6a5e6-0eae-458d-bbfe-72a51f80bf2b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344905354344984,
                    47.75463924806532,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 6,
                    "end": 7,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2f2841db-cac2-4e5b-b6c0-98a855836eed",
            },
        },
        {
            "id": "aa9ce0ed-ce29-42dd-8a14-ea3ca2f13f8a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344893677512514,
                    47.75468378669875,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 11,
                    "end": 12,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "579f27eb-75dc-420d-a254-eec77a9175a1",
            },
        },
        {
            "id": "593ded97-0139-4e84-b3a4-117e4cc27b40",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344882000660511,
                    47.754728325329914,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 16,
                    "end": 17,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1de8b946-cc9f-427e-9292-a518620478af",
            },
        },
        {
            "id": "42fc0e81-d401-4c2c-b86f-7e3509c7d824",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344870323788974,
                    47.754772863958806,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 21,
                    "end": 22,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d152447f-97b2-4a9f-aaaa-0f0cb74b30c4",
            },
        },
        {
            "id": "46e220ea-8d0b-44cd-a747-97db5e4e10cc",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344858646897903,
                    47.75481740258543,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 26,
                    "end": 27,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ac5c89c1-d7e4-4706-be77-5d58073a823f",
            },
        },
        {
            "id": "c6c63815-9715-4dbb-a729-714ebf9d99cf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344850037621318,
                    47.75486224626719,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 31,
                    "end": 32,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "cbbe56d2-65c5-4dea-9f4c-522b8777e3b1",
            },
        },
        {
            "id": "7fbfcac1-2dc0-4eb1-a4f9-7b4582b4728f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344842152852097,
                    47.75490716199641,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 36,
                    "end": 37,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3e5c6a13-da3a-4c21-862b-ced96fcab62f",
            },
        },
        {
            "id": "31ebc2b7-934b-4184-b090-53810f578fe3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34483584502714,
                    47.75494309457862,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 40,
                    "end": 41,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9699b89e-e549-4009-b1ea-3f3e8d482e56",
            },
        },
        {
            "id": "7af59692-f103-42e0-b518-879929ccab45",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344826383273744,
                    47.754996993449936,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 46,
                    "end": 47,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a16b4f71-a81d-48eb-8e9c-118e56aedaed",
            },
        },
        {
            "id": "84983ef6-4671-4d37-a01e-1945fd5e579d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344818498464615,
                    47.75504190917421,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 51,
                    "end": 52,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "33692089-ca5a-40cf-a6b8-1c96e6f35775",
            },
        },
        {
            "id": "4e77f7e9-cc12-4f55-be85-ff1f33bcc193",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344810613642185,
                    47.75508682489684,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 56,
                    "end": 57,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3299bc01-2d57-4592-a397-18bd028b1ee6",
            },
        },
        {
            "id": "98d90094-65f6-45f2-b44f-415e6c2afad0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344802728806454,
                    47.75513174061782,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 61,
                    "end": 62,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "55473b6b-1aa1-4e3a-b4bf-758d2f3c2122",
            },
        },
        {
            "id": "684b325a-6956-462b-aa0e-7541ee802ce4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344796420928288,
                    47.75516767319342,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 65,
                    "end": 66,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "59490dc9-96ba-438f-9ca5-066e2ddff195",
            },
        },
        {
            "id": "2bbd4fbd-7c1e-4131-a11a-2c19ebddd949",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344791690014079,
                    47.75519462262444,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 68,
                    "end": 69,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c348de5b-9560-4f11-b101-8f4d46b72089",
            },
        },
        {
            "id": "3b341d3e-ec7f-4ef2-b405-b4e2efd0118d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344756797431394,
                    47.75526266136553,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 687,
                    "end": 688,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "585d9f36-94d2-4c08-ae8a-4a4c0cd1d6bb",
            },
        },
        {
            "id": "e0e71eda-1a8f-4e88-8b56-fb54c41d6033",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344734061321827,
                    47.75530502613832,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 692,
                    "end": 693,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b3ef9cdb-5e4c-4d99-aa66-928dc513748b",
            },
        },
        {
            "id": "d3f173b3-3c90-4e2b-b801-e2aae6b59760",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344715872408125,
                    47.755338917952656,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 696,
                    "end": 697,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ab5e41e0-8fb7-4cbb-969a-0a75c90a16c5",
            },
        },
        {
            "id": "7d549604-0427-4cb5-b43c-3f751e14413c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344693136233439,
                    47.75538128271571,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 701,
                    "end": 702,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c241d5c2-beef-49d5-8a2a-c31ed1754ef5",
            },
        },
        {
            "id": "2fee171f-7c29-42f9-9ddb-2892decd43cb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34467040002257,
                    47.75542364747334,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 706,
                    "end": 707,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "33c6b46c-15bf-48c9-8e04-baa09e0d8d1c",
            },
        },
        {
            "id": "7d96493c-b504-4a85-88a3-778732438f34",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344647663775526,
                    47.75546601222558,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 711,
                    "end": 712,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "45b9db2c-80dc-43e9-abd3-44c9d54b95fe",
            },
        },
        {
            "id": "1e956a46-911e-4e9c-a5a3-215516c11706",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344624927492304,
                    47.755508376972394,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 716,
                    "end": 717,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5bcab448-a420-4688-a974-d5b42c55650d",
            },
        },
        {
            "id": "dad7c254-f9dd-45a4-8889-89cc6e26019a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344597643904677,
                    47.75555921466144,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 722,
                    "end": 723,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0fdf563f-6f10-49ec-b45b-90f125c4685f",
            },
        },
        {
            "id": "0257909b-7af7-46df-b4ef-b46946db524c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344574907541861,
                    47.755601579396355,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 727,
                    "end": 728,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b9c8e1b0-3cde-4447-8c4d-19173004cc4c",
            },
        },
        {
            "id": "1e0260db-b43a-47d2-b855-237c61831d39",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344556718425558,
                    47.75563547118039,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 731,
                    "end": 732,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "61501e11-2a45-4812-a147-bf9820396098",
            },
        },
        {
            "id": "43861b67-1588-472a-b2ed-193a6baf8dd0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34453398199762,
                    47.75567783590557,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 736,
                    "end": 737,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4c84bd8a-ca13-4db2-8a25-ca0358496a35",
            },
        },
        {
            "id": "9957291a-53ed-4faf-bbb8-844aefab79ce",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344511245533502,
                    47.755720200625326,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 741,
                    "end": 742,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a04c92fe-a257-458f-a314-66cb9c84b13f",
            },
        },
        {
            "id": "0620deff-29d8-4b0e-8d07-eb4a409a97d9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344488509033204,
                    47.75576256533969,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 746,
                    "end": 747,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "62a07f58-47b5-4125-b7cd-fa1747acefb2",
            },
        },
        {
            "id": "19c2139b-f855-4872-93cf-54f31fba4a15",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344461225185094,
                    47.755813402989766,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 752,
                    "end": 753,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5e1faf9b-a408-4658-8ca1-1381aa55c894",
            },
        },
        {
            "id": "d9d848ca-c21c-4283-a614-fb6ad5a44fb5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344438488605203,
                    47.75585576769222,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 757,
                    "end": 758,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "66db23e4-13ef-4303-952c-b943f821d05c",
            },
        },
        {
            "id": "b28ab767-b524-4bff-961e-56448a9af977",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344415751989132,
                    47.75589813238926,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 762,
                    "end": 763,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ed6785dc-d357-46b0-b950-95335db454d3",
            },
        },
        {
            "id": "09bd3ba5-7bf5-4711-9ce0-ca85a91f4904",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344393015336882,
                    47.75594049708089,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 767,
                    "end": 768,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2d44a98f-3a72-4118-b11b-e3e8947ca03d",
            },
        },
        {
            "id": "65e61e19-bf9c-4a39-94d9-e33de8c043c8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344374825989034,
                    47.7559743888303,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 771,
                    "end": 772,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4d040563-102a-416d-9d15-ce5c310da0e3",
            },
        },
        {
            "id": "02423a23-37f0-404d-907f-c63a2fb9d477",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344352286156239,
                    47.756016820318465,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 121,
                    "end": 122,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "20958def-20d4-4186-9dd0-83132784f1e3",
            },
        },
        {
            "id": "542170de-3bcf-42d1-9979-59724a597035",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344329830666685,
                    47.75605928043256,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 126,
                    "end": 127,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "59d3eedf-4b04-4320-ba6f-312fe8a9d931",
            },
        },
        {
            "id": "f6403b92-5477-4bdd-957c-c7d1e03b7b3e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344307375141319,
                    47.756101740541354,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 131,
                    "end": 132,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "316ae6ab-f71f-4f99-93d6-5a9a611231b4",
            },
        },
        {
            "id": "2b27b0d0-54fc-4996-8d7c-69662cf0e6dd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344284919580141,
                    47.75614420064485,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 136,
                    "end": 137,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "74aa0e5b-4c7d-407f-80cf-d1a284e11809",
            },
        },
        {
            "id": "4382205b-3938-490a-8e7c-79ca8b8fed2e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344257972859452,
                    47.75619515276202,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 142,
                    "end": 143,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e5d6840e-9897-4acd-97eb-06412011e81c",
            },
        },
        {
            "id": "ca220472-6598-433e-a8da-999deaefdffa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344235517219482,
                    47.75623761285384,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 147,
                    "end": 148,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "faec0b78-4e00-4b44-9378-be8bea036936",
            },
        },
        {
            "id": "b35c2a36-283c-4bf5-93a7-7a22a0edc0ea",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344217552681722,
                    47.75627158092347,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 151,
                    "end": 152,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "16c68c6f-1c6e-407f-93b4-db13d9069ff4",
            },
        },
        {
            "id": "666a4376-432f-417d-af02-edb2419ea279",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344195096977289,
                    47.75631404100573,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 156,
                    "end": 157,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f1a68a19-d862-4273-bc53-242bf5ac40c2",
            },
        },
        {
            "id": "b5e3b1ca-6034-4d97-adb6-a0a61da41c4b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344172641237046,
                    47.75635650108268,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 161,
                    "end": 162,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b13cfe90-3f52-4abd-bea5-86e771e466c7",
            },
        },
        {
            "id": "bdc78178-d9ee-407b-b91a-c15cd29d6043",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344150185460989,
                    47.756398961154325,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 166,
                    "end": 167,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "033866df-6257-46f8-a601-d838baa29fe7",
            },
        },
        {
            "id": "2bed54fb-c4a3-42d3-8b65-fcfea765e718",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344127729649117,
                    47.75644142122066,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 171,
                    "end": 172,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "bd4b3eb4-7f46-4f88-98d0-7cf0e85c1669",
            },
        },
        {
            "id": "aa896658-f483-4ad1-b3cc-ac3b76ef80a4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344109764973836,
                    47.756475389269916,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 175,
                    "end": 176,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c1cbb0f2-0d3f-40eb-9734-32fb91385936",
            },
        },
        {
            "id": "2ddc147b-8946-4ba3-81b7-9c824d9d763f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344085701347633,
                    47.7565069941402,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 179,
                    "end": 180,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "34806833-2645-4a4f-9b9c-8055816ee753",
            },
        },
        {
            "id": "e9699112-32db-470d-9d19-4b88e822909a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344100782627597,
                    47.75649237329326,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 177,
                    "end": 178,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c9f357ce-1e7a-4b33-bb87-ed6d17673821",
            },
        },
        {
            "id": "0bd9fd36-42f0-4832-aa40-2fd051075436",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344051227647971,
                    47.756534565382445,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 183,
                    "end": 184,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2b685b76-81d2-4012-b913-b9c8f95dc2e8",
            },
        },
        {
            "id": "f27a9b4a-0bbc-48c6-8b4f-13c83fe61008",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344008135473189,
                    47.75656902942036,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 188,
                    "end": 189,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "296b1523-a6d6-4a28-bd74-7c34f05020ae",
            },
        },
        {
            "id": "2fe2394a-b89b-40a2-bb75-6a65f8069df2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343982280141544,
                    47.75658970783518,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 191,
                    "end": 192,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "622f3845-e3d7-4cf7-adaf-7a8387ce8efd",
            },
        },
        {
            "id": "5e0e4e95-a364-42b2-bba9-95b540477b7f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325685158856587,
                    47.74249986239737,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 0,
                    "end": 1,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ddb4f921-26c4-4316-bb6e-1d0ba5ac9155",
            },
        },
        {
            "id": "5a2dfb01-cc12-4b62-b29e-3b6e77b2315e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325723587509801,
                    47.742335319265145,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 149,
                    "end": 150,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "bf5373a0-df5d-4f8b-ac97-8a96d24c5158",
            },
        },
        {
            "id": "48fd943c-5828-4843-a92f-012857410164",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325693541035307,
                    47.74215615605861,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 169,
                    "end": 170,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "50803142-c92a-405d-ade9-388133af197e",
            },
        },
        {
            "id": "ff54587e-9f7e-4c85-85ff-f16c1514c0aa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325657031937662,
                    47.74198645531386,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 188,
                    "end": 189,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4dcb3714-6cac-4156-a85e-1d976e0f2947",
            },
        },
        {
            "id": "7329c491-742f-4b38-b4b3-8fea577b5323",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.3256186015599,
                    47.74180782292153,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 208,
                    "end": 209,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ebe53bfa-576b-4bf7-be6e-2e9b3c645192",
            },
        },
        {
            "id": "8d7509b8-a75f-48d1-bd07-8f22afdd8b9a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325589778945744,
                    47.74167384860745,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 223,
                    "end": 224,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1b910f2f-f864-43e4-b052-b2d658846f36",
            },
        },
        {
            "id": "f1ad91da-b0df-468e-be79-dafafa8f4906",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.32553605432848,
                    47.74144211482971,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 438,
                    "end": 439,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "155826f8-f32a-41fd-bd4c-26682bdf0f91",
            },
        },
        {
            "id": "2bf42bc5-9920-4286-a4a3-4e34e39c7cf4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325494121725328,
                    47.74126393667487,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 458,
                    "end": 459,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "bcd4bd7b-b682-4781-8c60-efcaf3bb35db",
            },
        },
        {
            "id": "de1bb950-a9f7-444b-a326-b551711e0fce",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325444163508168,
                    47.74109610153838,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 477,
                    "end": 478,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d79d68fb-2098-4923-8281-bc22b5dda093",
            },
        },
        {
            "id": "821305f4-cfe1-45ce-b367-9deb4ba7b353",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325353939159235,
                    47.74093621399934,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 496,
                    "end": 497,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a64c8677-2003-458e-a591-49da017c12d0",
            },
        },
        {
            "id": "4f8326e1-5ed6-442b-8a4a-49439dc85736",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325205849825148,
                    47.74078616426936,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 516,
                    "end": 517,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5d728154-d6a5-4602-96c0-a74f0d48a705",
            },
        },
        {
            "id": "52d5f866-123d-4146-8aac-c28309519706",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.32505160934164,
                    47.74063898495069,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 536,
                    "end": 537,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9df54232-6c27-4941-95fc-26eb25633df8",
            },
        },
        {
            "id": "a7ee2c24-d3be-457b-88aa-7c3cac70217e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.324885366239804,
                    47.74050937266116,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 555,
                    "end": 556,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "83f9f981-3473-484d-86c2-054363bbc095",
            },
        },
        {
            "id": "efbc9f5c-35f2-4f0a-8e99-1f4f99f5abca",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.324710374375103,
                    47.74037293840712,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 575,
                    "end": 576,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e6b2055a-c892-481c-8fde-0ad462ffae5c",
            },
        },
        {
            "id": "510f9f10-79b5-4e14-9a39-10a17a5886b5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.324579131064897,
                    47.74027061253821,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 590,
                    "end": 591,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "dbba5362-02f9-47ba-879a-8260dac67c29",
            },
        },
        {
            "id": "772c1e5a-65ed-4f26-80cd-06c79a657cf3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.32572461541216,
                    47.742524029726816,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 4,
                    "end": 5,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b464d74e-3c07-4440-882b-09f574df1a9a",
            },
        },
        {
            "id": "2b61d135-ae69-46a3-9e61-6cbe7568750c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325830171405957,
                    47.7426032412699,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 16,
                    "end": 17,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1ecda3ce-a4c1-4084-a9fc-c1f9bed12b37",
            },
        },
        {
            "id": "dd1d7576-bd77-47d0-805b-afa52226588f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325899916303744,
                    47.742773975124344,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 36,
                    "end": 37,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a2401fbd-ed50-4016-b5f5-216a2f0adb77",
            },
        },
        {
            "id": "648919f6-e6ea-48af-88cb-9c9cdd98fc41",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325935310371439,
                    47.74295212984394,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 56,
                    "end": 57,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ad4f1fa6-1f98-4b8b-acf9-0a1d18dcad61",
            },
        },
        {
            "id": "a99740a7-7872-4b36-a311-1743e5ab98e5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.325971180341992,
                    47.74313024111563,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 76,
                    "end": 77,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c3b91167-8f17-4b47-bb9f-f1d919538545",
            },
        },
        {
            "id": "d5353ad1-a015-475b-90a8-bc2c9f598370",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.326009066978751,
                    47.74331723757266,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 97,
                    "end": 98,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3afae163-1195-4755-afd0-59794a53d3d0",
            },
        },
        {
            "id": "a5521d5c-f471-4e11-8a92-70a840b2ab36",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.326043345593632,
                    47.74348642481614,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "QUAI DES CIGOGNES",
                    "start": 116,
                    "end": 117,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "68a455b0-83dc-4111-b976-a93dbf7fc648",
            },
        },
        {
            "id": "15fe12aa-63fd-45ab-abff-263584e9efa7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34714498283961,
                    47.75737902717994,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 398,
                    "end": 399,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4f613680-6cb2-48e3-8af9-f6fadc5058f4",
            },
        },
        {
            "id": "e192abc3-fd12-4b7a-840e-09be66ffc24a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347108517941301,
                    47.757405371296564,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 402,
                    "end": 403,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c071897f-b33e-43f1-a3ae-56c36b7e4301",
            },
        },
        {
            "id": "d15921d4-4760-4328-a4cf-d0e632f6f1d0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347062936767671,
                    47.75743830142586,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 407,
                    "end": 408,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "92b82062-3b8e-44a4-ad33-c6f47871e16f",
            },
        },
        {
            "id": "9e9c89e3-87cd-4ccd-89dd-74887435b654",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347017355537661,
                    47.757471231536776,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 412,
                    "end": 413,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "990803b3-e6d4-45df-8313-89ff2e48b1b4",
            },
        },
        {
            "id": "9a5e8aa8-079c-4123-baab-c568dd3348d0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346971774251272,
                    47.75750416162938,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 417,
                    "end": 418,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "78de9e1b-4993-4dce-ac0e-7d372f9095b3",
            },
        },
        {
            "id": "7fb01710-d4be-4843-9ad5-8e7d3018f4be",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346926192908503,
                    47.757537091703625,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 422,
                    "end": 423,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7282b52e-2ee3-48fe-91b0-e82cf893bbac",
            },
        },
        {
            "id": "90221fd2-3aae-4401-bafc-ed599424fa81",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346880611509352,
                    47.757570021759534,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 427,
                    "end": 428,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0b2c999a-68b1-47e0-a401-1d344bfabdf5",
            },
        },
        {
            "id": "4de5b9f7-052f-4d82-b40e-a8a8ebe1137a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346835030053823,
                    47.757602951797075,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 432,
                    "end": 433,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5f1517f0-7823-4a77-a315-29bc4a8bfaf0",
            },
        },
        {
            "id": "eeb2bd6c-818f-4575-82fe-49d93749b252",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346789448541914,
                    47.757635881816284,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 437,
                    "end": 438,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "16511c4c-4aec-4618-b4c6-5fa594179ad1",
            },
        },
        {
            "id": "d60da336-eff9-476e-892b-93d77cca0f19",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346743866973624,
                    47.75766881181716,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 442,
                    "end": 443,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "efbe51e1-5db9-4804-92bb-32306d1bcbec",
            },
        },
        {
            "id": "62ccc7b7-5b8a-4e0b-abf5-49aed3f64f15",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346698285348954,
                    47.75770174179968,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 447,
                    "end": 448,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2cf2ee26-adee-465e-9b90-93d538e907d0",
            },
        },
        {
            "id": "3ed76c7e-e0d6-450a-bdc1-1d13064312d9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346652703667902,
                    47.75773467176384,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 452,
                    "end": 453,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d271ad27-5e7b-4892-aa6c-8d9da283d98f",
            },
        },
        {
            "id": "5501f2dc-4764-41e2-b8cd-d6e4001d7fab",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346607121930473,
                    47.757767601709695,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 457,
                    "end": 458,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3b474691-dfa5-42c3-8c15-04aefd97a38f",
            },
        },
        {
            "id": "0ec17a68-23b0-431b-b7bf-ccc8f6a033ab",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346561540136662,
                    47.75780053163717,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 462,
                    "end": 463,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "694d0aca-9c84-4d0d-8e9c-4fa02b825138",
            },
        },
        {
            "id": "6f1c51ac-53a2-40b9-8c82-8466147fbfdb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346515958286469,
                    47.75783346154631,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 467,
                    "end": 468,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "60cfd9a9-9f7b-40ea-8831-f9fac9c9577e",
            },
        },
        {
            "id": "7c825385-4693-443a-805b-9f27dcbe348f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346470376379897,
                    47.75786639143712,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 472,
                    "end": 473,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "bf07d5f9-ec23-4b82-b4ee-d738fbf36415",
            },
        },
        {
            "id": "2bac1e5e-4e13-498b-a8e1-465a179e2d5d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346433910814044,
                    47.75789273533654,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 476,
                    "end": 477,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "dfb6eef1-2c94-4097-ab19-0ccbaae63052",
            },
        },
        {
            "id": "c9711b69-1d27-48b7-b323-4c658b68d586",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346379212397611,
                    47.757932251163666,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 482,
                    "end": 483,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "29906825-adb3-4701-9f9e-0af6564e90db",
            },
        },
        {
            "id": "0a47f9a5-2c5d-461d-bc1b-0ad34695bab3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346333630321895,
                    47.75796518099941,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 487,
                    "end": 488,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ef70801d-6ec8-4f75-8897-2a42f0e31928",
            },
        },
        {
            "id": "e202b218-ae8d-4804-b4c6-2ab83541c08a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346288048189799,
                    47.757998110816835,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 492,
                    "end": 493,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c5380063-4ee0-496e-8841-999672480dd5",
            },
        },
        {
            "id": "9cd4906c-d1cf-498b-9f71-4c84015613c3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346242466001321,
                    47.7580310406159,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 497,
                    "end": 498,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5cd79ff1-4edc-4d56-bc60-f9b592ba3f81",
            },
        },
        {
            "id": "904a4977-2313-45bb-9d04-138bb383dcd7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346196883756464,
                    47.75806397039663,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 502,
                    "end": 503,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a4925328-2689-4b32-b183-9b6333db9a5c",
            },
        },
        {
            "id": "28dd76cb-4564-4405-b326-60bb0816f286",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346160417919982,
                    47.75809031420799,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 506,
                    "end": 507,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ae96e6ad-0814-4c0e-becc-7c39f51d8964",
            },
        },
        {
            "id": "78b3a340-c71a-418d-872f-70a031c7f030",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346114835573639,
                    47.75812324395568,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 511,
                    "end": 512,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2dcf21eb-e38a-44d2-8fb5-d7029e92e47d",
            },
        },
        {
            "id": "9477b015-8c4e-4a17-a191-e6141a287fb8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342919620744811,
                    47.76049651977008,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 556,
                    "end": 557,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a637d6ca-816c-4409-a415-a86be0230517",
            },
        },
        {
            "id": "3ac4e991-34e1-40c1-bf72-37cbe992037b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342874715264148,
                    47.76051446130941,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 560,
                    "end": 561,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6cd448cd-884f-4f2f-bbff-85ea20848bc9",
            },
        },
        {
            "id": "7fea18e3-62d9-4c29-bb71-93700cb39c28",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342818583370762,
                    47.760536888209124,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 565,
                    "end": 566,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e6afd31f-d13f-42ea-bed7-7743326ed4be",
            },
        },
        {
            "id": "ca77adf6-995a-4ff0-81bf-a34b1a8d5206",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342751225036277,
                    47.7605638004529,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 571,
                    "end": 572,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b8db6df8-47a6-486f-8bde-a10691e1a016",
            },
        },
        {
            "id": "5b111c09-6fc5-4c2d-b8ce-737bb463e595",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342672621498337,
                    47.76059556089156,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1019,
                    "end": 1020,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0cc7dbaa-63b7-4d4d-8c45-7554f41b0def",
            },
        },
        {
            "id": "3c7fdaa3-78e8-4057-9744-261f40c4f82d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342638904808502,
                    47.76060974271676,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1022,
                    "end": 1023,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ac977a32-86af-4500-8f89-31be651102b6",
            },
        },
        {
            "id": "0f846ed6-ab61-452f-881c-2ec2c41f3ecf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342582710285535,
                    47.760633379070285,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1027,
                    "end": 1028,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "26d91beb-a3d1-4bb4-abe7-1d71baa806d6",
            },
        },
        {
            "id": "42fc848d-1880-4bf6-9807-e61d359f48fa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342537754631235,
                    47.76065228813345,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1031,
                    "end": 1032,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6f5a5178-9acb-4c67-bd8c-e5ad5f8484fa",
            },
        },
        {
            "id": "ffd2ce48-b549-486a-98ec-2604e4207af6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342481560018458,
                    47.76067592443786,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1036,
                    "end": 1037,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "96b9bc6a-251a-427f-b047-9c8a7b318052",
            },
        },
        {
            "id": "705689ac-2075-4701-9fc0-cc309945bed1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342425365355786,
                    47.760699560715,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1041,
                    "end": 1042,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fdec8f87-abde-4500-ba7f-b268366cf800",
            },
        },
        {
            "id": "c6492bca-f1e2-4b0a-895c-5a9086f4acb3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34236917064322,
                    47.76072319696486,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1046,
                    "end": 1047,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e6c7637f-036b-4571-93e6-e60ecdfe6662",
            },
        },
        {
            "id": "b670af6c-a2d8-450d-ac02-87234add251d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34231297588076,
                    47.76074683318744,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1051,
                    "end": 1052,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3e6ec022-494e-43fd-9f16-2cb760749c36",
            },
        },
        {
            "id": "74db2102-c2cd-4d93-bd2b-265cd0ed38a5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34224554209995,
                    47.760775196618546,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1057,
                    "end": 1058,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "dd9de9e4-eec5-4d20-8303-90e9346661f6",
            },
        },
        {
            "id": "def2ccf3-ea7d-4d34-9fed-58f5355b90c6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342189347227725,
                    47.76079883278113,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1062,
                    "end": 1063,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0280f348-dcf0-488b-8667-f7dfd09b6c3f",
            },
        },
        {
            "id": "b70a34a5-2af6-4fe1-be69-86e32cea389c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342133152305605,
                    47.76082246891643,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1067,
                    "end": 1068,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1829f4aa-6bee-42ab-a576-0c2ae3e9e3fb",
            },
        },
        {
            "id": "b9faebfd-4d56-444e-ac19-1550ef619f0f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342076957333592,
                    47.760846105024456,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1072,
                    "end": 1073,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "532130b0-2b4d-4c99-bbe7-e9fb6abbda48",
            },
        },
        {
            "id": "23a45c44-36bd-4198-8a68-80c528e2f202",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341998027473413,
                    47.76087910026584,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 578,
                    "end": 579,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e0d88fa9-adf7-46fa-b164-4d60cf48a9c9",
            },
        },
        {
            "id": "8b6dec3b-8d63-4d56-94e9-5edf6d5eec14",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341963796793248,
                    47.76089309136641,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 581,
                    "end": 582,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c3e91e11-8958-4aef-b310-dab6e8434ceb",
            },
        },
        {
            "id": "49ec07d6-36ac-4e70-82f7-8b3b2cca832b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341906745619658,
                    47.760916409844846,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 586,
                    "end": 587,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "598f3a39-c436-48d1-b7a4-577f75f15e15",
            },
        },
        {
            "id": "37bb83a4-e19f-41e8-89ee-7fb5e68b0ca1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341849694396096,
                    47.7609397282952,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 591,
                    "end": 592,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d5b018a8-6a16-4da4-abd1-57e5c89250d4",
            },
        },
        {
            "id": "84f8786a-40ec-4d0d-8ae7-2b417355a8fb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341792643122562,
                    47.76096304671746,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 596,
                    "end": 597,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ce0b4a73-42c6-40ea-8d41-9e1ae61350c7",
            },
        },
        {
            "id": "7372860f-ae51-4e30-ac4b-98536be9b6a0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341735591799052,
                    47.76098636511161,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 601,
                    "end": 602,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e16b7897-38a1-4d4f-9a0c-82cb19091e23",
            },
        },
        {
            "id": "f38af60c-43a6-443d-8d10-71541b4d032c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341678540425571,
                    47.76100968347769,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 606,
                    "end": 607,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "bd981bba-981a-426d-bdfb-ffcd09bc20c7",
            },
        },
        {
            "id": "1f0d2824-c552-4089-a3ff-e55a1d883c80",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341621489002115,
                    47.76103300181566,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 611,
                    "end": 612,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "963fd7e6-da76-45ee-9e2e-975304601f90",
            },
        },
        {
            "id": "47b9a81a-9fef-4816-beb3-97d1f4111da3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341564437528688,
                    47.76105632012553,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 616,
                    "end": 617,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "92550961-afa9-450e-8c1f-13bd48e48179",
            },
        },
        {
            "id": "14d310b0-dc21-4d1f-9f0e-10022e816212",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341507386005285,
                    47.7610796384073,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 621,
                    "end": 622,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3f1df896-1a97-456b-a8cd-1688495d8e81",
            },
        },
        {
            "id": "74b3bf8b-da0f-4740-aabd-72f17d9c2ae4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341450334431909,
                    47.76110295666098,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 626,
                    "end": 627,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "af1f6875-be4c-429c-bbf6-57abe531e4e4",
            },
        },
        {
            "id": "bbfe7ea9-3a8f-4b85-a29b-8789fa01e018",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341393282808562,
                    47.761126274886564,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 631,
                    "end": 632,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "942f4ac9-2f41-44fd-827d-c0d530c5a28a",
            },
        },
        {
            "id": "b5141fcb-7b21-4110-a99d-fdb96172a48e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341336231135238,
                    47.761149593084035,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 636,
                    "end": 637,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6238377d-186a-49f8-875d-bba5b0dc1dad",
            },
        },
        {
            "id": "87c0a930-2e8a-408a-8130-07f6b14939c8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341279179411942,
                    47.761172911253446,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 641,
                    "end": 642,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "cdec51b1-1384-47d6-8bd3-59be42c49154",
            },
        },
        {
            "id": "c6f31399-92db-49b3-85df-b31a5f4819ac",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341222127638673,
                    47.761196229394734,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 646,
                    "end": 647,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f605fe24-4bda-49ea-aeac-a659480595b9",
            },
        },
        {
            "id": "1f2ea68d-0c94-4fd2-a1df-95fd9b7282d5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341165075815431,
                    47.761219547507935,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 651,
                    "end": 652,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "253f723a-c663-40e6-8665-f5c148f43ab9",
            },
        },
        {
            "id": "5011ab53-05ed-45b4-b0cc-0cb37eda50ce",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341108023942216,
                    47.761242865593026,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 656,
                    "end": 657,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "171f2fb6-f6f9-428f-8f8f-5b518a35322e",
            },
        },
        {
            "id": "1792c0b0-0bd4-4e53-82d5-76b13ca6ccd0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341050972019025,
                    47.76126618365004,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 661,
                    "end": 662,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fa0fe13d-9090-4cf5-9eb5-400c37e224b7",
            },
        },
        {
            "id": "229393c1-17ba-4b5b-9f9d-a1147ef2221d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340993920045865,
                    47.761289501678945,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 666,
                    "end": 667,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "91c6d978-c86e-402b-958a-a002aed8499d",
            },
        },
        {
            "id": "9f7561f4-afcc-42fc-bbd5-528a2d5d0e07",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340936868022728,
                    47.761312819679745,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 671,
                    "end": 672,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "77f9b825-46bd-470a-bc02-7c974a588b01",
            },
        },
        {
            "id": "a7f7370c-638c-4557-89db-dbe8db307969",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340879815949617,
                    47.76133613765246,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 676,
                    "end": 677,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fc916413-79bc-4c32-ad76-d77bd0a3c5f8",
            },
        },
        {
            "id": "b122f9fb-52b5-44bd-bf5d-5e09148b0007",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340822763826536,
                    47.76135945559707,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 681,
                    "end": 682,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3d26b3e0-ad7f-4958-9608-83bd44b69de9",
            },
        },
        {
            "id": "155a35be-0b55-4388-805d-5734a8bd2c94",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340765711653479,
                    47.76138277351359,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 686,
                    "end": 687,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "73ab65c4-5c74-4fc2-8300-571e588021f1",
            },
        },
        {
            "id": "5cefcc2c-d882-4f21-a066-43de19c6655c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340708659430449,
                    47.76140609140201,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 691,
                    "end": 692,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2c32d341-79b0-4006-bd14-c572c75d11ac",
            },
        },
        {
            "id": "a4ce6381-e382-4ce1-a35f-0e609ea3937b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340651607157446,
                    47.761429409262334,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 696,
                    "end": 697,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "08a77886-1d3d-4463-a77d-c568e4aa0428",
            },
        },
        {
            "id": "74fa4289-d511-4b29-823c-d48ecd33ce9d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340594554834469,
                    47.76145272709455,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 701,
                    "end": 702,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "60be0632-f915-4153-a43f-e54aed37e14e",
            },
        },
        {
            "id": "11a03d69-a46e-4165-ab40-c51680517739",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340537502461519,
                    47.76147604489869,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 706,
                    "end": 707,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0415d0aa-5848-485d-ab08-30f4522c9e9b",
            },
        },
        {
            "id": "8bac54d4-0f77-4681-9cc3-1c9a5718bf71",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340480450038596,
                    47.76149936267472,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 711,
                    "end": 712,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8109b82b-24d6-4273-a283-035a8c26d34c",
            },
        },
        {
            "id": "c9f39efa-b1ab-408e-8c35-75ad8de764c1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340423397565698,
                    47.76152268042265,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 716,
                    "end": 717,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "61ab1f10-0db8-4961-9abd-c87a7c78dcb6",
            },
        },
        {
            "id": "eb6f1f0d-f972-45b8-a498-51c535fd5a54",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340366378534521,
                    47.76154589590381,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1080,
                    "end": 1081,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c35a6197-84c6-42ed-a85e-9dc6e40b5dfc",
            },
        },
        {
            "id": "dfac7c68-acac-46e9-80bd-2ffff3475205",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34030939294544,
                    47.76156900911821,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1085,
                    "end": 1086,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "75799084-e053-4c7d-a769-0f4d1e8c5bce",
            },
        },
        {
            "id": "60fbef6a-529c-4d39-94d8-9698d65a1f55",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340252407306879,
                    47.7615921223046,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1090,
                    "end": 1091,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3468ec2f-dd13-4043-b138-37c5dda749cd",
            },
        },
        {
            "id": "d74cd4a3-d65b-4a16-b153-a5160a8f8df1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340195421618844,
                    47.76161523546296,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1095,
                    "end": 1096,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "bcbc5128-0583-4573-94dc-cf616252b942",
            },
        },
        {
            "id": "9bb5babb-4012-4e10-9e80-42376eaff061",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34013843588133,
                    47.76163834859329,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1100,
                    "end": 1101,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1f3c2091-0496-47e0-9aba-6ce00242f766",
            },
        },
        {
            "id": "1141bc7f-eac3-49eb-829c-15b8172d90b3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340081450094339,
                    47.76166146169561,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1105,
                    "end": 1106,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b179f4a4-ec7b-44e2-a1ec-e2af4537e84a",
            },
        },
        {
            "id": "30463add-80e1-4496-8e91-2a13732d1ddc",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340024464257871,
                    47.761684574769866,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1110,
                    "end": 1111,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f704d7ab-d521-4541-bfae-6896525d3f81",
            },
        },
        {
            "id": "994c9287-2888-4bf5-8514-1ed7d07d3220",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339967478371925,
                    47.761707687816134,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1115,
                    "end": 1116,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "65922732-6f06-43ca-bb7a-3a786a759709",
            },
        },
        {
            "id": "82d24b4c-7ec9-43e3-9d7c-d4818e8cd5fb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339910492436502,
                    47.76173080083435,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1120,
                    "end": 1121,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7fb14fd2-26d1-44bd-b100-1c61bec99b8c",
            },
        },
        {
            "id": "44c8f312-a574-4dde-a8ce-a3a43c61af92",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339853506451604,
                    47.76175391382455,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1125,
                    "end": 1126,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c8978eca-61e8-422a-aac9-78840d4fbbc6",
            },
        },
        {
            "id": "46f9cf28-1cfa-4b86-a345-9cfbd57e0c65",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339796520417226,
                    47.761777026786724,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1130,
                    "end": 1131,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "346cd8ee-4139-449d-ba13-f17ee3a6f9d6",
            },
        },
        {
            "id": "7be7e306-2bcb-41d4-907b-640af79b8422",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339739534333371,
                    47.76180013972086,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1135,
                    "end": 1136,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b43542e2-0717-44fe-a55e-28d4258215c5",
            },
        },
        {
            "id": "c81c7250-f0c2-4854-89b0-031b3be2db02",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339682548200041,
                    47.76182325262697,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1140,
                    "end": 1141,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ace3a698-fd78-4736-9983-a973aec21594",
            },
        },
        {
            "id": "236ffa7c-d627-4f0d-802a-1c3db426479e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339648356496292,
                    47.761837120357185,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1143,
                    "end": 1144,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8ba20bf7-4140-47c3-92d1-0de86b2d1939",
            },
        },
        {
            "id": "4796fd4e-ab2b-4978-bfaa-98b4e8c74831",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339567971966694,
                    47.76187003826015,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 209,
                    "end": 210,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8879b9ad-a5c9-458d-81f1-12de37b7b6ee",
            },
        },
        {
            "id": "faaf899c-6d76-43e6-8175-6caac2395f12",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339510521207819,
                    47.76189358177783,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 214,
                    "end": 215,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "de3314d3-f963-4c0d-8e6c-e62d416954dc",
            },
        },
        {
            "id": "5a04a9d0-71b4-4998-8037-078bccab0c82",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339453070398135,
                    47.76191712526699,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 219,
                    "end": 220,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "494ba129-4954-4a30-be69-216bb8b4744b",
            },
        },
        {
            "id": "7d27f4ee-5f2b-4a8c-9987-a6fe557a21cf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339395619537642,
                    47.76194066872768,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 224,
                    "end": 225,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9de8f31c-580f-4c9e-8827-1f8ad48a078c",
            },
        },
        {
            "id": "d958090d-598f-453f-9cc8-29162e18c11f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339338168626337,
                    47.76196421215988,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 229,
                    "end": 230,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "cc466f11-e7f8-4e99-8768-0c3b100bb2e7",
            },
        },
        {
            "id": "192fe1d6-954c-42e6-b3b1-2cfca371c067",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339280717664224,
                    47.76198775556358,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 234,
                    "end": 235,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "980f4499-7b66-4a4c-81ea-9ea387ead011",
            },
        },
        {
            "id": "ce79b6e8-bd5b-4815-8898-d7bfbede918b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339234756857947,
                    47.76200659026602,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 238,
                    "end": 239,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a5ac7a70-dd9e-481c-a36e-dbcb8f6788c5",
            },
        },
        {
            "id": "c128c726-46cd-4c93-a85d-7cc802769639",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339165815587564,
                    47.7620348422855,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 244,
                    "end": 245,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "87953899-fd61-4ef8-99fe-b37f87452c27",
            },
        },
        {
            "id": "27533ca4-a206-42a7-ad66-4eee97148fa1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339108364473019,
                    47.76205838560373,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 249,
                    "end": 250,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "03ef7694-2bfe-48fe-a725-5c19636c8f15",
            },
        },
        {
            "id": "7978b901-d136-4e01-82ee-16ad2acced42",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339050913307666,
                    47.762081928893465,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 254,
                    "end": 255,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "39d492dc-e26d-409e-a9ea-5a29c60dba8b",
            },
        },
        {
            "id": "3a5e8442-dd7d-4ae9-a08b-df09f9b988e1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338993462091502,
                    47.76210547215469,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 259,
                    "end": 260,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "93896e08-3245-4b28-ad18-54772ba2534c",
            },
        },
        {
            "id": "52b606f8-7d3a-4071-9a87-24915d39eb49",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338936010824527,
                    47.76212901538744,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 264,
                    "end": 265,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0209ff65-eac1-44af-a666-e11718f46adb",
            },
        },
        {
            "id": "62d9c2fe-138c-424a-a483-dc30f3284412",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338901540039955,
                    47.76214314131341,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 267,
                    "end": 268,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b2675c64-1566-47d1-982b-961935b76775",
            },
        },
        {
            "id": "bc688b01-982c-4919-a84a-74e520dfe3fb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338821624022868,
                    47.76217518569169,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1150,
                    "end": 1151,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e3a31bdd-6f82-45d0-ae16-da67ebdd68a0",
            },
        },
        {
            "id": "4fac503a-6c20-4496-96dc-923628e0a16a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338775980360492,
                    47.762193456473206,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1154,
                    "end": 1155,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b7f2f531-abfe-4b93-853e-e4efd45c8191",
            },
        },
        {
            "id": "24285ecc-072e-4d1f-bb57-dd9c9a5be87c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338718925738471,
                    47.7622162949248,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1159,
                    "end": 1160,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f4e6a4c0-5eec-44c4-ac08-4f5f7102234e",
            },
        },
        {
            "id": "e49d2e74-e14d-4457-a467-da255fbdf5fb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338661871067498,
                    47.76223913334833,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1164,
                    "end": 1165,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0fe187b0-090e-429c-9214-2e146d6fb30e",
            },
        },
        {
            "id": "0d9b76ae-738b-4983-a79f-7ea7fcc06848",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338593405397721,
                    47.76226653941946,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1170,
                    "end": 1171,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6a05c3b8-df4e-48b1-901a-1b0945d2d425",
            },
        },
        {
            "id": "546e876f-1e64-4cfc-b5f3-cbbafc63334d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.33853635061906,
                    47.762289377781194,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1175,
                    "end": 1176,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "09aad3f7-a1ca-49df-86c6-8c4e87039b3d",
            },
        },
        {
            "id": "382d768f-ca0e-4c3a-a756-b701eea07c95",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338479295791454,
                    47.76231221611483,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1180,
                    "end": 1181,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ebf8a04d-94b3-4739-9e37-f22ae48fe4cf",
            },
        },
        {
            "id": "772b7a8f-842b-47a6-8200-33421ce54492",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338422240914896,
                    47.76233505442038,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1185,
                    "end": 1186,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d8dfea6b-67df-4516-97e3-88ad60a8abc5",
            },
        },
        {
            "id": "adf4d666-9764-4e35-b3ce-f1ad346c5f01",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338365185989391,
                    47.76235789269786,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1190,
                    "end": 1191,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fa713c86-1673-4e98-b7b5-964a2b3eb2e9",
            },
        },
        {
            "id": "5c915888-3ff5-4443-865d-8d1279667cf8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338308131014936,
                    47.76238073094722,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1195,
                    "end": 1196,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "09d53750-6448-46f5-8102-ba109895a2c8",
            },
        },
        {
            "id": "6c5c75de-f831-4806-a7ed-9e5e0c6e15fa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338251075991534,
                    47.762403569168505,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1200,
                    "end": 1201,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1e27dabe-57a6-4a51-a563-3b9383f80923",
            },
        },
        {
            "id": "31ac1cb5-d723-4513-abd6-60622ba75869",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338194020919182,
                    47.76242640736171,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1205,
                    "end": 1206,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "696bddb4-cb72-43d5-83df-d16927026853",
            },
        },
        {
            "id": "3c6cae12-1018-4482-b906-c1818eb36be5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.33813702332315,
                    47.762449675744676,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 721,
                    "end": 722,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "da85cccf-d21f-46c1-aeae-d24df9f5f6ce",
            },
        },
        {
            "id": "707e67a3-6af5-4151-9e71-deddc74a6ad7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338080083202132,
                    47.76247337431748,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 726,
                    "end": 727,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "238f2e49-b72b-4914-8f84-0563d618c62d",
            },
        },
        {
            "id": "a93d7e6b-44df-4d20-b321-d2c5539b14ec",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.338023143030427,
                    47.76249707286228,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 731,
                    "end": 732,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "756e7a55-e695-4ad6-b4ae-be5e58eff38b",
            },
        },
        {
            "id": "9bfe1e43-dae2-4bcc-aa45-5147f4d59abe",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337966202808027,
                    47.7625207713791,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 736,
                    "end": 737,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "89095ff9-c9e3-4c03-9cea-2d9b70a68577",
            },
        },
        {
            "id": "5c734606-deb2-4dd1-a14c-8550e5da51ee",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.33790926253494,
                    47.76254446986789,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 741,
                    "end": 742,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "38e12e68-b7a8-4adc-9615-b239919e1d66",
            },
        },
        {
            "id": "2cca69ba-485f-4ca8-b67b-87135ac879b6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337852322211162,
                    47.762568168328706,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 746,
                    "end": 747,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "13ff02be-793d-4ef4-becd-9c8f171608e2",
            },
        },
        {
            "id": "c191c51e-e930-444e-8c6d-cd0341ba3f2b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337806769915642,
                    47.7625871270772,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 750,
                    "end": 751,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8fd787bf-060d-49b7-be52-e0632dac3329",
            },
        },
        {
            "id": "24ab9d74-d2ac-4572-bda6-77aa61b0dcb9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.33774982950062,
                    47.76261082548762,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 755,
                    "end": 756,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d64d3481-6c92-49f5-bb26-58c247a2808e",
            },
        },
        {
            "id": "f6931756-429a-4238-beaa-a8ccc06a4bf9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337692889034908,
                    47.762634523870034,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 760,
                    "end": 761,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e9af85ad-da02-424d-9f6d-72b416e2b563",
            },
        },
        {
            "id": "80df5563-93db-4a24-bb72-60f44327ac6b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337635948518503,
                    47.762658222224445,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 765,
                    "end": 766,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "37862e90-27f7-4546-a1f7-ed6557a94051",
            },
        },
        {
            "id": "8784c0c3-1aa2-45e9-93da-109aca13860a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337579007951411,
                    47.76268192055088,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 770,
                    "end": 771,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7a0a7cdd-4265-4770-be06-31b8d7ddb569",
            },
        },
        {
            "id": "a0f422eb-37cf-464b-8a72-f2bae01ea89b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337522067333627,
                    47.762705618849296,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 775,
                    "end": 776,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "80dab80e-f8a0-4aae-9283-a9fda975e015",
            },
        },
        {
            "id": "0cb11a5e-9c38-4582-91b4-d50bfdba5127",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337464716260077,
                    47.76272952631034,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 780,
                    "end": 781,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "395d9a29-be3f-4366-b1d6-e59b2b521ac0",
            },
        },
        {
            "id": "204888ea-843d-44a4-8470-ef79b39deab6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337407319534409,
                    47.76275345698633,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 785,
                    "end": 786,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "977d1cf2-c038-4150-89ca-67f128b7f6ef",
            },
        },
        {
            "id": "adffd922-54b0-40d4-8d90-bb9d9ae7f7df",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337349922757144,
                    47.7627773876339,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 790,
                    "end": 791,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a9c63abc-8c28-4df4-a2f2-d16e3b99c8a6",
            },
        },
        {
            "id": "a33e2f3a-d7a0-4d8e-8910-368ca46ee39e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337292525928283,
                    47.762801318253004,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 795,
                    "end": 796,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b1997eda-ae3b-4006-b79e-3babf7d90b46",
            },
        },
        {
            "id": "a8f793f7-f7f9-4354-842c-d4b5f514f64f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337235532621934,
                    47.762825630441135,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 800,
                    "end": 801,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "443b439a-32c0-4f9f-bcef-ea7b3d1560e6",
            },
        },
        {
            "id": "e5d55b5f-a3a5-4d1e-b417-12ddb200f0ff",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346051020194035,
                    47.75816934557164,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 518,
                    "end": 519,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "cfe4a58c-a7e4-43f2-837f-d8cd83ff3eb8",
            },
        },
        {
            "id": "2b7b67c4-231e-491e-aeb0-c6d02a7f7b9b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.346014705007138,
                    47.75819567338255,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 2,
                    "end": 3,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "59b3ff0c-6f5a-48d6-93f9-3569eff7bf91",
            },
        },
        {
            "id": "77290590-fc39-462b-8ca5-bfc54bf9e4a5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345978480260731,
                    47.75822199160976,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 6,
                    "end": 7,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "87199781-1667-4692-8d6e-12b5c79f0571",
            },
        },
        {
            "id": "3b2b3ab8-7afa-4ec2-8003-fc48c9cf6959",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345924143073975,
                    47.75826146892883,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 12,
                    "end": 13,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1e24e404-cdd7-4039-a899-b940b23a5575",
            },
        },
        {
            "id": "cb792cf0-9d35-497e-9817-8b4394aea95c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345878862023461,
                    47.758294366674804,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 17,
                    "end": 18,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f14d580e-33fb-460e-8ca5-9708b39ecbc7",
            },
        },
        {
            "id": "dac3f0dc-f839-4875-9b12-d5b4b31483a2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345833580916996,
                    47.758327264402666,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 22,
                    "end": 23,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a662160e-3259-4222-adcf-49593c36f7b9",
            },
        },
        {
            "id": "caf15026-0155-4ab1-a922-2b25bdd00a75",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345788299754572,
                    47.758360162112425,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 27,
                    "end": 28,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d73ea1bc-89f4-4634-88c5-9ae6ac031d3d",
            },
        },
        {
            "id": "b9d2d87b-eca2-4752-88ad-686c0a24e50c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345743018536196,
                    47.75839305980406,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 32,
                    "end": 33,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4e8c721e-9e99-4ce8-a56b-1af47a917c1d",
            },
        },
        {
            "id": "334b663b-f68f-466e-9178-0d65545989be",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345697737261865,
                    47.7584259574776,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 37,
                    "end": 38,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "577874b1-cc3e-4a6d-bb10-520da8de9bed",
            },
        },
        {
            "id": "5e230d5f-daef-49f2-b983-92910430aec5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345652455931578,
                    47.758458855133,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 42,
                    "end": 43,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "22bba8e5-06e0-4377-91bb-8a87f6ec7b49",
            },
        },
        {
            "id": "ee9d27e2-e635-4944-bed1-13ff4883477e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345607174545338,
                    47.75849175277032,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 47,
                    "end": 48,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e3c71255-fbc7-4653-a95c-b05f7c6b295a",
            },
        },
        {
            "id": "34bda29d-b309-49ae-8691-c4e01fd0d811",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345561893103141,
                    47.758524650389504,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 52,
                    "end": 53,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c35620b2-e705-4360-8611-1aaa59eff923",
            },
        },
        {
            "id": "f09f16d4-4ca0-403a-87e2-5d558226839f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345516611604991,
                    47.758557547990584,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 57,
                    "end": 58,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "87cfb185-71ca-4177-9535-e80387a2bde4",
            },
        },
        {
            "id": "d904e7f3-6cf4-4b42-8417-f23b8fce3f1b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345471330050885,
                    47.75859044557356,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 62,
                    "end": 63,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "64acc124-ff9a-422d-b62d-2f9bd679b9f3",
            },
        },
        {
            "id": "dafd659f-6260-4949-beb5-3211281f3c22",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345426048440825,
                    47.758623343138424,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 67,
                    "end": 68,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ddf347f3-5737-4978-9775-01b2569f9649",
            },
        },
        {
            "id": "01121cd5-08b8-4719-88f6-58b380ab56a1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34538076677481,
                    47.75865624068517,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 72,
                    "end": 73,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3ebe0201-5e77-457b-a311-126b28694830",
            },
        },
        {
            "id": "9a05c48f-8cca-45bf-a247-41e3d813afaa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345335485052838,
                    47.7586891382138,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 77,
                    "end": 78,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b9c37776-6ef9-498a-8430-9a05fbaf7792",
            },
        },
        {
            "id": "48fafe1c-d081-4fce-89d2-689ea9f644f9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345290203274912,
                    47.75872203572434,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 82,
                    "end": 83,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8bf5fcf8-4c72-43d6-9de1-85fb7d8388ef",
            },
        },
        {
            "id": "1a747b55-3b22-4234-b996-71df7be2c840",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345253977812286,
                    47.758748353719724,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 86,
                    "end": 87,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a8947897-b213-40d0-9ae6-47aba790614d",
            },
        },
        {
            "id": "df64e417-99db-47e5-9762-77a3e97aac7a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345199639551195,
                    47.75878783069106,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 92,
                    "end": 93,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3aa58fbc-3a29-46fc-8ddf-a7a2ff52d14b",
            },
        },
        {
            "id": "703c6506-9c7f-47e1-8fca-cacb4738a7e9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345154357605401,
                    47.75882072814724,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 97,
                    "end": 98,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ee39f27d-5c13-4d69-9d21-d46d622ca131",
            },
        },
        {
            "id": "9ddbbff2-1014-4412-bd74-a3360e27d598",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345109075603653,
                    47.758853625585346,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 102,
                    "end": 103,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "461837f9-4b03-4674-92df-814584c2e3db",
            },
        },
        {
            "id": "3bf30341-5047-45d4-9d87-976f66342c19",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345045684860787,
                    47.758899744780074,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 910,
                    "end": 911,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8fd12bdb-ca81-454c-93d4-023230c6a02a",
            },
        },
        {
            "id": "c41b613c-eeb5-4a74-99a2-24a4cfaaa03c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.345018523892961,
                    47.75891960884272,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 913,
                    "end": 914,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e83954d7-ea54-49e7-a06a-050713e64387",
            },
        },
        {
            "id": "95115da5-6b44-45a0-b1db-1ef7e4649cbc",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344973255568211,
                    47.75895271559928,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 918,
                    "end": 919,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "acc2277e-fdd0-4954-a37f-97447526e8c5",
            },
        },
        {
            "id": "ff885dac-5a27-4023-8a2b-e820ccf4f767",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34492798718717,
                    47.75898582233774,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 923,
                    "end": 924,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "759f8ea2-8ea5-4c4e-8bd5-995708429404",
            },
        },
        {
            "id": "c10aa36c-d91a-4ccb-9559-2d7290c24118",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34488271874983,
                    47.75901892905811,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 928,
                    "end": 929,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a660af13-53c0-471c-bd77-d2e3a29329e1",
            },
        },
        {
            "id": "20b8415b-a10d-4046-8fe0-3a3750274e0d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344837450256199,
                    47.759052035760355,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 933,
                    "end": 934,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9c3fb76e-c714-4892-b3ae-26472282f496",
            },
        },
        {
            "id": "7cb8932b-7360-44d0-acc0-40f7e78442ec",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344792181706271,
                    47.75908514244449,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 938,
                    "end": 939,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "765681e8-e53a-4cfc-8cdb-2dd5865a6f2b",
            },
        },
        {
            "id": "05ac181a-d894-49e8-9eec-e2fec95b3e0d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344755966825797,
                    47.75911162777876,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 942,
                    "end": 943,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a481bcdd-f689-4956-8c27-710560df3c21",
            },
        },
        {
            "id": "ed544201-2ba8-4752-9c92-0fdbe6dafe98",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344701644437531,
                    47.759151355758455,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 948,
                    "end": 949,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "50f7366e-80e3-4585-9c4c-0f2ff1042894",
            },
        },
        {
            "id": "4eee8933-185d-4c7c-b96a-943eb030a00e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344656375718717,
                    47.75918446238827,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 953,
                    "end": 954,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9588a454-9b33-4cb8-85cf-803e029e4709",
            },
        },
        {
            "id": "a5576d8f-3be1-49ff-a72b-e74f3a94471e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344611106943609,
                    47.75921756899997,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 958,
                    "end": 959,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "067a9259-a4f2-4697-962b-83bf0dd23218",
            },
        },
        {
            "id": "fd6b8219-7d62-404f-822f-e444b30c7bc2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344565838112206,
                    47.75925067559357,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 963,
                    "end": 964,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3d3302eb-18f8-4c05-b3fa-2e87edfaf7f6",
            },
        },
        {
            "id": "aa94f1d5-eaac-4307-ab81-a047257f894c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344529623006549,
                    47.75927716085542,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 967,
                    "end": 968,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d354eb4c-bf00-4062-85bb-18c2b1430ce5",
            },
        },
        {
            "id": "9e14972f-937d-4e5f-92eb-9f6734a561f7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344484354073815,
                    47.75931026741641,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 972,
                    "end": 973,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "23d30e14-2e77-4647-ada7-1b53ea65b3c2",
            },
        },
        {
            "id": "319c6183-a69c-49f2-aa9e-a9a619a26055",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344439085084783,
                    47.7593433739593,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 977,
                    "end": 978,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a4d3c98b-e478-4c25-b9c0-e2477b8607fc",
            },
        },
        {
            "id": "2cd4d9b7-0f51-4a90-ab01-79ef16f1e8c4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344393816039454,
                    47.75937648048408,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 982,
                    "end": 983,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "94ebaf66-aa1d-4e4d-a618-889b7c03cfd7",
            },
        },
        {
            "id": "d6042914-891f-4b64-bc97-046f4dbc0f98",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344339493110754,
                    47.759416208289906,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 988,
                    "end": 989,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0e643541-3a51-4dc7-8a63-3601ad1f3891",
            },
        },
        {
            "id": "2cc75394-5d19-4f96-b1e3-80ea1d6bf580",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344294223941575,
                    47.759449314774855,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 993,
                    "end": 994,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d22a3ba3-9291-4e27-a1f0-b8db1a3a03de",
            },
        },
        {
            "id": "9594bef6-cb28-4faf-8e4b-830541e30fe3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344248954716101,
                    47.75948242124169,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 998,
                    "end": 999,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d3fc40f8-9d6b-467d-9025-84ceafbdf1c7",
            },
        },
        {
            "id": "9a358d74-dd68-4fe6-adb5-ea30c5452ca9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344203685434331,
                    47.75951552769041,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1003,
                    "end": 1004,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "14ffcdd3-4e0c-4833-b9b2-ccc092867865",
            },
        },
        {
            "id": "f8ebcc37-78cc-4dab-8ed0-071fdde56f53",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344158416096265,
                    47.75954863412103,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1008,
                    "end": 1009,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e4ecbcc1-5fd0-406c-b14c-e43b5de87f2d",
            },
        },
        {
            "id": "395b0c34-60db-4585-928b-c04aa08651b0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344113146701903,
                    47.75958174053353,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1013,
                    "end": 1014,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5b09f49c-fb9d-4e6c-a665-b3a397228310",
            },
        },
        {
            "id": "e8374076-a59a-4a70-b30d-46f561ba1af3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344058916906678,
                    47.75962151875782,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 109,
                    "end": 110,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f2f66739-6c97-4c5d-9277-bafc84b7a21e",
            },
        },
        {
            "id": "2d184577-a0b0-462a-95ee-d33eb2d19c69",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344022950769512,
                    47.75964813866599,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 113,
                    "end": 114,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6577e1fd-976b-493e-b86a-8466831bf0bf",
            },
        },
        {
            "id": "c7260569-0445-4e8c-879e-6bdf8700c843",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343977993047479,
                    47.75968141353514,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 118,
                    "end": 119,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4abf625a-9e5b-4467-8468-cb0d1090c856",
            },
        },
        {
            "id": "5273df53-8aed-4e3c-b4fa-3c395b4014e2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343933035269255,
                    47.7597146883864,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 123,
                    "end": 124,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3f6d020e-5ed9-4ea0-9a27-aceeb442e574",
            },
        },
        {
            "id": "13963583-8c4d-457f-bd86-9632ee89c2d2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343888077434835,
                    47.75974796321979,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 128,
                    "end": 129,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e0b3a386-ff59-4367-8a9a-392044ff25e6",
            },
        },
        {
            "id": "ac255cc2-09b0-4c2d-bdeb-e2d8a9135609",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343852111126841,
                    47.75977458307363,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 132,
                    "end": 133,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b9ad8f89-dc2f-4d26-86c4-689c0b99121f",
            },
        },
        {
            "id": "6a888587-f7b2-44bf-bc4f-d728fb49e131",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343807153191274,
                    47.75980785787484,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 137,
                    "end": 138,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "bb1809c1-5858-4082-9c8d-009d82d1451f",
            },
        },
        {
            "id": "e5ef7815-4ccc-4120-9893-116da3cffa37",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343762195199515,
                    47.75984113265818,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 142,
                    "end": 143,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "382d8543-fe1c-4ad1-9e12-0bacf6782eac",
            },
        },
        {
            "id": "9d0a9320-b94f-4918-a0bd-ebc81bb230d0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34371723715156,
                    47.75987440742364,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 147,
                    "end": 148,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "30ea2388-7077-410e-b94b-4c6ec0652be0",
            },
        },
        {
            "id": "2d0b3ebe-aaf4-42a7-a868-cb7de20869d2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343663287419838,
                    47.75991433711859,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 153,
                    "end": 154,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e6712cde-be7a-4a21-ab80-e261bf8dca1c",
            },
        },
        {
            "id": "4c098096-cd9e-4962-88eb-a4f2877cdff9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343618329248261,
                    47.75994761184472,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 158,
                    "end": 159,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "71a5b6c2-9243-44e6-b960-a6230dc0f5b6",
            },
        },
        {
            "id": "212f8130-66c6-4c24-830b-689f7d698585",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343582362670535,
                    47.75997423161277,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 162,
                    "end": 163,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1e3df169-9a05-44bf-8f23-7c64e0472129",
            },
        },
        {
            "id": "7d7f53f7-2e93-48f4-9140-b732eeec10fe",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343537404397806,
                    47.760007506306735,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 167,
                    "end": 168,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "74553e49-b02b-4e2e-bfa7-a2d0904731e0",
            },
        },
        {
            "id": "e8c35f10-5544-4b5a-afbd-43532104c5f5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343492446068884,
                    47.760040780982806,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 172,
                    "end": 173,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b6dc71b4-f777-40ac-9c76-4d954cb0988b",
            },
        },
        {
            "id": "81874c09-3c8a-459e-8c12-e06413ab02cd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343447487683766,
                    47.76007405564101,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 177,
                    "end": 178,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "cc920b23-d79c-4a8a-8bd2-a71bbbc85e5a",
            },
        },
        {
            "id": "09bd590a-a131-49e0-88a5-38318bf1e350",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343393537547451,
                    47.760113985207255,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 183,
                    "end": 184,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "42de719c-314a-4ae1-9efe-ccf95b4b9aec",
            },
        },
        {
            "id": "0f7c11ce-4cac-4055-b404-56f7e1c0e639",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343357570744948,
                    47.76014060490379,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 187,
                    "end": 188,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "35392cb5-3135-4257-9516-672b8c50de37",
            },
        },
        {
            "id": "d4037524-4b7e-4759-906b-629b172c6abd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343321603906486,
                    47.7601672245889,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 191,
                    "end": 192,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8b806ddb-d340-4c89-863c-0aeab4f756c1",
            },
        },
        {
            "id": "6afc6229-958f-4991-8a89-a608f059201a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34327664530783,
                    47.76020049917917,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 196,
                    "end": 197,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5da0ee10-a461-47a4-b2db-517f56a591bf",
            },
        },
        {
            "id": "7eef600e-64ce-4ffe-bf42-183ccfd54dd6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343231686652982,
                    47.76023377375156,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 201,
                    "end": 202,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "279242f9-7414-4389-9712-5903640a7fa8",
            },
        },
        {
            "id": "34f389d8-f226-4e65-8a88-99c9d1a5f0be",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343195980297649,
                    47.760260200097534,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 522,
                    "end": 523,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "85406500-b600-4484-8373-24ff5d236be3",
            },
        },
        {
            "id": "e0ca84c8-b6f4-4c3b-9be1-fd8dc16c1fd7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343142655246647,
                    47.760299665626306,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 528,
                    "end": 529,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e5dd765c-584f-4019-9bd7-27cc79aea30e",
            },
        },
        {
            "id": "05a6d5ad-a806-4777-92c9-1c3cf5f76ab6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343099155858068,
                    47.76033305969616,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 533,
                    "end": 534,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "97c5c793-ede0-4629-974c-0cd3e1746a06",
            },
        },
        {
            "id": "601e3d50-2383-451b-b806-2fdf8b4b4ee8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343061319765451,
                    47.76036950901807,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 538,
                    "end": 539,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4567fc63-4e8f-4e9c-b878-3986dafef1cb",
            },
        },
        {
            "id": "f981861c-da60-44bb-8a57-cfcba03e80fe",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343023483621026,
                    47.760405958327,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 543,
                    "end": 544,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "121f1ea6-3b81-4d23-bb0a-c181698f0547",
            },
        },
        {
            "id": "41dad0b3-6bd0-46f5-8467-99d081459f83",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342985647424801,
                    47.760442407622975,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 548,
                    "end": 549,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c08eed6e-d296-4448-ac0a-78da226db40f",
            },
        },
        {
            "id": "26f4a3ef-1122-4fc8-ac06-34e910745508",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342955378430518,
                    47.76047156705041,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 552,
                    "end": 553,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "07bf27c9-521a-47d7-8e06-62583d9a683a",
            },
        },
        {
            "id": "b9c0bd9e-a8f3-4d5d-b54c-dbe981292933",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337079620208445,
                    47.76291173064467,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1214,
                    "end": 1215,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "346bdb04-9d2f-46b3-911b-10438045aa63",
            },
        },
        {
            "id": "f4d4aa5e-32c2-4614-8799-386859e534bf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337048662524214,
                    47.76292887988949,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1217,
                    "end": 1218,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "76cb012b-cfd5-4f46-bca0-9c7bbf1b7636",
            },
        },
        {
            "id": "65a3cd01-8968-40ed-90ed-d42a5b8644ae",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.337007385580879,
                    47.762951745536256,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1221,
                    "end": 1222,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "376c4431-cc09-4030-a4fc-f3e810f7a029",
            },
        },
        {
            "id": "7fd47106-ad42-4e4d-9f34-ecfcf59c7676",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336955789351855,
                    47.76298032757384,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1226,
                    "end": 1227,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7b335b66-6e5a-493a-8c71-e77947b2a502",
            },
        },
        {
            "id": "95c1cda1-0443-45dc-aed4-2c8767b95aaa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336904193067427,
                    47.76300890958823,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1231,
                    "end": 1232,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "33c0867a-2bff-4b56-8e68-319eb8bd290b",
            },
        },
        {
            "id": "adf9469e-8e1f-486a-80eb-ac29f25fb36d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336852596727603,
                    47.763037491579446,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1236,
                    "end": 1237,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "abc28cfa-d22a-4a0a-a6e0-026b4afcfa35",
            },
        },
        {
            "id": "9afe1c55-423a-4cfd-9c27-92171131d60d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336797294055171,
                    47.76306271580841,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1241,
                    "end": 1242,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3b32ad9f-dfb1-42d5-bc5c-2794e8a538d9",
            },
        },
        {
            "id": "cf75c248-127d-4dec-81a3-804617a32431",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336741874326162,
                    47.76308783400967,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1246,
                    "end": 1247,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "caa3bea2-9eef-4e51-a920-549ca4782b45",
            },
        },
        {
            "id": "6fb31b63-517f-4306-8fdb-370b29ad4b47",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336686454544862,
                    47.76311295218438,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1251,
                    "end": 1252,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "61b94709-ef6a-4f22-a8d7-f95399276ee7",
            },
        },
        {
            "id": "9602f01f-fd58-4c59-9845-50c983f7cb5f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336631034711267,
                    47.763138070332495,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1256,
                    "end": 1257,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8bf9eddb-179e-4870-b38a-84b403742f68",
            },
        },
        {
            "id": "dce5c8b3-e467-4874-aeb0-bfdf6c7219c0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336575614825378,
                    47.76316318845405,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1261,
                    "end": 1262,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9f726717-c74a-4fe9-8241-d7145e24029f",
            },
        },
        {
            "id": "fbf3ddc7-9b18-40da-9b42-d7205df8b717",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336531278879018,
                    47.76318328293214,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1265,
                    "end": 1266,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "96d21801-23a8-4367-8dc8-c244dd7cc6db",
            },
        },
        {
            "id": "661b6239-590a-417d-8c18-b15bf0cadc9d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336464774896724,
                    47.76321342461741,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1271,
                    "end": 1272,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6b0c2520-12f2-4136-a4a5-cfdd473a599b",
            },
        },
        {
            "id": "f8e2b6a5-4de3-4cca-8bbf-c8288fa871d9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336409354853957,
                    47.76323854265922,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1276,
                    "end": 1277,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fbb809ec-61d0-489f-af9c-075640aca2d3",
            },
        },
        {
            "id": "c291e230-89d0-4a74-bae8-ac354c17939e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336353934758897,
                    47.76326366067445,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1281,
                    "end": 1282,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ddb2f9e2-2f77-46b3-a6ef-a6cd402a1bd2",
            },
        },
        {
            "id": "f0823510-fbd1-44a3-9064-c6e58bb87f96",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336298514611542,
                    47.76328877866311,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1286,
                    "end": 1287,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8a5ebac2-253a-44de-b745-33a78b59a710",
            },
        },
        {
            "id": "943f72a4-2f22-4025-8710-111a7609bdeb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336243094411897,
                    47.76331389662518,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1291,
                    "end": 1292,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a971fea8-5fcd-44c5-9161-25f1cc25d61a",
            },
        },
        {
            "id": "9184f6fd-3ff6-4bc7-8862-514cc7453bcf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336187674159954,
                    47.763339014560685,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1296,
                    "end": 1297,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5cc6684e-aaf0-4196-ad6f-7f6086e31e9d",
            },
        },
        {
            "id": "6b9a96ef-a449-4f83-9e54-1dd3c7f90708",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336143337920753,
                    47.763359108889944,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1300,
                    "end": 1301,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0103b2f5-4e15-4e97-a937-e774ee40d173",
            },
        },
        {
            "id": "952c57fd-3460-4c5e-9582-40d7b0ed9618",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336087917574686,
                    47.76338422677762,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1305,
                    "end": 1306,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "00a5a8e5-ae99-4773-9ce0-734695d5a9df",
            },
        },
        {
            "id": "323e51e8-87c1-4c66-b54a-42ecea48d45c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.336032497176323,
                    47.763409344638696,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1310,
                    "end": 1311,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "43417ad8-f8e5-4463-97e5-ea1986160585",
            },
        },
        {
            "id": "06dcff20-a3d3-46f2-bccd-21a836efe6da",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.33596599262926,
                    47.76343948603693,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1316,
                    "end": 1317,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "42ac71ba-0be9-4141-9184-a41276196b6f",
            },
        },
        {
            "id": "544fbf11-38db-4f0a-848c-6901d01454fa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335910572115854,
                    47.76346460383953,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1321,
                    "end": 1322,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2ede208b-bafa-4730-8035-cc326823c2f3",
            },
        },
        {
            "id": "0c4f6f7c-1ed9-483a-af66-26f2e0fc5697",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335855151550153,
                    47.76348972161558,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1326,
                    "end": 1327,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "680badd2-776f-4a96-8a1e-434fb8191217",
            },
        },
        {
            "id": "e7f9b94c-2b90-4591-9023-02c33bd8c894",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335799730932159,
                    47.76351483936502,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1331,
                    "end": 1332,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "33acfa59-f7df-4354-a3b5-ec6e545bfd2c",
            },
        },
        {
            "id": "4ebef2f0-7e12-41f8-b433-a88999d77298",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335744310261872,
                    47.76353995708791,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1336,
                    "end": 1337,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "80ebf2a4-8899-4b5f-8c82-71b0bd03b033",
            },
        },
        {
            "id": "20af3d34-bd87-47da-9d5a-477f1c136bed",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335688889539291,
                    47.7635650747842,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1341,
                    "end": 1342,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4007a567-34b6-4afc-ab1a-02fbb5e05097",
            },
        },
        {
            "id": "97bf884d-08f9-4aec-abf0-ea3113690835",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335633468764415,
                    47.763590192453925,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1346,
                    "end": 1347,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "bc964328-f07c-49d9-9a71-e8abcecc29ca",
            },
        },
        {
            "id": "26c0bf9e-e138-4559-a82e-4aa5df655de4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335578047937249,
                    47.76361531009709,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1351,
                    "end": 1352,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "74ea81c9-0c40-421d-bc3c-b1cb4d179bac",
            },
        },
        {
            "id": "2ecae8e3-28b4-4cd0-aa78-68df7a8f1cc1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335533711237861,
                    47.763635404192456,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1355,
                    "end": 1356,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "300ab4f5-4e8e-4480-8a66-3606bcc229e0",
            },
        },
        {
            "id": "2cc3d095-bce9-4750-902d-b37d0038a76a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335467206126032,
                    47.76366554530364,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1361,
                    "end": 1362,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fb44d82f-34fb-43ba-a3b9-96b6fbec8bb2",
            },
        },
        {
            "id": "16bd95aa-98bf-49a1-8dc8-4a47304238db",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335411785141983,
                    47.76369066286705,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1366,
                    "end": 1367,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "cd8f7046-c64e-4e97-9cbf-b4714bd8ed32",
            },
        },
        {
            "id": "eacb68ef-7e76-472e-83c9-4252135342a4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335356364105642,
                    47.763715780403885,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1371,
                    "end": 1372,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6a98641f-eb83-4f33-9e10-755daf6c5c6c",
            },
        },
        {
            "id": "f8d5f1e4-916b-40a0-9f6b-2503b4850550",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335300580337266,
                    47.763740529455625,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1376,
                    "end": 1377,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9dc28569-0e90-452d-83f1-62ab9e9d984b",
            },
        },
        {
            "id": "a8f1da04-15d4-4ed5-8a87-08a70ed74cab",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335244723906449,
                    47.763765204712854,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1381,
                    "end": 1382,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ff50cffe-bca9-4789-ad4f-eea6efafee7d",
            },
        },
        {
            "id": "4c946761-07b5-4540-9b9b-11f6593ecbda",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335188867423856,
                    47.76378987994312,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1386,
                    "end": 1387,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d20ba3f8-2f85-4402-a5b3-b90aec687404",
            },
        },
        {
            "id": "0090b41c-513e-45e2-ba43-fc54333ff4eb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.33513301088949,
                    47.76381455514638,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1391,
                    "end": 1392,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "24085891-f1f0-40e6-9275-ab16b3b648e7",
            },
        },
        {
            "id": "86f091ff-41ea-41c5-9bc3-3791fb48ba9e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335077154303345,
                    47.76383923032269,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1396,
                    "end": 1397,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "12b84ce1-0028-42e5-a25d-b95f1e6241c6",
            },
        },
        {
            "id": "a9b06214-8058-45c9-be1b-fcac31ab0749",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.335021297665422,
                    47.763863905472014,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1401,
                    "end": 1402,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8abac0a6-77ff-4632-9be1-35f28d525ae9",
            },
        },
        {
            "id": "95096ad5-70c9-4e8e-88c7-826a658ff939",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334965440975725,
                    47.76388858059434,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1406,
                    "end": 1407,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ca9c0f97-f40d-450c-903b-f2f0c94be9ee",
            },
        },
        {
            "id": "b9f23961-279d-46e4-943f-0e58da37e14a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334909146721944,
                    47.76391279746731,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1411,
                    "end": 1412,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e6efe058-d9f4-48c9-bb56-a4cdf8a08c75",
            },
        },
        {
            "id": "731d09b6-7389-4d30-8485-f6e1091fe42b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334852708436519,
                    47.763936863516854,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1416,
                    "end": 1417,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e219a4c6-e408-46a7-8eed-88083f733408",
            },
        },
        {
            "id": "97684a0e-4fbf-4fec-81db-4f12c415a077",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.33480755777144,
                    47.76395611633666,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1420,
                    "end": 1421,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b8189f3d-09bd-41a9-977f-65bcb211ea15",
            },
        },
        {
            "id": "6b6ec12f-b881-4e6c-867a-031f7d880a2b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334751119394171,
                    47.763980182336645,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1425,
                    "end": 1426,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "48148cf4-f83d-48bf-8bfb-9f47ed41c102",
            },
        },
        {
            "id": "f7ae08d0-837a-4be0-8bd8-dfb0e31a9348",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334679848294999,
                    47.764004537052195,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1431,
                    "end": 1432,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "85ede546-f035-47e1-9b3b-26030ca8ce1f",
            },
        },
        {
            "id": "bc6ccdd9-6f59-44e8-808e-3fabcdf98fc8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334618553651556,
                    47.7640224050793,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1436,
                    "end": 1437,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "0525566a-613a-44da-8bf8-c5a62a9ba499",
            },
        },
        {
            "id": "fdc3a9f9-2808-4827-bd1a-b7e902df3694",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334555978928347,
                    47.76403793278741,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1441,
                    "end": 1442,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fa6e8057-0c91-451c-ae61-804b6d5a5231",
            },
        },
        {
            "id": "0f0a72c1-b5bc-4cfa-b8e6-aceaa01b334e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334492153953343,
                    47.764051174695865,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1446,
                    "end": 1447,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b6a242bf-a2bf-4467-be88-e4b34f0eff44",
            },
        },
        {
            "id": "ee75cd1b-7175-438d-889e-9a644f6f4987",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334441093950483,
                    47.76406176819752,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1450,
                    "end": 1451,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1963871d-d1d4-4d0a-ab16-92ec5cfbd3b1",
            },
        },
        {
            "id": "a7fd5903-6357-4e65-8094-93efa2bb9736",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334374881696824,
                    47.76406675324595,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1455,
                    "end": 1456,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c8dbfc10-bc34-46f6-b759-a18dc2a909da",
            },
        },
        {
            "id": "996773dd-7ebb-4187-8a18-7967ebb1da57",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334308353054894,
                    47.76407064398756,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1460,
                    "end": 1461,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4ce52296-b184-4a73-a40c-2547754d05bc",
            },
        },
        {
            "id": "a86bede3-52ee-4edb-a0b8-10e65a5d36d6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334241895460409,
                    47.76407201779671,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1465,
                    "end": 1466,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b4404d4a-7423-408c-93f8-4939fcab640b",
            },
        },
        {
            "id": "5491592b-5885-47bc-a666-1bb6d976c1b2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334175634611858,
                    47.76406642287495,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1470,
                    "end": 1471,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e002ef68-40d4-4855-a938-932a1ce06a8e",
            },
        },
        {
            "id": "66e5399d-199c-4ced-af88-a752ac60edfd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334109373777234,
                    47.7640608279157,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1475,
                    "end": 1476,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2101a24a-03b7-413c-967c-9899ed96a769",
            },
        },
        {
            "id": "4d2271fe-841e-41ae-8503-23ac43bb1780",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.334045355134521,
                    47.76404858260206,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1480,
                    "end": 1481,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4781676e-99f8-4292-b81a-992d2dfca999",
            },
        },
        {
            "id": "9364d091-7c1c-4017-a7b3-e4e3f35e4c6e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333982018444931,
                    47.76403431466934,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1485,
                    "end": 1486,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "60e38e74-4c0d-41fa-9d26-12593ad03c01",
            },
        },
        {
            "id": "a91190e8-a47d-4a3d-9251-11339dc8a668",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333931349117699,
                    47.764022900298414,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1489,
                    "end": 1490,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "aa0465fc-4719-4a29-9fec-739b0effa2c6",
            },
        },
        {
            "id": "19fdb85b-4b3f-4cee-ada1-1498425e8087",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333872589858786,
                    47.76400162480365,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1494,
                    "end": 1495,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e6c1afbf-80f2-46d8-b6a7-2e6f3edef1b8",
            },
        },
        {
            "id": "7cfcbdf9-12ee-45d9-9fb3-3906ef697b0e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333814220144367,
                    47.7639797529978,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1499,
                    "end": 1500,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "671a5b1b-5da9-40bd-90aa-0687e1aadf97",
            },
        },
        {
            "id": "f0a3bbed-e904-4a91-b3b4-f54310eeaeb9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.33375585047791,
                    47.76395788116258,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1504,
                    "end": 1505,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ff40e3e2-9352-487c-95b4-6ad1f91c52b5",
            },
        },
        {
            "id": "01947068-01ed-4f3f-b82c-a1148430d8a1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333697480859414,
                    47.763936009298014,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1509,
                    "end": 1510,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "43cb407c-14d6-482c-941f-8b30f811c9d4",
            },
        },
        {
            "id": "d308e65a-afe2-4602-af87-04ad02a9fcd0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333639111288873,
                    47.763914137404086,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1514,
                    "end": 1515,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "cbf4290c-e11c-455a-bd2f-56283c31a78c",
            },
        },
        {
            "id": "c72175aa-4368-4fcc-ae9f-008583315b1d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333580741766295,
                    47.7638922654808,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1519,
                    "end": 1520,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ac1a1e78-57a1-4bb8-80e8-d533e94771e2",
            },
        },
        {
            "id": "c710a10d-8ecf-4453-b2d5-be6b6167d9d9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333522372291673,
                    47.76387039352815,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1524,
                    "end": 1525,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b2c63609-644c-4b0b-8066-c77dc727d777",
            },
        },
        {
            "id": "e27664a2-5e0d-499c-b712-18e7126b7f94",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333452328985434,
                    47.763844147146216,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1530,
                    "end": 1531,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "70123bf1-ebcc-4924-8d5a-dea256fb529d",
            },
        },
        {
            "id": "0aa3d14a-32b6-4241-8677-9d751368fe9c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333393959616325,
                    47.76382227512899,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1535,
                    "end": 1536,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "9d25527c-f77c-466c-b7f1-d7aef208796b",
            },
        },
        {
            "id": "89f91797-120d-4428-8669-01fd78dfa8b6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333347264155566,
                    47.763804777494066,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1539,
                    "end": 1540,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "973f0e26-5d4a-4645-bc65-512afd9f635c",
            },
        },
        {
            "id": "6c100d03-74d1-40a8-94fd-983ab6ce10f7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333288894872783,
                    47.763782905423994,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1544,
                    "end": 1545,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d1e75ef8-6bd9-4d33-987b-641c15e4d78c",
            },
        },
        {
            "id": "298302dd-ca3d-4887-8b14-d85dc6b1cfd4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333230525637958,
                    47.763761033324556,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1549,
                    "end": 1550,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "506c30b1-555b-4370-96e2-7c6bd148db3c",
            },
        },
        {
            "id": "9f541c5f-2b76-48a4-b03b-853ef52c88be",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333172156451093,
                    47.76373916119577,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1554,
                    "end": 1555,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b1ef7089-2431-40dd-957c-1f99afb6f9fb",
            },
        },
        {
            "id": "e4e2a83b-63cd-425f-b402-b663ba52c405",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333113787312186,
                    47.76371728903761,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1559,
                    "end": 1560,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "90f74bed-a994-439e-a026-8e9ba4750d4c",
            },
        },
        {
            "id": "62e33707-8604-40bd-805f-133db4ed60f0",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.333055418221238,
                    47.76369541685012,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1564,
                    "end": 1565,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "434cfa51-7346-45bc-b04e-b1bf1249c719",
            },
        },
        {
            "id": "bae3f0da-0f6c-453e-af5d-a0b2b7ccef5b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332997049178251,
                    47.76367354463325,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1569,
                    "end": 1570,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "75c82e05-a4c3-43e3-9f86-cb161561027c",
            },
        },
        {
            "id": "07c04afb-0076-49ff-b487-dcf3f76001c6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332938680183223,
                    47.763651672387034,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1574,
                    "end": 1575,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6a458b84-1455-4dca-a163-41b73a8db38c",
            },
        },
        {
            "id": "5ddf2fdc-86d9-47bc-acae-286c96e91e62",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332880311236154,
                    47.763629800111445,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1579,
                    "end": 1580,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "de565c16-1f32-4946-b2ed-a1657d750e0f",
            },
        },
        {
            "id": "e218d1d8-ab9f-4edd-b1ab-ca768daa2036",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332821942337044,
                    47.76360792780652,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1584,
                    "end": 1585,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "577eb322-54a3-4b8c-9555-91c7755ee5d1",
            },
        },
        {
            "id": "94b43ac4-be7f-479b-a3f4-d4f9d1be705e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332763573485892,
                    47.76358605547222,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1589,
                    "end": 1590,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fd6ee45e-ead7-4a8b-9426-3d73b2f9385f",
            },
        },
        {
            "id": "f49480ba-7ac5-4287-935e-5d264ae1e591",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332705204682703,
                    47.76356418310857,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1594,
                    "end": 1595,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4125cd03-94d3-401e-8c0a-d6e00da4f883",
            },
        },
        {
            "id": "931bae9d-6895-4dbc-b3d7-7881c3b18f19",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332646835927468,
                    47.76354231071555,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1599,
                    "end": 1600,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "673bd38e-da10-498a-93b8-1589a6896719",
            },
        },
        {
            "id": "97ad803e-2616-4d03-9ba5-6ac4c7233e2f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332588467220195,
                    47.76352043829319,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1604,
                    "end": 1605,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3f092176-f3b6-4488-99bb-961615ac925e",
            },
        },
        {
            "id": "94fe5f8d-1c4c-41ae-ba81-487b79d7f5f6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332530098560882,
                    47.763498565841466,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1609,
                    "end": 1610,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a9b076ee-aae9-4ba9-b253-1f288f270cda",
            },
        },
        {
            "id": "9d78c21a-d5ee-4851-b4e4-768ed3e2f366",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332471729949527,
                    47.76347669336039,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1614,
                    "end": 1615,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "bde19edd-67e0-4f09-ba64-b2cdd16c8e20",
            },
        },
        {
            "id": "b5341af7-c53e-4f09-b886-121a3bd43439",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332413361386132,
                    47.763454820849944,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1619,
                    "end": 1620,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "cd1d71ad-edd1-409b-9e5b-d96dc144bf27",
            },
        },
        {
            "id": "db32f5e1-06b3-4ac7-aa11-8a59e577959e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332354992870695,
                    47.763432948310154,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1624,
                    "end": 1625,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "390aa996-2e74-4d1b-8dee-3e4d7f72b295",
            },
        },
        {
            "id": "d47f483e-da01-44fa-b2fa-3e2692ac7c9e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332296624403219,
                    47.763411075741004,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1629,
                    "end": 1630,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e9f57611-641b-4f9a-9d14-83e92ee9e778",
            },
        },
        {
            "id": "81851702-6ea2-43b8-b527-2046fe867159",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332238255983702,
                    47.76338920314248,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1634,
                    "end": 1635,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5e0d5980-2653-4e2b-9a91-ae2b5e2d9209",
            },
        },
        {
            "id": "46f0d92f-af5b-43ee-b5cd-0caaa3af8e72",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332179887612142,
                    47.763367330514626,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1639,
                    "end": 1640,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ad6e40ed-0e1e-4b6b-b7b8-6176d916c4c9",
            },
        },
        {
            "id": "be45cec7-56ba-4be6-914e-0e6fde726858",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332121273462041,
                    47.763345770978795,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1644,
                    "end": 1645,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "01f0d6d1-5453-4aa2-936d-6c086286637c",
            },
        },
        {
            "id": "31da1a40-c9cb-420f-9819-7d47d4bc3beb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332061628372196,
                    47.76332552463098,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1649,
                    "end": 1650,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b9c080bd-4cd1-4012-af1b-f6ba13fed5e0",
            },
        },
        {
            "id": "e25e93d2-4a7d-4f24-b224-bedd3676d18b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.332001983327719,
                    47.76330527825256,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 1654,
                    "end": 1655,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e85f4711-bfcf-4987-848b-82b78ec4c119",
            },
        },
        {
            "id": "c393e2d9-89ce-48a3-acef-f6ee77bb118f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347942083577263,
                    47.756906736301495,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 318,
                    "end": 319,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "860edfcf-4177-46c8-b292-3bf5823b3a12",
            },
        },
        {
            "id": "a6d9c6bb-b7e1-47e1-9ff1-c124859e31ee",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347887527825811,
                    47.756932353668184,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 323,
                    "end": 324,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7262260e-1609-4b61-9933-504bfc3ba507",
            },
        },
        {
            "id": "c2daec62-1fe1-4f27-bced-4f6b4c659fb7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347822060854776,
                    47.75696309447415,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 329,
                    "end": 330,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "4a93a7c5-0c1b-4e2d-aaea-186204008252",
            },
        },
        {
            "id": "2e992224-74f9-476e-a034-88733a800f4b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347767504987835,
                    47.75698871178412,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 334,
                    "end": 335,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "467b84d4-2220-4d62-9340-d0353b67ccc8",
            },
        },
        {
            "id": "299c4a72-177a-4ea1-bc90-4556cb233b83",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347712949068398,
                    47.75701432906829,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 339,
                    "end": 340,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e7de9ffe-30f7-4340-b54c-4b048df893af",
            },
        },
        {
            "id": "cb9cad54-6212-4d7d-8b1b-a544832ceab1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347658393096467,
                    47.75703994632669,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 344,
                    "end": 345,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "c51b1609-a10f-476a-9886-c1ad7ddc674c",
            },
        },
        {
            "id": "a4eec61e-36eb-4ce9-9687-a942186a6e6c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347605811227871,
                    47.75706722112898,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 349,
                    "end": 350,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2dd3a659-1ad8-4d7c-9f7a-04755802114f",
            },
        },
        {
            "id": "acfe0636-61cc-4b38-be8e-f03273e79c5c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347566716759156,
                    47.75709153556405,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 353,
                    "end": 354,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a74b9f0d-4e85-491b-a122-0bec07d60d6f",
            },
        },
        {
            "id": "a3607e8f-d3c2-40fc-8a26-c7150e019ac1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347517848623051,
                    47.757121928589115,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 358,
                    "end": 359,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a873c327-eab5-4ab5-83de-f0c02597b294",
            },
        },
        {
            "id": "fd0e76de-dc0c-449d-9b57-a54efe182337",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347468980431159,
                    47.75715232159325,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 363,
                    "end": 364,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ad5c3e4f-b2b3-4331-a417-074cd72e78ad",
            },
        },
        {
            "id": "6dcdf818-4e7b-41dd-b43e-7ccd81325bc7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347420112183478,
                    47.75718271457647,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 368,
                    "end": 369,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "e88fbe7e-bb7f-4809-807f-c9d2b01debc1",
            },
        },
        {
            "id": "5fedcf6a-f4e4-4add-aeae-717f0ac3e0df",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347363771471731,
                    47.75722096223357,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 374,
                    "end": 375,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "22d409b1-aa66-4db8-aaa0-f5ea4817f377",
            },
        },
        {
            "id": "b40d2804-e3f5-4db2-8f8b-07299a1f996e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347318190613826,
                    47.75725389246559,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 379,
                    "end": 380,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "46a2f9e2-fb85-431e-bb16-823228b8bd4b",
            },
        },
        {
            "id": "3d742905-5f01-4eec-8617-3010cd7f50d4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347281725886911,
                    47.757280236637996,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 383,
                    "end": 384,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "a2818d77-50f7-48c0-b08f-ae714132f76a",
            },
        },
        {
            "id": "f43f2a54-8d28-4b08-832a-716cca2004ce",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34722702872888,
                    47.75731975287458,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 389,
                    "end": 390,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "13ef4ffa-7796-442d-b8a8-919dcd31e275",
            },
        },
        {
            "id": "2b4ee235-a31a-41ab-ba2c-de411361fc01",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.347181447701838,
                    47.75735268305156,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 394,
                    "end": 395,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "fb36fdcb-f262-4372-bb11-06e63bead14c",
            },
        },
        {
            "id": "e1342c2f-f9e7-4f20-a8e8-78c9d39d20a4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348433082978083,
                    47.756676178841204,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 273,
                    "end": 274,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "168a25ac-8f2f-43a6-ae43-66aaddf907af",
            },
        },
        {
            "id": "f2861eb5-5d01-4c87-851f-5095e27a0293",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34837852769908,
                    47.75670179643993,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 278,
                    "end": 279,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f10089b1-c1ab-46b6-bf82-e690737a7ad8",
            },
        },
        {
            "id": "dc5c1e32-f635-4c6e-aba2-961e5cecd307",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348323972367584,
                    47.75672741401286,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 283,
                    "end": 284,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "87bff48f-b801-439e-a0ba-d8269e716ec9",
            },
        },
        {
            "id": "073ede7a-7a12-45ab-894c-ecce0dabe1fa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348269416983593,
                    47.75675303156002,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 288,
                    "end": 289,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "197fba47-6064-479c-b598-b54bd9f08dbe",
            },
        },
        {
            "id": "ebd0fa43-0f07-4b90-9f1f-c1d84f2727da",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348214861547108,
                    47.756778649081376,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 293,
                    "end": 294,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3e2969da-bcb7-4085-91fb-50e05a13bc98",
            },
        },
        {
            "id": "f442b2c9-2735-4fa0-92c7-f098ad144ec2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348160306058129,
                    47.756804266576964,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 298,
                    "end": 299,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5239a53b-592d-4e36-8a52-3fe0ef8a9ad4",
            },
        },
        {
            "id": "d8adfdbb-1ef5-4f9f-b445-25fb19be597c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34809483940206,
                    47.75683500753763,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 304,
                    "end": 305,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ddafde04-f988-404d-aed9-2d8f3b3f61f0",
            },
        },
        {
            "id": "eda18b14-78c4-48ce-9467-be7a4fd4c6d8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348040283797592,
                    47.75686062497651,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 309,
                    "end": 310,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "47a81c95-3f9f-423f-94ca-0ca2ade8e679",
            },
        },
        {
            "id": "b501635a-8ea0-49f0-b229-0e1b7ad0128d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34798572814063,
                    47.756886242389584,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 314,
                    "end": 315,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "5b40ce62-db2f-4799-8a43-a578dc7cfd6c",
            },
        },
        {
            "id": "5653438e-e300-4abf-9f1a-164ab4dcd8e3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348520396085369,
                    47.75663497594678,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 906,
                    "end": 907,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ce499fb7-beb0-42f6-b6c7-d34b1810413e",
            },
        },
        {
            "id": "9e1464ce-6e58-4a9e-be2f-777ab826d198",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348542237964565,
                    47.75662455713728,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 904,
                    "end": 905,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "1324698e-622a-47a0-a4bd-de13f42b13b3",
            },
        },
        {
            "id": "c46a069e-0e84-4e63-bf41-6cbea45e302b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348575000767331,
                    47.75660892891526,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 901,
                    "end": 902,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f759a797-d571-4585-a6e1-2423d7374d22",
            },
        },
        {
            "id": "584f24b5-b2cf-4b87-996b-5574060c256e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348629605395871,
                    47.7565828818579,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 896,
                    "end": 897,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b3f940dc-2c5f-4168-9dc5-631262034fa0",
            },
        },
        {
            "id": "7ccb72f8-f2db-475c-aeff-621be49e5b84",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348673289060239,
                    47.756562044193416,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 892,
                    "end": 893,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "b86bafce-feaa-4afb-8dde-6952629116ab",
            },
        },
        {
            "id": "6c7311d5-ceb3-454e-b52f-c2da26b40c70",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348727893592619,
                    47.75653599708954,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 887,
                    "end": 888,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2d62a6a4-b142-4a7f-90f3-dd4e3619aeeb",
            },
        },
        {
            "id": "d5bb4d27-6a0e-463c-ba5b-6f9d8a19dfde",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348782498071578,
                    47.75650994995984,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 882,
                    "end": 883,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "66e03cb1-2e75-4afd-8e85-3e76d9b17352",
            },
        },
        {
            "id": "1a1ee413-a10c-41c1-88b3-c2cc65b7420a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34883710249711,
                    47.75648390280429,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 877,
                    "end": 878,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "ae2e5b6a-4103-4a95-a7c7-100be6a3347b",
            },
        },
        {
            "id": "669db8a8-dd7a-48af-99ef-b05e7cff617a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348891706869225,
                    47.7564578556229,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 872,
                    "end": 873,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "aa5eef6a-1968-49b6-9668-6e825956f17f",
            },
        },
        {
            "id": "c1129814-35f5-49c6-8713-10b90b27a1ce",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34893539032845,
                    47.756437017859184,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 868,
                    "end": 869,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "79c8dba1-8c5d-4784-850d-f4085157a242",
            },
        },
        {
            "id": "7b3b2990-4299-42ab-8bef-d4a141b66ec9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.348989994604401,
                    47.75641097063131,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 863,
                    "end": 864,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "2d494cf0-6f36-4762-b6d3-79ac5d32b829",
            },
        },
        {
            "id": "4c9dd34e-4a48-4d5e-8408-0dd12e0c713d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.349055519665026,
                    47.756379713923714,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 857,
                    "end": 858,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "066b0ac2-8c47-468d-8f5c-1500c9a84a58",
            },
        },
        {
            "id": "440138eb-26df-40ce-bebf-7464027fed0f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.349110123823449,
                    47.756353666638965,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 852,
                    "end": 853,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d33eaa6a-87f2-4d45-a70d-4abc913f538b",
            },
        },
        {
            "id": "d503bdf2-e2bc-447d-acfb-369ecebfd761",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.349153807111724,
                    47.75633282879257,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 848,
                    "end": 849,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "3b98df0b-97a0-4697-9661-49a84629be3d",
            },
        },
        {
            "id": "4ca5cffc-85b7-4514-952c-e1aec6e50970",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.349208411173984,
                    47.75630678146131,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 843,
                    "end": 844,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d96f336c-09a4-4a97-a893-70c162be50a1",
            },
        },
        {
            "id": "44b1cb59-58ee-4a67-a605-d8f34eef506a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.349273935978181,
                    47.75627552462971,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 837,
                    "end": 838,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "d3445dd9-ca51-42e5-89e0-e369ab7cb0b3",
            },
        },
        {
            "id": "490f09fa-ba40-438e-a6dd-fee5010ba6f1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34931761913824,
                    47.75625468672128,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 833,
                    "end": 834,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "7ff1292e-5d3d-46f0-a751-185643452da2",
            },
        },
        {
            "id": "e4f7bf17-d5ec-4594-a7bd-a789869d1f88",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.349361302264112,
                    47.75623384879634,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 829,
                    "end": 830,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "6081c76c-eb4a-4c4f-a812-dfe564235d12",
            },
        },
        {
            "id": "c17b1515-3e4b-4933-9579-bebdaacc0b2a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.349415906123369,
                    47.75620780136689,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 824,
                    "end": 825,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "f0d20d5f-46d9-4a36-8aee-2714f33bd8eb",
            },
        },
        {
            "id": "89c378a2-24cb-4dae-9390-76f5a9fe3952",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.349470509929205,
                    47.756181753911605,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 819,
                    "end": 820,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "14f05509-a68f-4a92-bebf-67e767865052",
            },
        },
        {
            "id": "b572ec68-a4b4-439a-a4ff-9741865dcb8c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.349525113681618,
                    47.75615570643049,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "RUE LEFEBVRE",
                    "start": 814,
                    "end": 815,
                },
            ],
            "properties": {
                "layer": "c4d68142-b9b0-4ee6-8532-f4644acf46b6",
                "photo_Uuid": "8e009696-f07b-4851-bd46-9a882afdcfef",
            },
        },
    ],
}

export const imageCollectionMulhousePano = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": "8cb313bf-c0e8-493d-a204-2b1a4fd24b12",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340147916360933,
                    47.759813595480296,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 601,
                    "end": 602,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "c955cdb1-7686-4b06-90bd-41a97b8f7298",
            },
        },
        {
            "id": "03090ad3-093d-481c-a895-dd34141265ab",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340189727933408,
                    47.759778658512076,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 596,
                    "end": 597,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "57dd8fa3-bbd0-41f5-97ab-0353cdbebe43",
            },
        },
        {
            "id": "5dc6a8ee-3221-4575-b388-52d30c27eb59",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340231539451016,
                    47.75974372152826,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 591,
                    "end": 592,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "81a529db-5138-4694-bcd2-c28b6e9c7c19",
            },
        },
        {
            "id": "e5ee9f64-eb1b-4781-be31-84927a454284",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340273350913753,
                    47.75970878452884,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 586,
                    "end": 587,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "13ce7add-72b9-4c08-a170-ff2ffe9dbc55",
            },
        },
        {
            "id": "dd28ef02-bf77-4d45-a7c1-9f3d6247a4fa",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340315162321619,
                    47.759673847513795,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 581,
                    "end": 582,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "96a07ae9-f672-445c-933a-526d163f0432",
            },
        },
        {
            "id": "e97697ca-35b8-4fac-993d-d675c18ae7b1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340356973674614,
                    47.75963891048316,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 576,
                    "end": 577,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "e55084bf-13a9-4f22-a9f2-5f15a2d1e99a",
            },
        },
        {
            "id": "9c685e88-5f6f-4168-815b-24b047d50a95",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340398784972734,
                    47.75960397343692,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 571,
                    "end": 572,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "d00d54e7-7ff8-4f1c-9d49-0d1de6b6a83e",
            },
        },
        {
            "id": "098f119a-24b9-438c-8d34-a485341d448a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340440596215987,
                    47.75956903637505,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 566,
                    "end": 567,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "ab98ae2e-e3d2-4838-9e67-92c49e280c82",
            },
        },
        {
            "id": "639c9c0c-3ada-47a6-86e2-7e5f12d97e55",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34048240740437,
                    47.75953409929759,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 561,
                    "end": 562,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "cae4a011-b2cd-4bac-a8f6-5108a0b0b4d0",
            },
        },
        {
            "id": "893c2e25-c18c-4321-bb16-b0362a9e78c4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340524218537877,
                    47.75949916220451,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 556,
                    "end": 557,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "ff304b6e-ae4b-488d-aa1d-cd81836b6451",
            },
        },
        {
            "id": "0629236e-7e43-4f39-a8d0-b0663c3294e7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340566029616518,
                    47.75946422509583,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 551,
                    "end": 552,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "efc21016-c245-461a-94f0-5d15cb6d82fe",
            },
        },
        {
            "id": "c56af79a-1ea8-4545-b8d1-9734f2fa9263",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34060784064029,
                    47.75942928797154,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 546,
                    "end": 547,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "ce6b0bd0-2cf0-415b-a6f7-7fb61e85a266",
            },
        },
        {
            "id": "849752fc-08ed-4081-b9dc-708cebffe1a6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340649651609187,
                    47.75939435083166,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 541,
                    "end": 542,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "8344b08e-6bc4-4f9e-af43-60fb7c1a70f7",
            },
        },
        {
            "id": "c6c366c5-202b-4923-93d5-e9ef6a477ebe",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340691462523216,
                    47.75935941367616,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 536,
                    "end": 537,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "de8da9f7-2af9-4301-a2b4-36f61d375bba",
            },
        },
        {
            "id": "cf3e455d-12c4-4425-a255-14c6085731ac",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340733273382376,
                    47.759324476505064,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 531,
                    "end": 532,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "8f88b70c-b047-4d73-b904-85705c638599",
            },
        },
        {
            "id": "a9186f97-899a-4cd8-a02e-f8c339b58854",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340775077927186,
                    47.75928952090239,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 526,
                    "end": 527,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "3ddac7c8-9e51-4e43-b5d6-779604195f09",
            },
        },
        {
            "id": "5e2dbbdb-e223-4c39-914a-ad4cb62ba56b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340816826081743,
                    47.75925439954056,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 521,
                    "end": 522,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "e99047c4-419e-4f68-99e9-22da0d807561",
            },
        },
        {
            "id": "7799598d-fa45-4d75-8fb6-85ceb89fb934",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340858574181221,
                    47.75921927816316,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 516,
                    "end": 517,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "08695b77-92d5-4f82-9046-e0fd69379dd5",
            },
        },
        {
            "id": "7d7f1d2d-61d0-440a-8741-b022bf6fd4dd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340900322225624,
                    47.7591841567702,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 511,
                    "end": 512,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "306e73a7-1155-4a4f-9a96-a5022d30c176",
            },
        },
        {
            "id": "4d928c4f-ab11-4ccc-b5c4-5a341855d781",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340942070214951,
                    47.75914903536166,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 506,
                    "end": 507,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "167e8227-b74e-43bc-ae47-1fbc5935030b",
            },
        },
        {
            "id": "5780d41e-dbb3-44b0-a891-074c45186b50",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340983818149196,
                    47.759113913937554,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 501,
                    "end": 502,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "f194d393-b2d8-4b0c-8bda-36c8300c4fa0",
            },
        },
        {
            "id": "4ca9b7cb-1a00-42d4-8e84-3c9e161e6ca9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34102556602837,
                    47.759078792497874,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 496,
                    "end": 497,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "3e751b4f-334e-4d02-959d-e82ddfbf312e",
            },
        },
        {
            "id": "a1f19971-172f-4709-80ef-1fe7251071b1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341067313852466,
                    47.75904367104264,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 491,
                    "end": 492,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "59c46dcb-19be-4a44-9adf-73236b23bc5b",
            },
        },
        {
            "id": "c073f710-c8e8-4e6f-8f25-4d345dc03f63",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341109061621489,
                    47.75900854957184,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 486,
                    "end": 487,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "dd389388-7272-4c87-88ae-280074d259f3",
            },
        },
        {
            "id": "624dd8ce-74f1-4bdb-aed1-749f748932b8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341150809335433,
                    47.75897342808544,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 481,
                    "end": 482,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "2e38b7e9-74a4-4f56-a367-ceef177676c2",
            },
        },
        {
            "id": "4da7a029-6496-468e-87b6-f291a3340579",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341192556994299,
                    47.758938306583495,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 476,
                    "end": 477,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "6897e274-7e67-4842-aaf5-fa4effe39157",
            },
        },
        {
            "id": "5c18dc5b-3242-4803-a82e-e51bfa2fc021",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341234304598092,
                    47.758903185065975,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 471,
                    "end": 472,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "dd0b25ab-6895-4980-89e5-b5d661a489ee",
            },
        },
        {
            "id": "e9349da0-7b60-40a1-835b-48175e8d6af8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341276052146809,
                    47.75886806353289,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 466,
                    "end": 467,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "45bb3506-60fa-4d8a-99bf-d512915a4b95",
            },
        },
        {
            "id": "5e241c96-3709-4149-933f-22d8a03186f9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341317799640449,
                    47.75883294198424,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 461,
                    "end": 462,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "ac0e094c-7334-4491-a694-841c5c0be21b",
            },
        },
        {
            "id": "547932fe-2c1e-47fa-a6e1-613b504d80d9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341359547079014,
                    47.75879782042,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 456,
                    "end": 457,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "058ff504-3b9a-4588-b9c1-43fc0d291a84",
            },
        },
        {
            "id": "fee01e33-53e6-4e9a-82ee-5fd64f313c02",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341401294462504,
                    47.75876269884021,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 451,
                    "end": 452,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "24599409-32b6-4452-8a64-b666359faaaa",
            },
        },
        {
            "id": "00beecce-cbca-4b3d-a381-5ab211c3f269",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341443041790918,
                    47.75872757724484,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 446,
                    "end": 447,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "6d54280e-65b2-4357-8820-1c0bbcf07100",
            },
        },
        {
            "id": "1509d30d-4761-4328-90c0-51ab2516b15e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341484789064254,
                    47.75869245563392,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 441,
                    "end": 442,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "cde82710-a560-4a52-86ae-c4d0cbdd5d48",
            },
        },
        {
            "id": "028a69c8-9926-45f4-b046-c6b7fe57f327",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341526536282518,
                    47.75865733400741,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 436,
                    "end": 437,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "7b967bcf-aa18-46d3-b4c6-705888f6a2b2",
            },
        },
        {
            "id": "4d7ed5d4-d55d-408a-866b-534fc904e707",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341568283445705,
                    47.758622212365324,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 431,
                    "end": 432,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "dc7a29c5-d704-41ed-bb1f-fc36d76ae3eb",
            },
        },
        {
            "id": "151efeed-2bc9-4172-9254-13d014f5cef1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34161003055382,
                    47.7585870907077,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 426,
                    "end": 427,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "45fefd2a-2b88-4308-95aa-f63ec0888da8",
            },
        },
        {
            "id": "fe57bbb6-e4e6-4f16-ba55-634313ea5d1c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341651777606855,
                    47.758551969034485,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 421,
                    "end": 422,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "888ef8de-fdb1-44d7-9584-2783f9bddfcd",
            },
        },
        {
            "id": "348eb393-3876-4fce-9dae-233003bb1f92",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341676825812243,
                    47.758530896023096,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 418,
                    "end": 419,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "8d906c73-73bb-4902-aae9-8b908a64c2dd",
            },
        },
        {
            "id": "d6a2d264-6724-440b-9bcb-5ec2f7c4c063",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341701873997802,
                    47.758509823006094,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 415,
                    "end": 416,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "18642ddb-ce40-447e-8cf4-09d46f06c71e",
            },
        },
        {
            "id": "552f1b35-0801-4621-9782-dbd2bed1adaf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341743620929676,
                    47.758474701298624,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 410,
                    "end": 411,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "eb3542cc-a399-4b8f-b9cc-b108c8b66458",
            },
        },
        {
            "id": "17ca467a-51f5-4bb7-9ae9-a173cfe76e8d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341785367806474,
                    47.758439579575615,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 405,
                    "end": 406,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "7ed46e13-eb15-458d-a89b-8fd98dd5d1da",
            },
        },
        {
            "id": "6bbd0286-fc6e-4d07-b571-d0b9dcc2280d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341827114628196,
                    47.75840445783701,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 400,
                    "end": 401,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "bb6cb12b-c4e6-4f6e-8680-e5413f5f855b",
            },
        },
        {
            "id": "d5af34a4-2fa7-4933-b45f-7edf60a2a067",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341877271922097,
                    47.75836229638637,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 117,
                    "end": 118,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "b56a5376-d496-4a68-8743-f5c9fb6bf840",
            },
        },
        {
            "id": "571500d8-2065-4b5e-9223-c20537bfd85b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341911158730822,
                    47.758334076218986,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 113,
                    "end": 114,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "60abc6bf-f689-49da-9485-531f73691161",
            },
        },
        {
            "id": "2c4d6cb7-cee3-4ce9-b32e-4bc74fdff53d",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341945045503627,
                    47.758305856041346,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 109,
                    "end": 110,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "58612360-be70-4e6b-8baf-fc65e84dc1e4",
            },
        },
        {
            "id": "cdf05988-3ff9-448f-b1f2-27bb23915eae",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.341987403919121,
                    47.758270580804876,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 104,
                    "end": 105,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "c3dfe5bd-17b9-43d3-ac19-22c5b25e1f06",
            },
        },
        {
            "id": "4ab00728-9b30-49e8-8213-acc077f70940",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342029762278489,
                    47.75823530555241,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 99,
                    "end": 100,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "773c46fc-1a38-41bd-a205-7883fcfe9f08",
            },
        },
        {
            "id": "23b0da8c-4150-497b-a0ce-a1def3e9dd22",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342072120581732,
                    47.75820003028392,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 94,
                    "end": 95,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a59ca04e-ffc5-4d75-b25d-0fe43c336740",
            },
        },
        {
            "id": "92cb0788-c6a2-4344-a683-a7b1ec250e3b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342122950471538,
                    47.7581576999406,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 88,
                    "end": 89,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "fe665909-33b2-4b4f-a94c-16d8fb831d73",
            },
        },
        {
            "id": "d5eeaf80-d086-4bcb-abd7-47837391ee85",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342165308651306,
                    47.75812242463687,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 83,
                    "end": 84,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "0901e7b3-dc33-44d4-9793-255806d89c10",
            },
        },
        {
            "id": "edc3a3d7-f699-4a20-be9d-4771251b44e7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342207666774947,
                    47.75808714931715,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 78,
                    "end": 79,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "52370146-ce7d-44d9-b642-4208991122ad",
            },
        },
        {
            "id": "2f63233c-578c-474e-802b-7f9b9e45c1a7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34224155323345,
                    47.75805892904981,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 74,
                    "end": 75,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "216c3175-a6c0-41a4-8f5c-be112c6e3c88",
            },
        },
        {
            "id": "b999c190-3c4a-4038-9cfa-43f0f5bf8900",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342275439656033,
                    47.75803070877227,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 70,
                    "end": 71,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "19764d1e-bc01-4137-8c6d-d6706c835674",
            },
        },
        {
            "id": "bc540b6c-b912-4371-a037-2ab951e0d2c9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342325955698585,
                    47.75798866641002,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 390,
                    "end": 391,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "cc68f343-2484-47fe-8854-eb47b05fde39",
            },
        },
        {
            "id": "eeb918c9-624f-4aed-aff6-153a1ed97efc",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342367965249902,
                    47.75795371111115,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 385,
                    "end": 386,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "8bc9b46e-1aa2-4830-8673-99b55d7e0dcb",
            },
        },
        {
            "id": "aa827901-c174-426c-ba6b-90b2a72dcdbe",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342409974746059,
                    47.75791875579653,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 380,
                    "end": 381,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "03c7a48f-b15e-4a18-99db-c198040fb222",
            },
        },
        {
            "id": "530897e8-c730-4075-bdf8-40cece1b04c5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342460386068637,
                    47.7578768093982,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 374,
                    "end": 375,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "fe237836-c843-4f87-bee9-5ebfb4d8862b",
            },
        },
        {
            "id": "2edf8d15-d2b7-49bf-936d-4360684c0278",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342493993572901,
                    47.75784884512004,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 370,
                    "end": 371,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "f182cdac-bf0d-4924-ae9b-5050f9462b5e",
            },
        },
        {
            "id": "ba121611-7f01-48c1-8c50-087770894375",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339496668430612,
                    47.76036252290144,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 679,
                    "end": 680,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a4e8e073-7392-4794-9db8-626613e93272",
            },
        },
        {
            "id": "0afe0370-b0fb-4f7a-b325-588ce55420fb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342536002903583,
                    47.757813889758175,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 365,
                    "end": 366,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "9f2a75c5-ff2b-4673-a2fb-b9f38f757140",
            },
        },
        {
            "id": "58762bcf-e087-403e-92da-85a5100e5f3f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342578012179108,
                    47.75777893438055,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 360,
                    "end": 361,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a0c1f2a8-55a0-426c-9b0d-d32d317c1f46",
            },
        },
        {
            "id": "3cf68d51-f5da-4f1e-b7b2-e70f48c6a635",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342620021399477,
                    47.75774397898719,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 355,
                    "end": 356,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "cbf377fa-bbc6-432d-85ce-b606f14b751c",
            },
        },
        {
            "id": "3af759c8-c267-4d06-9192-f779bc7eec7e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342662030564687,
                    47.75770902357806,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 350,
                    "end": 351,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "89c25112-18ab-463d-9a17-9d157a6dbc2d",
            },
        },
        {
            "id": "b661e85d-e173-420e-8081-e64cdc884d31",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34270403967474,
                    47.75767406815321,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 345,
                    "end": 346,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "9a282cec-0e67-46da-ad65-7e3fa5956299",
            },
        },
        {
            "id": "dbdcb016-e7cf-4400-828b-82aa9bd4ddc9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342746048729635,
                    47.75763911271258,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 340,
                    "end": 341,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "ffe68dd9-4c51-4ed0-b0b9-4f0020bdcc9a",
            },
        },
        {
            "id": "f1a6bd24-2da8-4f39-bd68-948aa05dd2e8",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342796459522702,
                    47.75759716616306,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 334,
                    "end": 335,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "8f99c85c-2e55-4f9d-965a-6305fbbb770b",
            },
        },
        {
            "id": "8379d707-367a-4598-af28-369cc4bbd8be",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339521644655004,
                    47.76034113402418,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 676,
                    "end": 677,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "55e7c38b-1249-4ca3-9ee2-9f1a62d2ccea",
            },
        },
        {
            "id": "ecdc983c-7e81-4c7d-b6f4-167803a3e98c",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342830066673954,
                    47.757569201784094,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 330,
                    "end": 331,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "14486626-e3e5-4ba6-a2bf-0b4b312208a7",
            },
        },
        {
            "id": "7eeaa96b-5fda-4cb3-ae26-9c359093b18b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342872075563378,
                    47.757534246296245,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 325,
                    "end": 326,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "09af8a7e-56dc-48bd-afea-277a97417e5f",
            },
        },
        {
            "id": "f457c6ed-b52a-4011-a872-d5dc91d92b02",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342914084397644,
                    47.75749929079263,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 320,
                    "end": 321,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "f2794bbe-f864-4853-803f-08252f9ff4f1",
            },
        },
        {
            "id": "3e9433ab-9397-4bc5-a324-426203861acd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342964494925956,
                    47.7574573441675,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 314,
                    "end": 315,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "394f4948-d1b9-42e7-b69a-0fe321898f05",
            },
        },
        {
            "id": "d7d61bd9-069c-4996-a0e3-67d8e9d0b381",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.342997620353294,
                    47.757429142385824,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 310,
                    "end": 311,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "5c5d7db1-6e37-4050-8d0d-ec8e216200c4",
            },
        },
        {
            "id": "786c1580-fe3d-4755-b7d5-a556f93b0314",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343038941097535,
                    47.7573938477606,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 305,
                    "end": 306,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "e12b6b4a-92a4-4d63-b69a-010ca47c4217",
            },
        },
        {
            "id": "eef8ebe7-6084-4806-bf25-f19abb248a0b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343080261786993,
                    47.75735855312012,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 300,
                    "end": 301,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "670db6f1-37ba-4aec-9c59-1dc5d9102651",
            },
        },
        {
            "id": "5bbf0bd1-95d7-416f-bc65-8588382456dc",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339563271651056,
                    47.760305485883016,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 671,
                    "end": 672,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "7f92ddc7-6613-4dca-885c-4287835f8f24",
            },
        },
        {
            "id": "8523c11c-eee7-49ca-8721-981956fb5386",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343121582421675,
                    47.757323258464346,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 295,
                    "end": 296,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "6fea9efc-0d46-40a2-b912-086334463c82",
            },
        },
        {
            "id": "d5cd2d76-e3ae-4a6f-9f20-d8b4e06dfa1a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343162903001573,
                    47.757287963793324,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 290,
                    "end": 291,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "70b74fc9-1a77-4954-b3e6-11952c81d7f2",
            },
        },
        {
            "id": "255bd76b-190c-44d5-90fa-609d28b68114",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343204223526698,
                    47.75725266910702,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 285,
                    "end": 286,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "1ac66f0d-fbd1-46ce-a9c0-b7a04ebc1ae5",
            },
        },
        {
            "id": "58013c01-5f44-4da4-8f63-46aecd3e84f7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34324554399704,
                    47.75721737440544,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 280,
                    "end": 281,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "61b2ba96-6874-4bf0-bc08-97a227d667e4",
            },
        },
        {
            "id": "afa5f465-5313-476e-9ec5-3c44316229b6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343286864412602,
                    47.75718207968858,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 275,
                    "end": 276,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "9b99602a-8e34-4f96-9028-a76b88631142",
            },
        },
        {
            "id": "bd4e42c3-ea13-44f9-87c8-b00608467788",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343328184773386,
                    47.757146784956454,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 270,
                    "end": 271,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "c4801f3c-1151-4035-92f1-f13db5a38bb7",
            },
        },
        {
            "id": "439a8fa1-f686-4295-a2f5-29ac4a9a7303",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339604898591371,
                    47.76026983772635,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 666,
                    "end": 667,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "01ae3264-0622-45af-bfa4-85a06e4ada89",
            },
        },
        {
            "id": "2403f60e-ed6d-4454-b9a1-0ec9b9b3529f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34336950507939,
                    47.75711149020907,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 265,
                    "end": 266,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "be458d69-da2a-42d9-aab3-8441593266c1",
            },
        },
        {
            "id": "bc12fd56-5369-42cf-8870-51585761b144",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343410825330612,
                    47.7570761954464,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 260,
                    "end": 261,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "1c7ab135-116c-4df1-b9c6-3378012b7529",
            },
        },
        {
            "id": "33e42de9-fa50-4844-87ea-50ac6d419351",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34345214552706,
                    47.75704090066847,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 255,
                    "end": 256,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "75c1f476-6d26-4c3e-973c-9c5d8e94cda0",
            },
        },
        {
            "id": "a6dad398-5270-48b9-9fed-472bc96ba492",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343501729690487,
                    47.756998546914765,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 249,
                    "end": 250,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a4345230-b8ff-4da1-988c-0385922a7866",
            },
        },
        {
            "id": "94235027-6b1c-4f22-ba0a-9b8c963c8fd2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343534785755617,
                    47.756970311066766,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 245,
                    "end": 246,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "ec1c8856-89f8-46a3-8449-d49ce122f58a",
            },
        },
        {
            "id": "d677ab72-b571-4859-a271-03fa42433456",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343576105787725,
                    47.756935016243006,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 240,
                    "end": 241,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "66de4571-9b1b-485e-b34e-279076151d9b",
            },
        },
        {
            "id": "47b43db4-2121-488a-a473-a60cbc737775",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343617425765058,
                    47.75689972140399,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 235,
                    "end": 236,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "f28a82d7-46a9-4e9d-a4a6-be32fe38d0ad",
            },
        },
        {
            "id": "0eea13bb-4b6c-41e6-b4fb-46c703b8f561",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339646525475944,
                    47.760234189554176,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 661,
                    "end": 662,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "7e6a1fe8-dd27-4ad1-8356-ab44f04d50c6",
            },
        },
        {
            "id": "12dcfa38-7493-4bef-8ffd-0c1776851777",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343658745687606,
                    47.756864426549676,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 230,
                    "end": 231,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "b5b670b0-69b2-42ec-ba2b-97a163bfc1d9",
            },
        },
        {
            "id": "e15bb986-8ea1-40e2-a872-6386bfbc323e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343700065555382,
                    47.75682913168011,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 225,
                    "end": 226,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a0573f96-1379-4d0b-a937-73f1c3261955",
            },
        },
        {
            "id": "3713c958-52ab-4e83-8774-1bed5776d4bf",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343741385368377,
                    47.756793836795254,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 220,
                    "end": 221,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "478a93cb-e9bf-43ce-a8fe-8cce879bdf7c",
            },
        },
        {
            "id": "eca0ce53-78b3-4328-b8fa-9a49714f69b4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343782705126594,
                    47.75675854189513,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 215,
                    "end": 216,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "bd893825-42f0-4869-bbcf-475e92d686f6",
            },
        },
        {
            "id": "00baf8fc-196c-43a0-8148-fc13f83ff6d1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339688152304775,
                    47.76019854136651,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 656,
                    "end": 657,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "e1f99f9d-bdc6-4541-9a47-fecc9f129c08",
            },
        },
        {
            "id": "67251368-5e80-4798-8e50-a93e47825013",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343815760893728,
                    47.75673030596405,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 211,
                    "end": 212,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a620e567-0dce-4e4c-ad50-438df6420904",
            },
        },
        {
            "id": "2a2c509b-bc2e-476b-acc1-7fb9512077a7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339729797618201,
                    47.76016296430401,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 651,
                    "end": 652,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "6c899401-efc3-479c-9312-cbaf5b7b0ae8",
            },
        },
        {
            "id": "34587e15-0ba0-49e0-a8b6-d0175ec98277",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339771609739398,
                    47.76012802749188,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 646,
                    "end": 647,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "1c8dcc5f-db0a-47aa-8a84-bc05ba33b0f4",
            },
        },
        {
            "id": "d9de1004-5305-4e7b-b344-8a9b4b140515",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339813421805721,
                    47.76009309066412,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 641,
                    "end": 642,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "69ac5ba9-d467-4021-877e-6db4e4331c0d",
            },
        },
        {
            "id": "54271010-7cca-4d86-a98c-70945171f309",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343848816625802,
                    47.75670207002319,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 207,
                    "end": 208,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "2bbeff84-069f-467d-9d1b-0649521d771d",
            },
        },
        {
            "id": "a21306ed-dfe2-4564-83b5-b01dffb83936",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343890136241596,
                    47.75666677508335,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 202,
                    "end": 203,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "e1c099ab-b2fc-4eb4-91af-fb0ded2d04ef",
            },
        },
        {
            "id": "44afaced-bd14-467a-a90d-c4c5c89d69f6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339855233817174,
                    47.76005815382077,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 636,
                    "end": 637,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "6631bac3-4ffe-4d1e-9152-d85de2387aa1",
            },
        },
        {
            "id": "79038f7a-8363-40b2-b215-43e0b12ed419",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339888683386829,
                    47.76003020433485,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 632,
                    "end": 633,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "d121bd40-73d4-4831-8448-48684333a893",
            },
        },
        {
            "id": "47d36370-3da0-432a-943b-1bafbb9c365b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339930495299512,
                    47.75999526746342,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 627,
                    "end": 628,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "9d7246cb-eb85-4359-9882-520029130da1",
            },
        },
        {
            "id": "1f56512d-5d22-40c6-9abc-18e864b5a2a4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.339980669522301,
                    47.75995334319707,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 621,
                    "end": 622,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "7ced6d5d-35bb-412e-b7f9-eb1a07704ac0",
            },
        },
        {
            "id": "923506d7-b955-4fed-9ff8-1623f5aa9abd",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340022481314268,
                    47.75991840629129,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 616,
                    "end": 617,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "f3cc39f6-7056-4935-94aa-a1a2bca65dda",
            },
        },
        {
            "id": "e0c0c3a4-103f-49e1-95df-ea466badbc0a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.340064293051359,
                    47.7598834693699,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 611,
                    "end": 612,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "abb6b1b1-a198-424e-b991-6d190b8cdb5c",
            },
        },
        {
            "id": "72575e10-7331-477b-bbea-f1f87a219ff6",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34010610473358,
                    47.7598485324329,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 606,
                    "end": 607,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "953701bb-5fd4-43be-b1d1-604861d059c8",
            },
        },
        {
            "id": "540482b4-34b3-40ee-acbc-6c5dace04f10",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344905354344984,
                    47.75463924806532,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 6,
                    "end": 7,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "c65b79d7-b5c5-4ac5-80d2-9656527b5278",
            },
        },
        {
            "id": "5017f1c2-2b0f-4531-940d-ed19daf2515f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344893677512514,
                    47.75468378669875,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 11,
                    "end": 12,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "8bfb7eab-85d9-498f-90e6-ba55e36a0f9e",
            },
        },
        {
            "id": "a4fde622-24ae-45a9-9e32-0c65284d8c10",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344882000660511,
                    47.754728325329914,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 16,
                    "end": 17,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "932a6154-4747-4ae9-bc2a-0708fd97687d",
            },
        },
        {
            "id": "3693fafe-1e73-4348-84d7-98e1afa0d851",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344870323788974,
                    47.754772863958806,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 21,
                    "end": 22,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a3a3931a-ffaf-4ff9-817b-4ab27b65291b",
            },
        },
        {
            "id": "30c9380d-6a80-45f7-82d3-c884ef262a4f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344858646897903,
                    47.75481740258543,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 26,
                    "end": 27,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "5dec0750-f45b-4d7f-8f5a-c9e69894a5b8",
            },
        },
        {
            "id": "eab540a0-a228-4bad-8552-4ff98317ae46",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344850037621318,
                    47.75486224626719,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 31,
                    "end": 32,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "51fb22b5-3847-4466-89ce-21c6deffe39a",
            },
        },
        {
            "id": "8f757515-046f-4d57-9e68-7b4715b7d8ee",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344842152852097,
                    47.75490716199641,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 36,
                    "end": 37,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "d2875126-10b9-4452-84eb-c92f0c7c029e",
            },
        },
        {
            "id": "2ba047db-d7bd-4afb-be61-65cd2feaac6e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34483584502714,
                    47.75494309457862,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 40,
                    "end": 41,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "cfd5fe47-4cb1-4b70-b5d4-31c997054a33",
            },
        },
        {
            "id": "83d47e43-faec-4051-90cb-7740a022d040",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344826383273744,
                    47.754996993449936,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 46,
                    "end": 47,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "c3b179bd-b436-493a-8e38-fe87ac537fa2",
            },
        },
        {
            "id": "741a560a-0306-4228-a84e-3c053df82117",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344818498464615,
                    47.75504190917421,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 51,
                    "end": 52,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "7c0745ca-de0b-4daf-83fa-88bdaea69e6d",
            },
        },
        {
            "id": "671b26c9-5190-406f-b448-6804cccabb18",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344810613642185,
                    47.75508682489684,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 56,
                    "end": 57,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "3d57809f-598d-4793-ba2c-cbb317f754d7",
            },
        },
        {
            "id": "455fb91a-e86e-4ccc-b6c4-4b8e8f3ba917",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344802728806454,
                    47.75513174061782,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 61,
                    "end": 62,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "6ed63482-64d5-476b-ab1f-d8b5c34abfe9",
            },
        },
        {
            "id": "46991148-60a3-4a2f-b82b-b6c995fdedb1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344796420928288,
                    47.75516767319342,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 65,
                    "end": 66,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "dd3893e2-b85d-4fa1-87d3-9d916113e909",
            },
        },
        {
            "id": "ce1203b9-2880-4ad8-9d97-9e2cc558bdbe",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344791690014079,
                    47.75519462262444,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 68,
                    "end": 69,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "5dfc794c-a4ff-41ae-b419-2c83c2c77e68",
            },
        },
        {
            "id": "fa282ad6-da13-4927-90a9-86a14257f730",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344756797431394,
                    47.75526266136553,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 687,
                    "end": 688,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "7e300523-b42d-43c2-af98-6d0298963c69",
            },
        },
        {
            "id": "6e2ba2a2-c6f8-4369-a5bf-51485d1214f9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344734061321827,
                    47.75530502613832,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 692,
                    "end": 693,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a7569f78-39bf-44fc-8809-def876d485e9",
            },
        },
        {
            "id": "87b691d2-6ac0-43d0-9650-806e55c1c9ba",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344715872408125,
                    47.755338917952656,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 696,
                    "end": 697,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "1e63b52b-57c0-4a78-8b74-12fd51a6e6bf",
            },
        },
        {
            "id": "10e809ff-a543-49dc-896b-5548bcdf64c4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344693136233439,
                    47.75538128271571,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 701,
                    "end": 702,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "17fcf28e-aa47-4aa7-aa00-46d3e105c676",
            },
        },
        {
            "id": "72ed41f2-6b9c-44d8-b357-948a1badf88b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34467040002257,
                    47.75542364747334,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 706,
                    "end": 707,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "25a1bebd-1621-4add-82f4-fc93ea1f3b16",
            },
        },
        {
            "id": "fd100559-c711-4ad7-9311-8854896f78a4",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344647663775526,
                    47.75546601222558,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 711,
                    "end": 712,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a18a234f-ae06-4e47-9b74-098bab6792c8",
            },
        },
        {
            "id": "4f7d6e5e-218e-4e15-8168-99be226c88e2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344624927492304,
                    47.755508376972394,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 716,
                    "end": 717,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "b2d408ac-1bc6-4ec5-9fed-bca1952e65a0",
            },
        },
        {
            "id": "a0a81988-7c7a-435b-979a-421805cd74bb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344597643904677,
                    47.75555921466144,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 722,
                    "end": 723,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "cb5b0b82-e34d-4bc0-956e-34234fe3e223",
            },
        },
        {
            "id": "d7356275-ae6f-492e-8b82-cc9475c4fe5b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344574907541861,
                    47.755601579396355,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 727,
                    "end": 728,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "81305e8a-01a4-4f74-92b7-5090692a657a",
            },
        },
        {
            "id": "68b0e3ac-9d5f-4115-8865-468627aa5162",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344556718425558,
                    47.75563547118039,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 731,
                    "end": 732,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "84e4b642-bdeb-438d-8c91-fc1f798ae0bd",
            },
        },
        {
            "id": "685d69ec-d0e0-42ad-8c0c-924c050926b2",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.34453398199762,
                    47.75567783590557,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 736,
                    "end": 737,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "e064e60e-b2d2-469f-9ce3-892a1a9dbf17",
            },
        },
        {
            "id": "4da5c22b-1d54-48e5-8dec-7656af1209f9",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344511245533502,
                    47.755720200625326,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 741,
                    "end": 742,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "d3702fa5-6152-494c-be8d-32f4739861be",
            },
        },
        {
            "id": "40f0b711-438b-48b7-b218-c7f01879cbb1",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344488509033204,
                    47.75576256533969,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 746,
                    "end": 747,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "4f801f35-fee6-4e99-9de2-6ebdd215dd59",
            },
        },
        {
            "id": "047b897f-dca9-4b17-bf7f-9792e5c91263",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344461225185094,
                    47.755813402989766,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 752,
                    "end": 753,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "9d430fdb-8bb8-4760-8ee2-9178bcb9c135",
            },
        },
        {
            "id": "26d49096-9f41-4a7c-aad9-d72c3e17da6e",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344438488605203,
                    47.75585576769222,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 757,
                    "end": 758,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "294baae3-d0bf-4ff4-9460-07901d6d93c2",
            },
        },
        {
            "id": "35b09971-9bbd-4586-8192-fc3f2a410601",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344415751989132,
                    47.75589813238926,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 762,
                    "end": 763,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "f26f0361-0584-4dab-8cab-6252de204e08",
            },
        },
        {
            "id": "f6ec5157-d289-4481-a7a7-6be80bff193f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344393015336882,
                    47.75594049708089,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 767,
                    "end": 768,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "119aa6c2-8bb8-4444-98fd-216312c99c2d",
            },
        },
        {
            "id": "0710438f-530e-4394-853c-c2a6e18400a5",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344374825989034,
                    47.7559743888303,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 771,
                    "end": 772,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "142494f4-2e06-4a59-80ea-6322880d4ea3",
            },
        },
        {
            "id": "9be3b047-d73a-4679-8a60-370650a9b3e7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344352286156239,
                    47.756016820318465,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 121,
                    "end": 122,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "e0909a30-46b2-4f5d-b0bd-b641dbe81da7",
            },
        },
        {
            "id": "acc222c2-f436-4863-8aae-83b5b36b3507",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344329830666685,
                    47.75605928043256,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 126,
                    "end": 127,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "1f99ccef-854a-4aa0-85e7-fee67cb45743",
            },
        },
        {
            "id": "3f26ea4f-fd2b-44d1-80b0-b213641f92b7",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344307375141319,
                    47.756101740541354,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 131,
                    "end": 132,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "65c8a2be-ed77-4b6e-aca1-7fc90f0698d5",
            },
        },
        {
            "id": "83e6956b-f8c2-4474-83cf-980ca34bf899",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344284919580141,
                    47.75614420064485,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 136,
                    "end": 137,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "4b9b09d1-6046-4edd-9607-ae92557ffe55",
            },
        },
        {
            "id": "e84b9563-d505-47ca-a615-da9c74cbcdfb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344257972859452,
                    47.75619515276202,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 142,
                    "end": 143,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "06012ab1-b204-4475-b7eb-bb67cdce9196",
            },
        },
        {
            "id": "ebb65611-f118-43fe-b742-e00f6e72716b",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344235517219482,
                    47.75623761285384,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 147,
                    "end": 148,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "eb80fda2-004d-4048-8ece-29cefd691128",
            },
        },
        {
            "id": "b87ff1f6-100e-4b19-9767-a5fe9e0bed87",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344217552681722,
                    47.75627158092347,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 151,
                    "end": 152,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a41063fc-4b10-4f5b-a837-272ee6ef793b",
            },
        },
        {
            "id": "5516bb04-fccf-4732-8052-fe9944a32e62",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344195096977289,
                    47.75631404100573,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 156,
                    "end": 157,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "0f373597-990c-4b32-9ea5-9de73969c7cd",
            },
        },
        {
            "id": "b85ee72b-6186-4df9-b022-b882fedb3a7f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344172641237046,
                    47.75635650108268,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 161,
                    "end": 162,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a569c0b6-335e-4fa5-b83f-515624e39e58",
            },
        },
        {
            "id": "f5b21dba-1077-446d-9bb4-efb5dba9c1cc",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344150185460989,
                    47.756398961154325,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 166,
                    "end": 167,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "fe53f85d-931e-4e22-95bf-b2acf52f9a04",
            },
        },
        {
            "id": "f15ee31d-7c58-4c33-a044-15c5aab14720",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344127729649117,
                    47.75644142122066,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 171,
                    "end": 172,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "f6cfa6ca-ffd8-4d9b-8c2c-68e1455b5f86",
            },
        },
        {
            "id": "b97a7d66-f6a3-4ab6-bb36-dd342a1b22ca",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344109764973836,
                    47.756475389269916,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 175,
                    "end": 176,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "338b5e72-ce1f-439b-aa80-25a42e570478",
            },
        },
        {
            "id": "fc1e2376-5962-4050-b2e4-25f4a607deb3",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344085701347633,
                    47.7565069941402,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 179,
                    "end": 180,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "70e8ece3-fba9-46a1-ab4a-ceae6768bfe7",
            },
        },
        {
            "id": "998a0dce-5222-4a69-ad52-118fd969595a",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344100782627597,
                    47.75649237329326,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 177,
                    "end": 178,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "6c64a5ac-3114-4da1-a9d7-e6192a163a97",
            },
        },
        {
            "id": "6ce23e26-0973-4101-9b70-d10ada8d2f30",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344051227647971,
                    47.756534565382445,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 183,
                    "end": 184,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "2f8912be-f59e-4103-b6ca-405a1b455f96",
            },
        },
        {
            "id": "6624b92f-846f-43fe-b4cf-d6f2ceafd25f",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.344008135473189,
                    47.75656902942036,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 188,
                    "end": 189,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "a468221b-6793-4fe6-b285-e2443eda7890",
            },
        },
        {
            "id": "27ceafdb-3cc6-4ee5-97bc-8b33244d2cbb",
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    7.343982280141544,
                    47.75658970783518,
                ],
            },
            "linearLocation": [
                {
                    "typeid": 0,
                    "road": "BOULEVARD DES ALLIES",
                    "start": 191,
                    "end": 192,
                },
            ],
            "properties": {
                "layer": "17f97507-7b4e-4707-ba0a-4f0d17ff5617",
                "photo_Uuid": "9c805451-460e-4618-b6ab-5369dda95000",
            },
        },
    ],
}

export const allImageCollections = {
    "c4d68142-b9b0-4ee6-8532-f4644acf46b6": imageCollectionMulhousePhoto,
    "17f97507-7b4e-4707-ba0a-4f0d17ff5617": imageCollectionMulhousePano,
}