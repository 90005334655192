import * as turf from "@turf/turf"

import { NOTATION_SCALE } from "../../../constants/getNotationConfig"

export function useWeightedAverageGrades(features) {
    if (!features?.length) {
        return null
    }

    const weightedGradesInfo = features.reduce((acc, feature) => {
        if (!feature.geometry || typeof (feature.data.gradeGlobal) !== "number") {
            return acc
        }
        const featureLength = turf.length(feature.geometry)
        return ({
            gradeGlobal: acc.gradeGlobal + feature.data.gradeGlobal * featureLength,
            gradeStructure: acc.gradeStructure + feature.data.gradeStructure * featureLength,
            gradeSurface: acc.gradeSurface + feature.data.gradeSurface * featureLength,
            totalLength: acc.totalLength + featureLength,
        })

    }, { gradeGlobal: null, gradeStructure: null, gradeSurface: null, totalLength: null })

    const {
        gradeGlobal,
        gradeStructure,
        gradeSurface,
        totalLength,
    } = weightedGradesInfo

    const clampGrade = (grade) => Math.min(Math.max(grade, 0), NOTATION_SCALE)

    const averageGrades = {
        gradeGlobal: totalLength ? clampGrade(gradeGlobal / totalLength) : null,
        gradeStructure: totalLength ? clampGrade(gradeStructure / totalLength) : null,
        gradeSurface: totalLength ? clampGrade(gradeSurface / totalLength) : null,
    }

    return averageGrades
}